import React, {lazy, Suspense } from "react";
import { Auth } from "aws-amplify";
import { toJS } from "mobx";
import { SignIn } from "aws-amplify-react";
import TextField from "@material-ui/core/TextField";
import { IconButton } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {
  googleSignInHandler,
} from "../../controllers/app-controller";
import { inject, observer } from "mobx-react";
import {
  textValue,
  textValueChanged,
} from "../../controllers/utility-controls";
import {handleLogin} from "../../controllers/app-controller"
import SafeComponentWrapper from "../../templates/safe-component-wrapper";
import { instanceProvider, isNullUndefined, toastProps } from "../../util/util";
import { validatePassword } from "../../util/formHelper";
import { AlertToast } from "../../components/_shared/alert-toast";
import {userService} from "../../services/user-service"
import Footer from "../../components/_shared/footer-module";
import Header from "../../components/_shared/header-module.js";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RouterLink } from "mobx-state-router"
import styles from "../../components/forms/logIn.module.css";

const PasswordStrengthBar = lazy(() => import("react-password-strength-bar"));

const PasswordVisibilityToggle = ({ isVisible, onToggle }) => (
  <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={onToggle}>
          {isVisible ? <VisibilityOffIcon /> : <Visibility />}
      </IconButton>
  </InputAdornment>
);
@inject("rootStore")
@observer
class ResetPassword extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.loginStore = this.props.rootStore.appStores.login;
    this.appStore = this.props.rootStore.appStores.app;
    this.routerStore = this.props.rootStore.routerStore
    this.routerState = this.routerStore.routerState;
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  static routeName = "reset-password";
  static routePathPattern = "/reset-password";

  state = {
    toastProps: instanceProvider(toastProps),
    showCodeArea: false,
    emailRequired: false,
    showPasswordOneInput: false,
    showPasswordTwoInput: false,
    getResetCodePressed: false,
    changePasswordPressed: false,
    hasEmailBeenExtractedFromLink: false,
    resetError: null,
    emailError: null,
    validationErrors: [],
    isVisible: false,
  };

  componentDidMount() {
    this.checkForQueryParamsFromResetLink()

    document.title = "Reset Password | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
      
  }

  handlePasswordChange = (event) => {
    const password = event.target.value;

    textValueChanged( this.loginStore, password, "passwordResetFirstPassword", this);
    const validationErrors = validatePassword(password);
    this.setState({ validationErrors });
    if (password.length > 0) {
      this.setState({isVisible: true});
    } else {
        this.setState({isVisible: false});
    }
  }

  validateEmailAndSendCode = (email) => {
    const regExpEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValidEmail = regExpEmail.test(String(email).toLowerCase());
    if (isValidEmail) {
      //send email with token to the address, then show them the input form for the code
      Auth.forgotPassword(email)
        .then((data) => {
          this.setState(
            { showCodeArea: true, getResetCodePressed: false },
            () => {
              const {
                message,
                activity,
                type,
                ...other
              } = this.state.toastProps;
              const newToastProps = {
                ...other,
                message: `Reset code sent to ${email}`,
                activity: this,
                type: "success",
              };
              this.setState(
                { ...this.state, toastProps: newToastProps },
                () => {
                  setTimeout(
                    () =>
                      this.setState({
                        toastProps: instanceProvider(toastProps),
                        emailError: null
                      }),
                    4000
                  );
                }
              );
            }
          );
        })
        .catch((err) => {
          const { message, activity, type, ...other } = this.state.toastProps;
          var errorMessage = err.code === "UserNotFoundException"
            ? "This user does not exist"
            :  err.message;

          var userLambdaValidationExceptionMessage = err.code.includes('UserLambdaValidationException') ? "Your account is awaiting approval by the admin": '';
          if (userLambdaValidationExceptionMessage.length){
            errorMessage = userLambdaValidationExceptionMessage;
            err.message = userLambdaValidationExceptionMessage;
          }

          const newToastProps = {
            ...other,
            message: errorMessage,
            activity: this,
            type: "error",
          };
          this.setState(
            {
              ...this.state,
              toastProps: newToastProps,
              getResetCodePressed: false,
              emailError: err
            });
          return;
        });
    } else {
      const { message, activity, type, ...other } = this.state.toastProps;
      const newToastProps = {
        ...other,
        message: "Your email seems invalid",
        activity: this,
        type: "error",
      };
      this.setState({ ...this.state, toastProps: newToastProps }, () => {
        setTimeout(
          () =>
            this.setState({
              toastProps: instanceProvider(toastProps),
              getResetCodePressed: false,
              emailError: {message: "Your email seems invalid"}
            }),
          1500
        );
      });
      return;
    }
  };
  getResetCode = () => {
    this.setState({emailError: null}, () => this.validateEmailAndSendCode(this.loginStore.passwordResetEmail))
  }
  validateFieldsAndPerformPasswordReset = async (
    pwdOne,
    pwdTwo,
    email,
    code
  ) => {
    this.setState({resetError: null, changePasswordPressed: true});

    if (this.state.emailRequired) {
      //if they already have a code, they need to enter an email
      const regExpEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const isValidEmail = regExpEmail.test(String(email).toLowerCase());
      if (!isValidEmail) {
        
        this.setState({resetError: "Invalid email", changePasswordPressed: false});

        return {error: true, message: "Invalid email"};
      }
    }

    if (code.length < 6) {
      // a code is 6 characters long
      this.setState({resetError: "Invalid code", changePasswordPressed: false})
      return {error: true, message: "Invalid code"};
    }

    //validate these two passwords before saving
    if (pwdOne.trim() !== pwdTwo.trim()) {
      this.setState({resetError: "Passwords do not match", changePasswordPressed: false})
      return {error: true, message: "Passwords do not match"};
    }

    // Validate password before proceeding to the next step
    const passwordErrors = validatePassword(pwdOne); // Get validation errors
    const hasErrors = passwordErrors.length > 0; 

    if (hasErrors) {
      this.setState({resetError: "Password does not meet requirements", changePasswordPressed: false});
      return { error: true, message:"Password does not meet requirements" };
    }

    //after validations
    return await Auth.forgotPasswordSubmit(email, code, pwdOne.trim())
      .then(async (data) => {

        let loggedIn = false;

        let approvalStatus = await userService.getUserApprovalByAdminStatus(email.trim());

        if(approvalStatus === "approved"){
          try {
            await Auth.signIn(email, pwdOne.trim())
            loggedIn = true;
          }catch(e){}
        }

        this.setState({changePasswordPressed: false, emailError: null})

        this.props.rootStore.alertStore.setAlert({ type: "alert-success", message: "Password changed successfully", onDismiss: () => {
          this.props.rootStore.alertStore.clearAlert();
          if(loggedIn){
            this.routerStore.goTo("home");
          }else{
            this.setState({ showCodeArea: false, emailRequired: false }, () => {
              this.props.goBackToLogin()
            })
          }
        }})

        return {error: false, message: "Success"};
      })
      .catch((err) => {
        this.setState({resetError: err.message, changePasswordPressed: false})
        return {error: true, message: err.message};
      });
  };
  closeToast = () => {
    this.setState({ toastProps: instanceProvider(toastProps) })
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  checkForQueryParamsFromResetLink = () => {
    if (this.areQueryParamsValid()) {
      let email = this.routerState.queryParams.email;
      if(this.hasWhiteSpace(this.routerState.queryParams.email)){
        email = this.routerState.queryParams.email.replace(/\s+/g, "+") // replace spaces with + signs to avoid errors for emails with + in them
      }
      this.setState({ showCodeArea: true, emailRequired: true }, () => {
        this.setState({ hasEmailBeenExtractedFromLink: true })
        textValueChanged(
          this.loginStore,
          email,
          "passwordResetEmail",
          this
        );
        textValueChanged(
          this.loginStore,
          this.routerState.queryParams.code,
          "passwordResetCode",
          this
        );

      })
    }

    else {
      if (!this.props.fromLogin) {
        window.location.href = "/"
      }
    }
  }

  areQueryParamsValid = () => {
    if (Object.keys(this.routerState.queryParams).length > 1) {
      const { code, email } = this.routerState.queryParams;
      if (code && email) {
        if (code.length > 5 && email.length > 1) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }

togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPasswordOneInput: !prevState.showPasswordOneInput,
    }));
}

  render() {
    const {
      rootStore: { authStore },
    } = this.props;

    const { validationErrors, isVisible } = this.state;


    return (
      <SafeComponentWrapper>
        <div className={styles.log_in_wrap}>
        {this.areQueryParamsValid() && <Header />}
        <div className={this.state.show ? `${styles.show}` : "log-in-card"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <div className={`${styles.logIn_card} reset_password`}>
                  <div className={"row"}>
                    <div className={"col-12"}>
                      <div
                        style={{
                          border: "1px solid #1a0a60",
                          padding: "5px",
                          borderRadius: "5px",
                          marginBottom: "10px",
                          marginTop: "60px",
                        }}
                        >
                          <p className="mb-1 mt-1">
                            <strong>
                              This website is intended for use by Healthcare
                              Professionals only.
                            </strong>
                          </p>
                          <p className="mt-1">
                            {" "}
                            <strong>
                              This promotional meeting is organised and funded
                              by Teva Pharmaceuticals Europe B.V.
                            </strong>
                          </p>
                          <p  >
                            Some sessions may contain product information.{" "}
                            <br />
                            For the fremanezumab{" "}
                             UK
                            prescribing information and adverse events
                            reporting, please see footer of the page.
                            <br />
                          </p>
                          </div>
                        <h3 className="">
                        {!this.state.showCodeArea
                          ? "Enter email to reset password."
                          : "Enter and confirm your new password."}
                      </h3>
                    </div>
                  </div>

                  {this.state.emailError && (
                    <div className="error">
                      <p>{this.state.emailError.message || this.state.emailError}</p>
                    </div>
                  )}

                  <form
                    id="form1"
                    className="form row"
                    style={{ margin: "30px auto", maxWidth: "600px", alignSelf: 'center', }}
                  >
                    {!this.state.showCodeArea && (
                      <div className={"col-12"}>
                        <TextField
                          id="passwordResetEmail"
                          key="passwordResetEmail"
                          name="passwordResetEmail"
                          label="Email"
                          type="text"
                          autoComplete="on"
                          variant="outlined"
                          style={{
                            width: "100%",
                            minWidth: "100%",
                            marginBottom: "20px",
                            textTransform: "lowercase"
                          }}
                          value={textValue(
                            this.loginStore,
                            "passwordResetEmail",
                            this
                          )}
                          onChange={(event) => {
                            textValueChanged(
                              this.loginStore,
                              event.target.value.toLowerCase(),
                              "passwordResetEmail",
                              this
                            );
                          }}
                        />
                        <i className="icon mail outline"></i>
                      </div>
                    )}

                    {this.state.showCodeArea && (
                      <React.Fragment>
                        <div className={"col-12 reset-code-div"}>
                          {this.state.emailRequired && (
                            <TextField
                              id="passwordResetEmail"
                              key="passwordResetEmail"
                              name="passwordResetEmail"
                              label="Email"
                              type="text"
                              autoComplete="on"
                              variant="outlined"
                              style={{
                                width: "100%",
                                minWidth: "100%",
                                marginBottom: "20px",
                                textTransform: "lowercase"
                              }}
                              value={textValue(
                                this.loginStore,
                                "passwordResetEmail",
                                this
                              )}
                              onChange={(event) => {
                                if (this.routerState.queryParams.email) {
                                  return
                                }
                                textValueChanged(
                                  this.loginStore,
                                  event.target.value.toLowerCase(),
                                  "passwordResetEmail",
                                  this
                                );
                              }}
                            />
                          )}

                          <TextField
                            id="passwordResetCode"
                            key="passwordResetCode"
                            name="passwordResetCode"
                            label="Reset code"
                            type="number"
                            autoComplete="on"
                            variant="outlined"
                            style={{
                              width: "100%",
                              minWidth: "100%",
                              marginBottom: "20px",
                            }}
                            value={textValue(
                              this.loginStore,
                              "passwordResetCode",
                              this
                            )}
                            onChange={(event) => {
                              if (this.routerState.queryParams.code) {
                                return
                              }
                              textValueChanged(
                                this.loginStore,
                                event.target.value,
                                "passwordResetCode",
                                this
                              );
                            }}
                          />
                          <i className="icon mail outline"></i>
                        </div>
                        
                        <div className={"col-12 password-textfield"}>
                        <div className="error-div">
                            {validationErrors?.length > 0 && validationErrors.map((error, index) => (
                                <span key={index} className="error_messag">
                                    {error}
                                </span>
                            ))}
                        </div>
                          <TextField
                            id="passwordResetFirstPassword"
                            key="passwordResetFirstPassword"
                            name="passwordResetFirstPassword"
                            label="Password"
                            type={
                              this.state.showPasswordOneInput
                                ? "text"
                                : "password"
                            }
                            autoComplete="on"
                            variant="outlined"
                            style={{
                              width: "100%",
                              minWidth: "100%",
                              marginBottom: "20px",
                            }}
                            value={textValue(
                              this.loginStore,
                              "passwordResetFirstPassword",
                              this
                            )}
                            onChange={this.handlePasswordChange}
                            required={{ minLength: 12 }}
                            InputProps={{
                              endAdornment:(
                                  <PasswordVisibilityToggle
                                      isVisible={this.state.showPasswordOneInput}
                                      onToggle={this.togglePasswordVisibility}
                                  />
                              )
                            }}
                          />
                          {isVisible && (
                              <Suspense fallback={<div>Loading password strength...</div>}>
                                <PasswordStrengthBar
                                    className='password-strength-bar'
                                    password={textValue(
                                      this.loginStore,
                                      "passwordResetFirstPassword",
                                      this
                                    )}
                                    minLength={12}
                                />
                            </Suspense>
                          )}
                          {/* {this.state.showPasswordOneInput ? (
                            <VisibilityOffIcon
                              className="view_pw"
                              onClick={() => {
                                this.setState({
                                  showPasswordOneInput: false,
                                });
                              }}
                            />
                          ) : (
                              <Visibility
                                className="view_pw"
                                onClick={() => {
                                  this.setState({
                                    showPasswordOneInput: true,
                                  });
                                }}
                              />
                            )} */}
                        </div>

                        <div className={"col-12"} style={{ marginTop: "10px" }}>
                          <TextField
                            id="passwordResetSecondPassword"
                            key="passwordResetSecondPassword"
                            name="passwordResetSecondPassword"
                            label="Confirm password"
                            type={
                              this.state.showPasswordTwoInput
                                ? "text"
                                : "password"
                            }
                            autoComplete="on"
                            variant="outlined"
                            style={{
                              width: "100%",
                              minWidth: "100%",
                              marginBottom: "20px",
                            }}
                            value={textValue(
                              this.loginStore,
                              "passwordResetSecondPassword",
                              this
                            )}
                            onChange={(event) => {
                              textValueChanged(
                                this.loginStore,
                                event.target.value,
                                "passwordResetSecondPassword",
                                this
                              );
                            }}
                          />
                          {this.state.showPasswordTwoInput ? (
                            <VisibilityOffIcon
                              className="view_pw"
                              onClick={() => {
                                this.setState({
                                  showPasswordTwoInput: false,
                                });
                              }}
                            />
                          ) : (
                              <Visibility
                                className="view_pw"
                                onClick={() => {
                                  this.setState({
                                    showPasswordTwoInput: true,
                                  });
                                }}
                              />
                            )}
                        </div>
                      </React.Fragment>
                    )}

                    <div className={"col-12"}>
                      <div className="forgot_pw">
                        {!this.state.showCodeArea && (
                          <span
                            style={{
                              margin: "10px 0 10px 0",
                              display: "block",
                              textAlign: "right",
                            }}
                          >
                            <a
                              href="#"
                              onClick={() => {
                                this.setState({
                                  showCodeArea: true,
                                  emailRequired: true,
                                });
                              }}
                            >
                              I already have a code
                            </a>
                          </span>
                        )}
                        <span
                          style={{
                            margin: "10px 0 10px 0",
                            display: "block",
                            textAlign: "right",
                          }}
                        >
                          {!this.areQueryParamsValid() ? (<a
                            href="#"
                            onClick={() => {
                              this.setState({ showCodeArea: false, emailRequired: false }, () => {
                                this.props.goBackToLogin()
                              })
                            }}
                          >
                            Log in instead
                          </a>) : (<RouterLink routeName="home">Return to home</RouterLink>)}
                        </span>
                      </div>
                    </div>
                    <div className={"col-12"}>
                      {!this.state.showCodeArea &&
                        !this.state.getResetCodePressed && (
                          <button
                          className="button"
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: "10px" }}
                            onClick={() => {
                              this.setState({ getResetCodePressed: true, emailError: null });
                              this.validateEmailAndSendCode(
                                this.loginStore.passwordResetEmail
                              );
                            }}
                          >
                            <span>Get reset code</span>
                          </button>
                        )}

                      {!this.state.showCodeArea &&
                        this.state.getResetCodePressed && (
                          <button
                          className="button"
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: "10px" }}
                          >
                            <span className="mr-2">Sending code</span>
                            <CircularProgress
                              size={16}
                              style={{ color: "white" }}
                            />
                          </button>
                        )}

                        {this.state.resetError && <div className={"error mb-3"}>
                          {this.state.resetError}
                        </div>}

                      {this.state.showCodeArea &&
                        !this.state.changePasswordPressed && (
                          <button
                          className="button"
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: "10px" }}
                            onClick={async (e) => {
                              e.preventDefault();
                              const didPasswordResetSucceed = await this.validateFieldsAndPerformPasswordReset(
                                this.loginStore.passwordResetFirstPassword,
                                this.loginStore.passwordResetSecondPassword,
                                this.loginStore.passwordResetEmail,
                                this.loginStore.passwordResetCode
                              );
                            }}
                          >
                            <span>Change password</span>
                          </button>
                        )}
                      {this.state.showCodeArea &&
                        this.state.changePasswordPressed && (
                          <button
                          className="button"
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: "10px" }}
                          >
                            <span className="mr-2">Updating</span>
                            <CircularProgress
                              size={16}
                              style={{ color: "white" }}
                            />
                          </button>
                        )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isNullUndefined(this.state.toastProps) &&
          this.state.toastProps.message &&
          <AlertToast
            message={this.state.toastProps.message}
            type={this.state.toastProps.type}
            duration={this.state.toastProps.duration}
            position={this.state.toastProps.position}
            activity={this}
            errorCode={this.state.toastProps.errorCode}
            handleCloseToast={this.closeToast}
            getResetCode={this.getResetCode}
          />
        }
        {this.areQueryParamsValid() && <Footer />}
        </div>
      </SafeComponentWrapper>
    );
  }
}

export default ResetPassword;
