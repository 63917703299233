import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/Tim.png"
class Modal extends React.Component {
    constructor (props){
        super(props);
        
    }
    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className={`modal-body ${styles.modalbody}`}>
                       <div className="row">
                           <div className="col-12 col-sm-12 col-md-4 text-center">
                                <img alt="Hans photo" src={ProfilePhoto}/>
                           </div>
                           <div className="col-12 col-sm-12 col-md-8">
                                    <h3>Tim Jürgens,  MD, PhD, PD
</h3>
                               <p style={{color: "#f61a19"}}>Germany</p>
                               <p>
                                        After his medical studies at the University of Düsseldorf, Tim Jürgens specialised in clinical neurology at the Dept. of Neurology, University of Regensburg. He then joined Prof. Arne May's group at the University Medical Center Hamburg-Eppendorf where he obtained his Habilitation and became a board-certified pain specialist. At present, he is consultant at the Dept. of Neurology, University Medical Center Rostock, Germany (Director: Prof. Dr. Alexander Storch) and head of the Headache Center North-East. Additionally, he is vice-president of the German Migraine and Headache Society, Visiting Editor at The Journal of Headache and Pain and Associate Editor at Frontiers of Neurology (Section Headache Medicine and Facial Pain). His research interests cover neurophysiology and pain, neuromodulation in headache and pain, trigeminal autonomic cephalgias, human models of pain and facial pain.
                               </p>

                   
                           </div>
                       </div>
                      
                  </div>
                  <div className={`modal-footer ${styles.modal_footer}`}>
                    <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                </div>
                  
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;