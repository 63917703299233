import React from "react";
import { RouterLink } from "mobx-state-router";
import { inject, observer } from "mobx-react";
import styles from "../../components/forms/logIn.module.css";
import Branding3 from "../../media/images/branding3.png";
@inject("rootStore")
@observer
class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title =
      "Thank You | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
  }
  render() {
    return (
      <div className={styles.thankyou_wrap}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  border: "1px solid #fff",
                  padding: "10px 5px 5px",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                <p className="mb-1 mt-1">
                  <strong>
                    This website is intended for use by Healthcare Professionals
                    only.
                  </strong>
                </p>
                <p>
                  {" "}
                  <strong>
                    This promotional meeting is organised and funded by Teva
                    Pharmaceuticals Europe B.V.
                  </strong>
                </p>
                <p  >
                  Some sessions may contain product information. <br />
                  For the fremanezumab UK
                  prescribing information and adverse events reporting, please
                  see footer of the page.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2>Thank you</h2>
        <p>
          <strong>
            You have registered for Migraine Headache Management 2024: Patients, Treatments, Choices
          </strong>
        </p>
        <p>You will receive email confirmation of your registration shortly.</p>
        <RouterLink routeName="home">
          <button className="button red">GO TO HOMEPAGE</button>{" "}
        </RouterLink>
        <div className={styles.curve3}>
          <img alt="Branding 3 image" src={Branding3} />
        </div>
      </div>
    );
  }
}

export default ThankYou;
