import React from 'react';

import moment from "moment"
import {inject, observer} from "mobx-react"
import styles from "../home/workshopManagementSection/workshopManagement.module.css";

import {data} from "../../../data/data"

import Radio from '@material-ui/core/Radio'


@inject("rootStore")
@observer
class EventTimes extends React.Component {
    constructor(props) {
        super(props);
        this.eventsType = props.eventsType;
        this.state = { 
            selectedEvent: null,
            agendaModal : false,
            DagnyPoup: false,
            PeterPoup: false,
            MessoudPoup: false,
            PatriciaPoup: false,
        }
    }

    
openModal = (type) => {
    this.disableScroll()
    switch(type){
        case "agendaModal": 
        this.setState({
            agendaModal: true,

        })
        break;
        case "DagnyPoup":
            this.setState({
                
                DagnyPoup:true

            })
            break;
        case "PeterPoup":
            this.setState({
                
                PeterPoup:true

            })
            break;

        case "MessoudPoup":
            this.setState({
                
                MessoudPoup:true

            })
            break;

        case "PatriciaPoup":
            this.setState({
                
                PatriciaPoup:true

            })
            break;
        
        default: 
             return 
    }
 
   
}


closeModal = (type) => {
    this.enableScroll()
    switch(type){
        case "agendaModal": 
        this.setState({
            agendaModal: false,

        })
        break;
        case "DagnyPoup":
            this.setState({
                
                DagnyPoup:false

            })
            break;
            case "PeterPoup":
                this.setState({
                    
                    PeterPoup:false
    
                })
                break;
    
            case "MessoudPoup":
                this.setState({
                    
                    MessoudPoup:false
    
                })
                break;
    
            case "PatriciaPoup":
                this.setState({
                    
                    PatriciaPoup:false
    
                })
                break; 
        
        default: 
             return 
    }
 
   
}
disableScroll = () => {
    document.body.style.overflow = 'hidden'
}
enableScroll = () => {
  
    document.body.style.overflow = 'auto'
}

getEventTimeSections = (event, speaker,eventsType, firstEvent, secondEvent, index) => {
        var eventTimes = event.eventTimes.filter((i)=> i.speakerName === speaker);
        return (
            //TODO: Revert back to col-md-12 when speaker is re-introduced
        <div  className={`col-12 col-sm-12 col-md-6 ${styles.slotsMT}`} key={`${index}`}>
        <div className='row'>
                    {/* //TODO: Remove display none when speaker is re-introduced */}
                    <div className={`col-12 col-sm-12 col-md-6 ${styles.speaker_text} `} style={{ display: "none" }}>
                       
                <p style={{ color: '#f89410', paddingLeft: '40px' }}>{speaker}</p>
                
            </div>
            {/* //TODO: Revert back to col-md-6 when speaker is re-introduced */}
            <div  className="col-12 col-sm-12 col-md-12 text-center">
                <div className='row'>
                    {eventTimes.length > 0 ? eventTimes.map((item, index )=> {
                        let isFullyBooked = parseInt(item.eventLimit) === -1 ? false : item.registeredUsers.length >= parseInt(item.eventLimit);
                        let hasBooked = this.props.user ? item.registeredUsers.includes(this.props.user.username) : false;

                        let type = eventsType === "Interactive workshops" ? "InteractiveWorkshop" : "QuestionClinic" ;
                        let when = item.time.includes("10:00") || item.time.includes("15:15") ? "first" : "second";
                        let name = `${when}${type}`;

                        if(item?.statusId === data.status.deleted){
                            return <React.Fragment key={index} />
                        }
                        return (
                        
                            
                            <div key={index} className="col-12 col-sm-12 col-md-6 text-center m-text-left"> 

                                <div style={{marginBottom: '10px'}} className={isFullyBooked ? "fully_booked" : " "}>
                                <Radio checked={item.id === (when === 'first'? firstEvent : secondEvent)} value={item.id} name={name} inputProps={{disabled: isFullyBooked}} onChange = {async () => {
                                        await this.props.updateSelectedEvent(name, item.id)
                                    }} /> <span className='hide-for-large'>{item.time}</span>
                                </div>
                                        {isFullyBooked  ? 
                                    <> 
                                        <h6 className={styles.red}>Fully booked </h6>
                                        </> 
                                        :
                                    <></>                            
                            }
                            </div>
                            
                        ) 
                            }) : <div className={`col-12 col-sm-12 col-md-6 col-lg-3 ${styles.date_section_item}`}>There are no times for this event</div>
                    }
                    </div>
                </div>
            </div>
        </div>
        )
    }

    render() {  
        const {allEvents,eventsType,secondEvent, firstEvent} =  this.props;      
        return (
            <>
                <div className="row hide-for-small" >
                    <div className="col-12 col-sm-12 col-md-6 mt-4">

                    </div>
                    <div className="col-12 col-sm-12 col-md-3 mt-4 text-center">
                        <h5 className='hide-for-small' style={{ color: "#f89410"}}>{ eventsType === data.eventTypes[0].id ? '15:15–15:45 CEST' :'10:00–10:30 CEST'}</h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 mt-4 text-center">
                            <h5 className='hide-for-small' style={{ color: "#f89410"}}>{ eventsType === data.eventTypes[0].id ? '16:00–16:30 CEST' : '10:45–11:15 CEST'}</h5>
                    </div>
                </div>
                {allEvents && allEvents.length &&
                    allEvents?.map((event, index )=>{
                        const distinct = (value, index, self) => {
                            return self.indexOf(value) === index;
                        }

                        var speakers = event.eventTimes.map(i => i.speakerName);
                        speakers = speakers.filter(distinct);
                        
                        return (
                            <div className="row" key={index}>
                                {/* //TODO: Revert back to col-md-6 when speaker is re-introduced */}
                                <div className="col-12 col-sm-12 col-md-6 mt-4"> 
                                    {/* <h3 style={{ color: "#fff", marginBottom:'30px' }}>{event.title}</h3> */}
                                    <div className='h3' dangerouslySetInnerHTML={{__html: `${event.title}`}}></div>
                                </div>
                                {speakers.map((speaker, index) => {
                                    return this.getEventTimeSections(event, speaker, eventsType, firstEvent, secondEvent, index)
                                    })
                                }
                            </div>
                    )})
            
                }
            </>

        );
    }
}
 
export default EventTimes;