import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/lambru_purple.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="Hans photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Giorgio Lambru, MD, PhD</h3>
                  <p style={{ color: "#f61a19" }}>London, UK</p>
                  <p>
                    Dr Giorgio Lambru is a Consultant Neurologist at Guy’s and
                    St Thomas’ NHS Foundation Trust and a Reader at King’s
                    College London, UK.
                  </p>
                  <p>
                    Dr Lambru gained his degree in Medicine and Surgery at the
                    University of Milan in 2004 and trained in Neurology at the
                    Department of Neuroscience at the University Hospital of
                    Parma. In 2010 he undertook a clinical fellowship within the
                    Headache group at the National Hospital for Neurology and
                    Neurosurgery and subsequently completed his PhD at the
                    Institute of Neurology UCL, London.{" "}
                  </p>
                  <p>
                    At St Thomas’ Hospital he set up one of the largest
                    dedicated National Headache and Facial Pain Services for the
                    diagnosis and treatment of complex headache and facial pain
                    disorders in the UK. He pioneered several multidisciplinary
                    services in collaboration with the Neuromodulation and
                    Neurosurgical Teams within King’s Health Partner including
                    the fast-track cluster headache clinic, the complex facial
                    pain clinic and the invasive and non-invasive
                    neuromodulation clinics for refractory headaches.{" "}
                  </p>
                  <p>
                    Dr Lambru is involved in clinical research aiming to advance
                    the treatment of headache and facial pain disorders and the
                    understanding of their underlying mechanisms. He is involved
                    in preclinical headache research in collaboration with the
                    Headache laboratory within the Wolfson CARD – King’s College
                    London.{" "}
                  </p>
                  <p>
                    He runs clinical trials in new treatments for headache and
                    facial pain conditions, including the CGRP treatments, as
                    site Principal Investigator and Chief Investigator for the
                    UK.{" "}
                  </p>
                  <p>
                    He has authored several scientific publications and he
                    regularly presents at national and international
                    conferences. He is also Co-chair of the Trigeminal Neuralgia
                    Association (TNA) UK and a member of the Council of the
                    British Association for the Study of Headache (BASH) UK.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
