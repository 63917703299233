import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { AdminHeader } from "../../components/admin/admin-header";
import AdminNav from "../../components/admin/admin-nav";
import Footer from "../../components/_shared/footer-module";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";
import { contentService } from "../../services/content-service";

import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { data } from "../../data/data";
import ContentTable from "../../components/admin/content-table";
import UsersTable from "../../components/admin/users-table";
import { RouterLink } from "mobx-state-router"
import MassEmails  from '../../components/admin/mass-emails/mass-emails-table';

@inject("rootStore")
@observer
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.authStore = this.props.rootStore.authStore;
    this.routerStore = this.props.rootStore.routerStore;
    this.state = {

      selectedContentNode: data.contentTypes[this.getActiveNodeNumber()],
      content: {},
      contentToEdit: {
        contentId: "",
        content: "",
        meetingCategory: "",
      },
      showContentForm: false,
      nextToken: "",

    };
  }

  static routeName = "admin";
  static routePathPattern = "/admin/:id";

  componentDidMount(){
    let role = this.authStore.getRole();
    if(role !== ADMIN_USER_ROLE && role !== SUPER_ADMIN_USER_ROLE){
      this.routerStore.goTo("home");
      return;
    }
  }


  getActiveNodeNumber() {
    let { routerStore } = this.props.rootStore;
    let role = this.authStore.getRole()

    let pathName = routerStore.routerState.params.id;
    return (data.contentTypes.filter(x => x.path === pathName)[0]
      ? parseInt(data.contentTypes.filter(x => x.path === pathName)[0].id)
      : role == SUPER_ADMIN_USER_ROLE
        ? 1
        : 0
    );
  }

  render() {
    const { selectedContentNode } = this.state;

    if (this.state.selectedContentNode.id != this.getActiveNodeNumber()) {
      this.setState({
        selectedContentNode: data.contentTypes[this.getActiveNodeNumber()]
      });
    }

    return (
      <div>
        <div className="admin header">
          <span className={`${this.state.isActive ? `${"active"}` : ""}   `} id="menu-action" >  <ViewHeadlineIcon />  </span>
          <div className="">
            <AdminHeader authStore={this.authStore} />
          </div>
          
        </div>
        <AdminNav selectedContentNode={selectedContentNode}/>

        <div>
          <div className={`${this.state.isActive ? `${"active"}` : ""} main  `}>
            <>
              {selectedContentNode.id === 0 && (
                <div className="row" style={{ minHeight: "85vh" }}>
                  <div className="col-12 col-sm-12">
                    <UsersTable />
                  </div>
                </div>
              )}

              {selectedContentNode.id === 1 && (
                <div className="row" style={{ minHeight: "85vh" }}>
                  <div className="col-12 col-sm-12">

                    <ContentTable selectedContentNode={selectedContentNode} />

                  </div>
                </div>
              )}
              {selectedContentNode.id === 2 && (
                <div className="row" style={{ minHeight: "85vh" }}>
                  <div className="col-12 col-sm-12">
                    <MassEmails selectedContentNode={selectedContentNode} />
                  </div>
                </div>
              )}
            </>
            <div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
