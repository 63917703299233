import React from 'react';
import styles from "../migraineExpertForum/modal.module.css"

class Modal extends React.Component {
    constructor (props){
        super(props);
        
    }
    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>

                        <div className="modal-body">
                            <h3>
                                Day 2 – Saturday 5 October, 09:00 – 13:00 CEST
                            </h3>
                            {/* MOBILE LAYOUT  */}
                          
                            <div className="hide_large">
                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td> <strong>09:00–09:10</strong> </td>
                                    </tr>
                                    <tr>
                                        <th> Session and description </th>
                                        <td> <strong>Welcome to Day 2</strong><br/></td>
                                        
                                    </tr>

                                    <tr>
                                        <th>Faculty</th>
                                        <td>Patricia Pozo-Rosich</td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td> <strong>09:10–09:55</strong> </td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td> <strong>Plenary 5 – Practical perspectives in migraine management with CGRP pathway targeting treatments</strong><br /><br />
                                        <ul style={{ margin: "0", padding: "0 15px" }}>
                                            <li>Treatment persistence and outcomes</li>
                                            <li>Discontinuing, pausing, switching and repeating </li>
                                            <li>Managing patients with migraine and medication overuse </li>
                                        </ul>
                                        </td>

                                         
                                    </tr>
                                    <tr>
                                        <th>Faculty</th>
                                        <td>Case 1: Cristina Tassorelli <br/>
                                            Case 2: Peter J Goadsby <br/>
                                            Case 3: Patricia Pozo-Rosich <br/>
                                        </td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td> <strong>10:00–10:30</strong> </td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td> <strong>Question clinic 1  </strong> (choose from 6 options)   <br /><br />
                                        <ol style={{ margin: "0", padding: "0 15px" }}>
                                            <li>Practical migraine management with CGRP pathway targeting treatments </li>
                                            <li>Treatment differences and patient preferences </li>
                                            <li>Long-term management of migraine </li>
                                            <li>Treating migraine in patients with comorbidities</li>
                                            <li>Clinical tools for practical migraine management</li>
                                            <li>Beyond typical migraine: approaches to specific migraine disorders</li>
                                        </ol> 	
                                        </td>


                                    </tr>
                                    <tr>
                                        <th>Faculty</th>
                                        <td>
                                        <ol style={{ margin: "0", padding: "0 15px" }}>
                                            <li> Piero Barbanti + <br/>
                                            Roberto De Icco
                                            </li>
                                            <li>Antonio Russo + <br/>
                                            Isabel Pavão Martins
                                            </li>
                                            <li>Patricia Pozo-Rosich + <br/> Edoardo Caronna </li>
                                            <li>Francesca Puledda + <br/>
                                            Gisela Terwindt</li>
                                            <li>Cristina Tassorelli + <br/> Andreas Gantenbein</li>
                                            <li> Peter J Goadsby + <br/>
                                            Uwe Reuter</li>
                                        </ol>
                                        </td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td><strong>10:30–10:45</strong></td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td> <strong><i>Break</i></strong></td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td> <strong>10:45–11:45</strong> </td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td><strong>Question clinic II </strong>(second opportunity to attend a question clinic)</td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td> <strong>11:20–12:00</strong> </td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td><strong>Debate – Are CGRP pathway mAbs disease-modifying treatments?</strong></td>
                                    </tr>
                                    <tr>
                                        <th>Faculty</th>
                                        <td>Moderator: Piero Barbanti <br/>
                                            Debaters: Messoud Ashina + <br/>Uwe Reuter
                                        </td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td><strong>12:00–12:15</strong></td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td><strong>Plenary 6 – 2024 in the spotlight: What is new in migraine research</strong><br /></td>
                                        
                                    </tr>
                                    <tr>
                                        <th>Faculty</th>
                                        <td>Patricia Pozo-Rosich + <br/> Messoud Ashina</td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td><strong>12:15–12:50</strong></td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td> <strong>Townhall discussion</strong>
                                        </td>

                                    </tr>
                                    <tr>
                                        <th>Faculty</th>
                                        <td>
                                            Moderator: Patricia Pozo-Rosich
                                            <br/> <br/>
                                            Panel: Andreas Gantenbein, <br/>
                                            Isabel Pavão Martins, <br/> Francesca Puledda, <br/> Bianca Raffaelli, <br/> Uwe Reuter, <br/> Roberto De Icco
                                        </td>
                                    </tr>
                                </table>

                                <table className="table table-sm table-bordered ">
                                    <tr>
                                        <th> Time (CEST) </th>
                                        <td><strong>12:50–13:00</strong></td>
                                    </tr>
                                    <tr>
                                        <th> Topic </th>
                                        <td>Meeting close </td>
                                        
                                    </tr>
                                    <tr>
                                        <th>Faculty</th>
                                        <td>Peter J Goadsby</td>
                                    </tr>
                                </table>


                            </div>

                            { /* END MOBILE LAYOUT */}
                            {/* DESKTOP VIEW */}
                            <div className="hide_small">
                                <table className="table table-sm table-bordered " >
                                    <thead>
                                        <tr>
                                            <th style={{ width: "55px" }}>Time (CEST)</th>
                                            <th style={{ width: "300px" }} scope="col">Session and description</th>
                                            <th style={{ width: "50px" }}>Faculty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><strong>09:00–09:10</strong></td>
                                            <td><strong>Welcome to Day 2</strong><br/></td>
                                            <td>Patricia Pozo-Rosich</td>
                                        </tr>
                                        <tr>
                                            <td><strong>09:10–09:55</strong></td>
                                            <td>
                                                <strong>Plenary 5 – Practical perspectives in migraine management with CGRP pathway targeting treatments</strong><br /><br />
                                                <ul style={{ margin: "0", padding: "0 15px" }}>
                                                    <li>Treatment persistence and outcomes </li>
                                                    <li>Discontinuing, pausing, switching and repeating </li>
                                                    <li>Managing patients with migraine and medication overuse </li>
                                                </ul> 
                                            </td>
                                            <td>Case 1: Cristina Tassorelli <br/>
                                                Case 2: Peter J Goadsby <br/>
                                                Case 3: Patricia Pozo-Rosich <br/>
                                            </td>
                                            
                                        </tr>
                                        <tr>
                                            <td><strong>10:00–10:30</strong></td>
                                            <td><strong>Question clinic 1 </strong> (choose from 6 options) <br /><br />
                                                <ol style={{ margin: "0", padding: "0 15px" }}>
                                                    <li>Practical migraine management with CGRP pathway targeting treatments </li>
                                                    <li>Treatment differences and patient preferences </li>
                                                    <li>Long-term management of migraine </li>
                                                    <li>Treating migraine in patients with comorbidities</li>
                                                    <li>Clinical tools for practical migraine management</li>
                                                    <li>Beyond typical migraine: approaches to specific migraine disorders</li>
                                                </ol> 	
                                            </td>
                                            <td>
                                        <ol style={{ margin: "0", padding: "0 15px" }}>
                                            <li> Piero Barbanti + 
                                            Roberto De Icco
                                            </li>
                                            <li>Antonio Russo + 
                                            Isabel Pavão Martins
                                            </li>
                                            <li>Patricia Pozo-Rosich +  Edoardo Caronna </li>
                                            <li>Francesca Puledda +
                                            Gisela Terwindt</li>
                                            <li>Cristina Tassorelli + Andreas Gantenbein</li>
                                            <li> Peter J Goadsby + 
                                            Uwe Reuter</li>
                                        </ol>
                                        </td>
                                        </tr>
                                        <tr>
                                            <td><strong>10:30–10:45</strong></td>
                                            <td> <strong><i>Break</i></strong></td>
                                        </tr>
                                        <tr>
                                            <td><strong>10:45–11:15</strong></td>
                                            <td><strong>Question clinic II </strong>(second opportunity to attend a question clinic)</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td><strong>11:20–12:00</strong></td>
                                            <td><strong>Debate – Are CGRP pathway mAbs disease-modifying treatments?</strong></td>
                                            <td>Moderator: Piero Barbanti <br/>
                                            Debaters: Messoud Ashina + Uwe Reuter
                                        </td>
                                        </tr>
                                        <tr>
                                            <td><strong>12:00–12:15</strong></td>
                                            <td><strong>Plenary 6 – 2024 in the spotlight: What is new in migraine research</strong><br /></td>
                                            <td>Patricia Pozo-Rosich + Messoud Ashina</td>
                                        </tr>
                                        <tr>
                                            <td><strong>12:15–12:50</strong></td>
                                            <td><strong>Townhall discussion</strong><br /></td>
                                            <td>
                                                Moderator: Patricia Pozo-Rosich
                                                <br/> <br/>
                                                Panel: Andreas Gantenbein, <br/>
                                                Isabel Pavão Martins, <br/> Francesca Puledda, <br/> Bianca Raffaelli, <br/> Uwe Reuter, <br/> Roberto De Icco
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>12:50–13:00</strong></td>
                                            <td><strong>Meeting close </strong></td>
                                            <td>Peter J Goadsby</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/* END DESKTOP LAYOUT  */}
                        </div>
                        <div className={`modal-footer`}>
                            <button className={`button`} onClick={closeModal}>CLOSE</button>
                        </div>
                    </div>


                </div>
            </div>
         );
    }
}
 
export default Modal;