import React from 'react';
import WelcomeSection from "./welcomeSection/welomeSection";

import Banner from './banner/banner';


class IsNotLoggedInHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount() {
        document.title = "MHM 2024 | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"

    }
    render() { 
        return ( 
            <div>
         <Banner/>
       
            <WelcomeSection/> 
            {/* <ProgrammeOverview/> */}
            {/* <MigraneEducationSection/> */}
            {/* <SteeringCommittee/> */} 
       
            </div>
         );
    }
}
 
export default IsNotLoggedInHome;