import { inject, observer } from "mobx-react";
import React from "react";
import EACIC_Logo from "../../../media/images/EACIC_Full-logo.jpg";
import styles from "./cme.module.css";
import Banner from "../../public/home/banner/banner";
@inject("rootStore")
@observer
class CMEAccreditation extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title =
      "CME Accreditation | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    return (
      <div className={`page-wrapper ${styles.cme_accreditation}`}>
        <Banner />
        <section className={`mt-5 ${styles.cme_section}`} style={{ paddingLeft: "15px", paddingRight: "15px"}}>
        <span className={styles.icon1}></span>
        <span className={styles.icon2}></span>
            <div className={`container ${styles.container}`}>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      style={{
                        border: "1px solid #1a0a60",
                        padding: "10px 5px 5px",
                        borderRadius: "5px",
                        marginBottom: "30px",
                        textAlign: "center",
                      }}
                    >
                      <p className="mb-1 mt-1">
                        <strong>
                          This website is intended for use by Healthcare
                          Professionals only.
                        </strong>
                      </p>
                      <p>
                        {" "}
                        <strong>
                          This promotional meeting is organised and funded by Teva
                          Pharmaceuticals Europe B.V.
                        </strong>
                      </p>
                      <p  >
                        Some sessions may contain product information. <br />
                        For the fremanezumab UK
                        prescribing information and adverse events reporting, please
                        see footer of the page.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
            </div>
        </section>
        <div className="container">
          <div className={"row"}>
            <div className={"col-12"}>
              <div className="top_section text-center">

                <h1 className="graphical_heading ">CME Accreditation</h1>
              </div>
            </div>
          </div>

          <div className="row">
            {/* <span className={styles.icon1}></span> */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <h3 className="pb-4">Programme</h3>
              <p>
                The meeting programme has been developed by an independent 
                Steering Committee, led by Professor Peter J Goadsby.<br />
                CME accreditation has been provided via the European 
                Accreditation Committee in CNS (EACIC).
              </p>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5 pb-4">
              <h3>CME accreditation </h3>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 pb-5">
              <img
                src={EACIC_Logo}
                alt="European Accreditation Committee Logo"
              />
            </div>

            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pb-5">
              <p>
                Migraine Headache Management 2024: Patients, Treatments, 
                Choices has been granted <strong>7 CME credits</strong> with EACIC. 
                You can find further information about CME on the EACIC website.
              </p>

              <p>
                Following your attendance and participation at the face-to-face 
                event in Berlin, you will be required to complete an evaluation 
                form in order to receive a CME certificate of attendance. 
                The certificate will enable you to claim your CME credits.
              </p>
              <p>
                You will be rewarded a maximum of 7 CME credits for your 
                full participation in the MHM 2024 programme. 
              </p>
              <p>
                If you have not received your CME certificate of attendance by <strong>Monday 11 November 2024</strong>,<br className="hide-for-devices" /> please contact us at{" "}
                <strong>
                  <a href="mailTo:mhm@asandk.com"> mhm@asandk.com</a>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CMEAccreditation;
