import React from "react"
import PodcastImg from "../../../../media/images/podcast.png"
import MhmLogo from "../../../../media/images/migraine_education_on_demand/podcast/podcast_logo@2x.png"
import styles from "./podcast.module.css"
import ZazaModal from "./ZazaModal/modal"
import PeterModal from "./PeterModal/modal"
import PatriciaModal from "./PatriciaModal/modal"
import AudioFile from "../../../../media/images/migraine_education_on_demand/podcast/sample.mp3"
import {saveFileFromUrl, trackDownload} from "../../../../util/util"
import { CircularProgress } from "@material-ui/core"

class Podcast extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            downloading: false,
            loadSlower: true
        }
    }
    openModal = (type) => {
        this.disableScroll()
        switch(type){
            case "modal": 
            this.setState({
                modal: true,
    
            })
            break;
            case "petermodal": 
            this.setState({
                petermodal: true,
    
            })
            break;
            case "patriciamodal": 
            this.setState({
                patriciamodal: true,
    
            })
            break;
          
            
            default: 
                 return 
        }
     
       
    }
    
    
    closeModal = (type) => {
        this.enableScroll()
        switch(type){
            case "modal": 
            this.setState({
                modal: false,
    
            })
            break;

            case "petermodal": 
            this.setState({
                petermodal: false,
    
            })
            break;

            case "patriciamodal": 
            this.setState({
                patriciamodal: false,
    
            })
            break;
          
            
            default: 
                 return 
        }
     
       
    }
  
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {
      
        document.body.style.overflow = 'auto'
    }

    downloadFile = (e, fileUrl) => {
        e.preventDefault();

        const {downloading, loadSlower} = this.state;

        if(downloading){
            return;
        }

        let timeout = loadSlower ? 3500 : 2000;

        this.setState({downloading: true}, () => {
            saveFileFromUrl(fileUrl);
            setTimeout(() => {
                this.setState({downloading: false, loadSlower: false});
            }, timeout);
        })
    }

    render(){
        let {modal, petermodal, patriciamodal, downloading} = this.state;
        return(
            <div className={`${styles.podcast_wrap} mt-5 pt-5`}>  
                {/* POP-UP */}
                <ZazaModal closeModal={() => this.closeModal("modal")} isActive={modal}/>
                <PeterModal closeModal={() => this.closeModal("petermodal")} isActive={petermodal}/>
                <PatriciaModal closeModal={() => this.closeModal("patriciamodal")} isActive={patriciamodal}/> 
                {/* END POP-UP */}
                <div className="row justify-content-center mb-4">
                    <div className={`col-12 col-sm-12 col-md-6 col-lg-3 ${styles.podcast}`}>
                        <img alt="webcast" src={PodcastImg}/>
                        <h1>Podcasts</h1>
                    </div>
                </div>

                <div className={`row`}>
                <div className="col-12">
                    <p>
                    Each 15-minute episode features two experts tackling some of the biggest questions in migraine management.
                    </p>
                </div>
            </div>

            <div className="row text-left mt-5">
                <div className="col-12 col-sm-12 col-md-3">
                {/* <img className="mb-2" style={{borderRadius : "20px"}} alt="mhm logo" src={MhmLogo}/> */}
                <span className={styles.neuro_logo}>

                </span>
                <p>
                Neuro Reflections: bringing together some of the top minds in neurology. Listen here, or download the episodes to top up your migraine knowledge on the go!
                </p>
                </div>
                <div className="col-12 col-sm-12 col-md-9">
                    <div className={`row mb-4 pb-3 ${styles.podcast_item}`}>
                        <div className="col-12">
                            <h3>
                            Zaza Katsarava and Dagny Holle-Lee

                            </h3>
                            <p>
                            Episode 1 - Neuroimaging in migraine: Used too much or not enough? 

                            </p>
                            <p className="text-sm-left text-center"> 
                            <button onClick={() => this.openModal("modal")} className="btn orange mr-2 mb-2">PLAY</button> 
                            <a
                            className={`btn bordered ml-2 ${styles.download_btn}`} 
                            href={"https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Neuroimaging_Podcast_Katsarava_Holle-Lee.mp3"}
                            target={"_blank"}  download="true" >
                                {downloading ? <CircularProgress style={{color: "#1a0a60"}} size={18} /> : "DOWNLOAD"}
                            </a>
                            </p>
                        </div>
                    </div>

                    <div className={`row mb-4 pb-3 ${styles.podcast_item}`}>
                        <div className="col-12">
                        <h3>
                        Peter Goadsby and Messoud Ashina

                            </h3>
                            <p>
                            Episode 2 - Episodic vs chronic migraine: One disease or two?

                            </p>
                            <p className="text-sm-left text-center">
                            <button className="btn orange mr-2 mb-2" onClick={() => this.openModal("petermodal")} >PLAY</button> 
                            <a className={`btn bordered ml-2 ${styles.download_btn}`} href={"https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_EM_vs_CM_Podcast_Goadsby_Ashina.mp3"} target="_blank"  download="true">
                            {downloading ? <CircularProgress style={{color: "#1a0a60"}} size={18} /> : "DOWNLOAD"}
                            </a>
                            </p>
                        </div>
                    </div>

                    <div className={`row mb-4 pb-3 ${styles.podcast_item}`}>
                        <div className="col-12">
                        <h3>
                        Patricia Pozo-Rosich and Dimos Mitsikostas

                            </h3>
                            <p>
                            Episode 3 - Migraine and comorbidities: A practical or a scientific challenge?

                            </p>
                            <p className="text-sm-left text-center">
                            <button className="btn orange mr-2 mb-2" onClick={() => this.openModal("patriciamodal")} >PLAY</button>
                            <a className={`btn bordered ml-2 ${styles.download_btn}`} href={"https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Comorbidities_Pozo-Rosich_Mitsikostas.mp3"}  target="_blank" download="true">
                            {downloading ? <CircularProgress style={{color: "#1a0a60"}} size={18} /> : "DOWNLOAD"}
                            </a>
                            </p>
                        </div>
                    </div>

                    
                </div>
            </div>
         

            </div>
        )
    }
}
export default Podcast;