import React from 'react';
import moment from "moment"
import { AddBox, CloudDownload } from '@material-ui/icons';
import { data } from "../../data/data";
import { inject, observer } from "mobx-react";
import MaterialTable from 'material-table';
import { userService } from '../../services/user-service';
import { contentService } from '../../services/content-service'
import Switch from '@material-ui/core/Switch';
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";
import Editor from '../editor/editor';
import { downloadCsv } from "../../util/util"
import SettingsIcon from '@material-ui/icons/Settings';
import {Auth} from "aws-amplify"
import {getTimezoneFromCountryCode} from "../../controllers/utility-controls"

@inject('rootStore')
@observer
class UserEventsTable extends React.Component {

    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            data: [],
            columns: [],
            filterBy: '',
            eventIdToAdd: null,
            timeZoneName: ""
        }
        this.addOrRemoveUserFromAttendedList = this.addOrRemoveUserFromAttendedList.bind(this);
    }
    getMeetingsLookupData(){

    }

    async componentDidMount() {
        await this.getData();
    }

    componentDidUpdate(prevProps, prevState){

        if(this.props.context.state.shouldUpdate){
            this.props.context.setState({shouldUpdate: false}, async () => {
                await this.getData()
            })
        }
    }

    getData = async () => {
        try { 
            let data = this.getEventsUserHasRegistered();
            this.setState({
                columns: this.getColumns(),
                data: data,
            });
        } catch (e) { }
    }
   
    getColumns() {
        const {events} = this.props;
        let eventsLookUpData =  {};
        let eventTimesLookUpData = {};
        let eventSpeakerLookUpData = {};
        let eventTimeOptions = [];

        if (events){
            events.forEach((item)=>{
                eventsLookUpData[item.id] = item.title;
                item.eventTimes.forEach((eventTime) => {
                    eventTimesLookUpData[eventTime.id] = moment(eventTime.date).format("Do MMMM YYYY") + ", " + eventTime.time + " (CEST)";
                    eventSpeakerLookUpData[eventTime.id] = eventTime.speakerName;
                    eventTimeOptions.push({ value: eventTime.id, text: moment(eventTime.date).format("Do MMMM YYYY") + " " + eventTime.time, optgroup: item.title, optgroupId: item.id});
                });
            });

        }
        let columns = [
            {
                title: "Date and Time (CEST)",
                field: "id",
                lookup: eventTimesLookUpData,
                editComponent: rowData => {
                    return <Editor type={"select"} onChange={(field, value) => { rowData.onChange(value)}} placeholder="Event Date and Time (CEST)" name={'id'} value={rowData.id} options={eventTimeOptions} />
                }
            },
            {
                title: "Event Title",
                field: "eventId",
                lookup: eventsLookUpData,
                editable: 'never'
            },
            {
                title: "Event speaker",
                field: "id",
                lookup: eventSpeakerLookUpData,
                editable: 'never'
            },
            
            { 
                title: "Attended", 
                field: "attended", 
                editable: 'never',
                render: rowData => 
                    <Editor type={"checkbox"} onChange={() => this.addOrRemoveUserFromAttendedList(rowData)} placeholder="Attended" name={'attended'} checked={rowData.attended} />
            }

        ];
        return columns;
    }

    async addOrRemoveUserFromAttendedList(rowData) {
        const { eventId, id, attended } = rowData;
        const {user, context} = this.props;
        var successMessage = attended ? 'Successfully removed from attended list' : 'Successfully added to attended list';

        await contentService.addOrRemoveUserFromAttendedList(user.Username, eventId, id).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: successMessage, onDismiss: async () => context.setState({shouldUpdate: true}, async () => await context.getAllEvents()) });
            return result;
        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Could not add user to attended list', onDismiss: async () => context.setState({shouldUpdate: true}, async () => await context.getAllEvents()) });
            return null;
        });
    }
    getEventsUserHasRegistered() {
        const { user, events } = this.props;
        var userEvents = [];
        if (events) {

            for(let element of events){
                if(element.eventTimes && element?.eventTimes?.length > 0){
                    for(let time of element.eventTimes){
                        if(time.registeredUsers && (time.registeredUsers.find(x => x == user.Username))){
                            var attendedEvent = { ...time, 
                                date: moment(time.date).format("Do MMMM YYYY"),
                                eventId: element.id,
                                attended: time.attendedUsers.find(x => x == user.Username) ? true : false, 
                                title: element.title,
                                speaker: element.speakerName
                            }
                            userEvents.push(attendedEvent);
                        }
                    }
                }
            }

        }
        return userEvents;
    }

    onRowDelete = async (oldData) => new Promise((resolve, reject) => {
        const { eventId, id } = oldData;
        const { user } = this.props;

        this.deleteUserRegistration(user, eventId, id ).then(
            (result) => {
                if (result) {
                    this.setState((prevState) => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                    });
                    resolve();
                } else {
                    resolve();
                }
            });
    });

    async deleteUserRegistration(user, eventId, timeId) {
        let username = user.Username;
        let message = 'User successfully removed from meeting';

        try {
            await contentService.addOrRemoveUserFromAttendedList(username, eventId, timeId);
        }catch(e){}

        try {
            let results = await contentService.addOrRemoveUserFromRegisteredList(username, eventId, timeId); 
            if (results){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: message, onDismiss: () => {
                this.props.rootStore.alertStore.clearAlert()
                this.props.context.updateEvents();
            } });
            }else{
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "User was not removed from meeting.", onDismiss: () => this.props.rootStore.alertStore.clearAlert() });
            }
            return results;
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "Changes could not be saved. Kindly try again.", onDismiss: () => this.props.rootStore.alertStore.clearAlert() });
            return false;
        }
    }

    render() {
        const {user} = this.props;
        let title = user?.Attributes?.find(x => x.Name === "custom:title")?.Value;
        if(title === "Other" || title === 'I prefer not to say'){
            title = '';
        }else{
            title += " ";
        }
        let usersName = `${title}${user?.Attributes?.find(x => x.Name === "custom:firstName")?.Value} ${user?.Attributes?.find(x => x.Name === "custom:lastName")?.Value}`;

        return (

            <div className="row">
                <div className="admin-table col-12 col-sm-12">

                    <MaterialTable
                        icons={data.tableIcons}
                        title={`Manage ${usersName.includes("undefined") ? "User's" : usersName} Events`}
                        columns={this.state.columns}
                        data={this.state.data}
                        editable={{
                            onRowDelete: this.onRowDelete,
                        }}
                        options={{ searchFieldStyle: { maxWidth: '350px' }, searchFieldAlignment: 'right', sorting: true, pageSize: 25, emptyRowsWhenPaging: false }}
                    />

                </div>

            </div>
        );
    }
}

export default UserEventsTable;