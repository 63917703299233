import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { VpnKey, Edit } from '@material-ui/icons';
import { data } from "../../data/data";
import EditIcon from '@material-ui/icons/Edit';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { contentService } from "../../services/content-service"; 
import { inject, observer } from "mobx-react";
import MaterialTable from 'material-table'
import {getAllEvents} from "../../controllers/events-controller"
import Editor from "../editor/editor"
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import Loader from "../_shared/loading-div"

@inject('rootStore')
@observer
class ContentTable extends React.Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            columns: [],
            data: [],
            activeEvents: [],
            loading: true
        }
    }

    async componentDidMount(){
        try {
            let allEvents = await getAllEvents();
            await this.filterByActiveEvents(allEvents);
        }catch(e){
            this.setState({loading: false})
        }
    }

    filterByActiveEvents = async (allEvents) => {
        if(allEvents){

            let activeEvents = allEvents.filter(eventItem => (eventItem.statusId !== data.status.deleted))
            this.setState({
                columns: this.getTableColumns(activeEvents),
                data: activeEvents,
                loading: false,
                activeEvents
            })
        }
    }

    getTableColumns(allEvents) {
        let eventTypes = [];
        for(let item of allEvents){
            eventTypes.push({ value: item.type, text: item.type, optgroup: item.type, optgroupId: item.id});
        }

        let columns = [
            {
              title: "Type",
              field: "type",
              required: true,
              editComponent: props => {
                  if(eventTypes.length > 0){
                    return <Editor type={"select"} onChange={(field, value) => {props.onChange(value)}} placeholder="Type" name={'type'} value={props.type} options={eventTypes} />
                  }
                  return <Editor type={"text"} onChange={(field, value) => {props.onChange(value)}} placeholder="Type" name={'type'} value={props.type} />
              }
            },
            { title: "Title", field: "title", required: true },
            { title: "Sort Order", field: "sortOrder", required: true },
           
            { 
              title: "Date Created",
              field: "dateCreated",
              editable: 'never',
              render: rowData => {
                if(rowData.dateCreated){
                    return <span>{(new Date(rowData.dateCreated)).toGMTString()}</span>
                }
                return <span>-</span>
              }
            },
            { 
              title: "Date Modified",
              field: "dateModified",
              editable: 'never',
              render: rowData => {
                if(rowData.dateModified){
                    return <span>{(new Date(rowData.dateModified)).toGMTString()}</span>
                }
                return <span>-</span>
              }
            }
        ];
        return columns;
    }

    onRowAdd = (newData) => {
        let upToDateData = []
        return new Promise(async (resolve, reject) => {
            const data = this.state.data
            const addedItem = await this.addNewEvent(newData);
            if(addedItem === null){
                this.setState({data: data}, () => reject());
                return
            }
            upToDateData = [addedItem, ...data]
            this.setState({data: upToDateData}, () => resolve())
        });
    }

    onRowDelete = (oldData) => {
        return new Promise(async (resolve, reject) => {
            const data = [...this.state.data]
            const deletedItem = await this.deleteSelectedEvent(oldData)
            data.splice(data.indexOf(oldData), 1);
            this.setState({data: data}, () => resolve());
        });
    }

    addNewEvent = async (item) => {
        const { selectedContentNode } = this.props;

        if(Object.keys(item).length < 1){
            return null
        }

        item = {...item,
            id: uuidv4(),
            dateCreated: new Date().toISOString(),
            dateModified: null,
            eventTimes: []
        }

        const addedItem = await contentService.saveEvent(item).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully added event'});
            return item;

        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Could not add event'});
            return null;
        });

        return addedItem
    }

    updateSelectedEvent = async (item) => {
        if(Object.keys(item).length < 1){
            return null
        }

        item = {
            ...item,
            dateModified: new Date().toISOString(),
            statusId: item.statusId || 1
        }

        const updatedItem = await contentService.updateEvent(item).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully updated event'});
            return item;
        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Could not update event'});
            return null;
        });
        return updatedItem
    }

    deleteSelectedEvent = async (item) => {
        const { selectedContentNode } = this.props;

        const deletedItem = await contentService.deleteEvent(item.id).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully deleted ' + selectedContentNode.name });
            return item;

        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Could not delete ' + selectedContentNode.name });
            return null;
        });
        return deletedItem;
    }

    goToEvent = (e, rowData) => {
        this.routerStore.goTo("SelectedEvent", {id: rowData.id});
    }

    remedyAttendeeList = async () => {
        const {activeEvents} = this.state;

        const modifiedEvents = activeEvents.map((item) => {
            let clonedItem = Object.assign({}, item);
            let clonedEventTimes = [];
            for(let eventTime of clonedItem.eventTimes){
                let clonedEventTime = Object.assign({}, eventTime);
                clonedEventTime.attendedUsers = clonedEventTime.attendedUsers.filter(attendee => clonedEventTime.registeredUsers.includes(attendee));
                clonedEventTimes.push(clonedEventTime)
            }
            clonedItem.eventTimes = clonedEventTimes;
            return clonedItem;
        })

        try {
            for(let item of modifiedEvents){
                await contentService.updateEvent(item);
            }
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: "Successfully updated events" });
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "Something went wrong, please try again" });
        }     
    }

    onRemedyAttendeeList = (e, rowData) => {
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Due to a previous bug, the registered list and attendee list for some (or all events) had mismatched users. This action will clean out the lists and ensure only registered users are found on the attendee list. Do you wish to proceed?`, onConfirm: this.remedyAttendeeList, data: rowData })
    }

    render() {
        const {loading} = this.state;

        const { selectedContentNode } = this.props;

        if(loading){
            return <Loader />
        }

        return (

            <div className="row">
                <div className="admin-table col-12 col-sm-12">

                    <MaterialTable
                        tableRef={this.tableRef}
                        icons={data.tableIcons}
                        title={`Manage ${selectedContentNode.name}`}
                        columns={this.state.columns}
                        data={this.state.data}
                        editable={{
                            onRowAdd: this.onRowAdd,
                            onRowDelete: this.onRowDelete,
                        }}
                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: 'Go to event',
                                onClick: this.goToEvent,
                            },
                            // {
                            //     icon: () => <BuildOutlinedIcon />,
                            //     tooltip: 'Update event attendee list',
                            //     onClick: this.onRemedyAttendeeList,
                            //     isFreeAction: true
                            // }
                        ]}
                        options={{ searchFieldStyle: { maxWidth: '350px' }, searchFieldAlignment: 'right', sorting: true ,pageSize: 25,emptyRowsWhenPaging: false}}
                    />

                </div>

            </div>
        );
    }
}

export default ContentTable;