import { inject, observer } from "mobx-react";
import React from 'react';
import { RouterLink } from "mobx-state-router"
import styles from '../migraineExpertForum/styles.module.css';
import PracticalWorkshopIcon from "../../../../media/images/mhm2022/practical_workshop.png"
import MasterClassIcon from "../../../../media/images/mhm2022/masterclass.png"
import Forum from "../../../../media/images/mhm2022/forum.png"
import SeeAgendaModal from "../../../../components/public/programmerOverview/migraineExpertForum/modal"
@inject('rootStore')
@observer
class MigraineExpertForum extends React.Component{

    constructor(props) {
        super(props);
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            agendaModal: false,
            expertSessionModal: false,
            migraineExpertForumModal: false,
            isAuthenticated: false

        }

    }

    openModal = (type) => {
        this.disableScroll()
        switch (type) {
            case "agendaModal":
                this.setState({
                    agendaModal: true,

                })
                break;



            default:
                return
        }


    }


    closeModal = (type) => {
        this.enableScroll()
        switch (type) {
            case "agendaModal":
                this.setState({
                    agendaModal: false,

                })
                break;



            default:
                return
        }


    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {

        document.body.style.overflow = 'auto'
    }
    async componentDidMount() {

        let { authStore } = this.props.rootStore;
        let isAuthenticated = await authStore.isAuthenticated();
        this.setState({ isAuthenticated })
    }
    render() {
        let { agendaModal, isAuthenticated } = this.state;
        const scrollTop = () =>{
            window.scrollTo({top: 0, behavior: 'smooth'});
         };
        return(
            
         
               <div className={styles.migraine_expert_forum}>
                  <div className="container text-center">
                
                     <div className="row">
                        <span className={styles.icon2}></span>
                    <div className="col-12 col-sm-12 col-md-12 text-center mb-5">
                   
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-2 text-center">
                                    <img src={Forum} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-10 text-center">
                                    <h1>Day 2 – Saturday 07 October, 09:00 – 13:10 CEST</h1>
                                </div>
                            </div>
                           
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                            <p>Focusing on the practical aspects of care, Day 2 begins with case-based discussions on the hot topics in migraine management. You will then attend two question clinics of your choice, which will be run by a panel of experts. 
                        </p>
                         
                        <p>
                                Beyond treating migraine, speakers will discuss how to treat the patient as a whole. The day will end with an open townhall session for a chance to ask your unanswered questions to the panel, before the meeting close.
                        </p>
                       
                    </div>
                </div>
                
              
                    <div className="row justify-content-center">
                    
                        
                        <div className="col-12 col-sm-12 col-md-4 text-center">
                            <a onClick={() => this.openModal("agendaModal")} className={`button  mt-5 red ${styles.white}`}>SEE AGENDA</a>
                            {/* POP UP */}
                            <SeeAgendaModal closeModal={() => this.closeModal("agendaModal")} isActive={agendaModal} />
                        </div>
                     
                
                    </div>
                

               </div>
               

            

              </div>
        );
    }

} 

export default MigraineExpertForum; 