import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, Remove, LastPage, Search, ViewColumn, SaveAlt } from "@material-ui/icons";
import { forwardRef } from 'react';
import React from 'react';
const countryList = require('country-list');

export const data = {  
    testMode: true,
    status: {
        live: 1,
        disabled: 3,
        deleted: 5,
    },
    emailStatus: [
        { id: 'Draft', name: 'Draft' },
        { id: 'Sent', name: 'Sent' },
        { id: 'Deleted', name: 'Deleted' },
    ],
    contentTypes: [
        { id: 0, name: 'User', path: 'users' },
        { id: 1, name: 'Events', path: 'events' },
        { id: 2, name: 'Mass Emails', path: 'mass-emails' }

    ],
    eventTypes: [
        { id: "Interactive workshops", name: "Interactive workshops"},
        { id: "Question Clinics", name: "Question Clinics"}
    ],
    // eventTopics: [
    //     { id: "1", name: "Combination therapy"},
    //     { id: "2", name: "Dosing (daily versus long-acting agents)"},
    //     { id: "3", name: "Initiation and discontinuation"},
    //     { id: "4", name: "Late response to therapy"},
    //     { id: "5", name: "Treatment failure"},
    //     { id: "6", name: "Interictal burden"},
    //     { id: "7", name: "Comorbidities"},
    //     { id: "8", name: "Patient-reported outcomes"}
    // ],
    // extraTopics: [
    //     { id: "1", name: "Menstrual-related migraine"},
    //     { id: "2", name: "Migraine and mental health"},
    //     { id: "3", name: "Sleep pathways and migraine"},
    //     {
    //         id: "4", name: "Safety and tolerability issues with mAbs"},
    // ],
    tableIcons: {
        Add: forwardRef((props, ref) => <div><AddBox {...props} ref={ref} /></div>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    },
    getMeetingCategories() {
        return ['SPAF', 'VTE', 'VP', 'Cross-indication'];
    },
    getHoursIn24hrFormat() {
        let toReturn = [];
        for (let index = 0; index < 24; index++) {
            toReturn.push((index < 10 ? '0' : '') + index);
        }
        return toReturn;
    },
    getMinutesPrefixedWithZero() {
        let toReturn = [];
        for (let index = 0; index < 60; index++) {
            toReturn.push((index < 10 ? '0' : '') + index);
        }
        return toReturn;
    },
    getUserTitles() {
        return ['Dr','Miss','Mr', 'Mrs',  'Ms',  'Mx', 'Professor', 'I prefer not to say','Other']
    },

    getSpeciality() {
        return ['Headache Specialist', 'Neurologist', 'Pain Specialist', 'Vendor / Agency','Teva Employee' , 'Other']
    },
    getActiveCountriesCodes(){
        return ['AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR', 'HU', 'IE', 'IT','LV', 'LT', 'LU','MT','NL','NO','PL','PT', 'RO','SI','SK', 'ES','SE','GB', 'CH', 'RS'];
    },

    getCountries() {
        var data = countryList.getData();
        var activeCountryCodes = this.getActiveCountriesCodes();
        data = data.filter(x => activeCountryCodes.includes(x.code));

        var gbIndex = data.findIndex(x => x.code === 'GB');
        data[gbIndex].name = 'United Kingdom';

        var gbIndex = data.findIndex(x => x.code === 'CZ');
        data[gbIndex].name = 'Czech Republic';

        return data;
    },
    getMeetingFlyerSetting() {
        return [
            'Delivered to your practice in a virtual setting',
            'Delivered to your practice in a face to face setting',
            'Delivered to your hospital in a virtual setting',
            'Delivered to your hospital in a face to face setting',
            'Delivered to your department in a virtual setting',
            'Delivered to your department in a face to face setting'
            ]
    },
    getEventTypesSelectItemsList(){
        return this.eventTypes.map((item, index) => { return { value: item.id, text: item.name, optgroup: '' } });
    },
    getEventTypes(){
        return ['Practical Workshop', 'Migraine Masterclass'];
    },
    getMethodsOftransportation(){
        return ['Flight','Train', 'Car', 'Coach','Other'];
    },
    getDietaryRequestOptions(){
        return ['Gluten free', 'Lactose free', 'Vegetarian', 'Vegan', 'Halal', 'Kosher', 'Other, please specify below'];
    }
    
}
