const qa = {
    apiUrl: 'https://drtr1yajo7.execute-api.eu-west-1.amazonaws.com/dev'
}

const prod = {
    apiUrl: 'https://tg6m8ui0g2.execute-api.eu-west-1.amazonaws.com/prod',
}

const urls = () => {
    if (process.env.REACT_APP_ENV === 'production') return prod;

    else {
        return qa;
    }
}

export const config = {
    ...urls(),
};

export const userManagementApi = {
    apiName: 'MhmApi',
    createUserPath: '/createUser',
    addUserToGroupPath: '/addUserToGroup',
    listUsersInGroupPath: '/listUsersInGroup',
    getUserByUsernamePath: '/getUser',
    checkUserExistsPath: '/checkUserExists',
    resetUserPasswordPath: '/resetUserPassword',
    disableUserPath: '/disableUser',
    enableUserPath: '/enableUser',
    createEnabledUserWithWebUserRolePath: '/createEnabledUserWithWebUserRole',
    createUserWithTempPasswordPath: '/createEnabledUserWithWebUserRoleAndTempPassword',
    deleteUserPath: '/deleteUser',
    updateUser: '/updateUser',
    removeUserFromGroup: '/removeUserFromGroup',
    confirmUserSignup: '/confirmUserSignup',
    adminApproveUser: '/adminApproveUser',
    adminDenyUser: '/adminDenyUser',
    getAllUsers: '/getAllUsers',
    listAllUsers: '/listAllUsers',
    getAllUsersInAGroup: '/getAllUsersInAGroup',
    updateUserDetails: '/updateUserDetails',
    getUserApprovalByAdminStatus: '/getUserApprovalByAdminStatus',
    getUserShowConfirmationMessage: '/getUserShowConfirmationMessage',
    hideUserConfirmationMessage: '/hideUserConfirmationMessage'
}
export const serviceUrls = {
    getAllContent: '/content',
    addContent: '/addcontent',
    deleteContent:'/deletecontent',
    updateContent: '/updatecontent',
    getAllEvents: '/getAllEvents',
    saveEvent: '/saveEvent',
    updateEvent: '/updateEvent',
    updateEventDetails: '/updateEventDetails',
    deleteEvent: '/deleteEvent',
    bookEvent: '/bookEvent',
    registerToEvents: "/registerToEvents",
    addOrRemoveUserFromAttendedList: '/addOrRemoveUserFromAttendedList',
    addOrRemoveUserFromRegisteredList: '/addOrRemoveUserFromRegisteredList',
    addEventDate: '/addEventDate',
    updateEventDate: '/updateEventDate',
    deleteEventDate: '/deleteEventDate',
}
export const emailUrls ={
    sendMassEmail: '/sendMassEmail',
    getAllEmails: '/getAllEmails',
    getEmailById: '/getEmailById',
    getMainEmailTemplate: '/getMainEmailTemplate',
    createMassEmail: '/createMassEmail',
    deleteMassEmail: '/deleteMassEmail',
    updateMassEmail: '/updateMassEmail',
    sendCommentEmail: '/sendCommentEmail',
    contactUsEmail:  '/contactUsEmail'
}
export const  ReactAppConfigurations = {
    identityPoolId: process.env.REACT_APP_ENV == 'production' ? 'eu-west-1:c048e0b0-8975-424e-b989-13e35da54ecf' : 'eu-west-1:fda067a6-712b-41e2-820f-f1cb931c8dd0',
    region: 'eu-west-1',
    userPoolId: process.env.REACT_APP_ENV == 'production' ? 'eu-west-1_DzdV7tuOu' : 'eu-west-1_M8huZcfae',
    userPoolWebClientId: process.env.REACT_APP_ENV == 'production' ? '37e6jibs37ouu3468a0o5i4i22' : '7rljbmj1o513rakq1t7pokesms',

    bucket: process.env.REACT_APP_ENV == 'production' ? 'mhm-files-production': 'mhm-files',
    bucketRegion: 'eu-west-1',
    defaultProtectionLevel: 'public',
    googleAnalyticsId:  process.env.REACT_APP_ENV == 'production' ? "G-18Z5Z8X5QB" : "G-8CQ00Q7DK8",
    matomoSiteId: process.env.REACT_APP_ENV == 'production' ? "1" : "2"
}