import React from 'react';
import { inject, observer } from "mobx-react";
import { Auth } from "aws-amplify";
import FormWrapper from "../templates/form-wrapper";
import RegisterForm from "../components/forms/register-form"
import Loader from "../components/_shared/loading-div"
@inject('rootStore')
@observer

class EditProfile extends React.Component {

    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            profile: {
                type: 'edit',
                title: '',
                firstName: '',
                lastName: '',
                email: "",
                confirmEmail: "",
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                phoneNumber: '',
                speciality: '',
                country: '',
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                postCode: '',
                events: [],
                aggreedToCommunicate: true,
                // extraTopics: [],
                // eventTopics: [],
                dietaryRequest: '',
                tevaContactPerson: '',
                transportMethod: '',
                altContactName: '',
                altContactEmail: '',
                altContactTelephone: '',
                flightNumber: '',
                // extraTopics: '',
                // eventTopics: '',
                arrivalLocation: '',
                arrivalDate: '',
                arrivalTime: '',
                hotelCheckinDate: '',
                hotelCheckOutDate: '',
                // allergies: '',
                specialRequest: '',
            },
            
        };

    }
    static routeName = 'editProfile';
    static routePathPattern = '/my-profile';

    async componentDidMount() {
        let profile = await this.getProfile()
        localStorage.removeItem("profileRedirect")
        this.setState({profile:profile})
    }

    getProfile = async () => {
        let currentUser = null;

        try {
            currentUser = await Auth.currentUserInfo();
        }catch(e){}

        if(!currentUser){
            this.routerStore.goTo("login");
            return;
        }

        let {email, email_verified, sub, ...customAttributes} = currentUser.attributes;

        let userProfile = Object.keys(customAttributes).reduce((userProfile, key) => {
            let cleanedKey = key.split("custom:")[1];

            
           if(cleanedKey === "extraTopics" || cleanedKey === "eventTopics"){
            userProfile[cleanedKey] = customAttributes[key].split(",");

           }else{
            userProfile[cleanedKey] = customAttributes[key];
           }
            
            return userProfile;
        }, {});

        userProfile = {
            ...this.state.profile,
            ...userProfile,
            email: email,
            confirmEmail: email,
            userId: currentUser?.username,
        };
        return userProfile;
    }

    render() {
        const { profile } = this.state;
        if(profile?.email){
            return <RegisterForm viewModel={profile} />
        }else{
           return   <FormWrapper><Loader/></FormWrapper>

        }
        
      
    }

}

export default EditProfile;
