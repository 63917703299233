import React from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment"
import {data} from "../../data/data"
import Editor from '../editor/editor';
import {getAllEvents, adminBookEventForUser} from "../../controllers/events-controller"
import CircularProgress from "@material-ui/core/CircularProgress"



@inject('rootStore')
@observer
class EventRegisterUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredList: [],
            allEvents: [],
            viewModel: {
                eventId:'',
                timeId: '',
                time: '',
                title: ''
            },
            showLoading: false,
        };
        this.handleSubmitform = this.handleSubmitform.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount(){
        let allEvents = await getAllEvents();
        await this.filterByActiveEvents(allEvents);
    }

    filterByActiveEvents = async (allEvents) => {
        if(allEvents){
            let activeEvents = allEvents.filter(eventItem => (eventItem.statusId !== data.status.deleted))
            this.setState({allEvents: activeEvents})
        }
    }

    onChange(field, value) {
        const { viewModel, allEvents } = this.state;
        const newViewModel = Object.assign({}, viewModel);

        let eventTitle = (allEvents.find(item => item.id === value))?.title || value;

        let eventTime = "";

        if (field === "timeId") {
            for (let item of allEvents) {
                for (let time of item.eventTimes) {
                    if (time.id === value) {
                        eventTime = `${moment(time.date).format("Do MMMM YYYY")} ${time.time} (CEST)`;
                        break;
                    }
                }
            }
        }

        eventTime = eventTime.length > 0 ? eventTime : value;

        newViewModel[field] = value;

        this.setState({
            viewModel: {
                ...newViewModel,
                ...(field === "eventId" && { title: eventTitle }),
                ...(field === "timeId" && { time: eventTime }),
            },
        });
    }

    handleSubmitform = async () => {
        const {viewModel: {eventId, timeId}} = this.state;
        const {user} = this.props; 

        if(user.UserStatus === "UNCONFIRMED"){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "This user account has not been confirmed yet. Cannot sign them up for this event." });
            return; 
        } 

        if(user.Attributes.find(attribute => attribute.Name === "custom:approvedByAdmin").Value !== "approved"){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "Please approve this user first before signing them up for this event." });
            return; 
        }

        if(eventId.length < 1 || timeId.length < 1){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "Please select an event and its time." });
            return; 
        }
        if (!user?.Attributes.find(x => x.Name === "email_verified").Value || user?.Attributes.find(x => x.Name === "custom:approvedByAdmin").Value != 'approved') {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "This user account has not been approved and cannot be registered." });
            return;
        }

        let userTimezone = user.Attributes?.find(attribute => attribute.Name === "custom:timeZoneName")?.Value;

        try {
            await adminBookEventForUser(user?.Username, eventId, timeId, this, userTimezone);
        }catch(e){
        }

        this.setState({showLoading: false})
    }
    render() {
        const { viewModel, showLoading } = this.state;
        const { events, user } = this.props;

        let eventOptions = [];
        let eventTimeOptions = [];
        if (events) {
            let shouldPushEvent = true;
            
            for(let item of events){
                if(item.type === data.eventTypes?.find(eventType => eventType.id === 'Practical Workshop')?.id){
                    for(let eventTime of item.eventTimes){
                        if(eventTime.registeredUsers.includes(user.Username)){
                            shouldPushEvent = false;
                            break;
                        }
                    }
                }
                if(shouldPushEvent){
                    eventOptions.push({
                        value: item.id,
                        text: item.title,
                        optgroup: ''
                    });
                }

                for(let eventTime of item.eventTimes){
                    let isRegistered = eventTime?.registeredUsers?.includes(user.Username);
                    if(eventTime?.statusId !== data.status.deleted && !isRegistered && shouldPushEvent){
                        eventTimeOptions.push({
                            value: eventTime.id,
                            text: moment(eventTime.date).format("Do MMMM YYYY") + " " + eventTime.time + " (CEST)",
                            optgroup: eventTime.speakerName,
                            optgroupId: eventTime.speakerName
                        });
                    }
                }

                shouldPushEvent = true;
            }
        }
        let event = events.find( k => viewModel.eventId == k.id);
        let filteredOptions = [];
        if(event){
            var eventIds = event.eventTimes.map(x => x.id );
            filteredOptions = eventTimeOptions.filter(x => eventIds.includes(x.value));
        }
        else{
            filteredOptions = eventTimeOptions
        }

        return (
            
           
                <div className="row justify-content-center">
                    <div className={` col-12 col-sm-12 col-md-10`} >
                        <div className="row">
                            <div className="col-4 col-sm-12 col-md-4 text-center">
                                <Editor type={"select"} onChange={this.onChange} placeholder="Event Title" name={'eventId'} value={viewModel.title} overwriteValueFrontend={viewModel.title}  context={this} required options={eventOptions}/>
                            </div>
                            <div className="col-4 col-sm-12 col-md-4 text-center">

                                <Editor type={"select"} onChange={this.onChange} placeholder="Date and Time (CEST)" name={'timeId'} value={viewModel.time} overwriteValueFrontend={viewModel.time} context={this} required options={filteredOptions}/>
                            </div>
                            
                            <div className="col-4 col-md-4 text-center">
                                <button className="button" onClick={this.handleSubmitform}>
                                    {showLoading ? (
                                        <CircularProgress size={18} />
                                    ) : "Sign User Up"}
                                </button>
                            </div> 
                        </div>
                    </div>
                </div>
          
        );
    }

}

export default EventRegisterUserForm;
