import React, {Component} from 'react';
import SafeComponentWrapper from "../templates/safe-component-wrapper";
import FormWrapper from "../templates/form-wrapper";
import {RouterLink} from 'mobx-state-router';
import Header from '../components/_shared/header-module.js'
import Footer from "../components/_shared/footer-module";

export default class NotFound extends Component {

    static routeName = 'notFound';
    static routePathPattern = '/notFound';

    render() {
        return (
            <>
            <Header/>
            <div style={{minHeight:"calc(92.6vh - 215px)"}}>
            
               <div className="container error_page" >
                    <div className="row text-center">
                        <div className="col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main" style={{top:"140px"}}>
                        <div className="row">
                            <div className="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                            <h1 className="m-0">404</h1>
                            <h6>Page not found</h6>
                            <p>Opps! The page you are looking for can't be found. Click <RouterLink  routeName="home" style={{color: "#f89410", cursor: "pointer",}} >here</RouterLink> to go back to  <RouterLink  routeName="home" style={{color: "#f89410", cursor: "pointer",}}>the home page</RouterLink></p>
                            </div>
                        </div>
                        </div>
                    </div>
                 </div>
            
            </div>
            <Footer/>
            </>
        );
    }
}
