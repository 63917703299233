import React from 'react';
import { inject, observer } from "mobx-react";
import FormWrapper from "../templates/form-wrapper";
import RegisterForm from "../components/forms/register-form"

@inject('rootStore')
@observer

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };

    }
    static routeName = 'register';
    static routePathPattern = '/register';
    componentDidMount() {
        
    }
   
    getProfile(){
        return  {
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            password: '',
            confirmPassword: '',
            phoneNumber: '',
            speciality: '',
            country: '',
            companyName: '',
            address1: '',
            address2: '',
            city: '',
            postCode: '',
            events: [],
            aggreedToCommunicate: false,
            type: "register",
            // extraTopics: [],
            // eventTopics:[]
        }
    }


    render() {
        const { } = this.state;
        var profile = this.getProfile()
        return (
         
            <RegisterForm viewModel={profile}/>
          
        );
    }

}

export default Register;
