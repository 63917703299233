//key
//sd - self described
import {action, computed, observable, toJS} from 'mobx';
import {RouterState} from 'mobx-state-router';
import {DEFAULT_ROUTE, HOME_ROUTE} from "../routes";
import {Auth} from "aws-amplify";



/**
 * sd
 * by HashMaloo
 */
export class ContentStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //to assist with differentiation during storage to persistence media
    static namespace = 'ContentStore_';

    rootStore;

  
    @observable content = null
    @observable eventsModel = null
    // @computed

    @computed get report(){
        if (this.content.length === 0)
            return "<none>";
            
        return this.content;
    }
	addContent(model) {
        this.content= model;
	}
    addEvents(eventsModel) {
        this.eventsModel = eventsModel;
	}
    @action
    revertStores = () => {
        //TODO >> might not be needed, so leave it empty like this when not needed
    };

}
