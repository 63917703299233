import React from "react";
import { toJS } from "mobx";
import { SignIn } from "aws-amplify-react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { handleLogin } from "../../controllers/app-controller";
import { inject, observer } from "mobx-react";
import {
  textValue,
  textValueChanged,
} from "../../controllers/utility-controls";
import SafeComponentWrapper from "../../templates/safe-component-wrapper";
import { instanceProvider, isNullUndefined, toastProps } from "../../util/util";
import { AlertToast } from "../../components/_shared/alert-toast";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_SIGNIN_APP_KEY } from "../../app-config";

import Footer from "../../components/_shared/footer-module";
import Header from "../../components/_shared/header-module.js";
import ResetPassword from "./reset-password";
import SetNewPassword from "./set-new-password";
import { RouterLink } from "mobx-state-router";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import styles from "../../components/forms/logIn.module.css";
import Branding1 from "../../media/images/branding1.png";
import Branding2 from "../../media/images/branding2.png";
import Branding3 from "../../media/images/branding3.png";
@inject("rootStore")
@observer
class Login extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.loginStore = this.props.rootStore.appStores.login;
    this.appStore = this.props.rootStore.appStores.app;
    this.routerStore = this.props.rootStore.routerStore;

    this.state = {
      toastProps: instanceProvider(toastProps),
      resetPasswordPressed: false,
      shouldShowPasswordInput: false,
      loginPressed: false,
      loginError: null,
      profileRedirect: null,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title =
      "Log in | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";

    this.timeoutId = setTimeout(
      function () {
        this.setState({ show: true });
      }.bind(this),
      1000
    );
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  logInUser = async () => {
    const {
      rootStore: { authStore, routerStore },
    } = this.props;
    this.setState({ loginPressed: true, loginError: null });
    try {
      const loginResult = await handleLogin(
        this.loginStore.email,
        this.loginStore.password,
        authStore,
        this,
        routerStore
      );
      if (typeof loginResult === "object" && "message" in loginResult) {
        this.setState({ loginPressed: false, loginError: loginResult });
      }
    } catch (e) {
      this.setState({ loginPressed: false, loginError: e });
    }
  };

  showComponent() {
    return (
      <SafeComponentWrapper>
        <div className={styles.log_in_wrap}>
          <div className={styles.curve1}>
            <img alt="Branding 1 image" src={Branding1} />
          </div>

          <div className={styles.curve2}>
            <img alt="Branding 2 image" src={Branding2} />
          </div>
          <div className={styles.curve3}>
            <img alt="Branding 3 image" src={Branding3} />
          </div>
          <div className={this.state.show ? `${styles.show}` : "log-in-card"}>
            <Header />

            <div className={"container"}>
              <div className={"row"}>
                <div className={"col-12"}>
                  {this.state.resetPasswordPressed && (
                    <ResetPassword
                      goBackToLogin={(email) => {
                        this.setState({ resetPasswordPressed: false }, () => {
                          if (!isNullUndefined(email)) {
                            //set the login email
                            textValueChanged(
                              this.loginStore,
                              email,
                              "email",
                              this
                            );
                          }
                        });
                      }}
                      fromLogin={true}
                    />
                  )}
                  {!this.state.resetPasswordPressed && (
                    <div className={`${styles.logIn_card}`}>
                      <div className={"row"}>
                        <div className={"col-12"}>
                          <div
                            style={{
                              border: "1px solid #1a0a60",
                              padding: "5px",
                              borderRadius: "5px",
                              marginBottom: "10px",
                              marginTop: "70px",
                            }}
                          >
                            <p className="mb-1 mt-1">
                              <strong>
                                This website is intended for use by Healthcare
                                Professionals only.
                              </strong>
                            </p>
                            <p className="mt-1">
                              {" "}
                              <strong>
                                This promotional meeting is organised and funded
                                by Teva Pharmaceuticals Europe B.V.
                              </strong>
                            </p>
                            <p  >
                              Some sessions may contain product information.{" "}
                              <br />
                              For the fremanezumab{" "}
                               UK
                              prescribing information and adverse events
                              reporting, please see footer of the page.
                              <br />
                            </p>
                          </div>
                          <h1>Hello</h1>
                        </div>
                      </div>

                      {this.state.loginError && (
                        <div className="error">
                          <p>{this.state.loginError.message}</p>
                        </div>
                      )}

                      <form
                        id="form1"
                        className="form row"
                        style={{
                          margin: "50px auto",
                          maxWidth: "600px",
                          alignSelf: "center",
                        }}
                      >
                        <div className={"col-12"}>
                          <TextField
                            id="email"
                            key="email"
                            name="email"
                            label="Email"
                            type="email"
                            autoComplete="on"
                            variant="outlined"
                            style={{
                              width: "100%",
                              minWidth: "100%",
                              marginBottom: "20px",
                              textTransform: "lowercase",
                            }}
                            value={textValue(this.loginStore, "email", this)}
                            onChange={(event) => {
                              textValueChanged(
                                this.loginStore,
                                event.target.value.toLowerCase(),
                                "email",
                                this
                              );
                            }}
                          />
                          <i className="icon mail outline"></i>
                        </div>

                        <div className={"col-12"}>
                          <TextField
                            id="password"
                            key="password"
                            name="password"
                            label="Password"
                            type={
                              this.state.shouldShowPasswordInput
                                ? "text"
                                : "password"
                            }
                            variant="outlined"
                            style={{
                              width: "100%",
                              minWidth: "100%",
                              marginBottom: "10px",
                            }}
                            value={textValue(this.loginStore, "password", this)}
                            onChange={(event) => {
                              textValueChanged(
                                this.loginStore,
                                event.target.value,
                                "password",
                                this
                              );
                            }}
                          />
                          {this.state.shouldShowPasswordInput ? (
                            <VisibilityOffIcon
                              className="view_pw"
                              onClick={() => {
                                this.setState({
                                  shouldShowPasswordInput: false,
                                });
                              }}
                            />
                          ) : (
                            <Visibility
                              className="view_pw"
                              onClick={() => {
                                this.setState({
                                  shouldShowPasswordInput: true,
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className={"col-12"}>
                          <div className="forgot_pw">
                            <span
                              style={{
                                margin: "10px 0 10px 0",
                                display: "block",
                                textAlign: "right",
                                color: "#00617f",
                              }}
                            >
                              <a
                                href="#"
                                onClick={() =>
                                  this.setState({ resetPasswordPressed: true })
                                }
                                style={{
                                  color: "#fff",
                                }}
                              >
                                Reset password
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className={"col-12"}>
                          {!this.state.loginPressed && (
                            <button
                              className="button"
                              variant="contained"
                              color="primary"
                              style={{ marginBottom: "10px" }}
                              onClick={this.logInUser}
                            >
                              <span>LOG IN</span>
                            </button>
                          )}

                          {this.state.loginPressed && (
                            <button
                              className="button"
                              variant="contained"
                              color="primary"
                              style={{ marginBottom: "10px" }}
                            >
                              <span className="mr-2">LOGGING IN</span>
                              <CircularProgress
                                size={16}
                                style={{ color: "white" }}
                              />
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!isNullUndefined(this.state.toastProps) &&
              this.state.toastProps.message && (
                <AlertToast
                  message={this.state.toastProps.message}
                  type={this.state.toastProps.type}
                  duration={this.state.toastProps.duration}
                  position={this.state.toastProps.position}
                  activity={this}
                />
              )}
          </div>
          <Footer />
        </div>
      </SafeComponentWrapper>
    );
  }
}

export default Login;
