import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { VpnKey, Edit } from '@material-ui/icons';
import { data } from "../../../data/data";
import EditIcon from '@material-ui/icons/Edit';
import {Add} from '@material-ui/icons/Add'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { emailService } from "../../../services/email-service";
import { inject, observer } from "mobx-react";
import MaterialTable from 'material-table'
import Editor from "../../editor/editor"
import Loader from "../../_shared/loading-div"
import moment from 'moment';

@inject('rootStore')
@observer
class MassEMailsTable extends React.Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            columns: this.getTableColumns(),
            data: [],
            loading: true
        }
    }

    async componentDidMount() {
        this.getAllEmails();
    }

    getAllEmails = async () => {
        try {
            await emailService.getAllEmails().then((emails) => {
                if (emails) {
                        this.setState({
                            data: emails.Items.sort((a,b) => moment(b.dateCreated).unix() - moment(a.dateCreated).unix() ),
                            loading: false
                        });
                }
            });
        } catch (e) {
            this.setState({loading: false})
         }
    }
   
    getTableColumns() {

        let columns = [
            { title: "Subject", field: "subject", required: true },
            { title: "Status", field: "status", required: true },
            {
                title: "Date Created",
                field: "dateCreated",
                editable: 'never',
                render: rowData => {
                    if (rowData.dateCreated) {
                        return <span>{(new Date(rowData.dateCreated)).toGMTString()}</span>
                    }
                    return <span>-</span>
                }
            },
            {
                title: "Date Modified",
                field: "dateModified",
                editable: 'never',
                render: rowData => {
                    if (rowData.dateModified) {
                        return <span>{(new Date(rowData.dateModified)).toGMTString()}</span>
                    }
                    return <span>-</span>
                }
            }
        ];
        return columns;
    }

    onRowAdd = (newData) => {
       
    }

    onRowDelete = (oldData) => {
        return new Promise(async (resolve, reject) => {
            let data = [...this.state.data]
            await this.deleteMassEmail(oldData)
            
            data.splice(data.indexOf(oldData), 1);
            this.setState({ data: data }, () => resolve());
        });
    }
    deleteMassEmail = async (item) => {
        const { selectedContentNode } = this.props;

        const deletedItem = await emailService.deleteMassEmail(item.id).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully deleted ' + selectedContentNode.name });
            return true;
        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Could not delete ' + selectedContentNode.name });
            return false;
        });
        return deletedItem;
    }
    addNewEvent = async (item) => {
        
    }
    handleGoToPreview = async () => {
        this.routerStore.goTo("MassEmail");
    }
    goToEditEmail = (e, rowData) => {
        this.routerStore.goTo("MassEmail", { id: rowData.id });
    }
    render() {

        const {loading} = this.state;
        const { selectedContentNode } = this.props;

        if(loading){
            return <Loader />
        }

        return (
            

            <div className="row">
              <div className="col-12">
                  <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 text-left">
                          <h3 className="mt-2">View emails</h3>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 text-right">
                          <button onClick={this.handleGoToPreview} className="btn mb-3">
                              Create new email
                          </button>
                      </div>
                  </div>
                  <div className="row">
                  <div className="admin-table col-12 col-sm-12">
                    

                    <MaterialTable
                        tableRef={this.tableRef}
                        icons={data.tableIcons}
                        title={`Manage ${selectedContentNode.name}`}
                        columns={this.state.columns}
                        data={this.state.data}
                        editable={{
                            onRowDelete: this.onRowDelete,
                        }}
                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: 'Go to mass email',
                                onClick: this.goToEditEmail,
                            }
                        ]}
                        options={{ searchFieldStyle: { maxWidth: '350px' }, searchFieldAlignment: 'right', sorting: true, pageSize: 25, emptyRowsWhenPaging: false }}
                    />

                </div>
                  </div>
              </div>

            </div>
        );
    }
}

export default MassEMailsTable;