import React from "react";
import styles from "./contact.module.css";
import { updateViewModel, submitContactUsForm } from "../../../util/formHelper";

import { inject, observer } from "mobx-react";

import Editor from "../../editor/editor";

import Icon1 from "../../../media/images/branding3.png";

@inject("rootStore")
@observer
class ContactLoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.authStore = this.props.rootStore.authStore;
    this.state = {
      requiredList: [],
      viewModel: {
        message: "",
      },
      showLoading: false,
    };
  }
  onChange = (field, value) => {
    updateViewModel(this, this.state.viewModel, field, value);
  };

  async componentDidMount() {}
  refreshPage = () => {
    this.props.rootStore.alertStore.clearAlert();
    window.location.reload(false);
  };
  onSubmitForm = async (e) => {
    const { showLoading } = this.state;

    if (showLoading) {
      return;
    }

    this.setState({ showLoading: true });

    var result = await submitContactUsForm(this);

    if (result == true) {
      this.setState({ showLoading: true });

      this.props.rootStore.alertStore.setAlert({
        type: "alert-success",
        message: "Message submitted to the MHM 2024 team",
        onDismiss: () => this.refreshPage(),
      });
    } else {
      this.setState({ showLoading: true });

      this.props.rootStore.alertStore.setAlert({
        type: "alert-danger",
        message: "Message not submitted to the MHM2024 team",
        onDismiss: () => {
          this.setState({ showLoading: false });
          this.props.rootStore.alertStore.clearAlert();
        },
      });
    }
  };

  render() {
    const { viewModel, showLoading } = this.state;

    return (
      <div className={`contact_us ${styles.wrap}`}>
        <span className={styles.icon1}></span>
        <span className={styles.icon2}></span>

        <div className={`container ${styles.container}`}>
          <div
          className="mt-5"
            style={{
              border: "1px solid #1a0a60",
              padding: "10px 5px 5px",
              borderRadius: "5px",
              marginBottom: "30px",
              textAlign: "center",
              zIndex: "999", 
              position: "relative"
            }}
          >
            <p className="mb-1 mt-1">
              <strong>
                This website is intended for use by Healthcare Professionals
                only.
              </strong>
            </p>
            <p>
              {" "}
              <strong>
                This promotional meeting is organised and funded by Teva
                Pharmaceuticals Europe B.V.
              </strong>
            </p>
            <p  >
              Some sessions may contain product information. <br />
              For the fremanezumab UK
              prescribing information and adverse events reporting, please see
              footer of the page.
              <br />
            </p>
          </div>
          <div className={"row"}>
            <div className={"col-12"}>
              <div className="text-center">
                <h1 className={`${styles.graphical_heading} graphical_heading `}>
                  Contact
                </h1>
                <h2 className="mb-3">Submit your comments, questions and feedback</h2>
              </div>
            </div>
          </div>

              <Editor
                type={"textarea"}
                className={styles.textarea}
                placeholder="Type your comments, questions and feedback here"
                onChange={this.onChange}
                name={"message"}
                value={viewModel.message}
                context={this}
                required
              />

              {showLoading ? (
                <button className="button">SUBMITTING...</button>
              ) : (
                <button className="button" id="submit" onClick={this.onSubmitForm}>
                  SUBMIT
                </button>
              )}
        </div>
      </div>
    );
  }
}

export default ContactLoggedIn;
