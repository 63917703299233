import React from 'react';
import '../../../theme/regular-user.scss';
import {inject, observer} from "mobx-react";
import FormWrapper from "../../../templates/form-wrapper";
import {RouterLink} from 'mobx-state-router';
import PracticalWorkshop from '../../../components/post-registration/practical-workshop/practicalWorkshop';


@inject('rootStore')
@observer
class Cme extends React.Component {

    constructor(props) {
        super(props);
    }

    static routeName = 'practicalWorkshop';
    static routePathPattern = '/book-session-slots';

    render() {
        let {authStore, routerStore} = this.props.rootStore;
        return (
            <FormWrapper>
           <div className={`page-wrapper`}>

                <div className="regular-user-wrapper " >     

                    <PracticalWorkshop />                           
                      
                </div>

                </div>
           </FormWrapper>
        );
    }

}

export default Cme;
