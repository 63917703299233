import React from "react";
import {handleLogout} from "../../controllers/app-controller";


export function AdminHeader(props) {

    let {authStore} = props;

    return (
        <div>
            <span>Hello, welcome: {authStore?.user?.email}</span>
        </div>
    );
}
