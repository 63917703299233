import React from "react";
import styles from "./facultySection.module.css";
// Faculty Modal Pop Ups
import FayyazAhmedPopupModal from "./FayyazAhmedPopup/modal";
import Piero_BarbantiPopupModal from "./Piero_BarbantiPopup/modal";
import Edoardo_CaronnaPopupModal from "./Edoardo_CaronnaPopup/modal";
import RobertoPopupModal from "./RobertoPopup/modal";
import AntoinettePopupModal from "./AntoinettePopup/modal";
import CharlyGaulPopupModal from "./CharlyGaulPopup/modal";
import TimPopupModal from "./TimPopup/modal";
import DagnyPopupModal from "./DagnyPopup/modal";
import Christian_LamplPopupModal from "./Christian_LamplPopup/modal";
import DimosMitsikostasPopupModal from "./DimosMitsikostasPopup/modal";
import RussoPopupModal from "./RussoPopup/modal";
import PuledaPopupModal from "./PuledaPopup/modal";
import GilesaPopupModal from "./GilesaPopup/modal";
import CristinaPopupModal from "./CristinaPopup/modal";
import AndreasPopupModal from "./AndreasPopup/modal";
import IsabelPopupModal from "./IsabelPopup/modal";

// Faculty Images
import Bianca from "../../../../media/images/faculty/bianca-red.png";
import A_Russo from "../../../../media/images/faculty/lambru_purple.png";
import Antoinette_Maassen_van_den_Brink from "../../../../media/images/faculty/Antoinette_Maassen_van_den_Brink.png";
import Charly_Gaul from "../../../../media/images/faculty/Charly_Gaul.png";
import Yellow from "../../../../media/images/faculty/yellow.png";
import Gisela from "../../../../media/images/faculty/gisela-purple.png";
import Edoardo_Caronna from "../../../../media/images/faculty/edoardo-caronna-purple.png";
import roberto_de_icco from "../../../../media/images/faculty/roberto-de-icco-yellow.png";
import andreas_gantenbein_red from "../../../../media/images/faculty/andreas-gantenbein-red.png";
import Isabel from "../../../../media/images/faculty/isabel-purple.png";
import Uwe from "../../../../media/images/faculty/uwe-purple.png";
import Gisela_TerwindtGÇï from "../../../../media/images/faculty/Gisela.png";
import Tomas from "../../../../media/images/faculty/tomas.png";
import Piero_Barbanti from "../../../../media/images/faculty/piero-barbanti_red.png";
import Christina from "../../../../media/images/faculty/christina-red.png";
import AnimationPath from "../../../../media/images/Group33.svg";
import Icon1 from "../../../../media/images/mhm2022//branding10.png";
import Puleda from "../../../../media/images/faculty/francesca-puledda-yellow.png";

// eslint-disable-next-line import/no-webpack-loader-syntax
import Animation from "-!svg-react-loader!./animation.svg";

class FacultySection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FayyazAhmedPopup: false,
      Piero_BarbantiPopup: false,
      Edoardo_CaronnaPopup: false,
      RobertoPopup: false,
      AndreasPopup: false,
      IsabelPopup: false,
      AntoinettePopup: false,
      CharlyGaulPopup: false,
      TimPopup: false,
      Christian_LamplPopup: false,
      DimosMitsikostasPopup: false,
      RussoPopup: false,
      PabloPopup: false,
      GilesaPopup: false,
      CristinaPopup: false,
      DagnyPopup: false,
    };
  }

  openModal = (type) => {
    this.disableScroll();
    switch (type) {
      case "FayyazAhmedPopup":
        this.setState({
          FayyazAhmedPopup: true,
        });
        break;

      case "Piero_BarbantiPopup":
        this.setState({
          Piero_BarbantiPopup: true,
        });
        break;

      case "Edoardo_CaronnaPopup":
        this.setState({
          Edoardo_CaronnaPopup: true,
        });
        break;

      case "RobertoPopup":
        this.setState({
          RobertoPopup: true,
        });
        break;

      case "AndreasPopup":
        this.setState({
          AndreasPopup: true,
        });
        break;

      case "IsabelPopup":
        this.setState({
          IsabelPopup: true,
        });
        break;

      case "AntoinettePopup":
        this.setState({
          AntoinettePopup: true,
        });
        break;

      case "CharlyGaulPopup":
        this.setState({
          CharlyGaulPopup: true,
        });
        break;

      case "TimPopup":
        this.setState({
          TimPopup: true,
        });
        break;

      case "DagnyPopup":
        this.setState({
          DagnyPopup: true,
        });
        break;

      case "Christian_LamplPopup":
        this.setState({
          Christian_LamplPopup: true,
        });
        break;

      case "DimosMitsikostasPopup":
        this.setState({
          DimosMitsikostasPopup: true,
        });
        break;

      case "RussoPopup":
        this.setState({
          RussoPopup: true,
        });
        break;
      case "PabloPopup":
        this.setState({
          PabloPopup: true,
        });
        break;
      case "GilesaPopup":
        this.setState({
          GilesaPopup: true,
        });
        break;
      case "CristinaPopup":
        this.setState({
          CristinaPopup: true,
        });
        break;

      default:
        return;
    }
  };

  closeModal = (type) => {
    this.enableScroll();
    switch (type) {
      case "FayyazAhmedPopup":
        this.setState({
          FayyazAhmedPopup: false,
        });
        break;

      case "Piero_BarbantiPopup":
        this.setState({
          Piero_BarbantiPopup: false,
        });
        break;

      case "Edoardo_CaronnaPopup":
        this.setState({
          Edoardo_CaronnaPopup: false,
        });
        break;

      case "RobertoPopup":
        this.setState({
          RobertoPopup: false,
        });
        break;

      case "AndreasPopup":
        this.setState({
          AndreasPopup: false,
        });
        break;

      case "IsabelPopup":
        this.setState({
          IsabelPopup: false,
        });
        break;

      case "AntoinettePopup":
        this.setState({
          AntoinettePopup: false,
        });
        break;

      case "CharlyGaulPopup":
        this.setState({
          CharlyGaulPopup: false,
        });
        break;

      case "TimPopup":
        this.setState({
          TimPopup: false,
        });
        break;
      case "DagnyPopup":
        this.setState({
          DagnyPopup: false,
        });
        break;

      case "Christian_LamplPopup":
        this.setState({
          Christian_LamplPopup: false,
        });
        break;

      case "DimosMitsikostasPopup":
        this.setState({
          DimosMitsikostasPopup: false,
        });
        break;

      case "RussoPopup":
        this.setState({
          RussoPopup: false,
        });
        break;

      case "PabloPopup":
        this.setState({
          PabloPopup: false,
        });
        break;
      case "GilesaPopup":
        this.setState({
          GilesaPopup: false,
        });
        break;
      case "CristinaPopup":
        this.setState({
          CristinaPopup: false,
        });
        break;

      default:
        return;
    }
  };
  disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  enableScroll = () => {
    document.body.style.overflow = "auto";
  };
  render() {
    let {
      FayyazAhmedPopup,
      Piero_BarbantiPopup,
      Edoardo_CaronnaPopup,
      RobertoPopup,
      AndreasPopup,
      IsabelPopup,
      AntoinettePopup,
      CharlyGaulPopup,
      TimPopup,
      DagnyPopup,
      Christian_LamplPopup,
      DimosMitsikostasPopup,
      RussoPopup,
      PabloPopup,
      GilesaPopup,
      CristinaPopup,
    } = this.state;
    return (
      <div className={styles.wrap}>
        <section className="" style={{ paddingLeft: "15px", paddingRight: "15px"}}>
          <div className={`container ${styles.facultyContainer}`}>
            <div className="row">
              <div className="col-md-12">
              <div
                style={{
                  border: "1px solid #1a0a60",
                  padding: "10px 5px 5px",
                  borderRadius: "5px",
                  marginBottom: "30px",
                  textAlign: "center"
                }}
              >
                <p className="mb-1 mt-1">
                  <strong>
                    This website is intended for use by Healthcare Professionals
                    only.
                  </strong>
                </p>
                <p>
                  {" "}
                  <strong>
                    This promotional meeting is organised and funded by Teva
                    Pharmaceuticals Europe B.V.
                  </strong>
                </p>
                <p  >
                  Some sessions may contain product information. <br />
                  For the fremanezumab UK
                  prescribing information and adverse events reporting, please
                  see footer of the page.
                  <br />
                </p>
              </div>
              </div>
            </div>
          </div>
        </section>
        <div className={`container ${styles.container}`}>
          <h1 className={`${styles.graphical_heading} graphical_heading`}>
            Faculty
          </h1>
          <div className="row row-cols-4  text-left justify-content-center">
            <div className={`col ${styles.profile_item}`}>
                <img alt="Piero_Barbanti photo" src={Piero_Barbanti} />
                <h5>Piero Barbanti</h5>
                <p>Italy</p>
                <a
                  onClick={() => this.openModal("Piero_BarbantiPopup")}
                  className={`button ${styles.button}`}
                >
                  biography
                </a>
                <Piero_BarbantiPopupModal
                  closeModal={() => this.closeModal("Piero_BarbantiPopup")}
                  isActive={Piero_BarbantiPopup}
                />
              </div>

            <div className={`col ${styles.profile_item}`}>
                <img alt="Piero_Barbanti photo" src={Edoardo_Caronna} />
                <h5>Edoardo Caronna</h5>
                <p> Spain</p>
                <a
                  onClick={() => this.openModal("Edoardo_CaronnaPopup")}
                  className={`button ${styles.button}`}
                >
                  biography
                </a>
                <Edoardo_CaronnaPopupModal
                  closeModal={() => this.closeModal("Edoardo_CaronnaPopup")}
                  isActive={Edoardo_CaronnaPopup}
                />
              </div>

            <div className={`col ${styles.profile_item}`}>
                <img alt="roberto-de-icco-yellow photo" src={roberto_de_icco} />
                <h5>Roberto De Icco</h5>
                <p>Italy</p>
                <a
                  onClick={() => this.openModal("RobertoPopup")}
                  className={`button ${styles.button}`}
                >
                  biography
                </a>
                <RobertoPopupModal
                  closeModal={() => this.closeModal("RobertoPopup")}
                  isActive={RobertoPopup}
                />
            </div>

            <div className={`col ${styles.profile_item}`}>
                <img alt="roberto-de-icco-yellow photo" src={andreas_gantenbein_red} />
                <h5>Andreas Gantenbein</h5>
                <p>Switzerland</p>
                <a
                  onClick={() => this.openModal("AndreasPopup")}
                  className={`button ${styles.button}`}
                >
                  biography
                </a>
                <AndreasPopupModal
                  closeModal={() => this.closeModal("AndreasPopup")}
                  isActive={AndreasPopup}
                />
            </div>

            <div className={`col ${styles.profile_item}`}>
                <img alt="roberto-de-icco-yellow photo" src={Isabel} />
                <h5>Isabel Pavão Martins</h5>
                <p>Portugal</p>
                <a
                  onClick={() => this.openModal("IsabelPopup")}
                  className={`button ${styles.button}`}
                >
                  biography
                </a>
                <IsabelPopupModal
                  closeModal={() => this.closeModal("IsabelPopup")}
                  isActive={IsabelPopup}
                />
            </div>

            <div className={`col ${styles.profile_item}`}>
              <img alt="A_Russo's photo" src={Puleda} />
              <h5>Francesca Puledda</h5>
              <p>UK </p>
              <a
                onClick={() => this.openModal("PabloPopup")}
                className={`button ${styles.button}`}
              >
                biography
              </a>
              <PuledaPopupModal
                closeModal={() => this.closeModal("PabloPopup")}
                isActive={PabloPopup}
              />
            </div>

            <div className={`col ${styles.profile_item}`}>
              <img alt="Antoinette_Maassen_van_den_Brink photo" src={Bianca} />
              <h5>Bianca Raffaelli</h5>
              <p>Germany</p>
              <a
                onClick={() => this.openModal("AntoinettePopup")}
                className={`button ${styles.button}`}
              >
                biography
              </a>
              <AntoinettePopupModal
                closeModal={() => this.closeModal("AntoinettePopup")}
                isActive={AntoinettePopup}
              />
            </div>

            <div className={`col ${styles.profile_item}`}>
              <img alt="Fayyaz_Ahmed photo" src={Uwe} />
              <h5>Uwe Reuter</h5>
              <p>Germany</p>
              {/* POP UP */}

              <a
                onClick={() => this.openModal("FayyazAhmedPopup")}
                className={`button ${styles.button}`}
         
              >
                biography
              </a>
              <FayyazAhmedPopupModal
                closeModal={() => this.closeModal("FayyazAhmedPopup")}
                isActive={FayyazAhmedPopup}
              />
            </div>

            <div className={`col ${styles.profile_item}`}>
              <img alt="Christian_Lampl's photo" src={Yellow} />
              <h5>Antonio Russo</h5>
              <p>Italy</p>
              <a
                onClick={() => this.openModal("Christian_LamplPopup")}
                className={`button ${styles.button}`}
               
              >
                biography
              </a>
              <Christian_LamplPopupModal
                closeModal={() => this.closeModal("Christian_LamplPopup")}
                isActive={Christian_LamplPopup}
              />
            </div>

            <div className={`col ${styles.profile_item}`}>
              <img alt="Tim's photo" src={Christina} />
              <h5>Cristina Tassorelli</h5>
              <p>Italy</p>
              <a
                onClick={() => this.openModal("DagnyPopup")}
                className={`button ${styles.button}`}
              >
                biography
              </a>
              <DagnyPopupModal
                closeModal={() => this.closeModal("DagnyPopup")}
                isActive={DagnyPopup}
              />
            </div>

            <div className={`col ${styles.profile_item}`}>
              <img alt="Charly_Gaul's photo" src={Gisela} />
              <h5>Gisela Terwindt</h5>
              <p> The Netherlands</p>
              <a
                onClick={() => this.openModal("CharlyGaulPopup")}
                className={`button ${styles.button}`}
              >
                biography
              </a>
              <CharlyGaulPopupModal
                closeModal={() => this.closeModal("CharlyGaulPopup")}
                isActive={CharlyGaulPopup}
              />
            </div>


          </div>
        </div>
        <div className="row">
          <div className={` ${styles.facultyIcon}`}>
            <img src={Icon1} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default FacultySection;
