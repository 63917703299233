import React from 'react';
import '../../../theme/regular-user.scss';
import { inject, observer } from "mobx-react";
import FormWrapper from "../../../templates/form-wrapper";
import { RouterLink } from 'mobx-state-router';
import Programme from "../../../components/public/programmerOverview/programmeOverview"
import LoggedInProgramme from "../../../components/post-registration/programme/loggedInProgramme"
import Banner from "../../../components/public/home/banner/banner"

@inject('rootStore')
@observer
class ProgrammeOverview extends React.Component {

    constructor(props) {
        super(props);
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            isAuthenticated: false
        }
    }

    static routeName = 'Programme';
    static routePathPattern = '/Programme';


    async componentDidMount() {
        document.title = "Programme | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
        let { authStore } = this.props.rootStore;
        let isAuthenticated = await authStore.isAuthenticated();
        this.setState({ isAuthenticated })
    }

    render() {
        let {isAuthenticated} = this.state;
        let { authStore, routerStore } = this.props.rootStore;
        return (
            <div style={{background: "#e7e6eb"}}>
    
            <FormWrapper >
                <div className={`page-wrapper`}>
                    <Banner/>
                        {isAuthenticated ? <LoggedInProgramme /> : <Programme />}
                </div>
            </FormWrapper>
            </div>
        );
    }

}

export default ProgrammeOverview;
