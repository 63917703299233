import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/Cristina_Tassorelli.png"
class Modal extends React.Component {
    constructor (props){
        super(props);
        
    }
    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className={`modal-body ${styles.modalbody}`}>
                       <div className="row">
                           <div className="col-12 col-sm-12 col-md-4 text-center">
                                <img alt="Hans photo" src={ProfilePhoto}/>
                           </div>
                           <div className="col-12 col-sm-12 col-md-8">
                                    <h3>Cristina Tassorelli, MD, PhD</h3>
                                    <p style={{ color: "#f61a19" }}>Italy
</p>
                               <p>
                                        Prof. Tassorelli is Chair of Neurology, Director of the Rehabilitative Neurology Unit, Headache Science & Neurorehabilitation Research Centre, Director of the Residency in Neurology Program, and Director of the Department of Brain and Behavioural Sciences at the University of Pavia, Italy. She is also affiliated with the IRCCS C. Mondino Foundation in Pavia. 

                               </p>
                               <p>
                                        She is President of the International Headache Society (IHS) for the biennium 2021-2023, having previously served as a member of the IHS Board of Directors and Chair of the IHS Committee for the Guidelines for Clinical Trials. Prof. Tassorelli is also President of the Italian Association of University Professors of Full Professors of Neurology for the biennium 2022-2023. She is also a founding member of the European Headache Alliance, where she served as Vice-President for several years, and she has served as a Director on the Board of the Italian Society of Neurorehabilitation.

                               </p>
                               <p>
                                        Prof. Tassorelli has coordinated and participated in several competitive grants funded by the European Union, the Migraine Research Foundation, the Italian Ministry of Health, the Italian Ministry of University and the Italian Multiple Sclerosis Foundation. She has authored or co-authored more than 350 peer-reviewed publications, as well as several books and book chapters.

                               </p>
                               <p>
                                        Prof. Tassorelli graduated summa cum laude in medicine from the University of Pavia, where she completed her training in neurology. She has a PhD in human psychobiology from the University of Modena, Italy, and undertook a post-doctoral fellowship at the Department of Neuroscience of the University of Rochester, New York, USA.
                               </p>

                   
                           </div>
                       </div>
                      
                  </div>
                  <div className={`modal-footer ${styles.modal_footer}`}>
                    <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                </div>
                  
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;