import React from 'react';
import { action, computed, observable, toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";
import appNavigator from "../../app-navigation";
@inject('rootStore')
@observer
class AdminSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.appStore = this.props.rootStore.appStores.app;
        this.authStore= this.props.rootStore.authStore
      
        this.state = {   showAdminModel: false, };
       
       

    }
    componentDidMount() {
        let role = this.authStore.getRole();
        if (role === ADMIN_USER_ROLE || role === SUPER_ADMIN_USER_ROLE) {
            if(localStorage.getItem("showAdminModel") === "true"){
               
                this.appStore.showAdminModel = true;
                this.setState({showAdminModel:true})
            }
        }else{
            if(localStorage.getItem("showAdminModel") !== null){
                
                localStorage.removeItem("showAdminModel")
            }
        }
    }
    changeAdminModelOnToggleSwitch = (e) => {
        const {routerStore} = this.props.rootStore;
        if (e.target.checked) {
            this.appStore.showAdminModel = true;
            this.setState({showAdminModel:true})
            appNavigator.navigateToAdministrationWizard(routerStore,{ id: 'home'});
        } 
      };
  
  
    render() {
        const { rootStore: { authStore }} = this.props;
        let role = this.authStore.getRole();

        if(role != ADMIN_USER_ROLE && role != SUPER_ADMIN_USER_ROLE){

          return ''
        }


        return <FormControlLabel className="adminSwitch" 
        control={
          <Switch
            checked={this.state.showAdminModel}
            onChange={this.changeAdminModelOnToggleSwitch}
            name="showAdminModel"
          />
        }
        label={`Admin ${this.state.showAdminModel ? "On" : "Off"}`} style={{fontSize: "12px"}}
      />
    }
}

export default AdminSwitch;
