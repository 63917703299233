import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/gisela-purple.png"
class Modal extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { closeModal, isActive } = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return (

            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>

                        <div className={`modal-body ${styles.modalbody}`}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 text-center">
                                    <img alt="Hans photo" src={ProfilePhoto} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-8">
                                    <h3>  Gisela Terwindt, MD, MSc, PhD
                                    </h3>
                                    <p style={{ color: "#f61a19" }}>Leiden, the Netherlands</p>
                                    <p>
                                        Gisela Terwindt is a consultant neurologist and is Director of the Leiden Headache 
                                        Clinic (LUMC) in the Netherlands. She is Chair of the Scientific and Headache 
                                        Treatment Guideline Committees of the Dutch Headache Society, Chair of the 
                                        International Headache Society’s Clinical Trial Guideline Committee and Female 
                                        Leadership Forum, and a committee member of The International Classification of Headache Disorders (ICHD-3).
                                    </p>
                                    <p>
                                        Her research focuses on neurobiological and molecular triggering mechanisms that 
                                        lead to migraine attacks, to identify treatment targets for the prevention of 
                                        attacks and disease progression. Her group is also studying the pathogenesis 
                                        of monogenic disorders such as familial hemiplegic migraine and cerebral 
                                        hereditary angiopathies, which serve as models for migraine pathogenesis 
                                        and help improve insight into the pathophysiology of common neurovascular 
                                        disorders including stroke and dementia. Prof. Terwindt is involved in 
                                        exploring the migraine–stroke relationship, with a special focus on 
                                        female-specific triggers for migraine and stroke. She has authored almost 500 scientific papers and book chapters.
                                    </p>
                                    <p>
                                        Prof. Terwindt earned her MSc in Biology and MD from the University of Leiden, 
                                        Netherlands. Her PhD centred on the epidemiology and genetics of migraine and 
                                        resulted in the discovery of the first migraine gene. 
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className={`modal-footer ${styles.modal_footer}`}>
                            <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default Modal;