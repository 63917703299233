import { inject, observer } from "mobx-react";
import React from 'react';
import styles from './styles.module.css';
import PracticalWorkshopIcon from "../../../../media/images/mhm2022/practical_workshop.png"
import MasterClassIcon from "../../../../media/images/mhm2022/masterclass.png"
import Forum from "../../../../media/images/mhm2022/forum.png"
@inject('rootStore')
@observer
class MigraineMasterclass extends React.Component{

    constructor(props) {
        super(props);
    }

   
    render() {
        return(
            
         
               <div className={styles.master_class}>
                  <div className="container text-center">
                
                     <div className="row">
                
                    <div className="col-12 col-sm-12 col-md-12 text-center mb-5">
                            <img src={MasterClassIcon}/>
                            <h1>Migraine Masterclass</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <p>
                                Select one of the following topics to explore with our expert faculty in a small-group setting, where you will have the
                                opportunity to ask questions and discuss the topic in relation to your own clinical practice.
                        </p>
                            <p><strong>Group sizes are limited for optimum discussion so be sure to reserve your space early!</strong></p>
                    </div>
                </div>
                 
                 <div className="row mt-5 text-left">
                    <div className={` col-12 col-sm-12 col-md-6`}>
                            <h5>Antonio Russo & Cristina Tassorelli </h5>
                            <h4 style={{color: "#fff"}}>Understanding the full spectrum of
                                migraine patients <span className={styles.tooltip} data-title="Discuss the most important evidence supporting treatment of different migraine patients and how to best manage diverse patients in clinical practice">i</span></h4>
                    </div>
                   
                        <div className={` col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>8 June 2022
                                <span>17:00 - 18:00 CEST </span>
                              
                        
                        </h4>
                        
                    </div>
                    <div className={`col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>9 June 2022
                                <span>18:00 - 19:00 CEST</span>
                              </h4>
                    </div>
                </div>
                <hr/> 


                    <div className="row mt-5 text-left">
                        <div className={` col-12 col-sm-12 col-md-6`}>
                            <h5>Tim Jürgens & Patricia Pozo-Rosich </h5>
                            <h4 style={{ color: "#fff" }}>Closing the treatment gap: how can
                                we ensure effective therapies reach
                                the patients who need them? <span className={styles.tooltip} data-title="Explore the most important evidence supporting which patients benefit from the most effective treatments, especially anti-CGRP mAbs, and how to optimise patient outcomes in clinical practice">i</span></h4>
                        </div>

                        <div className={` col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>25 May 2022
                                <span>18:00 - 19:00 CEST  </span>
                              </h4>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>1 June 2022
                                <span>17:00 - 18:00 CEST </span>
                              </h4>
                        </div>
                    </div>
                    <hr /> 


                    <div className="row mt-5 text-left">
                        <div className={` col-12 col-sm-12 col-md-6`}>
                            <h5>Messoud Ashina & Peter J Goadsby</h5>
                            <h4 style={{ color: "#fff" }}>At the cutting edge: how does
                                understanding migraine neurobiology
                                enhance research into new therapies?<span className={styles.tooltip} data-title="Look into the latest evidence on the advances of neurobiology, and how that can lead to improved therapies for treating migraine">i</span></h4>
                        </div>

                        <div className={` col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>17 May 2022
                                <span>17:00 - 18:00 CEST </span></h4>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>24 May 2022
                                <span>18:00 - 19:00 CEST </span></h4>
                        </div>
                    </div>
                    <hr /> 


                     <div className="row mt-5 text-left">
                    <div className={` col-12 col-sm-12 col-md-6`}>
                            <h5>Christian Lampl & Pablo Irimia Sieira </h5>
                            <h4 style={{ color: "#fff" }}>Exploring every option: using
                                nutraceuticals and complementary
                                approaches alongside pharmacotherapy <span className={styles.tooltip} data-title="Discuss the role, effectiveness, practicality and overall value of complementary treatment options alongside pharmacotherapy">i</span></h4>
                    </div>
                   
                        <div className={` col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                        <h4>    25 May 2022
                                <span>17:00 – 18:00</span>
                              </h4>
                    </div>
                    <div className={`col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>26 May 2022
                                <span> 18:00 – 19:00 </span>
                              </h4>
                    </div>
                </div>
                <hr/> 


                    <div className="row mt-5 text-left">
                        <div className={` col-12 col-sm-12 col-md-6`}>
                            <h5>Antoinette Maassen van den Brink & Gisela Terwindt </h5>
                            <h4 style={{ color: "#fff" }}>Hormonal influences on migraine
                                and treatment <span className={styles.tooltip} data-title="Explore the impact of hormones on migraine pathogenesis, and implications on approaches to treatment">i</span></h4>
                        </div>

                        <div className={` col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>19 May 2022 
                                <span>17:00 - 18:00 CEST </span>
                                </h4>
                        </div>
                        <div className={`col-12 col-sm-12 col-md-3 col-lg-3 text-center`}>
                            <h4>9 June 2022 
                                <span>18:00 - 19:00 CEST </span>
                                </h4>
                        </div>
                    </div>
                    <hr /> 
              
               </div>
               

            

              </div>
        );
    }

} 

export default MigraineMasterclass; 