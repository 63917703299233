import React from "react";
import { Auth } from "aws-amplify";
import { RouterLink, Router, Link } from "mobx-state-router";
import { getAllEvents } from "../../controllers/events-controller";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { data } from "../../data/data";
import Banner from "../public/home/banner/banner";
import styles from "../../components/forms/logIn.module.css";

@inject("rootStore")
@observer
class MySessions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allEvents: [],
      loading: true,
    };

    this.authStore = this.props.rootStore.authStore;

    this.handleSubmitform = this.handleSubmitform.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
  }
  componentDidMount = async () => {
    let user = await Auth.currentAuthenticatedUser();

    var eventsModel = await getAllEvents();
    this.props.rootStore.contentStore.addEvents(eventsModel);

    this.setState({ allEvents: eventsModel, user: user, loading: false });
    document.title =
      "My Sessions | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
  };

  handleSubmitform = async () => {
    await this.props.handleSubmitform();
  };
  handleBackButton = async () => {
    this.props.rootStore.routerStore.goTo("admin", { id: "events" });
  };
  render() {
    const { allEvents, user, loading } = this.state;
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const activeEvents = allEvents.filter(
      (eventItem) => eventItem.statusId !== data.status.deleted
    );
    //First interactive
    var firstInteractiveWorkshopEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[0].id)
      .find((x) =>
        x.eventTimes.find(
          (time) =>
            time.registeredUsers.includes(user.username) &&
            time.time.includes("15:15")
        )
      );
    var firstInteractiveWorkshopEventTime =
      firstInteractiveWorkshopEvent?.eventTimes.find(
        (time) =>
          time.registeredUsers.includes(user.username) &&
          time.time.includes("15:15")
      );

    //secong interactive
    var secondInteractiveWorkshopEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[0].id)
      .find((x) =>
        x.eventTimes.find(
          (time) =>
            time.registeredUsers.includes(user.username) &&
            time.time.includes("16:00")
        )
      );
    var secondInteractiveWorkshopEventTime =
      secondInteractiveWorkshopEvent?.eventTimes.find(
        (time) =>
          time.registeredUsers.includes(user.username) &&
          time.time.includes("16:00")
      );

    //first clinic
    var firstQuestionClinicEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[1].id)
      .find((x) =>
        x.eventTimes.find(
          (time) =>
            time.registeredUsers.includes(user.username) &&
            time.time.includes("10:00")
        )
      );
    var firstQuestionClinicEventTime =
      firstQuestionClinicEvent?.eventTimes.find(
        (time) =>
          time.registeredUsers.includes(user.username) &&
          time.time.includes("10:00")
      );

    //second  clinic
    var secondQuestionClinicEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[1].id)
      .find((x) =>
        x.eventTimes.find(
          (time) =>
            time.registeredUsers.includes(user.username) &&
            time.time.includes("10:45")
        )
      );
    var secondQuestionClinicEventTime =
      secondQuestionClinicEvent?.eventTimes.find(
        (time) =>
          time.registeredUsers.includes(user.username) &&
          time.time.includes("10:45")
      );

    return (
      <>
        {/* <Banner/> */}
        <div className={styles.my_sessions_wrap}>
          <span className={styles.icon1}></span>
          <span className={styles.icon2}></span>
          <div className="container mb-sm-5 mt-sm-5">
            <div
              style={{
                border: "1px solid #fff",
                padding: "10px 5px 5px",
                borderRadius: "5px",
                marginBottom: "30px",
                textAlign: "center"
              }}
            >
              <p className="mb-1 mt-1">
                <strong>
                  This website is intended for use by Healthcare Professionals
                  only.
                </strong>
              </p>
              <p>
                {" "}
                <strong>
                  This promotional meeting is organised and funded by Teva
                  Pharmaceuticals Europe B.V.
                </strong>
              </p>
              <p  >
                Some sessions may contain product information. <br />
                For the fremanezumab UK
                prescribing information and adverse events reporting, please see
                footer of the page.
                <br />
              </p>
            </div>

            <div className="row justify-content-md-center">
              <div className={`  col-12 col-sm-12 col-md-12 text-center`}>
                <h1 className="graphical_heading">My Sessions</h1>
              </div>
            </div>

            <hr />
            {!loading ? (
              firstInteractiveWorkshopEvent &&
              secondInteractiveWorkshopEvent ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h3 className={styles.white}>Day 1: Friday 4 October</h3>
                    </div>
                  </div>
                  <div className="row  pt-2 pb-2">
                    <div className={` col-12 col-sm-12 col-md-5 text-left`}>
                      <p className={styles.white}>Interactive Workshop 1</p>
                      <p style={{ color: "#f89410" }}>15:15–15:45 CEST</p>
                    </div>

                    <div className={` col-12 col-sm-12 col-md-6 text-left`}>
                      <h3 className="">Booked session:</h3>
                      <div className='p' dangerouslySetInnerHTML={{__html: `${firstInteractiveWorkshopEvent.title}`}}></div>
                      {/* <p>
                        <strong>{firstInteractiveWorkshopEvent.title}</strong>
                      </p> */}
                      <p style={{ display: "none" }}>{firstInteractiveWorkshopEventTime.speakerName}</p>
                    </div>
                  </div>

                  <div className="row  pt-2 pb-2">
                    <div className={` col-12 col-sm-12 col-md-5 text-left`}>
                      <p className={styles.white}>Interactive Workshop 2</p>
                      <p style={{ color: "#f89410" }}>16:00–16:30 CEST</p>
                    </div>

                    <div className={` col-12 col-sm-12 col-md-6 text-left`}>
                      <h3 className="">Booked session:</h3>
                      <div className='p' dangerouslySetInnerHTML={{__html: `${secondInteractiveWorkshopEvent.title}`}}></div>
                      {/* <p>
                        <strong>{secondInteractiveWorkshopEvent.title}</strong>
                      </p> */}
                      <p style={{ display: "none" }}>{secondInteractiveWorkshopEventTime.speakerName}</p>
                    </div>
                  </div>

                  <hr />
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                <div class="skeleton">
                  <div class="s-img"></div>
                  <div class="s-line first"></div>
                  <div class="s-line second"></div>
                  <div class="s-line third"></div>
                </div>
                <hr />
              </>
            )}

            {!loading ? (
              firstQuestionClinicEvent && secondQuestionClinicEvent ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h3 className={styles.white}>
                        Day 2: Saturday 5 October
                      </h3>
                    </div>
                  </div>
                  <div className="row  pt-2 pb-2">
                    <div className={` col-12 col-sm-12 col-md-5 text-left`}>
                      <p className={styles.white}>Question Clinic 1</p>
                      <p style={{ color: "#f89410" }}>10:00–10:30 CEST</p>
                    </div>

                    <div className={` col-12 col-sm-12 col-md-6 text-left`}>
                      <h3 className="">Booked session:</h3>
                      <div className='p' dangerouslySetInnerHTML={{__html: `${firstQuestionClinicEvent.title}`}}></div>
                      {/* <p>
                        <strong>{firstQuestionClinicEvent.title}</strong>
                      </p> */}
                      <p style={{ display: "none" }}>{firstQuestionClinicEventTime.speakerName}</p>
                    </div>
                  </div>
                  <div className="row  pt-2 pb-2">
                    <div className={` col-12 col-sm-12 col-md-5 text-left`}>
                      <p className={styles.white}>Question Clinic 2</p>
                      <p style={{ color: "#f89410" }}>10:45–11:15 CEST</p>
                    </div>

                    <div className={` col-12 col-sm-12 col-md-6 text-left`}>
                      <h3 className="">Booked session:</h3>
                      <div className='p' dangerouslySetInnerHTML={{__html: `${secondQuestionClinicEvent.title}`}}></div>
                      {/* <p>
                        <strong>{secondQuestionClinicEvent.title}</strong>
                      </p> */}
                      <p style={{ display: "none" }}>{secondQuestionClinicEventTime.speakerName}</p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )
            ) : (
              <div class="skeleton">
                <div class="s-img"></div>
                <div class="s-line first"></div>
                <div class="s-line second"></div>
                <div class="s-line third"></div>
              </div>
            )}
            <hr />

            <div className="row justify-content-md-center pt-2 pb-2">
              <div className={` col-12 col-sm-12 col-md-12 text-center pt-3`}>
                <p>
                  If you have any questions, please{" "}
                  <RouterLink onClick={scrollTop} routeName="Contact">
                    contact us
                  </RouterLink>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MySessions;
