import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {RouterView} from 'mobx-state-router';
import {viewMapBuilder, views} from "./routes";
import Alert from './components/alert/alert';
@inject('rootStore')
class AppSetup extends Component {

    render() {

        const {rootStore: {routerStore}} = this.props;
        const viewMap = viewMapBuilder(views);

        return (
            <div>
                <Alert />
                <RouterView routerStore={routerStore} viewMap={viewMap}/>
            </div>
        );

    }

}

export default AppSetup;
