import React from 'react';
import styles from "./modal.module.css"

class Modal extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            modal : false
        }
        this.onSubmitCommentConfirm = this.onSubmitCommentConfirm.bind(this);
        this.onCancelSubmitComment = this.onCancelSubmitComment.bind(this);
    }
    onCancelSubmitComment(){
        this.props.onCancelSubmitComment();
    }
    onSubmitCommentConfirm(){
        this.props.onSubmitCommentConfirm();
    }
    render() { 
        let {closeModal, isActive, link} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className="modal-body">
                        <p>
                       
                        Thank you for your comment! It will be shared with the faculty, who will do their best to answer as many questions as possible at the Migraine Expert Forum on 18 June.

                        Please click CONFIRM to submit your comment.
                        </p>
                    </div>
                    <div className={`modal-footer  ${styles.modal_footer}`}>
                            <a className={`button`} onClick={this.onSubmitCommentConfirm}>Confirm</a>
                            <a className={`button`} onClick={this.onCancelSubmitComment}>Close</a>
                </div>
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;