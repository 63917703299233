import React from "react"
import { inject, observer } from "mobx-react"
import { AdminHeader } from "../../components/admin/admin-header";
import AdminNav from "../../components/admin/admin-nav";
import Footer from "../../components/_shared/footer-module";
import EventForm from "../../components/admin/event-form";
import UserEventsTable from "../../components/admin/user-events-table";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { data } from "../../data/data";
import { getActiveEvents } from "../../controllers/events-controller";
import { userService } from "../../services/user-service";
import EventRegisterUserForm from '../../components/admin/event-register-user-form';
import AdminTimezoneModal from "../../components/admin/admin-timezone-modal"
import Loader from "../../components/_shared/loading-div"

@inject("rootStore")
@observer
class ManageUser extends React.Component {
    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            user: null,
            events: [],
            shouldUpdate: false,
            showUserTimezoneSelector: false,
            timezoneSelector: {
                show: false,
                confirm: async () => {},
                timeZoneFromUser: ""
            },
            eventsLoading: true
        }
        this.registerUserToEvent = this.registerUserToEvent.bind(this);
    }
    static routeName = "ManageUser";
    static routePathPattern = "/admin/users/:id";

    async componentWillMount() {
        let role = this.authStore.getRole();
        if (role !== ADMIN_USER_ROLE && role !== SUPER_ADMIN_USER_ROLE) {
            this.routerStore.goTo("home");
            return;
        }
        let { params } = this.routerStore.routerState;
        if (!params || Object.keys(params).length < 1) {
            this.routerStore.goTo("admin");
            return;
        }
        await this.getSelectedUser(params.id);

        await this.getAllEvents();

        this.setState({eventsLoading: false})
    }
    getAllEvents = async () => {
        let events = await getActiveEvents()
        this.setState({ events })
    }
    getSelectedUser = async (id) => {
        let users = await userService.listAllUsers();
        let user = users.find(x => x.Username === id);
        this.setState({ user })
    }

    registerUserToEvent (eventId, timeId) {
        let { events, user } = this.state;
        let updatedEvents = [];

        for(let item of events){
            if(item.id === eventId){
                for(let time of item.eventTimes){
                    if(time.id === timeId){
                        time.registeredUsers.push(user.Username);
                    }
                }
            }
            updatedEvents.push(item);
        }

        this.setState({ events: updatedEvents, shouldUpdate: true});

    }

    updateEvents = async () => {
        await this.getAllEvents();
    }

    openUserTimezoneSelector = (callback, timeZoneFromUser) => {
        this.setState({timezoneSelector: {...this.state.timezoneSelector, show: true, confirm: callback, timeZoneFromUser: timeZoneFromUser}})
    }

    closeUserTimezoneSelector = () => {
        this.setState({timezoneSelector: {...this.state.timezoneSelector, show: false}})
    }

    render() {
        let { events, user, timezoneSelector, eventsLoading } = this.state;
        return (
            <div>
                {timezoneSelector.show && (
                    <AdminTimezoneModal 
                        shouldShow={timezoneSelector.show}
                        timeZoneFromUser={timezoneSelector.timeZoneFromUser}
                        close={this.closeUserTimezoneSelector}
                        user={user}
                        context={this}
                    />
                )}
                <div className="admin header">
                    <span className={`${this.state.isActive ? `${"active"}` : ""}   `} id="menu-action" >
                        <ViewHeadlineIcon />
                    </span>
                    <div className="">
                        <AdminHeader authStore={this.authStore} />
                    </div>
                </div>
                <AdminNav />
                <div className="main" >
                    {eventsLoading ? (
                        <Loader />
                    ) : (
                        <div style={{ minHeight: "86vh" }}>
                            {events.length > 0 && 
                                <EventRegisterUserForm user={user} events={events} registerUserToEvent={this.registerUserToEvent} context={this} />
                            }
                            {user && events.length > 0 &&
                                <UserEventsTable user={user} events={events} context={this} />
                            }
                        </div>
                    )}
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ManageUser;