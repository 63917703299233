import {action, observable} from "mobx";
import {
    persistedStore,
    persistStoreUpdatesToLocalStorage,
    revertStoresPersistence,
    storeFromSchema
} from "./stores-utils";
import {AppSchema} from "./store-schemas";

/**
 * sd
 * by kevinbarasa
 */
export default class AppStores {

    constructor() {
        // this.persistStoreUpdatesWebWorker();
    }

    //to assist with differentiation during storage to persistence media, if application uses several stores classes
    static namespace = 'AppStores_';

    persistAppStoreStoreUpdatesTimer = null;

    //NOTE: THIS STORE SHOULD NEVER BE PERSISTED BECAUSE IT HOLDS THE USER'S username AND password
    loginStoreSchema = {
        email: "",
        password: "",

        signUpEmail: '',
        signUpPassword: '',
        signUpPasswordConfirm: '',
        signUpName: '',
        signUpInstitution: '',
        signUpContactInfo: '',

        forgotPasswordEmail: '',

        passwordResetEmail: "",
        passwordResetCode: "",
        passwordResetFirstPassword: "",
        passwordResetSecondPassword: ""
    };

    @observable
    login = storeFromSchema(this.loginStoreSchema);

    @observable
    app = persistedStore(AppStores.namespace + AppSchema(AppStores.namespace).storeName) || storeFromSchema(AppSchema((AppStores.namespace)));

    stores = [
        {store: this.app, storeSchema: AppSchema},
    ];

    @action
    stopStoreUpdatesPersistence = () => {
        clearInterval(this.persistAppStoreStoreUpdatesTimer);
    };

    @action
    persistStoreUpdatesWebWorker = () => {
        this.persistAppStoreStoreUpdatesTimer = setInterval(() => {
            persistStoreUpdatesToLocalStorage(this.stores, this.stopStoreUpdatesPersistence, AppStores.namespace);
        }, 2000);
    };

    @action
    revertStores = () => {
        revertStoresPersistence(this.stores, this.stopStoreUpdatesPersistence);
    };

}
