import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/piero-barbanti_red.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="Piero_Barbanti photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Piero Barbanti, MD, PhD</h3>
                  <p style={{ color: "#f61a19" }}>Rome, Italy</p>
                  <p>
                    Piero Barbanti is Associate Professor of Neurology and Director of the Headache and Pain Unit at 
                    University-IRCCS San Raffaele in Rome, President of the Italian Headache Sufferers Association and 
                    the founder and coordinator of the Italian Migraine Registry (I-GRAINE). His clinical and research 
                    activities are primarily focused on the pharmacological treatment of headache. He has authored 159 
                    peer-reviewed publications, 4 books and numerous chapters (h-index: 37).
                  </p>
                  <p>
                    Prof. Barbanti takes part in peer-review activities for a range of international journals, including JAMA 
                    Neurology, Lancet Neurology, Neurology, Cephalalgia, Headache, Cochrane Reviews, Journal of 
                    Headache and Pain, European Journal of Neurology, Expert Review of Neurotherapeutics, and 
                    Journal of Neurology, Neurosurgery and Psychiatry. He is the principal investigator of 62 
                    pharmacological clinical trials (Phases IIb, III, and IV) in the areas of headache, neuropathic pain, 
                    dementia, multiple sclerosis, epilepsy and movement disorders.
                  </p>
                  <p>
                    In addition to his clinical and research duties, Prof. Barbanti also works on educational activities – 
                    informing and educating the public on the topics of headaches and neurology. He has performed 
                    these activities through national radio, television networks, national newspapers and magazines. 
                  </p>
                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
