import { pdfService } from '../services/pdf-service'
import {handleRegister, handleEditUserDetails} from '../controllers/app-controller'
import { Auth } from "aws-amplify";
import { contentService } from "../services/content-service"; 
import { emailService} from '../services/email-service'
import {validateEmail} from "../util/validation"
import { userService } from '../services/user-service';

export function updateViewModel(context, model, field, value) {
    context.setState({
        viewModel: {
            ...updateValue(model, field, value)
        }
    });
}

export function updateRequredList(context, field) {

    var list = context.state.requiredList;
    if(list.find(x => x.name == field.name) && (field.name == "hotelCheckinDate" || field.name == "hotelCheckOutDate" )){
        return;
    }

    list.push(field);
    context.setState({
        requiredList: list

    }, () => {

    });

}
export function removeFromRequredList(context, field) {
    var list = context.state.requiredList;
    var filteredItems = list.filter(item => item.name !== field)


    context.setState({
        requiredList: filteredItems

    }, () => {

    });
}
export function updateValue(model, field, value) {
    var names = field?.split('.');
    if (names?.length === 3) {
        var childIndex = names[1];
        var name = names[2];
        var childModel = model[names[0]];
        childModel = childModel?.map((item, index) => { return index == childIndex ? { ...item, [name]: value, isDirty: true } : item; });
        return {

            ...model,
            [names[0]]: childModel
        }
    }

    let updatedModel = { ...model, [field]: value };
    return updatedModel;
}

async function userExists(user, context) {
    return await userService.getUserByUsername(user.email).then(
        (result) => {
            context.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'A user with that email aready exists' });
            return true;
        },
        (error) => {
            return false;
        });
}

export async function submitForm(context) {
    context.setState({invalidFormMessage: null})
    let {  formStore } = context.props.rootStore;
    let { viewModel } = context.state;

    if (await isFormInValid(context, formStore)) {
        context.setState({showLoading: false});
       

        context.setState({invalidFormMessage: "Please fill in all required fields"});
        return;
    }
    if(!viewModel.aggreedToCommunicate){
        context.setState({showLoading: false});
        context.setState({ invalidFormMessage: "Please agree to your email being used to communicate details about this event"})
        return;
    }
    const {type, email, confirmEmail, password, confirmPassword} = viewModel;

    formStore.addForm(viewModel);

    if(type === "register"){

        if(!validateEmail(email.trim())){
            context.setState({showLoading: false});
            context.setState({invalidFormMessage: "Email field is invalid"})
            return;
        }
        
        const exists = await userExists(email.toLowerCase().trim(), context);

        if(exists){
            context.setState({showLoading: false});
            context.setState({invalidFormMessage: "A user with that email already exists"})
            return;
        }

        if(!validateEmail(confirmEmail.trim())){
            context.setState({showLoading: false});
            context.setState({invalidFormMessage: "Confirm email field is invalid"})
            return;
        }

        if(email.trim() !== confirmEmail.trim()){
            context.setState({showLoading: false});
            context.setState({invalidFormMessage: "Emails do not match"})
            return;
        }
    
        if(password.trim() !== confirmPassword.trim()){
            context.setState({showLoading: false});
            context.setState({invalidFormMessage: "Passwords do not match"})
            return;
        }

        if(password.trim().length < 8){
            context.setState({showLoading: false});
            context.setState({invalidFormMessage: "Password should have a minimum of 8 characters"})
            return;
        }

        if(!viewModel.aggreedToCommunicate){
            context.setState({showLoading: false});
            context.setState({ invalidFormMessage: "Please agree to your email being used to communicate details about this event"})
            return;
        }

        return await handleRegister(viewModel,context);
    }
    
    if(type === "edit"){
        return await handleEditUserDetails(viewModel,context);
    }

}

export async function isFormStepValid(context){
    let { formStore } = context.props.rootStore;
    var isInvalid = await isFormInValid(context, formStore, true);
    return !isInvalid;
}

export async function submitEventEditForm(context){
    let { routerStore, formStore, alertStore } = context.props.rootStore;
    let { viewModel, requiredList } = context.state;
    if (await isFormInValid(context, formStore)) {
        return;
    } 
    formStore.addForm(viewModel);
    return await handleSubmitEventEditForm(viewModel, context);
}
export async function submitEventTimeEditForm(context, eventId) {
    let { routerStore, formStore, alertStore } = context.props.rootStore;
    let { viewModel } = context.state;
    if (await isFormInValid(context, formStore)) {
        return false;
    } 
    formStore.addForm(viewModel);
    return await handleSubmitEventTimeEditForm(viewModel, eventId);
}

export async function deleteEventDate(context, eventId) {
    let { routerStore, formStore, alertStore } = context.props.rootStore;
    let { viewModel } = context.state;
    if (await isFormInValid(context, formStore)) {
        return;
    } 
    formStore.addForm(viewModel);
    return await handleDeleteEventDate(viewModel, eventId);
}

export async function handleSubmitEventEditForm(viewModel, context) {
    viewModel = {
        ...viewModel,
        dateModified: new Date().toISOString(),
        statusId: viewModel.statusId || 1
    }

    const updatedItem = await contentService.updateEventDetails(viewModel).then((result) => {
        return viewModel;
    }, (error) => {
        return null;
    });
    return updatedItem
}
export async function handleDeleteEventDate(viewModel, eventId) {
    viewModel = {
        ...viewModel,
        dateModified: new Date().toISOString(),
        statusId: viewModel.statusId || 1
    }
    var result = await contentService.deleteEventDate(eventId, viewModel.id).then((result) => {
        return true;
    }, (error) => {
        return false;
    });
    return result;
}
export async function handleSubmitEventTimeEditForm(viewModel, eventId) {
    if (!viewModel.id || viewModel.id.length <= 0){
        let results = await contentService.addEventDate(viewModel, eventId);
        return results;
    }
    viewModel = {
        ...viewModel,
        dateModified: new Date().toISOString(),
    }

    try {
        await contentService.updateEventDate(viewModel, eventId)
        return viewModel
    }catch(e){
        return null
    }
}
/*start submit mass email form */
export async function submitMassEMailForm(context) {

    let { routerStore, formStore, alertStore } = context.props.rootStore;
    let { viewModel, requiredList } = context.state;
    if (await isFormInValid(context, formStore)) {
        return;
    }
    formStore.addForm(viewModel);
    return await handleSubmitMassEMailForm(viewModel, context);
}

export async function handleSubmitMassEMailForm(viewModel, context) {
    try {
        await emailService.createMassEmail(viewModel)
        return true
    }catch(e){
        return false
    }
}

export async function submitMassEMailEditForm(context) {

    let { routerStore, formStore, alertStore } = context.props.rootStore;
    let { viewModel, requiredList } = context.state;
    if (await isFormInValid(context, formStore)) {
        throw new Error("Ensure you fill the title and body fields");
    }
    formStore.addForm(viewModel);
    return await handleSubmitMassEMailEditForm(viewModel, context);
}



export async function submitContactUsForm(context) {

    let { formStore } = context.props.rootStore;
    let { viewModel } = context.state;
    var user = null;
    try{
        user = await Auth.currentAuthenticatedUser();

    }catch(e){

    }
    var validEmail = false;

    if(user && user.username ){
        validEmail = true;
        viewModel.email =  user.attributes['email'];
        viewModel.fullName = user.attributes['custom:firstName'] + " " + user.attributes['custom:lastName']

    }else{
        var validEmail = validateEmail(viewModel.email);
    }
    
    if (await isFormInValid(context, formStore) || !validEmail) {
        return false;
    }
    formStore.addForm(viewModel);
    return await handleSubmitContactUsForm(viewModel);
}

export async function handleSubmitContactUsForm(viewModel) {
    const status = await emailService.contactUsEmail(viewModel).then((result) => {
        return true;
    }, (error) => {
        return false;
    });
    return status
}
export async function handleSubmitMassEMailEditForm(viewModel, context) {
    try {
        await emailService.updateMassEmail(viewModel)
        return true
    }catch(e){
        return false
    }
}
export async function isCommentFormInvalid(context){
    let {  formStore } = context.props.rootStore;

    return await isFormInValid(context, formStore );
}
/* end submit mass email form */

export async function isFormInValid(context, formStore, isCheckingStep) {
    let { viewModel, requiredList,activeSlide } = context.state;
    let password = viewModel.password;
    
    var isInvalid = false;
    if (requiredList && requiredList.length > 0) {
        var items = requiredList.filter(item => (item?.name !== "aggreedToCommunicate"));

        for(let i = 0; i < items.length; i++){
            let item = items[i];

            var names = item.name.split('.');
            if (names.length === 3 && !viewModel[names[0]][names[1]]) {
                continue;
            }
            var value = names.length === 3 ? viewModel[names[0]][names[1]][names[2]] : viewModel[item.name];
            if(!correctOptionsSelected(item, value)){
                if(isCheckingStep && item.required?.group != activeSlide){
                }else{
                context.setState({invalidFormMessage: "Please fill in all required fields", formStepIsValid: false});
                isInvalid = true;
                break;
                }
                    
            }


            // Validate password before proceeding to the next step
            const passwordErrors = validatePassword(password); // Get validation errors
            const hasErrors = passwordErrors.length > 0; 
            if (hasErrors) {
                if(isCheckingStep && item.required?.group !== activeSlide){
                   }else{
                    context.setState({
                        invalidFormMessage: "Password must meet all the requirements to proceed",
                        formStepIsValid: false, // Ensure the correct case and spelling
                    });
                    isInvalid = true;
                   }    
            }

            if (!value && checkLength(item, value) ) {
                if(isCheckingStep && item.required?.group != activeSlide){
                   }else{
                    context.setState({invalidFormMessage: "Please fill in all required fields", formStepIsValid: false});
                    isInvalid = true;
                    break;
                   }    
            }

            if( item.required?.fieldToMatch && value != viewModel[item.required.fieldToMatch]){
                if(isCheckingStep && item.required?.group != activeSlide){
                
                   }else{
                    context.setState({invalidFormMessage: "Please fill in all required fields", formStepIsValid: false});
                    isInvalid = true;
                    break;
                   }
            }
            if(isCheckingStep && item.required?.validateEmailExists && item.name == 'email' && validateEmail(viewModel[item.name].toLowerCase().trim()) ){
                let doesNotExist = await checkUserDoesNotExist(viewModel[item.name].toLowerCase().trim());
                if(!doesNotExist){
                    context.setState({invalidFormMessage: "A user with that email already exists", formStepIsValid: false, userExists: true});
                    isInvalid = true;
                    break;
                }
            }
        }
    }

    formStore.setInvalid(isInvalid)

    return isInvalid;
}
async function checkUserDoesNotExist(email){
    const code = '000000XXXX'
    let result = await Auth.confirmSignUp(email, code, {
        forceAliasCreation: false
    }).then((result) => {return false})
    .catch( err => {
            switch ( err.code ) {
                case 'UserNotFoundException':
                    return true;
                case 'NotAuthorizedException':
                    return false;
                case 'AliasExistsException':
                    return false;
                case 'CodeMismatchException':
                    return false;
                case 'ExpiredCodeException':
                    return false;
                default:
                    return false;
            }
        } )
        return result;
}

export function validatePassword(value) {
    const errors = [];
    const password = value || "";

    if (password === "") {
        return errors; // No errors if the input is empty
    }

    // Check for minimum length of 12 characters
    if (password.length < 12) {
        errors.push('A minimum of 12 characters is required for the password.');
    }

    // Check for a mix of letters and numbers
    const hasLetters = /[a-zA-Z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    if (!hasLetters || !hasNumbers) {
    errors.push('Password must contain both letters and numbers.');
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
    errors.push('At least one uppercase letter required.');
    }

    // Check for at least one special character
    if (!/[!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]/.test(password)) {
    errors.push('At least one special character required.');
    }

    return errors;
};

function hideKeyboard() {
    document.activeElement.blur();
    Array.prototype.forEach.call(document.querySelectorAll('input, textarea'), function (it) {
        it.blur();
    });
}
function checkLength(required, value) {
    var maxLength = required.maxLength;

    if (maxLength) {

        return value.length >= maxLength;
    }

    return true;
}

function correctOptionsSelected(item, value) {
    
    let {minOptions,maxOptions } = item.required;
    if(!minOptions &&  !maxOptions){
        return true;
    }
    return (!minOptions ||  value.length >= minOptions) && (!maxOptions ||  value.length <= maxOptions);
  
}
export function renderPDf(context, type, isForPrint) {

    var isLandscape = context.state.isA5;

    let element = document.getElementById("pdfDocument");
    var dpi = isForPrint ? 300 : 96
    var scale = isForPrint ? 4 : 1.5
    pdfService.generatePdf(type, isLandscape, element, dpi, scale).then(() => {
        if (process.env.NODE_ENV === 'development') {
            return;
        }
        context.setState({ showPDF: false }, () => {

            context.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'PDF Created' });

        });

    });

}