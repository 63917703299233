import React from "react";
import styles from "./steeringCommittee.module.css";
import Speaker1 from "../../../../media/images/faculty/Peter.png";
import Speaker2 from "../../../../media/images/faculty/Patricia.png";
import Speaker3 from "../../../../media/images/faculty/Messoud.png";
import AnimationPath from "../../../../media/images/Group33.svg";
import { inject, observer } from "mobx-react";
import Icon1 from "../../../../media/images/branding3.png";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Animation from "-!svg-react-loader!./animation.svg";

// eslint-disable-next-line import/no-webpack-loader-syntax
import PostRegistrationAnimation from "-!svg-react-loader!./postRegistrationAnimation.svg";

@inject("rootStore")
@observer
class SteeringCommittee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
    };
  }
  async componentDidMount() {
    await this.isAuthenticated();
  }
  isAuthenticated = async () => {
    let loggedIn = await this.props.rootStore.authStore.isAuthenticated();
    this.setState({ isAuthenticated: loggedIn });
  };
  render() {
    return (
      <div className={styles.wrap} id="SteeringCommittee">
        <div className="row">
          <span className={styles.icon1}></span>
          <span className={styles.icon2}></span>
        </div>

        <div className={`container ${styles.container}`}>
          <div
            style={{
              border: "1px solid #fff",
              padding: "10px 5px 5px",
              borderRadius: "5px",
              marginBottom: "30px",
              zIndex: "999", 
              position: "relative"
            }}
          >
            <p className="mb-1 mt-1">
              <strong>
                This website is intended for use by Healthcare Professionals
                only.
              </strong>
            </p>
            <p>
              {" "}
              <strong>
                This promotional meeting is organised and funded by Teva
                Pharmaceuticals Europe B.V.
              </strong>
            </p>
            <p  >
              Some sessions may contain product information. <br />
              For the fremanezumab UK
              prescribing information and adverse events reporting, please see
              footer of the page.
              <br />
            </p>
          </div>
          <h1 className={"graphical_heading"}>Steering Committee</h1>
          <div className="row pt-2 mt-2">
            <div className="col-12 col-sm-12 col-md-3">
              <img
                alt="placeholder image"
                title="placeholder image"
                src={Speaker1}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-9 text-left">
              <h3>Peter J Goadsby, MD, PhD, FRS</h3>
              <p style={{ color: "#f89410" }}>London, UK </p>
              <p>
                Peter Goadsby is Director, NIHR King’s Clinical Research Facility, 
                and Professor of Neurology, King’s College London and Honorary 
                Consultant Neurologist, King’s College Hospital. He is an 
                Honorary Consultant Neurologist at the Hospital for Sick 
                Children, Great Ormond St, London, UK. He is Professor Emeritus 
                of Neurology, University of California, Los Angeles.
              </p>
              <p>
                His major experimental and clinical research interests are the 
                basic mechanisms of primary headache disorders including migraine 
                and cluster headache, and the translation of mechanistic insights 
                into better management.
              </p>
              <p>
                Prof. Goadsby obtained his medical degree and training at the 
                University of New South Wales (UNSW), Australia. His neurology 
                training was with James W. Lance and his clinical neurophysiology 
                training was with David Burke. After post‐doctoral work in New 
                York with Don Reis at Cornell and with Jacques Seylaz at 
                Universite VII, Paris, and post‐graduate neurology training 
                at Queen Square, London with C David Marsden, Andrew Lees, 
                Anita Harding and W. Ian McDonald, he returned to UNSW and 
                the Prince of Wales Hospital, Sydney as a Consultant 
                Neurologist, and went on to become Associate Professor of 
                Neurology. He was appointed a Wellcome Senior Research 
                Fellow at the Institute of Neurology, University College 
                London in 1995. He was Professor of Clinical Neurology 
                and Honorary Consultant Neurologist at the National 
                Hospital for Neurology and Neurosurgery, Queen Square, 
                London. He was Professor of Neurology, at University of 
                California, San Francisco.
              </p>
            </div>
          </div>

          <div className="row pt-4 mt-4">
            <div className="col-12 col-sm-12 col-md-9 text-left  order-2 order-md-1">
              <h3>Patricia Pozo-Rosich, MD, PhD</h3>
              <p style={{ color: "#f89410" }}>Barcelona, Spain </p>
              <p>
                Patricia Pozo-Rosich is a specialist in Neurology. She is head 
                of Section in the Neurology Department and Director of the 
                Migraine Adaptive Brain Center at the Vall d’Hebron University 
                Hospital in Barcelona. She is the Group Leader of the Headache 
                Research Laboratory at the Vall d’Hebron Institute of 
                Research (VHIR), which belongs to the Universitat Autonoma 
                of Barcelona (UAB), and is currently the Coordinator of 
                the eCORE “Brain, Mind & Behaviour” at VHIR.
              </p>
              <p>
                She is the Honorary Secretary of the Executive Board of 
                the International Headache Society and co-chair of the 
                European Academy of Neurology Scientific Panel on Headache, 
                a past member of the European Headache Federation Council, 
                and a past Coordinator of the Spanish Headache Study Group 
                of the Spanish Neurological Society. Additionally, she is 
                a member of the Scientific Advisory Board of the Fundación Lilly in Spain.
              </p>
              <p>
                She is the Editor of Headache for Revista de Neurologia, and 
                Associate Editor of Cephalalgia, Headache, Neurologia and 
                The Journal of Headache and Pain.
              </p>
              <p>
                Dr. Pozo-Rosich’s research focuses on better understanding 
                the adaptive brain, in particular  migraine as a neurocognitive 
                and neurosensorial disorder, from a multidisciplinary approach 
                using genetics, molecular biology, neuroimaging, and electrophysiology. 
                In this regard, she is a member of the International Headache 
                Genetics Consortium. In 2019 she was awarded the “Scientfic Prize 
                for Headache Research” from the Spanish Neurological Society. 
                She has also been awarded research grants from the la Caixa Foundation, 
                Fondo de Investigaciones Sanitarias (ISCIII), ERANet Neuron, PERIS 
                grant, FEDER, la Marató TV3 Foundation, AGAUR, Mutual Medica and, 
                the Migraine Research Foundation. She has been the principal 
                investigator of more than 45 clinical trials in phases II, III and IV. 
              </p>
              <p>
                She educates neurologists, other physicians, and patients with the 
                goal of improving the quality of life of patients suffering from 
                headache, and helping to reduce the stigma of migraine. She is 
                the founder and developer of “Mi dolor de cabeza”, a website 
                dedicated to headache for patients and physicians.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-3 order-1 order-md-2">
              <img alt="placeholder image" src={Speaker2} />
            </div>
          </div>

          <div className="row  pt-4 mt-4">
            <div className="col-12 col-sm-12 col-md-3">
              <img alt="placeholder image" src={Speaker3} />
            </div>
            <div className="col-12 col-sm-12 col-md-9 text-left mb-5">
              <h3>Messoud Ashina, MD, PhD, DMSc, FEAN</h3>
              <p style={{ color: "#f89410" }}>Copenhagen, Denmark </p>
              <p>
                Messoud Ashina holds the position of Professor of Neurology 
                and serves as the Director of the Human Migraine Research 
                Unit at the Department of Neurology & Danish Headache 
                Center, Rigshospitalet, the University of Copenhagen, 
                Denmark. His distinguished career includes the roles of 
                Past President of the International Headache Society 
                (2019–2021) and Past General Secretary of the European 
                Headache Federation. Prof. Ashina is recognized as a 
                Fellow of the European Academy of Neurology. He also 
                contributes as an Associate Editor for prestigious 
                journals including Brain, The Journal of Headache and Pain.
              </p>
              <p>
                Prof. Ashina's extensive research focuses on understanding 
                the complexities of migraine and cluster headache 
                pathophysiology. He investigates the mechanisms of action 
                of antimigraine medications, conducts pioneering work 
                with experimental migraine models, utilizes functional 
                neuroimaging techniques, and explores novel targets for 
                antimigraine drug development. His scholarly contributions 
                comprise over 600 papers, abstracts, and book chapters, 
                primarily focusing on headaches, including both migraine and cluster headache.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SteeringCommittee;
