import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/yellow.png"
class Modal extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { closeModal, isActive } = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return (

            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>

                        <div className={`modal-body ${styles.modalbody}`}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 text-center">
                                    <img alt="Hans photo" src={ProfilePhoto} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-8">
                                    <h3>Antonio Russo, MD, PhD 
                                    </h3>
                                    <p style={{ color: "#f61a19" }}>
                                    Naples, Italy
                                    </p>
                                    <p>
                                    Antonio Russo is Full Professor of Neurology and Director of the Headache Center at the University of Campania Luigi Vanvitelli in Naples, Italy. He is the author of about 200 scientific articles and has been awarded important international prizes, such as the Enrico Greppi Award by the European Headache Federation in 2019 and the Harold G. Wolff Lecture Award by the American Headache Society in 2020.
                                    </p>
                                    <p>
                                    Prof. Russo is a member of the Headache Panel of the European Academy of Neurology and serves as Chief Editor of the Headache and Neurogenic Pain section of the Frontiers in Neurology journal and as Deputy Editor of Confinia Cephalalgica et Neurologica. He graduated with honours in medicine in 2003, earned the title of Specialist in Neurology with honours in 2009, and earned a PhD in experimental pathophysiology and neuroscience in 2013.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className={`modal-footer ${styles.modal_footer}`}>
                            <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default Modal;