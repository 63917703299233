import { config, serviceUrls, emailUrls} from "../util/config";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import amplify, { API } from 'aws-amplify';
import { Auth } from "aws-amplify";
import { data } from "../data/data";

export const emailService = {
    sendMassEmail,
    getAllEmails,
    getEmailById,
    createMassEmail,
    deleteMassEmail,
    updateMassEmail,
    sendCommentEmail,
    contactUsEmail
};

async function sendMassEmail(model) {
    var result  = await axios({
        method: 'post',
        url: config.apiUrl + emailUrls.sendMassEmail,
        data: { model: model },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    if(result == false){
        let wait = await new Promise(resolve => setTimeout(resolve, 20000));
        result = true;
    }
    return result;
}

async function sendCommentEmail(model) {
    var result = await axios({
        method: 'post',
        url: config.apiUrl + emailUrls.sendCommentEmail,
        data: { model: model },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    return result;
}
async function getAllEmails() {
    let data = [];

    await axios({
        method: "get",
        url: config.apiUrl + emailUrls.getAllEmails,
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    }).then((response) => {
        data = response.data;
    }, (error) => { });

    return data;
}

async function getEmailById() {
    let data = [];

    await axios({
        method: "get",
        url: config.apiUrl + emailUrls.getEmailById,
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    }).then((response) => {
        data = response.data;
    }, (error) => { });

    return data;
}

async function createMassEmail(model) {
    await axios({
        method: 'post',
        url: config.apiUrl + emailUrls.createMassEmail,
        data: { model: model },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
    },
        (error) => {
        },
    );
    return;
}

async function updateMassEmail(model) {
    try{
        await axios({
            method: 'post',
            url: config.apiUrl + emailUrls.updateMassEmail,
            data: { model: model },
            headers: {
                "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
        })
    }catch(e){
        console.log(e);
    }
    
    return;
}

async function deleteMassEmail(id){
    await axios({
        method: 'post',
        url: config.apiUrl + emailUrls.deleteMassEmail,
        data: { model: {id: id} },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
    },
        (error) => {
        },
    );

    return;
}

async function contactUsEmail(model){
    await axios({
        method: 'post',
        url: config.apiUrl + emailUrls.contactUsEmail,
        data: { model }
    }).then((response) => {
    },
        (error) => {
        },
    );
}