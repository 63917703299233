import {handleValidateEmailExists} from '../controllers/app-controller';


export const validationHelper ={
    validateEmail,
    validateName,
    validatePassword,
    validateAddress,
};

export function validateEmail(email){
  let regex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  return regex.test(email);
}
function validatePassword(pass){
    return pass.length >= 8;
}
function validateName(name){
    return name.length >= 2;
}
function validateAddress(address){
    return address.length > 0;
}

export async function validateEmailExists(email){
    return await handleValidateEmailExists(email);
}

