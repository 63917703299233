import React from 'react';
import {RouterLink} from 'mobx-state-router';
import {inject, observer} from "mobx-react";

@inject('rootStore')
@observer
class EditButton extends React.Component {
    constructor(props){
      super(props);
      this.handleSubmitform = this.handleSubmitform.bind(this);
      this.handleBackButton = this.handleBackButton.bind(this);
    }
  handleSubmitform = async () => {
    await this.props.handleSubmitform();
  }
  handleBackButton = async () => {
    this.props.rootStore.routerStore.goTo("admin", { id: "events" });
  }
    render() { 
      const { showLoading } = this.props;
    
        return ( 
            <>
             {/* start top buttons html */}
          <div className="row top_buttons ml-0 mr-0">
          <div className="col-12">
          <div className="container">
            <div className="row justify-content-sm-center has_arrow_buttons mt-4 p-2">
              <div className="col-12 col-sm-12 col-md-6 mt-2 text-md-right text-center">
                      <button className="button min-width-unset" onClick={this.handleBackButton}>Back</button>
              </div>
              <div className="col-12 col-sm-12 col-md-6 mt-2 text-md-left text-center">
               
             {showLoading ? <a className="button min-width-unset" >
                        Updating
                </a> : <a className="button min-width-unset" onClick={this.handleSubmitform}>
                Update
                </a>}
              </div>
            
            </div>
          </div>
        
          </div>
          </div>
          {/* end top buttons */}
          </>
         );
    }
}
 
export default EditButton;