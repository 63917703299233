import { inject, observer } from "mobx-react";
import React from "react";
import { Auth } from "aws-amplify";
import {
  getAllEvents,
  bookEvents,
} from "../../../controllers/events-controller";
import { RouterLink } from "mobx-state-router";
import styles from "./practical-workshop.module.css";
import { data } from "../../../data/data";
import EventTimes from "../event-registration/eventTimes";
@inject("rootStore")
@observer
class PracticalWorkshop extends React.Component {
  constructor(props) {
    super(props);
    this.routerStore = this.props.rootStore.routerStore;

    this.state = {
      firstInteractiveWorkshop: "",
      secondInteractiveWorkshop: "",
      firstQuestionClinic: "",
      secondQuestionClinic: "",

      allEvents: [],

      showModal: false,
      user: null,
      eventToBook: null,
      selectedDate: null,
      showLoading: false,
      showTimezone: false,
      opening: false,
    };
  }

  async componentDidMount() {
    document.title =
      "Book session slots | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
    window.scrollTo({ top: 0, behavior: "smooth" });

    let user = await Auth.currentAuthenticatedUser();

    this.setState({ user }, () => {
      getAllEvents().then((allEvents) =>
        this.setState({ allEvents: allEvents }, () => {
          this.setSelectedEvents();
        })
      );
    });
  }
  updateSelectedEvent = async (name, value) => {
    let {
      firstInteractiveWorkshop,
      firstQuestionClinic,
      secondQuestionClinic,
      secondInteractiveWorkshop,
      allEvents,
    } = this.state;

    const activeEvents = (await getAllEvents()).filter(
      (eventItem) => eventItem.statusId !== data.status.deleted
    );
    let ids = [];
    ids[0] = firstInteractiveWorkshop;
    ids[1] = firstQuestionClinic;
    ids[2] = secondQuestionClinic;
    ids[3] = secondInteractiveWorkshop;

    var event = activeEvents.find((event) =>
      event.eventTimes.some((time) => time.id == value)
    );
    var bookedTime = event.eventTimes.find((time) => time.id == value);

    var hasBookedSessionBySpeakerAlready = event.eventTimes.some(
      (time) =>
        time.id != bookedTime.id &&
        ids.includes(time.id) &&
        time.speakerName == bookedTime.speakerName
    );
    if (hasBookedSessionBySpeakerAlready) {
      this.openModal("You cannot book two events by the same faculty");
      return;
    }
    this.setState({ [name]: value });
  };
  setSelectedEvents = () => {
    const { allEvents, user } = this.state;

    var firstInteractiveWorkshop = "";
    var secondInteractiveWorkshop = "";
    var firstQuestionClinic = "";
    var secondQuestionClinic = "";

    let events = allEvents?.filter(
      (item) => item.statusId !== data.status.deleted
    );
    if (events && events.length > 0) {
      events.forEach((event) => {
        event.eventTimes.forEach((item) => {
          let hasBooked = user
            ? item.registeredUsers.includes(user.username)
            : false;

          if (
            hasBooked &&
            event.type === data.eventTypes[0].id &&
            item.time.includes("15:15")
          ) {
            firstInteractiveWorkshop = item.id;
          }
          if (
            hasBooked &&
            event.type === data.eventTypes[0].id &&
            item.time.includes("16:00")
          ) {
            secondInteractiveWorkshop = item.id;
          }
          if (
            hasBooked &&
            event.type === data.eventTypes[1].id &&
            item.time.includes("10:00")
          ) {
            firstQuestionClinic = item.id;
          }
          if (
            hasBooked &&
            event.type === data.eventTypes[1].id &&
            item.time.includes("10:45")
          ) {
            secondQuestionClinic = item.id;
          }
        });
      });
    }
    if (
      firstInteractiveWorkshop ||
      secondQuestionClinic ||
      secondInteractiveWorkshop ||
      firstQuestionClinic
    ) {
      this.routerStore.goTo("myProfile");
    }

    this.setState({
      firstInteractiveWorkshop,
      firstQuestionClinic,
      secondQuestionClinic,
      secondInteractiveWorkshop,
    });
  };

  openModal = async (message) => {
    const { opening } = this.state;

    if (opening) {
      return;
    }

    this.setState({ opening: true });

    this.props.rootStore.alertStore.setAlert({
      type: "alert-danger",
      message: message,
      onDismiss: () => {
        this.props.rootStore.alertStore.clearAlert();
        this.setState({ opening: false });
      },
    });

    this.setState({ showModal: true });
  };
  OnSubmitBookEvents = async () => {
    let { showLoading } = this.state;
    if (showLoading) {
      return;
    }

    var isvalid = this.checkFormIsValid();
    if (!isvalid) {
      return;
    }
    this.setState({ showLoading: true });

    let {
      firstInteractiveWorkshop,
      firstQuestionClinic,
      secondQuestionClinic,
      secondInteractiveWorkshop,
    } = this.state;

    const activeEvents = (await getAllEvents()).filter(
      (eventItem) => eventItem.statusId !== data.status.deleted
    );

    //First interactive
    var firstInteractiveWorkshopEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[0].id)
      .find((x) =>
        x.eventTimes.find((time) => time.id == firstInteractiveWorkshop)
      );
    var firstInteractiveWorkshopEventTime =
      firstInteractiveWorkshopEvent.eventTimes.find(
        (time) => time.id == firstInteractiveWorkshop
      );

    //secong interactive
    var secondInteractiveWorkshopEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[0].id)
      .find((x) =>
        x.eventTimes.find((time) => time.id == secondInteractiveWorkshop)
      );
    var secondInteractiveWorkshopEventTime =
      secondInteractiveWorkshopEvent.eventTimes.find(
        (time) => time.id == secondInteractiveWorkshop
      );

    //first clinic
    var firstQuestionClinicEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[1].id)
      .find((x) => x.eventTimes.find((time) => time.id == firstQuestionClinic));
    var firstQuestionClinicEventTime = firstQuestionClinicEvent.eventTimes.find(
      (time) => time.id == firstQuestionClinic
    );

    //second  clinic
    var secondQuestionClinicEvent = activeEvents
      .filter((x) => x.type == data.eventTypes[1].id)
      .find((x) =>
        x.eventTimes.find((time) => time.id == secondQuestionClinic)
      );
    var secondQuestionClinicEventTime =
      secondQuestionClinicEvent.eventTimes.find(
        (time) => time.id == secondQuestionClinic
      );

    var model = {
      firstInteractiveWorkshopEvent: firstInteractiveWorkshopEvent,
      firstInteractiveWorkshopEventTime: firstInteractiveWorkshopEventTime,

      secondInteractiveWorkshopEvent: secondInteractiveWorkshopEvent,
      secondInteractiveWorkshopEventTime: secondInteractiveWorkshopEventTime,

      firstQuestionClinicEvent: firstQuestionClinicEvent,
      firstQuestionClinicEventTime: firstQuestionClinicEventTime,

      secondQuestionClinicEvent: secondQuestionClinicEvent,
      secondQuestionClinicEventTime: secondQuestionClinicEventTime,
    };

    await bookEvents(model, this);

    getAllEvents()
      .then((allEvents) => {
        this.setState({ allEvents: allEvents }, () => {
          this.props.rootStore.contentStore.addEvents(allEvents);
          this.props.rootStore.routerStore.goTo("EventBookingThankYouPage", {});
        });
      })
      .catch((err) => {
        this.setState({ showLoading: false });
        this.closeModal();
      });
  };
  checkFormIsValid = () => {
    let {
      firstInteractiveWorkshop,
      firstQuestionClinic,
      secondQuestionClinic,
      secondInteractiveWorkshop,
    } = this.state;
    if (
      !firstInteractiveWorkshop ||
      !firstQuestionClinic ||
      !secondQuestionClinic ||
      !secondInteractiveWorkshop
    ) {
      this.openModal("Please select two sessions you wish to attend per day");
      return false;
    }
    return true;
  };
  render() {
    const {
      allEvents,
      user,
      showLoading,
      firstInteractiveWorkshop,
      secondInteractiveWorkshop,
      firstQuestionClinic,
      secondQuestionClinic,
    } = this.state;

    const workshopEvents = allEvents
      ?.filter((event) => event.type == data.eventTypes[0].id)
      .sort((a, b) => a.sortOrder - b.sortOrder);
    const clinicEvents = allEvents
      ?.filter((event) => event.type == data.eventTypes[1].id)
      .sort((a, b) => a.sortOrder - b.sortOrder);

    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
      <div
        className={`page-wrapper practical_workshop ${styles.practical_workshop}`}
      >
        {allEvents && allEvents.length && (
          <div className="container">
            <div className={"row"}>
              <div className={"col-12 mt-4 pt-4"}>

                <div className="top_section text-center mt-5 pt-5">
                <div
                style={{
                  border: "1px solid #fff",
                  padding: "10px 5px 5px",
                  borderRadius: "5px",
                  marginBottom: "30px",
                }}
              >
                <p className="mb-1 mt-1">
                  <strong>
                    This website is intended for use by Healthcare Professionals
                    only.
                  </strong>
                </p>
                <p>
                  {" "}
                  <strong>
                    This promotional meeting is organised and funded by Teva
                    Pharmaceuticals Europe B.V.
                  </strong>
                </p>
                <p  >
                  Some sessions may contain product information. <br />
                  For the fremanezumab UK
                  prescribing information and adverse events reporting, please
                  see footer of the page.
                  <br />
                </p>
              </div>
                  <h1 className="graphical_heading">Book Session Slots</h1>
                </div>
              </div>
            </div>

            <hr />
            <div
              className="row text-center mb-4 mt-4"
              style={{ padding: "1rem 0" }}
            >
              <span className={styles.icon1}></span>
              <span className={styles.icon2}></span>
              <div className="col-12">
                <h2 className=" ">
                  Day 1: Friday 4 October <br /> Interactive Workshops
                </h2>
                <p>Please select the two interactive workshops you would like to attend on Day 1.<sup>*</sup></p>
              </div>
            </div>

            {
              <EventTimes
                user={user}
                eventsType={"Interactive workshops"}
                allEvents={workshopEvents}
                modal={{
                  open: this.openModal,
                  close: this.closeModal,
                }}
                firstEvent={firstInteractiveWorkshop}
                secondEvent={secondInteractiveWorkshop}
                updateSelectedEvent={this.updateSelectedEvent}
              ></EventTimes>
            }

            <hr />
            <div className="row text-center mt-5">
              <div className="col-12 mt-3 mb-3">
                <h2 className=" ">
                  Day 2: Saturday 5 October <br /> Question Clinics
                </h2>
                <p>Please select the two question clinics you would like to attend on Day 2.<sup>*</sup></p>
              </div>
            </div>

            {
              <EventTimes
                user={user}
                eventsType={"Question Clinics"}
                allEvents={clinicEvents}
                modal={{
                  open: this.openModal,
                  close: this.closeModal,
                }}
                firstEvent={firstQuestionClinic}
                secondEvent={secondQuestionClinic}
                updateSelectedEvent={this.updateSelectedEvent}
              ></EventTimes>
            }

            <hr />
            <div className="row text-center mt-5 pb-5">
              <div className="col-12">
                <p>
                <sup>*</sup>All spaces are allocated on a first come, first served basis. 
                  Please contact the team via the{" "}
                  <RouterLink
                    onClick={scrollTop}
                    routeName="Contact"
                    style={{ color: "#f89410 " }}
                  >
                    {" "}
                    contact page
                  </RouterLink>{" "} <br className="hide-for-devices"/>
                  if you have any questions.
                </p>
                <p>
                After submitting your preferences, you can view your booked breakout 
                sessions by selecting <br/> ‘My sessions’ which will appear at the top of the page.
                </p>
                {showLoading ? (
                  <button className={`button ${styles.yellow}`}>
                    SUBMITTING...
                  </button>
                ) : (
                  <button
                    className={`button ${styles.yellow}`}
                    onClick={this.OnSubmitBookEvents}
                  >
                    {showLoading ? "SUBMITTING..." : "SUBMIT"}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PracticalWorkshop;
