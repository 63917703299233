import React from "react";
import { RouterLink } from "mobx-state-router";
import { inject, observer } from "mobx-react";
import moment from "moment";
import FormWrapper from "../templates/form-wrapper";
import Branding1 from "../media/images/branding1.png";
import Branding2 from "../media/images/branding2.png";
import Branding3 from "../media/images/branding3.png";
import styles from "../components/forms/logIn.module.css";
import { data } from "../data/data";

@inject("rootStore")
@observer
class ThankYou extends React.Component {
  constructor(props) {
    super(props);
    this.routerStore = this.props.rootStore.routerStore;
  }

  static routeName = "EventBookingThankYouPage";
  static routePathPattern = "/thanks-for-booking";

  componentDidMount() {
    document.title =
      "Thank You | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    return (
      <FormWrapper>
        <div className={`${styles.thankyou}`}>
          <div className={styles.curve1}>
            <img alt="Branding 1 image" src={Branding1} />
          </div>

          <div className={styles.curve2}>
            <img alt="Branding 2 image" src={Branding2} />
          </div>

          <div className={styles.thankyou_wrap}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    style={{
                      border: "1px solid #fff",
                      padding: "10px 5px 5px",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <p className="mb-1 mt-1">
                      <strong>
                        This website is intended for use by Healthcare
                        Professionals only.
                      </strong>
                    </p>
                    <p>
                      {" "}
                      <strong>
                        This promotional meeting is organised and funded by Teva
                        Pharmaceuticals Europe B.V.
                      </strong>
                    </p>
                    <p  >
                      Some sessions may contain product information. <br />
                      For the fremanezumab{" "}
                      UK prescribing information and adverse events reporting,
                      please see footer of the page.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4>Thank you for booking</h4>
            <p>Thank you for registering for your breakout sessions. </p>

            <p>
              Should you have any questions, please{" "}
              <RouterLink routeName="Contact"> contact us</RouterLink>.
            </p>
            <RouterLink routeName="home">
              {" "}
              <button className="button red">GO TO HOMEPAGE</button>{" "}
            </RouterLink>

            <div className={styles.curve3}>
              <img alt="Branding 3 image" src={Branding3} />
            </div>
          </div>
        </div>
      </FormWrapper>
    );
  }
}

export default ThankYou;
