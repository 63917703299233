/* eslint-disable react/prop-types,no-unused-vars,no-console,no-undef */
import React from "react";
import FallBackPage from "../fall-back-page";

export default class SafeComponentWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error: error};
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log("caught error --- ", JSON.stringify(this.state.error));
        console.log("has error --- ", JSON.stringify(this.state.hasError));
        console.log("info --- ", JSON.stringify(info));
        console.log("error --- ", JSON.stringify(error));
    }

    render() {
        if (this.state.hasError) {
            return <FallBackPage/>;
        }
        return this.props.children;
    }
}
