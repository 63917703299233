import React from 'react';
import { inject, observer } from "mobx-react";

import Editor from '../editor/editor';
import EditButton from "../forms/editButton"
import { updateViewModel, submitEventEditForm } from '../../util/formHelper'

@inject('rootStore')
@observer
class EventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredList: [],
            viewModel: this.props.viewModel,
            showLoading: false,
        };
        this.handleSubmitform = this.handleSubmitform.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(field, value) {
        updateViewModel(this, this.state.viewModel, field, value);
    }

    handleSubmitform = async () => {
        var result = await submitEventEditForm(this);
        if(result){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully updated event' });
        }else{
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Event was not updated. Kindly retry again' });
        }
    }

    render() {
        const { viewModel } = this.state;
        const { authStore, routerStore, } = this.props.rootStore;
        return (
                <div className='container'>
                    <div className="row justify-content-md-center">
                        <div className={` col-12 col-sm-12 col-md-12`} >
                    <div  className="row">
                        <div className="col-12">
                            <div className={'admin_buttons_wrap'}>
                            <EditButton  handleSubmitform={this.handleSubmitform} showLoading={this.state.showLoading} />
                            </div>
                        </div>
                    </div>
                     <div className="row justify-content-md-center mt-5">
                     <div className="col-md-12 mb-5 mt-5">
                            <h1>{viewModel.title}</h1>
                     </div>
                             
                            </div>
                    
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 text-center">

                                    <Editor type={"text"} onChange={this.onChange} placeholder="Title" name={'title'} value={viewModel.title} context={this} required />
                                </div>

                            </div>
                            
                           
                            {viewModel && 
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 text-center">
                                        <Editor type={"select"} onChange={this.onChange} placeholder="Event type" name={'type'} value={viewModel.type} context={this} required/>
                                    </div>
                                </div>
                            }
                           <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 text-center">

                                    <Editor type={"text"} onChange={this.onChange} placeholder="Sort Order" name={'sortOrder'} value={viewModel.sortOrder} context={this} required />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        );
    }

}

export default EventForm;
