import {RouterState} from "mobx-state-router";
import {DEFAULT_ROUTE,EDIT_PROFILE_ROUTE,HOME_ROUTE,LOGIN_ROUTE} from '../routes'
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../app-config";
export const redirectIfLoggedIn = async (fromState, toState, routerStore) => {

    const {rootStore: {authStore}} = routerStore;

    let isAuthenticated = await authStore.isAuthenticated().then(isAuthenticated => {
        return isAuthenticated;
    });
    const profileRedirect = !!localStorage.getItem('profileRedirect') || false;

    if (isAuthenticated) {
        if(profileRedirect){
            return Promise.reject(new RouterState(EDIT_PROFILE_ROUTE.routeName));
        }
        return Promise.reject(new RouterState(HOME_ROUTE.routeName));
    } else {
      
        authStore.setSignInRedirect(toState);
        return Promise.reject(new RouterState(LOGIN_ROUTE.routeName));
    }

};

export const checkIfAuthenticated = async (fromState, toState, routerStore) => {

    const {rootStore: {authStore}} = routerStore;

    let isAuthenticated = await authStore.isAuthenticated().then(isAuthenticated => {
        return isAuthenticated;
    });

    if (isAuthenticated) {
        return Promise.resolve();
    } else {
        
        authStore.setSignInRedirect(toState);
        return Promise.reject(new RouterState(LOGIN_ROUTE.routeName));
    }

};

export const checkIfAuthenticatedAsAdmin = async (fromState, toState, routerStore) => {

    const {rootStore: {authStore}} = routerStore;

    let isAuthenticated = await authStore.isAuthenticated().then(isAuthenticated => {
        return isAuthenticated;
    });
    let role = authStore.getRole() 
    if (isAuthenticated &&  (role === ADMIN_USER_ROLE ||  role === SUPER_ADMIN_USER_ROLE)) {
        return Promise.resolve();
    } 
    
    else {
        
        authStore.setSignInRedirect(toState);
        return Promise.reject(new RouterState(LOGIN_ROUTE.routeName));
    }

};

