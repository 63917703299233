import React from "react"
import { inject, observer } from "mobx-react"
import { AdminHeader } from "../../../components/admin/admin-header";
import AdminNav from "../../../components/admin/admin-nav";
import Footer from "../../../components/_shared/footer-module";
import EventForm from "../../../components/admin/event-form";
import UserEventsTable from "../../../components/admin/user-events-table";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../../app-config";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { data } from "../../../data/data";
import { getAllEvents } from "../../../controllers/events-controller";
import { userService } from "../../../services/user-service";
import { emailService } from "../../../services/email-service"
import EventRegisterUserForm from '../../../components/admin/event-register-user-form';
import MassEmailForm from '../../../components/admin/mass-emails/mass-email-form'

@inject("rootStore")
@observer
class MassEmail extends React.Component {
    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            users: null,
            shouldUpdate: false,
            selectedEmail: null
        }
    }
    static routeName = "MassEmail";
    static routePathPattern = "/admin/mass-emails/:id";

    async componentDidMount() {
        let role = this.authStore.getRole();

        if (role !== ADMIN_USER_ROLE && role !== SUPER_ADMIN_USER_ROLE) {
            this.routerStore.goTo("home");
            return;
        }
        
        await this.getAllUsers();
    }
    
    getAllUsers = async () => {
        try {
            await userService.listAllUsers().then((users) => {
                if (users) {
                    userService.getAllusersInAGroup(60, 'Administrators').then((admins) => {
                        let data = this.getTransformedUsersList(users, admins);
                        this.setState({
                            users: data,
                        });
                    });

                }
            });
        } catch (e) { }
    }

    getTransformedUsersList(users, admins) {
        if (users) {
            let usersList = users.filter(x => x.Attributes.find(x => x.Name === 'custom:approvedByAdmin')?.Value == 'approved' && (x.UserStatus === "CONFIRMED" || x.Attributes.find(x => x.Name == 'email_verified')?.Value  == "true")).map((user, index) => {
                return {
                    username: user.Username,
                    enabled: user.Enabled,
                    status: user.UserStatus,
                    title: user.Attributes.find(x => x.Name == 'custom:title')?.Value,
                    firstName: user.Attributes.find(x => x.Name == 'custom:firstName')?.Value,
                    lastName: user.Attributes.find(x => x.Name == 'custom:lastName')?.Value,
                    email: user.Attributes.find(x => x.Name == 'email')?.Value,
                    speciality: user.Attributes.find(x => x.Name == 'custom:speciality')?.Value,
                    companyName: user.Attributes.find(x => x.Name == 'custom:companyName')?.Value,
                    phoneNumber: user.Attributes.find(x => x.Name == 'custom:phoneNumber')?.Value,
                    address1: user.Attributes.find(x => x.Name == 'custom:address1')?.Value,
                    address2: user.Attributes.find(x => x.Name == 'custom:address2')?.Value,
                    country: user.Attributes.find(x => x.Name == 'custom:country')?.Value,
                    countryCode: user.Attributes.find(x => x.Name == 'custom:countryCode')?.Value,
                    timeZoneName: user.Attributes.find(x => x.Name == 'custom:timeZoneName')?.Value,
                    city: user.Attributes.find(x => x.Name == 'custom:city')?.Value,
                    postCode: user.Attributes.find(x => x.Name == 'custom:postCode')?.Value,
                    isSuperAdmin: user.Attributes.find(x => x.Name == 'custom:isSuperAdmin')?.Value === 'true',
                    isAdmin: admins.some(admin => { return admin.Username === user.Username }),
                    approvedByAdmin: user.Attributes.find(x => x.Name === 'custom:approvedByAdmin')?.Value,
                    userCreatedDate: user.UserCreateDate,
                    dateApproved: user.Attributes.find(x => x.Name === "custom:dateApproved")?.Value,
                    dateDeclined: user.Attributes.find(x => x.Name === "custom:dateDeclined")?.Value
                }
            });

            return usersList 
        }
        return [];
    }
    render() {
        let { users } = this.state;
        let { params } = this.routerStore.routerState;
        return (
            <div>
                <div className="admin header">
                    <span className={`${this.state.isActive ? `${"active"}` : ""}   `} id="menu-action" >
                        <ViewHeadlineIcon />
                    </span>
                    <div className="">
                        <AdminHeader authStore={this.authStore} />
                    </div>
                </div> 
                <AdminNav />
                <div className="main" >
                    <div style={{ minHeight: "86vh", backgroundColor:"#e0e0e0", padding:"20px" }}>
                        <MassEmailForm emailId={params?.id} users={users} context={this} />
                    </div>
                    <Footer />
                </div>
               
            </div>
        )
    }
}

export default MassEmail;