import React from "react";
import {Auth} from "aws-amplify"
import { inject, observer } from "mobx-react";
import { handleLogout } from "../../controllers/app-controller";
import appNavigator from "../../app-navigation";
import { RouterLink } from 'mobx-state-router';
import tevaImg from "../../media/images/Group26.png"
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";
import Modal from "../post-registration/faculty/disclaimerModal/modal";

@inject('rootStore')
@observer
class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: null,
            loggedIn: false,
            shouldShowDisclaimerModal: false
        }
    }

    async componentDidMount(){
        let loggedIn = await this.props.rootStore.authStore.isAuthenticated()
        if(loggedIn){
            let user = await Auth.currentAuthenticatedUser();
            this.setState({user})
        }
        this.setState({loggedIn})
        
    }
    showCookieBanner = (e) => {
        this.props.rootStore.alertStore.setShowCookieBanner(true)
    } 
    getApprovalCodesAndDate = () => {
        let routeName = this.props.rootStore.routerStore.routerState.routeName;

        const {user, loggedIn} = this.state;
        const pages = ["/migraine-education-on-demand", "/cme", "/faculty"];
        let currentPagePathname = window.location.pathname;

        let changeApprovalCode = false;
       
        for(let url of pages){
            if(currentPagePathname.startsWith(url)){
                changeApprovalCode = true;
                break;
            }
        }
        if(routeName === "faculty"){

            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }

        if(routeName === "EventBookingThankYouPage" ){
            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }

        if(routeName === "practicalWorkshop"){
            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }

        if(routeName === "editProfile"){
            return (
                <p> Job Code: AJO-TPE-00555

                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }
       
        if(routeName === "cme"){

            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }
        if(window.location.pathname === "/"){

            return (
                <p>Job Code: AJO-TPE-00555

                <br/>
                Date of preparation: November 2024
            </p>
            )
        }
        if(user && window.location.pathname === "/"){
            return (
                <p>AJO-TPE-00555

                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        } 
        
        if (routeName === "Programme") {

            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }
        if (user && loggedIn ){
            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }
        if(changeApprovalCode){
            return (
                <p> Job Code: AJO-TPE-00555
                    <br/>
                    Date of preparation: November 2024
                </p>
            )
        }

        return (
            <p> Job Code: AJO-TPE-00555
            <br/>
            Date of preparation: November 2024
        </p>
        )
    }

    showYouAreAboutToLeaveDisclaimerModal = (modalOpen) => {
        this.setState({shouldShowDisclaimerModal: modalOpen})
    }
 
    render() {

        const scrollTop = () =>{
            window.scrollTo({top: 0, behavior: 'smooth'});
         };
  
        let { authStore } = this.props.rootStore;
        const {user, shouldShowDisclaimerModal} = this.state;
        
        return (
            <div>
                <Modal isActive={shouldShowDisclaimerModal} closeModal={() => this.showYouAreAboutToLeaveDisclaimerModal(false)} link={"https://yellowcard.mhra.gov.uk/"}/>
                <footer className="page-footer font-small blue">
                    <div className="">
                      
                    <div className="row m-0 justify-content-center">
                        
                            <div className="col-12 col-sm1-12 col-md-8 col-lg-6 text-center">
                                <p className="text-center legal_disclaimer">  <strong>
                                    Some sessions may contain product information. For the fremanezumab UK PI please click <a style={{ color: "#f89410" }} href="https://products.tevauk.com/Ajovy_UK_PI" target="blank">here.</a> <br />
                                </strong>
                                    <div style={{marginTop: '10px', display: 'inline-flex', border: "1px solid #1a0a60", padding: "5px", borderRadius: "5px"}}>
<strong>
                                        Adverse events should be reported. Reporting forms and information can be found at the <br /> 
                                        national competent authority website e.g., <a style={{ color: "#f89410", cursor: "pointer" }} target={"_blank"} onClick={() => this.showYouAreAboutToLeaveDisclaimerModal(true)} href>https://yellowcard.mhra.gov.uk/.</a> Adverse events <br /> should also be reported via e-mail to Teva Pharmaceuticals: <a href="mailTo:AEmailboxTeva@tevapharm.com" style={{ color: "#f89410" }} target={"_blank"}>AEmailboxTeva@tevapharm.com.</a> <br />
                                        For UK Healthcare Professionals, please report to Teva UK Limited: <a href="mailTo:UK.Safety@tevauk.com" style={{ color: "#f89410" }} target={"_blank"}>UK.Safety@tevauk.com.</a>
                                        </strong>
                                    </div>
                                  
                              

                            </p>
                      </div> 
                    </div>

                    <div className="row m-0">
                        <div className="col-12 col-md-12">
                            <div className="footer-copyright row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4  text-sm-center">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                <img alt="teva logo" width="100px" src={tevaImg} />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0  ">
                                                <p><strong>This promotional meeting is organised and funded by <br/> Teva Pharmaceuticals Europe B.V.</strong></p>
                                             
                                            </div>
                                        </div>

                                    </div>
                                  
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 text-center">
                                        <p> Teva Pharmaceuticals Europe B.V, Busweg 1,<br /> 2031 DA Haarlem, The Netherlands
                                        </p>
                                </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2  text-center">
                                        {this.getApprovalCodesAndDate()}
                                    </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 text-right text-sm-center">
                                    <ul style={{ float: 'right'}}>
                                  
                                            <li><RouterLink onClick={scrollTop} routeName="PrivacyStatement" >Privacy Statement</RouterLink> | <RouterLink onClick={scrollTop} routeName="cookies">Cookie Policy </RouterLink> | <a  onClick={this.showCookieBanner} style={{cursor: "pointer"}} > Cookie Settings </a></li>
                                
                                  
                                        
                                            <li>
                                             
                                                AS&K Communications Ltd. 2024
                                            </li>
                                    {/* {authStore?.user?.email && <li onClick={() => { handleLogout(authStore, this) }}>Logout</li>} */}
                                </ul>
                              
                                </div>


                            </div>

                        </div>
                    </div>
                 
                    </div>
                </footer>

            </div>
        );
    }
}

export default Footer;