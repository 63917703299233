//key
//sd - self described
import { action, computed, observable, toJS } from 'mobx';

export class MassEmailsStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //to assist with differentiation during storage to persistence media
    static namespace = 'MassEmailsStore_';

    rootStore;


    @observable massEmails = null

    @observable temporaryEmail = null

    // @computed

    @computed get report() {
        if (this.content.length === 0)
            return "<none>";

        return this.content;
    }
    addMassEmails(model) {
        this.massEmails = model;
    }
    addTemporaryEmail(model){
        this.temporaryEmail = model;
    }
    @action
    revertStores = () => {
        //TODO >> might not be needed, so leave it empty like this when not needed
    };

}
