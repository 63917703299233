import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/christina-red.png"
class Modal extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { closeModal, isActive } = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return (

            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>

                        <div className={`modal-body ${styles.modalbody}`}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 text-center">
                                    <img alt="Hans photo" src={ProfilePhoto} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-8">
                                    <h3>Cristina Tassorelli, MD, PhD, FEAN</h3>
                                    <p style={{ color: "#f61a19" }}>Pavia, Italy</p>
                                    <p>
                                        Cristina Tassorelli is Full Professor, Chair of Neurology, Director of Residency in the Neurology Program and Dean of the Faculty of Medicine at the University of Pavia, and Director of the Rehabilitative Neurology Unit at the Headache Science & Neurorehabilitation Research Centre of the Neurological Research Institute C. Mondino Foundation, Italy. She is Past-President of the International Headache Society (IHS).
                                    </p>
                                    <p>
                                        Prof. Tassorelli earned her medical degree <i>summa cum laude</i> from the University of Pavia, her PhD in human psychobiology from the University of Modena, Italy, and her post-doctoral fellowship from the Department of Neuroscience of the University of Rochester, NY, USA. She is a past member of the Board of Directors of the IHS and Past Chair of the IHS Committee for the Guidelines of Clinical Trials. Prof. Tassorelli was President of the IHS from 2021 to 2023, and President of the Italian Association of Full Professors of Neurology for the biennium 2022–2023. She is a founding member of the European Headache Alliance, where she served as Vice-President for several years. </p>
                                    <p>
                                        She has coordinated and participated in several competitive grants funded by the EU, the Migraine Research Foundation, the Italian Ministry of Health, the Italian Ministry of University and the Italian Multiple Sclerosis Foundation. Prof. Tassorelli has authored 430 papers in peer-reviewed international scientific journals, and several books and book chapters.
                                    </p>


                                </div>
                            </div>

                        </div>
                        <div className={`modal-footer ${styles.modal_footer}`}>
                            <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default Modal;