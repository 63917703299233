import React from 'react'
import SafeComponentWrapper from "../../templates/safe-component-wrapper";
import {Authenticator, SignIn, SignUp} from "aws-amplify-react";
import awsconfig from "../../../aws-exports";
import {inject, observer} from "mobx-react";
import {isEmptyString} from "../../util/util";
import Login from "./login";



class AuthForm extends React.Component {


    render() {

        return (
            <Authenticator
                hide={[SignIn, SignUp]}
                amplifyConfig={awsconfig}
            >
                {this.props.children}
            </Authenticator>
        );
    }

}

@inject('rootStore')
@observer
class AppWithAuth extends React.Component {

    constructor(props) {
        super(props);
        this.appStore = this.props.rootStore.appStores.app;
    }

 
    static routeName = "login";
    static routePathPattern = "/login";
    render() {

        let showLogin = (this.appStore.selectedAuthenticationForm === this.appStore.SIGN_IN_AUTH)

    

        let showForgotPassword = (
            !isEmptyString(this.appStore.selectedAuthenticationForm)
            && (this.appStore.selectedAuthenticationForm === this.appStore.FORGOT_PASSWORD_AUTH)
        );

        return (
            <SafeComponentWrapper>

                {
                    showLogin &&
                    <AuthForm>
                        <Login/>
                    </AuthForm>
                }
              

               

            </SafeComponentWrapper>
        );
    }

}

export default AppWithAuth;
