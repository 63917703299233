/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "MhmApi",
            "endpoint": "https://tg6m8ui0g2.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:c048e0b0-8975-424e-b989-13e35da54ecf",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_DzdV7tuOu",
    "aws_user_pools_web_client_id": "37e6jibs37ouu3468a0o5i4i22",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "CreatedUsersQueue-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "MassEmails-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "Events-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "EmailTemplates-prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "mhm-storage-bucket152705-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
