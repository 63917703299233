import React from 'react';
import styles from "./modal.module.css"

import Iframe from 'react-iframe'


class Modal extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { closeModal, isActive } = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return (

            <div className={`modal ${styles.modal} ${styles.ppt_modal} ${newClass} `} id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${styles.modal_dialog}`} role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>

                        <div className={`modal-body ${styles.modalbody}`}>
                            <div className={`row ${styles.row}`}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                {/* <object data="https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM2022-Workshop-Mitsikostas/MHM2022WorkshopMitsikostas.pdf" type="application/pdf">
                                    <iframe src="https://docs.google.com/viewer?url=https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/presentations/6f6c6aca-5a11-4abc-bf88-9620c0c85514/MHM2022-Workshop-Mitsikostas/MHM2022WorkshopMitsikostas.pdf&embedded=true"
                                     width="100%"
                                     height="600px"
                                     id="myId"
                                     display="initial"
                                     position="relative"></iframe>
                                </object> */}
                                    <Iframe src={this.props.fileURL}
                                        width="100%"
                                        height="600px"
                                        id="myID"
                                        display="initial"
                                        position="relative" 
                                        className={`${styles.iframe}`}
                                        />
                                </div>
                            </div>

                        </div>
                       
                            <button className={`button ${styles.button}`} onClick={closeModal}>X</button>
                      

                    </div>


                </div>
            </div>
        );
    }
}

export default Modal;