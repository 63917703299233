import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/tomas.png"
class Modal extends React.Component {
    constructor (props){
        super(props);
        
    }
    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className={`modal-body ${styles.modalbody}`}>
                       <div className="row">
                           <div className="col-12 col-sm-12 col-md-4 text-center">
                                <img alt="Hans photo" src={ProfilePhoto}/>
                           </div>
                           <div className="col-12 col-sm-12 col-md-8">
                                    <h3> Tomáš Nezádal, MD, PhD
</h3>
                                    <p style={{ color: "#f61a19" }}>  Prague, Czech Republic
</p>
                                    <p>
                                       
                                  

                                    Dr Tomáš Nezádal received his medical degree from the First Faculty of Medicine, Charles University in Prague and he has been working as a neurologist for 25 years. His main professional interests are headaches, epilepsy, neuromodulation and acute stroke treatment. In 2010 he was awarded his Ph.D. at the Palacký University in Olomouc. He currently teaches, investigates and publishes in Headache Centers at the Department of Neurology of the Institute of Clinical Neurosciences, in the MiIlitary University Hospital, and in the Institute of Neuropsychiatric Care. Since 2021 he has served as President of the Czech Headache Society. He is a member of several Czech and international societies and Remedia Editorial Board. In addition, he is Chairman of the Board of Trustees of the Hedalga Foundation, which manages the Czech Migraine Registry (ReMig).

</p>
                   
                           </div>
                       </div>
                      
                  </div>
                  <div className={`modal-footer ${styles.modal_footer}`}>
                    <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                </div>
                  
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;