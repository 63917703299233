import React from 'react';
import {Auth} from "aws-amplify";
import { inject, observer } from "mobx-react"
import IsLoggedInBanner from './banner/banner';

import {getAllEvents, bookEvent} from "../../../controllers/events-controller"
import {data} from "../../../data/data"
import {userService} from "../../../services/user-service"
@inject("rootStore")
@observer
class IsLoggedInHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allEvents: [],
            showModal: false,
            user: null,
            eventToBook: null,
            selectedDate: null,
            showLoading: false,
            showTimezone: false,
            opening: false
        }
    }

    async componentDidMount(){
        let isAuthenticated = await this.props.rootStore.authStore.isAuthenticated();
        if(isAuthenticated){
            let user = await Auth.currentAuthenticatedUser();
            this.setState({user}, () => {
                getAllEvents().then(allEvents => { 
                    try{
                        this.props.rootStore.contentStore.addEvents(allEvents);
    
                    }catch(err){
                    }
                    this.setState({allEvents: allEvents}) 
            })
            })
        }
        
    }

    render() {
        return ( 
            <React.Fragment>
                <IsLoggedInBanner/> 
            </React.Fragment>
         );
    }
}
 
export default IsLoggedInHome;