import React from 'react';
import styles from "./modal.module.css"

class Modal extends React.Component {
    constructor (props){
        super(props);
        this.navigateAndClosePopUp = this.navigateAndClosePopUp.bind(this);
    }
    navigateAndClosePopUp (e){
        let { closeModal, link } = this.props;
        e.preventDefault();
        window.open(link, "_blank")
        closeModal();
    }
    render() { 
        let {closeModal, isActive, link} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                        <div className={`modal-body ${styles.modal_body}`}>
                        <p>
                        You are about to leave the MHM 2024 website and view the content of an external website. Links to external websites are provided as a resource to the viewer. This website is neither owned nor controlled by AS&K Communications Ltd. or Teva Pharmaceuticals Europe B.V.
                        </p>
                    </div>
                    <div className={`modal-footer  ${styles.modal_footer}`}>
                            <a className={`button`} href onClick={this.navigateAndClosePopUp} target="blank">Confirm</a>
                     <a className={`button`} onClick={closeModal} href>CLOSE</a>
                </div>
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;