// import MatomoTracker from '@datapunt/matomo-tracker-js';
import { inject, observer } from "mobx-react";
import { RouterLink } from "mobx-state-router";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { ReactAppConfigurations } from "../../util/config"

import { Auth } from "aws-amplify";
import { globalOnClickHandler } from "../../util/util";
import { createInstance } from '@jonkoops/matomo-tracker-react';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// const tracker = createInstance({
//     // urlBase: '//stats.mhm-meeting.com/',
//     // siteId: ReactAppConfigurations.matomoSiteId,
//     // // userId: 'UID76903202', // optional, default value: `undefined`.
//     // //trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
//     // //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
//     // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//     // heartBeat: { // optional, enabled by default
//     //   active: true, // optional, default value: true
//     //   seconds: 10 // optional, default value: `15
//     // },
//     // linkTracking: false, // optional, default value: true
//     // configurations: { // optional, default value: {}
//     //   // any valid matomo configuration, all below are optional
//     //     disableCookies: true,
//     //     setSecureCookie: true,
//     //     setRequestMethod: 'POST'
//     // }
//   })

// const tracker = new MatomoTracker({
//     urlBase: '//stats.mhm-meeting.com/',
//     siteId: ReactAppConfigurations.matomoSiteId,
//     heartBeat: { // optional, enabled by default
//         active: true, // optional, default value: true
//         seconds: 10 // optional, default value: `15
//     },
//     configurations: { // optional, default value: {}
//         setSecureCookie: true,
//         setRequestMethod: 'GET'
//     }
// })


@inject("rootStore")
@observer
class CookieComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            consentGiven: JSON.parse(localStorage.getItem('consentGiven')),
        }
    }
    async componentDidMount() {
        await this.isAuthenticated()
        const { consentGiven } = this.state;
        if (consentGiven) {
            this.loadMatomoScript();
        }
    }
    isAuthenticated = async () => {
        let loggedIn = await this.props.rootStore.authStore.isAuthenticated()
        this.setState({ isAuthenticated: loggedIn })

    }
    componentWillUnmount() {
        this.unsubscribeToClicks();

    }
    loadMatomoScript() {
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        // g.async = true; g.src = 'https://stats.mhm-meeting.com/js/container_rc8rLzuF.js'; s.parentNode.insertBefore(g, s); // staging
        g.async=true; g.src='https://stats.mhm-meeting.com/js/container_O7A8fZPN.js'; s.parentNode.insertBefore(g,s);// LIVE
    }

    

    removeMatomoCookies() {
        cookies.remove("_pk_id.1.32b8");
        cookies.remove("_pk_ses.1.32b8");
        cookies.remove("_pk_id.2.e1b8");
        cookies.remove("_pk_ses.2.e1b8");
        window._mtm = [];
    }
    onAccept = async () => {

        // send stats to matomo
        this.setState({ consentGiven: true });
        localStorage.setItem('consentGiven', true);
        this.loadMatomoScript();

        var user = null;
        try {
            user = await Auth.currentAuthenticatedUser();
        } catch (e) {
            // Handle error if needed
        }

        // tracker.trackPageView();
        // tracker.trackEvents();
        this.subscribeToClicks();
        this.props.rootStore.alertStore.setShowCookieBanner(false);
    }
    subscribeToClicks = () => {
        if (this?.documentBody?.current) {
            this.documentBody.current.addEventListener("click", globalOnClickHandler)
        }
    }
    unsubscribeToClicks = () => {
        if (this?.documentBody?.current) {
            this.documentBody.current.removeEventListener("click", (event) => { })
        }
    }
    onDecline = async () => {

        this.setState({ consentGiven: false });
        localStorage.setItem('consentGiven', false);
        this.removeMatomoCookies();
        cookies.remove("_pk_id.1.32b8")
        cookies.remove("_pk_ses.1.32b8")
        cookies.remove("_pk_id.2.e1b8")
        cookies.remove("_pk_ses.2.e1b8")
        //Allow only necessary cookies
        this.props.rootStore.alertStore.setShowCookieBanner(false)

    }
    render() {

        return (

            <CookieConsent
                location="bottom"
                buttonText="ACCEPT ALL COOKIES"
                cookieName="mhmwebsite_cookie"
                style={{ background: "#1a0a60", fontSize: 20, borderTop: "2px solid orange", fontFamily: 'Gill Sans Light' }}
                buttonStyle={{ color: "#1a0a60", fontSize: 16 }}
                buttonClasses={"button orange"}
                enableDeclineButton={true}
                expires={150}

                onAccept={this.onAccept}
                onDecline={this.onDecline}
                declineButtonStyle={{ color: "#1a0a60", fontSize: 16 }}
                declineButtonText={"ACCEPT STRICTLY NECESSARY COOKIES ONLY"}
                {... (this.props.rootStore.alertStore.showCookieBanner == true ? { visible: true } : {})}
                button={<button>Manage preferences</button>}
            >

                <p>This site uses cookies to give you a better browsing experience. Strictly Necessary Cookies are used, as are Performance Cookies. For more information, see our <RouterLink routeName={"PrivacyStatement"}>Privacy Statement</RouterLink>  and <RouterLink routeName={"cookies"}>Cookie Notice</RouterLink>. </p>
            </CookieConsent>
        )
    }
}

export default CookieComponent;