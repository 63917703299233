import CircularProgress from "@material-ui/core/CircularProgress";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../../app-config";
import Footer from "../../../components/_shared/footer-module";
import Loader from "../../../components/_shared/loading-div";
import { AdminHeader } from "../../../components/admin/admin-header";
import AdminNav from "../../../components/admin/admin-nav";
import { data } from "../../../data/data";
import { contentService } from "../../../services/content-service";
import { emailService } from "../../../services/email-service";
import { userService } from "../../../services/user-service";
import "../../../theme/regular-user.scss";
import Modal from "./Modal/modal";
import styles from "./preview.module.css";
import { submitMassEMailEditForm } from "../../../util/formHelper";

@inject("rootStore")
@observer
class PreviewEmail extends React.Component {
  constructor(props) {
    super(props);
    this.authStore = this.props.rootStore.authStore;
    this.routerStore = this.props.rootStore.routerStore;
    this.massEmailsStore = this.props.rootStore.massEmailsStore;
    this.usersStore = this.props.rootStore.usersStore;

    this.state = {
      viewModel: [],
      users: null,
      shouldUpdate: false,
      emailPreview: null,
      showLoading: false,
      sending: false,
      loading: true,
    };
    this.handleSaveDraftButton = this.handleSaveDraftButton.bind(this);
    this.handleUpdateDraftButton = this.handleUpdateDraftButton.bind(this);
  }
  async componentDidMount() {
    let role = this.authStore.getRole();
    if (role !== ADMIN_USER_ROLE && role !== SUPER_ADMIN_USER_ROLE) {
      this.routerStore.goTo("home");
      return;
    }
    let { params } = this.routerStore.routerState;

    if (params && params.id) {
      await this.getEmail(params.id);
    } else {
      let tempEmail = toJS(this.massEmailsStore.temporaryEmail);
      if (tempEmail) {
        this.setState({ viewModel: tempEmail });
      }
    }
    await this.getAllUsers();
    let mainTemplate = await contentService.getMainEmailTemplate("mass-emails.html");
    this.setState({ emailPreview: mainTemplate, loading: false });
  }

  static routeName1 = "PreviewUnsavedEmail";
  static routePathPattern1 = "/admin/mass-email/preview-email";

  static routeName = "PreviewEmail";
  static routePathPattern = "/admin/mass-email/preview-email/:id";

  getEmail = async (emailId) => {
    let allEmails = toJS(this.massEmailsStore.massEmails);
    if (!allEmails || !allEmails.find((x) => x.id === emailId)) {
      allEmails = (await emailService.getAllEmails())?.Items;
      this.massEmailsStore.addMassEmails(allEmails);
    }
    let selectedEmail = allEmails?.find((email) => email.id === emailId);

    let tempEmail = toJS(this.massEmailsStore.temporaryEmail);
    if (tempEmail) {
      this.setState({ viewModel: tempEmail });
    } else {
      this.setState({ viewModel: selectedEmail });
    }
  };

  getEmailRecipients = (email) => {
    if (!email) throw new Error("Email is null or undefined");
    let recipients = [];
    const { users } = this.props;
    if (email.to) {
      email.to.forEach((to) => {
        let user = users.find((x) => x.username === to);
        if (user) {
          recipients.push(user);
        }
      });
    }
    return recipients;
  };

  getAllUsers = async () => {
    this.setState({ loading: true });
    let users = toJS(this.usersStore.users);
    if (!users) {
      try {
        await userService
          .getAllUsersInAGroupFromAPI(60, "WebUser")
          .then((users) => {
            if (users) {
              userService
                .getAllUsersInAGroupFromAPI(60, "Administrators")
                .then((admins) => {
                  let data = this.getTransformedUsersList(users, admins);
                  this.usersStore.addUsers(data);
                  this.setState({ users: data });
                });
            }
          });
      } catch (e) {
        // this.setState({ loading: false })
      }
    }
  };
  getTransformedUsersList(users, admins) {
    if (users) {
      let usersList = users.map((user, index) => {
        return {
          username: user.Username,
          enabled: user.Enabled,
          status: user.UserStatus,
          title: user.Attributes.find((x) => x.Name === "custom:title")?.Value,
          firstName: user.Attributes.find((x) => x.Name === "custom:firstName")
            ?.Value,
          lastName: user.Attributes.find((x) => x.Name === "custom:lastName")
            ?.Value,
          email: user.Attributes.find((x) => x.Name === "email")?.Value,
          speciality: user.Attributes.find(
            (x) => x.Name === "custom:speciality"
          )?.Value,
          companyName: user.Attributes.find(
            (x) => x.Name === "custom:companyName"
          )?.Value,
          phoneNumber: user.Attributes.find(
            (x) => x.Name === "custom:phoneNumber"
          )?.Value,
          address1: user.Attributes.find((x) => x.Name === "custom:address1")
            ?.Value,
          address2: user.Attributes.find((x) => x.Name === "custom:address2")
            ?.Value,
          country: user.Attributes.find((x) => x.Name === "custom:country")
            ?.Value,
          countryCode: user.Attributes.find(
            (x) => x.Name === "custom:countryCode"
          )?.Value,
          timeZoneName: user.Attributes.find(
            (x) => x.Name === "custom:timeZoneName"
          )?.Value,
          city: user.Attributes.find((x) => x.Name === "custom:city")?.Value,
          postCode: user.Attributes.find((x) => x.Name === "custom:postCode")
            ?.Value,
          isSuperAdmin:
            user.Attributes.find((x) => x.Name === "custom:isSuperAdmin")
              ?.Value === "true",
          isAdmin: admins.some((admin) => {
            return admin.Username === user.Username;
          }),
          approvedByAdmin: user.Attributes.find(
            (x) => x.Name === "custom:approvedByAdmin"
          )?.Value,
          userCreatedDate: user.UserCreateDate,
          dateApproved: user.Attributes.find(
            (x) => x.Name === "custom:dateApproved"
          )?.Value,
          dateDeclined: user.Attributes.find(
            (x) => x.Name === "custom:dateDeclined"
          )?.Value,
        };
      });

      return usersList;
    }
    return [];
  }
  openModal = (type) => {
    let { sending } = this.state;

    if (sending) return;

    this.disableScroll();
    switch (type) {
      case "modal":
        this.setState({
          modal: true,
        });
        break;
      default:
        return;
    }
  };

  closeModal = (type) => {
    this.enableScroll();
    switch (type) {
      case "modal":
        this.setState({
          modal: false,
        });
        break;
      default:
        return;
    }
  };

  disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  onConfirmSendMassEmail = async (type) => {
    this.closeModal("modal");
    await this.handleSendMassEmailConfirm();
  };

  handleSendMassEmailConfirm = async () => {
    let { viewModel, sending } = this.state;

    if (sending) return;

    this.setState({ sending: true }, async () => {
      emailService.sendMassEmail(viewModel);

      setTimeout(() => {
        this.setState({ sending: false });
        this.props.rootStore.alertStore.setAlert({
          type: "alert-success",
          message: "Emails sent successfully",
        });
      }, 7000);
    });
  };

  handleSaveDraftButton = async () => {
    let { showLoading } = this.state;
    if (showLoading) return;
    this.setState({ showLoading: true });
    var id = uuidv4();
    let { viewModel } = this.state;
    if (!viewModel?.id || viewModel) {
      viewModel.id = id;
      viewModel.status = data?.emailStatus?.filter(
        (x) => x.name === "Draft"
      )[0].id;
    }

    this.setState({ viewModel }, async () => {
      var result = await emailService.createMassEmail(viewModel);
      if (result) {
        this.props.rootStore.alertStore.setAlert({
          type: "alert-success",
          message: "Successfully saved mass email draft",
        });
      } else {
        this.props.rootStore.alertStore.setAlert({
          type: "alert-danger",
          message: "",
        });
      }
      this.setState({ showLoading: false });
    });
  };

  handleUpdateDraftButton = async () => {
    let { showLoading } = this.state;
    if (showLoading) return;
    this.setState({ showLoading: true });
    const { viewModel } = this.state;
    this.setState({ viewModel }, async () => {
      try {
          await submitMassEMailEditForm(this);
          this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully updated mass emails' });
      }catch {
          this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Please ensure all required fields are filled' });
      }finally {
        this.routerStore.goTo("MassEmail", { id: viewModel.id });
      }
      this.setState({ showLoading: false });
  });
  };


  render() {
    const { viewModel, emailPreview, modal, showLoading, loading } =
      this.state;

    return (
      <div>
        <div className="admin header">
          <span
            className={`${this.state.isActive ? `${"active"}` : ""}   `}
            id="menu-action"
          >
            <ViewHeadlineIcon />
          </span>
          <div className="">
            <AdminHeader authStore={this.authStore} />
          </div>
        </div>
        <AdminNav />

        {loading ? (
          <Loader />
        ) : emailPreview ? (
          <div className="main">
            <div
              style={{
                minHeight: "86vh",
                backgroundColor: "#e0e0e0",
                padding: "20px",
              }}
            >
              <div className="container">
                <div
                  className={`row justify-content-md-center custom_mui_forms select-users `}
                >
                  <div className={` col-12 col-sm-12`}>
                    <div className="row">
                      <div className="col-12">
                        <h3> Preview</h3>
                      </div>
                    </div>
                    <div className={`row ${styles.bordered_section}`}>
                      <div className="col-12 col-sm-12 col-md-1 text-left">
                        <h5 className="">From:</h5>
                      </div>
                      <div className="col-12 col-sm-12 col-md-11 text-left">
                        <span className={`${styles.custom_budge}`}>
                          MHM MEETING (no-reply@mhm-meeting.com)
                        </span>
                      </div>
                    </div>
                    <div className={`row ${styles.bordered_section}`}>
                      <div className="col-12 col-sm-12 col-md-1 text-left">
                        <h5>To:</h5>
                      </div>
                      <div className="col-12 col-sm-12 col-md-11 pr-0 text-left">
                        <RecipientComponent
                          allUsers={this.usersStore.users}
                          viewModel={viewModel}
                          routerStore={this.props}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ padding: "20px 0" }}>
                      <div className="col-12 col-sm-12 col-md-1 text-left">
                        <h5 className="mt-1">Subject:</h5>
                      </div>
                      <div className="col-12 col-sm-12 col-md-11 text-left">
                        <span className={`${styles.custom_budge}`}>
                          {viewModel.subject}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-1 text-left">
                        <h5 className="mt-3">Email body:</h5>
                      </div>
                      <div className="col-12 col-sm-12 col-md-11 text-left">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: emailPreview.replace(
                              "#CONTENT#",
                              viewModel.body
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                    <div className="row ml-0 mr-0">
                      <div className="col-12">
                        <div className="container">
                          <div className="row justify-content-sm-center has_arrow_buttons mt-4 p-2">
                            <div className="col-12 col-sm-12 col-md-6 pr-0 mt-2 text-md-right text-center">
                              {viewModel &&
                                viewModel.id &&
                                viewModel?.to.length > 0 && (
                                  <button
                                    onClick={() => this.openModal("modal")}
                                    className="button min-width-unset"
                                  >
                                    {this.state.sending ? (
                                      <CircularProgress
                                        color={"white"}
                                        size={17}
                                      />
                                    ) : (
                                      "Send"
                                    )}
                                  </button>
                                )}
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 pl-3 mt-2 text-md-left text-center">
                              {(!viewModel || !viewModel.id) &&
                                !showLoading && (
                                  <button
                                    className="button min-width-unset"
                                    onClick={this.handleSaveDraftButton}
                                  >
                                    Save as draft
                                  </button>
                                )}
                              {viewModel && viewModel.id && !showLoading && (
                                <button
                                  className="button min-width-unset"
                                  onClick={this.handleUpdateDraftButton}
                                >
                                  Update draft
                                </button>
                              )}
                              {showLoading && (
                                <button className="button min-width-unset">
                                  Saving...
                                </button>
                              )}
                            </div>
                            {/* confirm modal */}
                            <Modal
                              closeModal={() => this.closeModal("modal")}
                              isActive={modal}
                              onConfirmSendMassEmail={
                                this.onConfirmSendMassEmail
                              }
                              sending={this.state.sending}
                            />
                            {/* end confirm modal */}
                          </div>
                        </div>
                      </div>
                    </div>

              </div>
            </div>
            <Footer />
          </div>
        ) : (
          <div>No email preview found</div>
        )}
      </div>
    );
  }
}

export default PreviewEmail;

function RecipientComponent({ allUsers, viewModel, routerStore, isOpen }) {
  const [users, setUsers] = useState([]);
  const [, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const getAllUsers = async () => {
      try {
        await userService.getAllusersInAGroup(60, "WebUser").then((users) => {
          if (users) {
            userService
              .getAllusersInAGroup(60, "Administrators")
              .then((admins) => {
                let data = getTransformedUsersList(users, admins);
                setUsers(data);
              });
          }
        });
      } catch (e) {
        setLoading(false);
      }
    };
    getAllUsers();
    setLoading(false);

    return () => {};
  }, [allUsers, viewModel]);

  const getTransformedUsersList = (users, admins) => {
    if (users) {
      let usersList = users.map((user, index) => {
        return {
          username: user.Username,
          enabled: user.Enabled,
          status: user.UserStatus,
          title: user.Attributes.find((x) => x.Name === "custom:title")?.Value,
          firstName: user.Attributes.find((x) => x.Name === "custom:firstName")
            ?.Value,
          lastName: user.Attributes.find((x) => x.Name === "custom:lastName")
            ?.Value,
          email: user.Attributes.find((x) => x.Name === "email")?.Value,
          speciality: user.Attributes.find(
            (x) => x.Name === "custom:speciality"
          )?.Value,
          companyName: user.Attributes.find(
            (x) => x.Name === "custom:companyName"
          )?.Value,
          phoneNumber: user.Attributes.find(
            (x) => x.Name === "custom:phoneNumber"
          )?.Value,
          address1: user.Attributes.find((x) => x.Name === "custom:address1")
            ?.Value,
          address2: user.Attributes.find((x) => x.Name === "custom:address2")
            ?.Value,
          country: user.Attributes.find((x) => x.Name === "custom:country")
            ?.Value,
          countryCode: user.Attributes.find(
            (x) => x.Name === "custom:countryCode"
          )?.Value,
          timeZoneName: user.Attributes.find(
            (x) => x.Name === "custom:timeZoneName"
          )?.Value,
          city: user.Attributes.find((x) => x.Name === "custom:city")?.Value,
          postCode: user.Attributes.find((x) => x.Name === "custom:postCode")
            ?.Value,
          isSuperAdmin:
            user.Attributes.find((x) => x.Name === "custom:isSuperAdmin")
              ?.Value === "true",
          isAdmin: admins.some((admin) => {
            return admin.Username === user.Username;
          }),
          approvedByAdmin: user.Attributes.find(
            (x) => x.Name === "custom:approvedByAdmin"
          )?.Value,
          userCreatedDate: user.UserCreateDate,
          dateApproved: user.Attributes.find(
            (x) => x.Name === "custom:dateApproved"
          )?.Value,
          dateDeclined: user.Attributes.find(
            (x) => x.Name === "custom:dateDeclined"
          )?.Value,
        };
      });

      return usersList;
    }
    return [];
  };
  return (
    <div
      className={`${styles.custom_budge}`}
      style={{
        maxHeight: "332px",
        overflow: "auto",
        padding: "10px 20px",
        boxShadow: "-16px 10px 40px 7px #bcbcbc",
        borderRadius: "7px",
      }}
    >
      {users.length === 0 ? (
        <>
          <CircularProgress size={10} /> Loading...
        </>
      ) : (
        <>
          {users.length > 0 && viewModel?.to?.length > 0 ? (
            viewModel?.to?.map(function (item) {
              let email = users.find((x) => x.username === item)?.email + ", ";
              return email ? email : item;
            })
          ) : (
            <span>No recipients selected</span>
          )}
        </>
      )}
    </div>
  );
}
