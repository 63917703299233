import { inject, observer } from "mobx-react";
import React from "react";

import FormWrapper from "../../templates/form-wrapper";
import Editor from "../editor/editor";

import { RouterLink } from "mobx-state-router";
import styles from "../../components/forms/logIn.module.css";
import Buttons from "./submitButton";

import {
  isFormStepValid,
  removeFromRequredList,
  submitForm,
  updateViewModel,
  validatePassword,
} from "../../util/formHelper";

import Slider from "react-slick";
import Branding1 from "../../media/images/branding1.png";
import Branding2 from "../../media/images/branding2.png";
import Branding3 from "../../media/images/branding3.png";
import { userService } from "../../services/user-service";
@inject("rootStore")
@observer
class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.routerStore = this.props.rootStore.routerStore;
    this.formStore = this.props.rootStore.formStore;
    this.handleSubmitform = this.handleSubmitform.bind(this);
    this.previous = this.previous.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      requiredList: [],
      showLoading: false,
      viewModel: this.props.viewModel,
      registerError: null,
      invalidFormMessage: null,
      activeSlide: 0,
      isAuthenticated: false,
      userExists: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.viewModel.type === "edit") {
      if (
        prevState?.viewModel?.type !== this?.props?.viewModel?.type &&
        prevState?.viewModel?.email !== this.props?.viewModel?.email
      ) {
        this.setState({ viewModel: this.props.viewModel });
      }
    }
  }
  componentDidMount() {
    let { authStore } = this.props.rootStore;
    window.scrollTo({ top: 0, behavior: "smooth" });

    document.title =
      "My Profile | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
    let isAuthenticated = authStore.isAuthenticated();
    this.setState({ isAuthenticated, isLoading: false });
  }
  onChange(field, value) {
    let { viewModel } = this.state;
    if(field == "willRequireHotelRoom"){
      removeFromRequredList(this, "hotelCheckOutDate");
      removeFromRequredList(this, "hotelCheckinDate");

    }
    updateViewModel(this, viewModel, field, value);
  }

  handleSubmitform = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const { showLoading } = this.state;

    if (showLoading) {
      return;
    }
    this.setState({ showLoading: true });
    await submitForm(this);
  };
  previous() {
    this.slider.slickPrev();
    this.setState({ invalidFormMessage: null });
  }
  next = async () => {
    var { activeSlide } = this.state;

    var valid = await isFormStepValid(this);

    if (valid) {
      this.formStore.setFormStepValid(activeSlide, true);
      this.setState({ formStepIsValid: true, invalidFormMessage: null });
      this.slider.slickNext();
    } else {
      this.formStore.setFormStepValid(activeSlide, false);
    }
  };

  render() {
    let { isAuthenticated } = this.state;
    const { viewModel, activeSlide } = this.state;
    const defaultDate = new Date(2024, 9, 1);

    let settings = {
      dots: false,
      speed: 500,
      swipe: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      infinite: false,
      arrows: false,
      beforeChange: (current) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      afterChange: (current) => {
        this.setState({ activeSlide: current });
      },
    };

    return (
      <FormWrapper>
        {/* {viewModel.type === 'edit' && <MySessions/> } */}

        <div className={`${styles.log_in_wrap}`}>
          <div className={styles.curve1}>
            <img alt="Branding 1" src={Branding1} />
          </div>

          <div className={styles.curve2}>
            <img alt="Branding 2" src={Branding2} />
          </div>

          <div className="container register legal-container">
            <div className="row justify-content-md-center">
              <div className={` col-12 col-sm-12 col-md-12  `} style={{ zIndex: 9 }}>
                <div className={""}>
                  <div className={`logIn_card ${styles.logIn_card}`}>
                    <div className="row mt-5">
                      <div className="col-12 mt-5 ">
                        <div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 text-center">
                            <div
                                    style={{
                                      border: "1px solid #fff",
                                      padding: "10px 5px 5px",
                                      borderRadius: "5px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <p className="mb-1 mt-1">
                                      <strong>
                                        This website is intended for use by
                                        Healthcare Professionals only.
                                      </strong>
                                    </p>
                                    <p>
                                      {" "}
                                      <strong>
                                        This promotional meeting is organised
                                        and funded by Teva Pharmaceuticals
                                        Europe B.V.
                                      </strong>
                                    </p>
                                    <p>
                                      Some sessions may contain product
                                      information. <br />
                                      For the fremanezumab{" "}
                                      {" "}
                                      UK prescribing information and adverse
                                      events reporting, please see footer of the
                                      page.
                                      <br />
                                    </p>
                                  </div>
                              {viewModel.type === "edit" && (
                                <>
                                <h1 className="graphical_heading">
                                  My Profile
                                </h1>
                                <h2 className="">Please <RouterLink routeName="Contact">CONTACT US</RouterLink>  if any changes are needed</h2>
                                </>
                              )}
                              {viewModel.type === "register" && (
                                  <h1 className="graphical_heading mt-4">
                                    {" "}
                                    Registration Form
                                  </h1>
                              )}
                            </div>
                          </div>
                          <Slider ref={(c) => (this.slider = c)} {...settings}>
                            {/* SLIDE */}
                            <div className="custom-height ">
                              <div className="disabled">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <h2 className="">Contact Information</h2>
                                  <h6>*Mandatory fields</h6>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor  
                                    type={"select"}
                                    onChange={this.onChange}
                                    placeholder="Title *"
                                    name={"title"}
                                    value={viewModel.title}
                                    context={this}
                                    sortOption={"none"}
                                    required={{ group: 0 }}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="First Name *"
                                    name={"firstName"}
                                    value={viewModel.firstName}
                                    context={this}
                                    required={{ group: 0 }}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="Last Name *"
                                    name={"lastName"}
                                    value={viewModel.lastName}
                                    context={this}
                                    required={{ group: 0 }}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"select"}
                                    onChange={this.onChange}
                                    placeholder="Country *"
                                    name={"country"}
                                    value={viewModel.country}
                                    context={this}
                                    autoFill={false}
                                    required={{ group: 0 }}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  {viewModel.type === "register" && (
                                    <Editor
                                      type={"text"}
                                      onChange={this.onChange}
                                      placeholder="Email address *"
                                      name={"email"}
                                      value={viewModel.email}
                                      context={this}
                                      required={{
                                        validateEmailExists:
                                          viewModel.type === "register",
                                        validEmail: viewModel.email,
                                        group: 0,
                                      }}
                                    />
                                  )}
                                  <p style={{ fontSize: "18px" }}>
                                    {viewModel.type === "edit" &&
                                      viewModel.email}
                                  </p>
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "5px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center confirm-email">
                                  {viewModel.type === "register" && (
                                    <Editor
                                      type={"text"}
                                      onChange={this.onChange}
                                      placeholder="Confirm email address *"
                                      name={"confirmEmail"}
                                      value={viewModel.confirmEmail}
                                      context={this}
                                      required={{
                                        match: viewModel.email,
                                        fieldToMatch: "email",
                                        group: 0,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="row" style={{}}>
                                <div className="col-12 col-sm-12 col-md-12 text-center password-div">
                                  {viewModel.type === "register" && (
                                    <Editor
                                      type={"password"}
                                      onChange={this.onChange}
                                      placeholder="Create Password"
                                      name={"password"}
                                      value={viewModel.password}
                                      context={this}
                                      required={{ minLength: 12, group: 0 }}
                                      showRequired
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="row" style={{ }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center password-area">
                                  {viewModel.type === "register" && (
                                    <Editor
                                      type={"password"}
                                      onChange={this.onChange}
                                      placeholder="Confirm Password"
                                      name={"confirmPassword"}
                                      value={viewModel.confirmPassword}
                                      context={this}
                                      required={{
                                        match: viewModel.password,
                                        fieldToMatch: "password",
                                        group: 0,
                                      }}
                                      showRequired
                                    />
                                  )}
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"number"}
                                    onChange={this.onChange}
                                    placeholder="Telephone number *"
                                    name={"phoneNumber"}
                                    value={viewModel.phoneNumber}
                                    context={this}
                                    required={{ group: 0 }}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="Your TEVA contact person"
                                    name={"tevaContactPerson"}
                                    value={viewModel.tevaContactPerson}
                                    context={this}
                                  />
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"select"}
                                    onChange={this.onChange}
                                    placeholder="Specialty *"
                                    name={"speciality"}
                                    value={viewModel.speciality}
                                    context={this}
                                    required={{ group: 0 }}
                                    sortOption={"none"}
                                  />
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="Organisation / Clinic / Institution / Hospital *"
                                    name={"companyName"}
                                    value={viewModel.companyName}
                                    context={this}
                                    required={{ group: 0 }}
                                  />
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <h2 className="altContact-h2">
                                    <strong>
                                      Contact details of the individual assisting HCP registration and arrangements <br/> if relevant
                                    </strong>
                                  </h2>
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="Name"
                                    name={"altContactName"}
                                    context={this}
                                    value={viewModel.altContactName}
                                  />
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="Email address"
                                    name={"altContactEmail"}
                                    context={this}
                                    value={viewModel.altContactEmail}
                                  />
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "18px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"text"}
                                    onChange={this.onChange}
                                    placeholder="Telephone number"
                                    name={"altContactTelephone"}
                                    context={this}
                                    value={viewModel.altContactTelephone}
                                  />
                                </div>
                              </div>
                              </div>
                              <div className="row justify-content-md-center p-md-0">
                                <div className="col-12 col-md-10 text-center p-md-0">
                                  <Buttons
                                    showLoading={this.state.showLoading}
                                    viewModel={viewModel}
                                    previous={this.previous}
                                    activeSlide={activeSlide}
                                    next={this.next}
                                  />
                                  <br />
                                </div>
                              </div>
                            </div>
                           
                            {viewModel.type === "registe" && (
                              <>
                                {/* SLIDE */}
                                <div>
                                <div className="disabled">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 text-center">
                                      <h2 className="">Event Topics </h2>
                                      <h6>*Mandatory fields</h6>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 text-left">
                                      <p>
                                        <strong>
                                          Please fill in your preference for the
                                          two topics you are most interested in
                                          for MHM 2024 *
                                        </strong>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <Editor
                                      type={"multi-select-checkboxes"}
                                      name={"eventTopics"}
                                      value={viewModel.eventTopics}
                                      context={this}
                                      onChange={this.onChange}
                                      required={{
                                        minOptions: 2,
                                        maxOptions: 2,
                                        group: 1,
                                      }}
                                      disablewhenMaxOptionsSelected
                                    />
                                  </div>

                                  <div className="row mt-4">
                                    <div className="col-12 col-sm-12 col-md-12 text-left">
                                      <p>
                                        <strong>
                                          Please select two areas you would like
                                          to learn more about from the following
                                          list *
                                        </strong>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <Editor
                                      type={"multi-select-checkboxes"}
                                      name={"extraTopics"}
                                      value={viewModel.extraTopics}
                                      context={this}
                                      onChange={this.onChange}
                                      required={{
                                        minOptions: 2,
                                        maxOptions: 2,
                                        group: 1,
                                      }}
                                      disablewhenMaxOptionsSelected
                                    />
                                  </div>
                                  </div>
                                  <div className="row justify-content-md-center p-md-0">
                                    <div className="col-12 col-md-10 text-center p-md-0">
                                      <Buttons
                                        showLoading={this.state.showLoading}
                                        viewModel={viewModel}
                                        previous={this.previous}
                                        activeSlide={activeSlide}
                                        next={this.next}
                                      />
                                      <br />
                                    </div>
                                  </div>
                                </div>
                                {/* SLIDE */}
                              </>
                            )}
                            <div className="travelDetails-section">
                            <div className="disabled">
                              <div className="has_date_time">
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <h2 className="">Travel Details</h2>
                                    <h6>*Mandatory fields</h6>
                                    {viewModel.type === "edit" && (
                                      <>
                                        <p></p>
                                      </>
                                    )}
                                    {viewModel.type === "register" && (
                                      <>
                                        <p>
                                          The travel details below can be
                                          completed at a later stage. You will
                                          be able to access this part of the
                                          form from your profile section when
                                          you log into the MHM 2024 website.
                                          Please submit this information as soon
                                          as possible so that we know when to
                                          expect you at the venue
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <p>Will you require a hotel room? *</p>

                                    <div className="row">
                                      <div className="col-12 col-sm-3 col-md-3 text-left"></div>

                                      <Editor
                                        type="radio"
                                        name={"willRequireHotelRoom"}
                                        onChange={this.onChange}
                                        context={this}
                                        required={{
                                          group:
                                            viewModel.type === "edit" ? 1 : 1,
                                        }}
                                        value={"Yes"}
                                        text={"Yes"}
                                        checked={
                                          viewModel.willRequireHotelRoom ===
                                          "Yes"
                                        }
                                      />

                                      <Editor
                                        type="radio"
                                        onChange={this.onChange}
                                        name={"willRequireHotelRoom"}
                                        context={this}
                                        required={{
                                          group:
                                            viewModel.type === "edit" ? 1 : 1,
                                        }}
                                        value={"No"}
                                        text={"No"}
                                        checked={
                                          viewModel.willRequireHotelRoom ===
                                          "No"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 text-center custom-height">
                                  <Editor
                                    type={"select"}
                                    name={"transportMethod"}
                                    onChange={this.onChange}
                                    placeholder="Method of transportation"
                                    context={this}
                                    value={viewModel.transportMethod}
                                    sortOption={"none"}
                                  />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 text-center custom-height">
                                  <Editor
                                    type={"text"}
                                    name={"flightNumber"}
                                    onChange={this.onChange}
                                    placeholder="Flight / train / coach number"
                                    context={this}
                                    value={viewModel.flightNumber}
                                  />
                                </div>
                              </div>

                              <div className="row arrival-location" style={{ marginTop: "20px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center custom-height">
                                  <Editor
                                    type={"text"}
                                    name={"arrivalLocation"}
                                    onChange={this.onChange}
                                    placeholder="Arrival location (airport/station)"
                                    context={this}
                                    value={viewModel.arrivalLocation}
                                  />
                                </div>
                              </div>

                              <div className="row" style={{ marginTop: "0px" }}>
                                <div className="col-12 col-sm-12 col-md-12 text-center arrival_date">
                                  <Editor
                                    type={"date"}
                                    onChange={this.onChange}
                                    placeholder="Arrival date"
                                    context={this}
                                    value={viewModel.arrivalDate}
                                    name={"arrivalDate"}
                                    minDate={defaultDate}
                                  />
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 text-center">
                                  <Editor
                                    type={"time"}
                                    onChange={this.onChange}
                                    placeholder="Arrival time (CEST)"
                                    context={this}
                                    value={viewModel.arrivalTime}
                                    name={"arrivalTime"}
                                  />
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 text-center date-area">
                                  {(viewModel.willRequireHotelRoom == "Yes") && <Editor
                                    type={"date"}
                                    onChange={this.onChange}
                                    placeholder="Hotel check-in date *"
                                    context={this}
                                    value={viewModel.hotelCheckinDate}
                                    name={"hotelCheckinDate"}
                                    minDate={defaultDate}
                                    // required = {{group: 1}}
                                  />
                                  }
                          
                                   {(viewModel.willRequireHotelRoom != "Yes") && <Editor
                                    type={"date"}
                                    onChange={this.onChange}
                                    placeholder="Hotel check-in date"
                                    context={this}
                                    value={viewModel.hotelCheckinDate}
                                    name={"hotelCheckinDate"}
                                    minDate={defaultDate}
                                  />
                                  }
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 text-center date-area">
                                {(viewModel.willRequireHotelRoom == "Yes") && <Editor
                                    type={"date"}
                                    onChange={this.onChange}
                                    placeholder="Hotel check-out date *"
                                    context={this}
                                    value={viewModel.hotelCheckOutDate}
                                    name={"hotelCheckOutDate"}
                                    minDate={defaultDate}
                                    // required = {{group: 1}}

                                  />
                                }
                                {(viewModel.willRequireHotelRoom != "Yes") && <Editor
                                    type={"date"}
                                    onChange={this.onChange}
                                    placeholder="Hotel check-out date"
                                    context={this}
                                    value={viewModel.hotelCheckOutDate}
                                    name={"hotelCheckOutDate"}
                                    minDate={defaultDate}
                                  />
                                }
                                </div>
                              </div>
                              </div>
                              <div className="row justify-content-md-center p-md-0">
                                <div className="col-12 col-md-10 text-center p-md-0">
                                  <Buttons
                                    showLoading={this.state.showLoading}
                                    viewModel={viewModel}
                                    previous={this.previous}
                                    activeSlide={activeSlide}
                                    next={this.next}
                                  />
                                  <br />
                                </div>
                              </div>
                            </div>
                            {/* SLIDE */}
                            <div className="special-requirements">
                            <div className="disabled">
                              <div className="has_date_time custom-height">
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <h2 className="">Dietary and Other Special Requirements</h2>
                                    <h6>*Mandatory fields</h6>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "18px" }}
                                >
                                  <div className="col-12 col-sm-12 col-md-12 text-center placeholder-fix">
                                    <Editor
                                      type={"select"}
                                      name={"dietaryRequest"}
                                      onChange={this.onChange}
                                      placeholder="Please specify if you have any allergies that should be considered"
                                      context={this}
                                      value={viewModel.dietaryRequest}
                                      sortOption={"none"}
                                    />
                                  </div>
                                </div>
                                {/* <div
                                  className="row"
                                  style={{ marginTop: "18px" }}
                                >
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <Editor
                                      type={"text"}
                                      onChange={this.onChange}
                                      placeholder="Please specify if you have any allergies that should be considered"
                                      context={this}
                                      value={viewModel.allergies}
                                      name={"allergies"}
                                    />
                                  </div>
                                </div> */}

                                <div
                                  className="row"
                                  style={{ marginTop: "18px" }}
                                >
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <Editor
                                      type={"text"}
                                      onChange={this.onChange}
                                      placeholder="Other special requests"
                                      context={this}
                                      value={viewModel.specialRequest}
                                      name={"specialRequest"}
                                    />
                                  </div>
                                </div>
                                <div className="row" style={{ marginTop: "18px" }}>
                                  <div className="col-12 col-sm-12 col-md-12 text-left">
                                    <Editor
                                      type="checkbox"
                                      id="aggreedToCommunicate"
                                      name="aggreedToCommunicate"
                                      onChange={this.onChange}
                                      value={viewModel.aggreedToCommunicate}
                                      context={this}
                                      required
                                    />
                                    I agree to the{" "}
                                    <a
                                      target={"_blank"}
                                      href="/privacy-statement"
                                    >
                                      <u>privacy statement</u>
                                    </a>{" "}
                                    and that my email may be used to communicate
                                    details relating to the event. *
                                  </div>
                                </div>
                                </div>

                                {viewModel.type === "register" && (
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12 text-center">
                                        <h5 className="mt-3 mb-3 text-center">
                                          Data Protection Statement
                                        </h5>

                                        <p style={{ fontSize: "18px" }}>
                                          Personal details are stored on the
                                          AS&K server solely for the purpose of
                                          this event. Data is collected,
                                          processed and used in compliance with
                                          the General Data Protection Regulation
                                          of the UK and European Union.
                                        </p>
                                      </div>
                                    </div>
                                    <hr style={{ borderColor: "#1a0a60" }} />
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12 text-center">
                                        <p style={{ fontSize: "18px" }}>
                                          Upon submission a verification email
                                          will be sent to the email address you
                                          provided. Once you have verified your
                                          email address your registration
                                          request will be reviewed. Once
                                          approved you will receive confirmation
                                          and will then be able to log in.
                                        </p>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}
     </div>
                                <div className="row justify-content-md-center p-md-0 ">
                                  <div className="col-12 col-md-10 text-center p-md-0">
                                    <Buttons
                                      showLoading={this.state.showLoading}
                                      viewModel={viewModel}
                                      previous={this.previous}
                                      activeSlide={activeSlide}
                                      next={this.next}
                                      handleSubmitform={this.handleSubmitform}
                                    />
                                    <br />
                                  </div>
                                </div>
                         
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>

                    {this.state.registerError && (
                      <div className="error">
                        <p>{this.state.registerError.message}</p>
                      </div>
                    )}

                    {this.state.invalidFormMessage &&
                      (!this.state.formStepIsValid ||
                        (activeSlide === 2 && viewModel.type === "register") ||
                        (activeSlide === 1 && viewModel.type === "edit")) && (
                        <div className="error">
                          {this.state.invalidFormMessage}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className={styles.registercurve3} style={{ zIndex: 0 }}>
                <img alt="Branding 3" src={Branding3} />
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>
    );
  }
}

export default RegisterForm;
