import React from 'react';
import '../../../theme/regular-user.scss';
import {inject, observer} from "mobx-react";
import FormWrapper from "../../../templates/form-wrapper";
import {RouterLink} from 'mobx-state-router';
import styles from "./migraine-education-on-demand.module.css"

import Webcast from "../../post-registration/migraine-education-on-demand/webcast/webcast"
import Podcast from './podcast/podcast';
import Slides from './slides/slides';

@inject('rootStore')
@observer
class MigraineEducationOnDemand extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         
        }
    }
    
    componentDidMount(){
        document.title = "Migraine Education on Demand | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
      }

    static routeName = 'migraine-education-on-demand';
    static routePathPattern = '/migraine-education-on-demand';


    render() {
        let {authStore, routerStore} = this.props.rootStore;
     
        return (
   
           <div className={`page-wrapper ${styles.wrap}`}>
               
                <div className="regular-user-wrapper pb-4 " >
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                        <div className={"row"}>
                            <div className={"col-12 mt-4"}>
                                <div className="text-center">
                                    <h1 className={`graphical_heading purple ${styles.graphical_heading}`}>Migraine Education On Demand</h1>
                                </div>
                                    

                            </div>
                        </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center">
                            <p>
                                <strong>
                                Now that you have attended the Practical Workshop, <br/>you have access to a variety of educational resources created by leading migraine experts.
                                <br/>
                                Additional on-demand resources will be made available between April and June 2021, so be sure to revisit this page!
                                </strong>
                            </p>
                        </div>
                    </div>
                        <Webcast/>
                        <Podcast/>
                        <Slides/>
                      
                    </div>

                </div>

        </div>
      
        );
    }

}

export default MigraineEducationOnDemand;

