import React from 'react';
import '../../../theme/regular-user.scss';
import {inject, observer} from "mobx-react";
import FormWrapper from "../../../templates/form-wrapper";
import {RouterLink} from 'mobx-state-router';
import MigraineEducation from '../../../components/post-registration/migraine-education-on-demand/migraine-education-on-demand';
import {Auth} from "aws-amplify"


@inject('rootStore')
@observer
class MigraineEducationOnDemand extends React.Component {

    constructor(props) {
        super(props);
    }

    static routeName = 'migraine-education-on-demand';
    static routePathPattern = '/migraine-education-on-demand';

    componentDidMount(){
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    render() {
        let {authStore, routerStore} = this.props.rootStore;
        return (
            <FormWrapper>
           <div className={`page-wrapper`}>
               <h1>migraine-education-on-demand</h1>
              <MigraineEducation/>
          

           </div>
           </FormWrapper>
        );
    }

}

export default MigraineEducationOnDemand;
