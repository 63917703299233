import React from 'react';
import '../theme/regular-user.scss';
import { inject, observer } from "mobx-react";
import appNavigator from "../app-navigation";
import { Icon } from 'react-icons-kit';
import { pencil2 } from 'react-icons-kit/icomoon/pencil2'
import { compass } from 'react-icons-kit/icomoon/compass'
import { userCheck } from 'react-icons-kit/icomoon/userCheck'
import { ic_loupe } from 'react-icons-kit/md/ic_loupe'
import Banner from "../components/_shared/header-module.js";
import Footer from "../components/_shared/footer-module.js";
import FormWrapper from "../templates/form-wrapper";
import { RouterLink } from 'mobx-state-router';
@inject('rootStore')
@observer
class PrivacyStatement extends React.Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        document.title = "Privacy Statement | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
    }

    static routeName = 'PrivacyStatement';
    static routePathPattern = '/privacy-statement';

    render() {

        const scrollTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };
        let { authStore, routerStore } = this.props.rootStore;
        return (
            <FormWrapper>
                <div className={`page-wrapper`} style={{ background: "#e7e6eb", paddingBottom: "20px" }}>

                    <div className="regular-user-wrapper " id="Privacy_statement">
                        <div className="container">

                            <div className={"row"}>
                                <div className={"col-12 mt-4"}>
                                    <div className="top_section pt-5">
                                        <h1 className="graphical_heading">Privacy Statement</h1>
                                        <h2>Migraine Headache Management 2024 </h2>
                                    </div>

                                    <p>This website is operated by AS&K Communications Ltd <strong>(“AS&K”)</strong> for itself and Teva Pharmaceuticals Europe B.V. <strong>(“TPE”) (“we”, "our" or "us")</strong> for the purpose of running the Migraine Headache Management (MHM) 2024 event. MHM 2024 is organised and funded by TPE.  </p>
                                    <p>We have designed the registration process together and share responsibilities in relation to your attendance at MHM 2024, so for the purposes of General Data Protection Regulation (GDPR) and the UK GDPR, TPE and AS&K are joint controllers of the data collected via this website and then used by us. Our respective information and contact details are set out at the end of the Privacy Statement.  </p>
                                    <p>We are committed to safeguarding and preserving the privacy of our visitors. By using and visiting our website and by submitting your personal information to us, you agree to us using your personal information as set out in this Privacy Statement. This Privacy Statement document <strong>(“Statement”)</strong> explains what happens to any personal data that you provide to us, or that we collect from you whilst you visit our site and how we use cookies on this website. We respect the privacy of users of our website and recognise that when you choose to provide us with information about yourself, you trust us to act in a responsible manner with that information.  </p>
                                    <p>We reserve the right to amend or modify this Statement at any time and in response to changes in applicable data protection and privacy laws.  All changes will be posted on this page. We encourage you to review our Privacy Statement periodically to remain informed of changes that may have occurred. <br /></p>

                                    <p>
                                        AS&K in conjunction with TPE will use the information you provide in these pages to register you for the MHM 2024 event and to assist with the organisation of your attendance. We may also use the information to arrange logistics, accommodation, meals and other aspects related to the event. Note that by providing information requesting mobility or medical assistance or flagging a dietary requirement, you will be providing explicit consent to our processing of this information in order to meet your request.
                                    </p>
                                    <p>
                                        If you are registering on behalf of another individual (i.e. a healthcare professional  <strong>(“HCP”)</strong>), please ensure that you have their consent to allow us to process this information in order to meet their requests. Additionally, please ensure that the HCP has received this Privacy Statement.
                                    </p>

                                    <h3>
                                        Information That We Collect
                                    </h3>
                                    <p>
                                        AS&K in conjunction with TPE, in running and maintaining the MHM 2024 website and organising the event including your attendance, may collect and process the following data:
                                    </p>
                                    <ul>
                                        <li>
                                            Personal information submitted by you or someone acting on your behalf including your name, contact details, role and employing organisation. This information is requested so that you can attend the MHM 2024 event and we can assist with your attendance including any dietary requirements you may have. We will also collect any other data including additional personal information when it is voluntarily submitted to us.
                                        </li>
                                        <li>
                                            Information that you provide when you communicate with us by any means.
                                        </li>

                                    </ul>
                                    <h3>
                                        Use of Your Information
                                    </h3>
                                    <p>
                                        AS&K and TPE, in conjunction with TPE’s affiliates whose contact details you can find in the Contacting Us section below (together “<strong>Teva</strong>”), may use the personal information you provided to us to:
                                    </p>
                                    <ul>
                                        <li>
                                            Register you for the MHM 2024 event
                                        </li>
                                        <li>
                                            Assist with the organisation of your attendance at the event
                                        </li>
                                        <li>
                                            Arrange logistics, accommodation, meals, and other aspects related to the event
                                            <ul>
                                                <li>
                                                    Note that by providing information requesting mobility or medical assistance or flagging a dietary requirement, you will be providing special personal information for which we need your explicit consent to process in order to meet your request. You will be provided with a consent check box on the website to confirm you consent to this processing when you register for MHM 2024
                                                </li>
                                            </ul>

                                        </li>
                                        <li>
                                            Following up with you after MHM 2024 to provide details of where the presentations and other event information can be accessed and issuing your Continuing Medical Education (CME) certificate
                                        </li>
                                        <li>
                                            Share your details with the European Accreditation Committee in CNS (EACIC) for the purposes of issuing you with a CME certificate
                                        </li>
                                    </ul>

                                    <p>
                                        We never give your personal information to third parties to use your data to enable them to provide you with information regarding unrelated goods or services.
                                    </p>
                                    <h3>
                                        Storing and Sharing Your Personal Information

                                    </h3>
                                    <p>
                                        We securely store your personal information on European Union (EU)-based cloud storage and servers.
                                    </p>
                                    <p>
                                        In running and maintaining the MHM 2024 website and organising the event, it may become necessary for us to transfer data that we collect from you including your personal information, to other group companies, affiliates and third parties to help us process your personal information for the purposes set out in this Privacy Statement.
                                    </p>
                                    <p>In particular, we share your personal information as follows: </p>
                                    <p>AS&K shares your personal information with its IT suppliers including third party cloud hosting suppliers. </p>
                                    <p>
                                        Teva shares your personal information with its IT suppliers including third party cloud hosting suppliers and its logistical program management services supplier.
                                    </p>
                                    <p>
                                        Some of these locations may be outside of the EU. By providing your personal information to us, you agree to this transfer, storing and processing. We do our utmost to ensure that all reasonable steps are taken to make sure that your personal information is transferred and stored securely and that the third parties who receive your personal information are required to keep it confidential and only use it for the purpose of providing services to us in compliance with applicable data protection laws.
                                    </p>
                                    <p>
                                        We are global companies and therefore we may transfer your personal information to countries around the world including the US and other countries outside Europe. We will, where necessary, put in place appropriate safeguards to ensure your information is protected.
                                    </p>
                                    <p>
                                        After storing your information for a reasonable period for the purposes set out above, we securely delete your personal information by running an SQL statement in the website database console.
                                    </p>
                                    <h3>
                                        Disclosing Your Information

                                    </h3>
                                    <p>
                                        We will not disclose your personal information to any other party other than in accordance with this Statement and in the circumstances detailed below:
                                    </p>
                                    <ul>
                                        <li>
                                            In the event that we sell any or all of our business to a buyer, in which case we will require any such buyer to agree to treat your information in accordance with this Statement
                                        </li>
                                        <li>
                                            Where we are legally required by law to disclose your personal information
                                        </li>
                                        <li>
                                            To further fraud protection and reduce the risk of fraud

                                        </li>
                                    </ul>
                                    <h3>
                                        Teva only

                                    </h3>
                                    <p>
                                        Teva representatives will record your interest, attendance at and experience of the MHM 2024 event in its client database as part of its usual record keeping obligations. For more information on how Teva collects, stores and shares your personal information for this purpose only please refer to the <a href="https://www.tevapharm.com/our-company/corporate-governance/data-privacy/" style={{ color: '#f89410 ' }} target={'_blank'}>Teva privacy notice.  </a>
                                    </p>
                                    <h3>
                                        Cookies

                                    </h3>
                                    <p>
                                        We place strictly necessary cookies on your device to ensure the website functions properly.<br /><br />

                                        AS&K also places third party analytics cookies on your device which collect information about your use of the site, including details of your visits such as pages viewed and the resources that you access. Such information includes traffic data, location data and other communication data. No personal information is collected by the third party analytics cookies. Please read the  <RouterLink style={{ color: '#f89410 ' }} onClick={scrollTop} routeName="cookies">cookie policy  applicable to this website.  </RouterLink>

                                    </p>
                                    <h3>
                                        Third Party Links

                                    </h3>
                                    <p>
                                        On occasion we include links to third parties on this website. Where we provide a link, it does not mean that we endorse or approve that site’s Statement towards visitor privacy. You should review their Privacy Statement before sending them any personal information. These websites may use cookies and collect your personal information in accordance with their own privacy statements. This Privacy Statement does not apply to third party websites, and we are not responsible for third party websites.
                                    </p>

                                    <h3>
                                        How Long Do We Keep Your Information?

                                    </h3>
                                    <p>
                                        We will keep your information for a reasonable period for the purposes set out above.
                                    </p>


                                    <h3>
                                        Legal Basis for Processing

                                    </h3>
                                    <p>
                                        We process your personal information if you give your consent by registering for the MHM 2024 event. Where you have consented to the processing of your personal information, you may withdraw such consent at any time, free of charge and without undue detriment. You can let us know that you would like to withdraw your consent, and therefore your registration, via the <RouterLink style={{ color: '#f89410 ' }} onClick={scrollTop} routeName="Contact"> Contact Us page.</RouterLink>
                                    </p>


                                    <h3>
                                        Your Data Protection Rights
                                    </h3>
                                    <p>
                                        You may be entitled under applicable law to ask for a copy of your personal information, to correct it, erase or restrict its processing, or to ask us to transfer some of this information to other organisations. You may also have rights to object to some processing. These rights may be limited in some situations; for example, where we can demonstrate we have a legal requirement to process your personal information.
                                    </p>
                                    <p>
                                        If you have any questions about this Statement, or have any questions, complaints or requests regarding your personal information or wish to exercise any of your rights or wish to obtain other information, you can get in touch with us using the details set out in the Contacting Us section below. We hope that we can satisfy any queries you may have about the way in which we process your personal information. However, if you have unresolved concerns you also have the right to complain to the data protection authority in the location in which you live, work or believe a data protection breach has occurred. If you are located in the EU, contact information for your local data protection authority is available on the European Data Protection Board website.
                                    </p>
                                    <p>
                                        If you are located outside the EU, please see information below:
                                    </p>

                                    <div className='privacy_table'>
                                    <div className='privacy_table_auto'>
                                    <table>
                                            <thead>
                                                <tr style={{ fontFamily: "'Gill Sans Light', Arial, Helvetica, sans-serif" }}>
                                                    <th></th>
                                                    <th style={{ fontFamily: "'Gill Sans Light', Arial, Helvetica, sans-serif" }}>United Kingdom </th>
                                                    <th style={{ fontFamily: "'Gill Sans Light', Arial, Helvetica, sans-serif" }}>Serbia </th>
                                                    <th style={{ fontFamily: "'Gill Sans Light', Arial, Helvetica, sans-serif" }}>Switzerland </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-label="Column 1">Regulator</td>
                                                    <td data-label="Column 2">Information Commissioner’s Office</td>
                                                    <td data-label="Column 3">Commissioner for Information of Public Importance and Personal Data Protection</td>
                                                    <td data-label="Column 4">Federal Data Protection and Information Commissioner </td>
                                                </tr>
                                                <tr>
                                                    <td data-label="Column 1">Address </td>
                                                    <td data-label="Column 2"> Information Commissioner’s Office<br />

                                                        Wycliffe House<br />

                                                        Water Lane<br />

                                                        Wilmslow<br />

                                                        Cheshire<br />

                                                        SK9 5AF </td>
                                                    <td data-label="Column 3">
                                                        15 Bulevar kralja Aleksandra street, Belgrade 11120<br />

                                                        Office outside the Commissioner's head office:<br />


                                                        21 Vojvode Šupljikca street, Novi Sad 21101
                                                    </td>
                                                    <td data-label="Column 4">Feldeggweg 1
                                                        CH - 3003 Berne</td>
                                                </tr>
                                                <tr>
                                                    <td data-label="Column 1">Contact details </td>
                                                    <td data-label="Column 2">Telephone: +44 (0) 303 123 1113<br />

                                                        Visit the ICO website for further information </td>
                                                    <td data-label="Column 3">Telephone: +381 (0) 11 3408 900<br />
                                                        Fax: +381 (0) 11 3343 379<br />
                                                        Email:<a style={{ color: "#f9930c" }} href="mailto:оffice@poverenik.rs"> office@poverenik.rs</a>
                                                    </td>
                                                    <td data-label="Column 4">Telephone: +41 (0)58 462 43 95 (Monday - Friday, 10-12 am)<br />
                                                    
                                                        Telefax: +41 (0)58 465 99 96 
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                       
                                        <h3 className='mt-4'>Contacting Us </h3>
                                        <p>Please do not hesitate to contact us regarding any matter relating to this Statement: </p>

                                        <div className='privacy_table_auto'>
                                        <table>

<tbody>
    <tr>
        <td data-label="Column 1">AS&K </td>
        <td data-label="Column 2">Teva Pharmaceuticals Europe B.V. and its affiliates. TPE affiliates are listed <a href="https://www.tevapharm.com/teva-worldwide/" style={{ color: "#f9930c" }} target={'_blank'}>here.</a>   </td>

    </tr>
    <tr>
        <td data-label="Column 1"><a style={{ color: "#f9930c" }} target={'_blank'} href="mailto:MHM@asandk.com ">MHM@asandk.com  </a> </td>
        <td data-label="Column 2"><a style={{ color: "#f9930c" }} target={'_blank'} href="mailto:EUPrivacy@tevaEU.com"> EUPrivacy@tevaEU.com   </a></td>

    </tr>
    <tr>
        <td data-label="Column 1">C/o Data Protection<br />

            AS&K Communications Ltd.<br />

            5–6 Underhill Street<br />

            London<br />

            NW1 7HS <br />

            UK   </td>
        <td data-label="Column 2"> Busweg 1,  <br />

        20231 DA Haarlem, <br />

            The Netherlands </td>

    </tr>


</tbody>
</table>
                                        </div>
                                       
                                        <p className='mt-4'>
                                            This Privacy Statement was last updated in May 2024.
                                        </p>


                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </FormWrapper>
        );
    }

}

export default PrivacyStatement;
