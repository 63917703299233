import React from "react";
import { inject, observer } from "mobx-react";
import appNavigator from "../../app-navigation";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import NoEncryptionIcon from "@material-ui/icons/NoEncryption";
import EventNote from "@material-ui/icons/EventNote"
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import { RouterLink } from 'mobx-state-router';
import { data } from "../../data/data";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";

@inject('rootStore')
@observer
class AdminNav extends React.Component {
    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore
        this.state = {};



    }

    selectContenttype = (id) => {
        const { routerStore } = this.props.rootStore;
        this.setState({ selectedContentNode: data.contentTypes.filter(x => x.id === id)[0] });

        appNavigator.navigateToAdministrationWizard(routerStore, { id: data.contentTypes.filter(x => x.id === id)[0].path });
    };

    getClassNameForNavlinkItems(id) {
        let { selectedContentNode } = this.state;

        if (selectedContentNode && selectedContentNode.id == id) {
            return "nav-link active";
        }
        return "nav-link";
    }
    render() {
        let role = this.authStore.getRole()

        return <div
            className={`${this.state.isActive ? `${"active"}` : ""} sidebar  `}
        >
            <ul>
                <li onClick={() => this.selectContenttype(0)} className={this.getClassNameForNavlinkItems(0)} >
                    <PeopleAltIcon /> <span>Users</span>
                </li>
                <li onClick={() => this.selectContenttype(1)} className={this.getClassNameForNavlinkItems(0)} >
                    <EventNote /> <span>Events</span>
                </li>
                <li onClick={() => this.selectContenttype(2)} className={this.getClassNameForNavlinkItems(0)} >
                    <MailOutlineIcon /> <span>Emails</span>
                </li>
                <li><RouterLink className="nav-link" routeName="home">  <NoEncryptionIcon />
                    <span>Exit</span></RouterLink>
                </li>
            </ul>
        </div>


    }
}
export default AdminNav;