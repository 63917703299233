//key
//sd - self described
import { Auth } from "aws-amplify";
import { action, observable } from 'mobx';
import { RouterState } from 'mobx-state-router';
import Cookies from 'universal-cookie';
import { ADMIN_USER_ROLE, REGULAR_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../app-config";
import { DEFAULT_ROUTE, HOME_ROUTE } from "../routes";
import { isEmptyArray } from "../util/util";

const defaultState = new RouterState(HOME_ROUTE.routeName);
const signOut = new RouterState(DEFAULT_ROUTE.routeName);

const cookies = new Cookies();

export class AuthStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //to assist with differentiation during storage to persistence media
    static namespace = 'AuthStore_';

    rootStore;

    possibleRoles = {
        admin: 'Administrators',
        superAdmin: 'SuperAdministrators'
    };

    @observable
    user = {
        signInUserSession: null,
        attributes: null,
        roles: [],
        email: null,
        name: null,
        username: null
    };

    // @computed 
    getRole = () => {
        let role = REGULAR_USER_ROLE;
        if (!isEmptyArray(this.user.roles)) {
            if (this.user.roles.includes(this.possibleRoles.admin)) {
                role = ADMIN_USER_ROLE;
            }
        }
        if (this.user.attributes && this.user.attributes['custom:isSuperAdmin'] === true) {
            role = SUPER_ADMIN_USER_ROLE;
        }
        return role;
    };
    // @computed
    getUserName() {
        let username = '';

        if (this.user.attributes) {
          username =  this.user.attributes['name'];
        }
        return username;
    }
     // @computed
    isInSuperAdministratorsGroup(){
        if (!isEmptyArray(this.user.roles)) {
            if (this.user.roles.includes('SuperAdministrators')) {
                return true;
            }
        }
        return false;
    }
    @action
    createSignedInUser = userData => {
        this.user.signInUserSession = userData?.signInUserSession;
        this.user.attributes = userData?.attributes;
        this.user.roles = userData?.signInUserSession?.accessToken?.payload?.['cognito:groups'];
        this.user.email = userData?.attributes?.email;
        this.user.username = userData?.username;
    };

    //NOTE: It can as well work with JWT, AWS Cognito, Google sign in
    // facebook sign in, Twitter sign in ... your choice
    isAuthenticated = async () => {

        return await Auth.currentAuthenticatedUser()
            .then(async (data) => {
                
                this.createSignedInUser(data);
                return true;
            })
            .catch(() => {
                return false;
            });

    };

    //Where should we redirect after sign-in/authentication?
    @observable
    signInRedirect = defaultState;

    @action
    handleLogin = (user) => {
        this.rootStore.routerStore.goTo(this.signInRedirect);
        window.location.reload();//enforce transition because of bug with mobx-state-router currently in use
    };

    @action
    setSignInRedirect = (routerState) => {
        this.signInRedirect = routerState;
    };

    resetSignInRedirect() {
        this.setSignInRedirect(defaultState);
    }

    @action
    handleLogout = (signOutCleanUp) => {
        try {
            // this.rootStore.appStores.revertStores();
            this.revertStores();
            // if (typeof signOutCleanUp === 'function') {
            //     signOutCleanUp();//passed method to handle any cleanup on logout
            // }
            this.rootStore.routerStore.goTo(signOut);
            window.location.href = "/"
        } catch (e) {
            alert(JSON.stringify(e))
        }
    };

    @action
    revertStores = () => {
        //TODO >> might not be needed, so leave it empty like this when not needed
    };

}
