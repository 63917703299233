import React from 'react';
import { RouterLink } from 'mobx-state-router';
import { inject, observer } from "mobx-react";

@inject('rootStore')
@observer
class MassEmailFormButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleSaveDraftButton = this.handleSaveDraftButton.bind(this);
        this.handlePreviewEmailButton = this.handlePreviewEmailButton.bind(this);
        this.handleUpdateDraftButton = this.handleUpdateDraftButton.bind(this);
    }
    handleSaveDraftButton = async () => {
        this.props.handleSaveDraftButton();
    }
    handlePreviewEmailButton = async () => {
        this.props.handlePreviewEmailButton();
    }
    handleUpdateDraftButton = async () => {
        this.props.handleUpdateDraftButton();
    }
    render() {
        const { showLoading, id, status} = this.props;

        return (
            <>
                {/* start top buttons html */}
                <div className="row ml-0 mr-0">
                    <div className="col-12">
                        <div className="container">
                            <div className="row justify-content-sm-center has_arrow_buttons mt-4 p-2">
                                <div className="col-12 col-sm-12 col-md-6 mt-2 text-md-right text-center">
                                <button className="button min-width-unset" onClick={this.handlePreviewEmailButton}>Preview</button>
                                </div>
                                    <div className="col-12 col-sm-12 col-md-6 mt-2 text-md-left text-center">

                                        {showLoading ? <a className="button min-width-unset" >
                                    
                                            Saving...
                                            
                                            </a> : id ? <a className="button min-width-unset" onClick={this.handleUpdateDraftButton}> Update draft </a>
                                                : <a className="button min-width-unset" onClick={this.handleSaveDraftButton}> Save as draft </a>}
                                    </div>
                                

                            </div>
                        </div>

                    </div>
                </div>
                {/* end top buttons */}
            </>
        );
    }
}

export default MassEmailFormButton;