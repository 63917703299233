import React from 'react';

import { Auth } from "aws-amplify";
import { Provider } from "mobx-react";
import './App.css';
import AppSetup from "./app/app-setup";
import CookieConsent from "./app/components/_shared/cookie-consent";
import rootStore from "./app/stores";
import "./app/theme/animate.min.css";
import { globalOnClickHandler } from "./app/util/util";
import { ReactAppConfigurations } from "./app/util/config"
import Cookies from 'universal-cookie';
import { createInstance } from '@jonkoops/matomo-tracker-react';

const cookies = new Cookies();
const hasAgreed = cookies.get('mhmwebsite_cookie') === 'true';

// const tracker = new MatomoTracker({
//         urlBase: '//stats.mhm-meeting.com/',
//         siteId: ReactAppConfigurations.matomoSiteId,
//         heartBeat: { // optional, enabled by default
//             active: true, // optional, default value: true
//             seconds: 10 // optional, default value: `15
//         },
//         configurations: { // optional, default value: {}
//             setSecureCookie: true,
//             setRequestMethod: 'GET'
//         }
//     })

    // const tracker = createInstance({
    //     // urlBase: '//stats.mhm-meeting.com/',
    //     // siteId: ReactAppConfigurations.matomoSiteId,
    //     // // userId: 'UID76903202', // optional, default value: `undefined`.
    //     // // trackerUrl: `${urlBase}matomo.php`,
    //     // //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    //     // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    //     // heartBeat: { // optional, enabled by default
    //     //   active: true, // optional, default value: true
    //     //   seconds: 10 // optional, default value: `15
    //     // },
    //     // linkTracking: false, // optional, default value: true
    //     // configurations: { // optional, default value: {}
    //     //   // any valid matomo configuration, all below are optional
    //     //     disableCookies: true,
    //     //     setSecureCookie: true,
    //     //     setRequestMethod: 'POST'
    //     // }
    // })


if(!hasAgreed){
    cookies.remove("_pk_id.1.32b8")
    cookies.remove("_pk_ses.1.32b8")
    cookies.remove("_pk_id.2.e1b8") 
    cookies.remove("_pk_ses.2.e1b8")
}
class App extends React.Component {
    constructor(props){
        super(props);
        this.documentBody = React.createRef(null);
        this.state = {}
    }
   async componentDidMount() {
    

    

        var user = null;
        try{
            user =  await Auth.currentAuthenticatedUser();
        }catch(e){
        }
        if(hasAgreed){

      
            // tracker.trackPageView()
            // tracker.trackEvents()
            this.subscribeToClicks();
        }

        this.showDisclaimer(user);
        this.emailVerifiedPopUp();
        if(!hasAgreed){
            cookies.remove("_pk_id.1.32b8")
            cookies.remove("_pk_ses.1.32b8")
            cookies.remove("_pk_id.2.e1b8") 
            cookies.remove("_pk_ses.2.e1b8")
        }
    }

    componentWillUnmount(){
        this.unsubscribeToClicks();

    }

    unsubscribeToClicks = () => {
        if(this?.documentBody?.current){
            this.documentBody.current.removeEventListener("click", (event) => {})
        }
    }
    showDisclaimer = (user) => {
        let ShowHcpDisclaimer = cookies.get('ShowHcpDisclaimer');
        // hide disclaimer if user is verifying email
        const urlParams = new URLSearchParams(window.location.search);
        const isVerifyingEmail = urlParams.get('emailVerified');
        if(!user && !ShowHcpDisclaimer && !isVerifyingEmail){
            rootStore.alertStore.setAlert({ 
                type: "alert-info", 
                message: "This website is intended for use by Healthcare Professionals only. Please confirm if you are a Healthcare Professional before entering the site.", 
                onDismiss: () => this.onDismissDisclaimer(),
                onConfirm: () => this.onConfirmDisclaimer(),
                confirmText: "Yes",
                declineText: "No",
                isHcpDisclaimerPopUp: true
            }) 
        }
    }

    emailVerifiedPopUp = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const isVerifyingEmail = urlParams.get('emailVerified');
        if(isVerifyingEmail){
            rootStore.alertStore.setAlert({ 
                type: "alert-info", 
                message: "Thank you, your email has been verified. We are currently reviewing your registration, and you will receive email confirmation for your registration shortly.", 
                isHcpDisclaimerPopUp: true
            }) 
        }
    }
    
    onDismissDisclaimer = () => {
    }
    onConfirmDisclaimer = () => {
        cookies.set('ShowHcpDisclaimer', 'false', { path: '/', maxAge: 12960000 });
    }
    subscribeToClicks = () => {
        if(this?.documentBody?.current){
            this.documentBody.current.addEventListener("click", globalOnClickHandler)
        }
    }
    
    render() {
        return (
            <div>
            <Provider rootStore={rootStore}>
                <div ref={this.documentBody}>
                 
                    <AppSetup/>
                    <CookieConsent />
                </div>
            </Provider>
            
             </div>
        );
    }
}
export default App;