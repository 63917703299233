import React from "react";
import { toJS } from "mobx"
import { SignIn } from "aws-amplify-react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { handleCompleteNewPasswordchallenge, handleLoginToSetNewUserPassword } from "../../controllers/app-controller";
import { inject, observer } from "mobx-react";
import { textValue, textValueChanged } from "../../controllers/utility-controls";
import SafeComponentWrapper from "../../templates/safe-component-wrapper";
import { instanceProvider, isNullUndefined, toastProps, stringifyObject } from "../../util/util";
import { AlertToast } from "../../components/_shared/alert-toast";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_SIGNIN_APP_KEY } from "../../app-config";

import Footer from "../../components/_shared/footer-module";
import Banner from "../../components/_shared/header-module.js";
import ResetPassword from "./reset-password";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility"

@inject("rootStore")
@observer
class SetNewPassword extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        this.loginStore = this.props.rootStore.appStores.login;
        this.appStore = this.props.rootStore.appStores.app;
    }

    static routeName = "set-new-password";
    static routePathPattern = "/set-new-password";
    state = {
        toastProps: instanceProvider(toastProps),
        password: '',
        confirmPassword: ''
    };

    componentDidMount(){
        let { temppwd, username } = this.props.rootStore.routerStore.routerState.queryParams;
        if(temppwd && temppwd.length >= 8 && username){
            handleLoginToSetNewUserPassword(username, temppwd, this.props.rootStore.authStore, this, this.props.rootStore.routerState).then((result) =>{
            }, (err) => {
                    this.showToast("An error occured while trying to verify your credentials.", 'error', this, 3000);
            });
        }
       
        this.timeoutId = setTimeout(
            function () {
                this.setState({ show: true });
            }.bind(this),
            1000
        );
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
    isValidForm = () => {
        let { password, confirmPassword } = this.state;
        let valid = true;
        if (!password || password.length < 8 || password.localeCompare(confirmPassword) != 0) {
            valid = false;
            let validationMessage = '';
            if (!password || password.length <= 0) {
                validationMessage = 'The password field is required'
            } else if (password.length < 8) {
                validationMessage = 'Password must be 8 characters or more';
            } else {
                validationMessage = 'Your passwords do not match';
            }

            this.showToast(validationMessage, 'error', this);
        }
        return valid;
    }
    showToast = (msg, errorType, currentActifvity, time, handler) => {
        const { message, activity, type, ...other } = this.state.toastProps;
        const newToastProps = {
            ...other,
            message: msg || message,
            activity: currentActifvity || activity,
            type: errorType || type,
        };
        this.setState({ ...this.state, toastProps: newToastProps }, () => {
            setTimeout(
                () => { handler ? handler() : this.setState({ toastProps: instanceProvider(toastProps) }) },
                time || 5000
            );
        });
    }
    handleSetPassword = async () => {
        let { password } = this.state;
        if (!this.isValidForm()) {
            return;
        }
        this.setState(
            { setPasswordPressed: true },
            await handleCompleteNewPasswordchallenge(
                this.props.rootStore.authStore?.userObjectToResetPassword,
                password,
                this.props.rootStore.routerStore
            ).then((passwordSet) => {
                this.setState({ setPasswordPressed: false })
                this.showToast('New password has been set. Redirecting...', 'success', this, 2000, this.goToPage);
            }, (err) => {
                this.showToast('En error was encountered while setting the new password. Kindly log in again', 'error', this, 2000, this.goToPage);
            })
        );
    }
    closeToast = () => {
        this.setState({ toastProps: instanceProvider(toastProps) })
    }
    goToPage = () => {
        this.props.rootStore.routerStore.goTo('home');
    }

    render() {
        const { rootStore: { authStore } } = this.props;
        let { password, confirmPassword } = this.state;
        return (
            <SafeComponentWrapper>
                <div className={this.state.show ? "show" : "log-in-card"}>
                    <Banner />
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"logIn_card has_arrow_buttons"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <h3>
                                                Set a new password </h3>
                                        </div>
                                    </div>
                                    <form
                                        id="form1"
                                        className="form row"
                                        style={{ margin: "50px auto", maxWidth: "600px", alignSelf: 'center', }}>
                                        <div className={"col-12"}>
                                            <TextField id="password" key="password" name="password" label="New Password"
                                                type={this.state.shouldShowPasswordInput ? "text" : "password"
                                                }
                                                variant="outlined"
                                                style={{
                                                    width: "100%",
                                                    minWidth: "100%",
                                                    marginBottom: "10px",
                                                }}
                                                value={password}
                                                onChange={(event) => { this.setState({ password: event.target.value }) }}
                                            />
                                            {this.state.shouldShowPasswordInput ? (<VisibilityOffIcon
                                                className="view_pw"
                                                onClick={() => {
                                                    this.setState({
                                                        shouldShowPasswordInput: false,
                                                    });
                                                }}
                                            />) : (<Visibility
                                                className="view_pw"
                                                onClick={() => {
                                                    this.setState({
                                                        shouldShowPasswordInput: true,
                                                    });
                                                }}
                                            />)}
                                        </div>

                                        <div className={"col-12"}>
                                            <TextField id="confirmPassword" key="confirmPassword" name="confirmPassword" label="Confirm New Password"
                                                type={
                                                    this.state.shouldShowPasswordInput
                                                        ? "text"
                                                        : "password"
                                                }
                                                variant="outlined"
                                                style={{
                                                    width: "100%",
                                                    minWidth: "100%",
                                                    marginBottom: "10px",
                                                }}
                                                value={confirmPassword}
                                                onChange={(event) => { this.setState({ confirmPassword: event.target.value }) }}

                                            />

                                            {this.state.shouldShowConfirmPasswordInput ? (<VisibilityOffIcon
                                                className="view_pw"
                                                onClick={() => {
                                                    this.setState({
                                                        shouldShowConfirmPasswordInput: false,
                                                    });
                                                }}
                                            />) : (<Visibility
                                                className="view_pw"
                                                onClick={() => {
                                                    this.setState({
                                                        shouldShowConfirmPasswordInput: true,
                                                    });
                                                }}
                                            />)}

                                        </div>

                                        <div className={"col-12"}>
                                            {!this.state.setPasswordPressed && (
                                                <Button variant="contained" color="primary" style={{ marginBottom: "10px" }} onClick={this.handleSetPassword}>
                                                    <span>Set password</span>
                                                </Button>
                                            )}

                                            {this.state.setPasswordPressed && (
                                                <Button variant="contained" color="primary" style={{ marginBottom: "10px" }}>
                                                    <span className="mr-2">Setting password</span>
                                                    <CircularProgress size={16} style={{ color: "white" }} />
                                                </Button>
                                            )}
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                    {!isNullUndefined(this.state.toastProps) &&
                        this.state.toastProps.message && (
                            <AlertToast
                                message={this.state.toastProps.message}
                                type={this.state.toastProps.type}
                                duration={this.state.toastProps.duration}
                                position={this.state.toastProps.position}
                                activity={this}
                            />
                        )}
                </div>
                <Footer />
            </SafeComponentWrapper>
        );
    }
}

export default SetNewPassword;
