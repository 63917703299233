import { inject, observer } from "mobx-react";
import React from 'react';
import FacultySection from '../home/facultySection/facultySection';
import '../../../theme/regular-user.scss';
import Banner from "../../public/home/banner/banner";
import DisclaimerModal from "./disclaimerModal/modal";
import styles from "./faculty.module.css";
@inject('rootStore')
@observer
class Faculty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disclaimerModal : false,
            link: ''
        }
    }
    componentDidMount(){
        document.title = "Faculty | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
    }
    
    static routeName = 'cme';
    static routePathPattern = '/cme';



    openModal = (e, type, link) => {
        e.preventDefault();

        this.disableScroll()

        switch(type){
            case "diclaimerPopUp": 
            this.setState({
                disclaimerModal: true,
                link: link
            })
            break;
          
            
            default: 
                 return 
        }
     
       
    }
    
    
    closeModal = (type) => {
        this.enableScroll()
        switch(type){
            case "diclaimerPopUp": 
            this.setState({
                disclaimerModal: false,
    
            })
            break;
          
            
            default: 
                 return 
        }
     
       
    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {
      
        document.body.style.overflow = 'auto'
    }

    render() {
        let {authStore, routerStore} = this.props.rootStore;
        let {disclaimerModal, link} = this.state;
        return (
   
           <div className={`page-wrapper `}>
                
                <Banner/>
               <FacultySection/>
            
            </div>
      
        );
    }

}

export default Faculty;
