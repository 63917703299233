import React from 'react';
import {Auth} from "aws-amplify"
import { VpnKey, CloudDownload} from '@material-ui/icons';
import { data } from "../../data/data";
import { inject, observer } from "mobx-react";
import MaterialTable from 'material-table';
import { userService } from '../../services/user-service';
import {contentService} from '../../services/content-service'
import Switch from '@material-ui/core/Switch';
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";
import Editor from '../editor/editor';
import {downloadCsv, capitalizeFirstLetter, getCountryCodeFromCountryName, isMeetTheExpertEvent, isInteractiveWorkshopEvent, getHeaders,getuserDetailsProperties} from "../../util/util"
import CardTravelIcon from "@material-ui/icons/CardTravel";
import moment from "moment"
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import Loader from "../_shared/loading-div"
import { getClinic1Events, getClinic2Events, getWorkshop1Events, getWorkshop2Events, isUserRegisteredInAnEvent } from '../../controllers/events-controller';

@inject('rootStore')
@observer
class UsersTable extends React.Component {

    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            requiredList: [],
            data: [],
            columns: [],
            events: [],
            loading: true
        }
    }

    async componentDidMount() {
        await this.getUsers();
    }

    getUsers = async () => {
        try {
            let superAdmins = await userService.getAllusersInAGroup(60, 'SuperAdministrators');

            await userService.listAllUsers().then((users) => {
                if (users) {
                    userService.getAllusersInAGroup(60, 'Administrators').then((admins) => {
                        let data = this.getTransformedUsersList(users, admins, superAdmins);
                        this.setState({
                            columns: this.getColumns(),
                            data: data,
                            loading: false
                        });
                    });
    
                }
            });
        }catch(e){
            this.setState({loading: false})
        }
    }

    getAllActiveEvents = async () => {
        try {
            let {Items} = await contentService.getAllEvents();
            this.setState({events: Items.filter(item => item.statusId !== data.status.deleted)})
        }catch(e){}
    }

    getColumns() {
        let role = this.authStore.getRole();
        let isInSuperAdministratorsGroup = this.authStore.isInSuperAdministratorsGroup();
        let columns = [
          
            {
              title: "Title",
              field: "title",
              editComponent: props => (
                  <Editor name={"title"} type={"select"} onChange={(name, value) => props.onChange(value)} context={this} placeholder="Title" value={props.value} required sortOption={"none"} />
              )
            },
            { title: "First Name", field: "firstName" },
            { title: "Last Name", field: "lastName" },
            { title: "Email", field: "email", editable: 'onAdd' },
            {
                title: "Approve",
                field: "approveUser",
                editable: "never",
                sorting: false,
                render: rowData => {
                      if(rowData.status === "UNCONFIRMED"){
                          return <span>Pending user confirmation</span>
                      }
                      if(rowData.status === "RESET_REQUIRED"){
                          return <span>Pending password reset</span>
                      }
                      if(rowData.approvedByAdmin === "pending" || rowData.approvedByAdmin === "denied"){
                          return (
                              <React.Fragment>
                              <button onClick={() => this.approveUser(rowData)}>
                                  Approve
                              </button>
                              </React.Fragment>
                          )
                      }
                      return <span>Approved</span>
                },
                customSort: (a, b) => {
                    return 0;
                }
              },
              {
                  title: "Deny",
                  field: "denyUser",
                  editable: "never",
                  sorting: false,
                  render: rowData => {
                      if(rowData.status === "UNCONFIRMED"){
                          return <span>Pending user confirmation</span>
                      }
                      if(rowData.status === "RESET_REQUIRED"){
                          return <span>Pending password reset</span>
                      }
                      if(rowData.approvedByAdmin === "pending" || rowData.approvedByAdmin === "approved"){
                        return (
                            <React.Fragment>
                              <button onClick={() => this.denyUser(rowData)}>
                                  Deny
                              </button>
                            </React.Fragment>
                        )
                      }
                      return <span>Denied</span>
                  },
              },
              {
                title: "Approval Status",
                field: "approvedByAdmin",
                editable: "never",
                render: rowData => {
                    return (
                      <span>
                          {capitalizeFirstLetter(`${rowData.approvedByAdmin}`)}
                      </span>
                    )
                }
              },
              {
                title: "Country",
                field: "country",
                editComponent: props => (
                    <Editor name={"country"} type={"select"} onChange={(name, value) => props.onChange(value)} context={this} placeholder="Country" value={props.value} required />
                )
              },
              { 
                  title: "Country Code",
                  field: "countryCode",
                  editable: 'never',
                  render: rowData => {
                      if(rowData.countryCode){
                          return <span>{rowData.countryCode}</span>
                      }
                      return <span>-</span>
                  }
              },
            { title: "Phone", field: "phoneNumber", editComponent: props => (                                                                       
                <Editor type={"number"} onChange={(name, value) => props.onChange(value)} placeholder="Telephone number *" name={'phoneNumber'} value={props.value} context={this} required/>
            ) },
            { title: "Teva Contact Person", field: "tevaContactPerson" },
            {
                title: "Speciality",
                field: "speciality",
                editComponent: props => (
                    <Editor name={"speciality"} type={"select"} onChange={(name, value) => props.onChange(value)} context={this} placeholder="Speciality" value={props.value} required />
                )
              },

            { title: "Organisation/Clinic/Institution/Hospital", field: "companyName" , editComponent: props => (                
                <Editor type={"text"} onChange={(name, value) => props.onChange(value)} placeholder="Organisation/Clinic/Institution/Hospital *" name={'companyName'} value={props.value} context={this} required />
                )},
           
          
            { title: "AltContact Name", field: "altContactName" },
            { title: "AltContact Email", field: "altContactEmail" },
            { title: "AltContact Telephone", field: "altContactTelephone" },
            // { 
            //     title: "Event Topics", 
            //     field: "eventTopics",
            //     editable: 'never',
            //     render: rowData => {
            //         if(rowData.eventTopics && rowData.eventTopics.length > 0){
            //             return <span>{ rowData.eventTopics.split(",").map(i => data.eventTopics.find(k => k.id === i)?.name).join(" & ")}</span>
            //         }
            //         return <span>-</span>
            //     }
            // },

            // { 
            //     title: "Extra Topics", 
            //     field: "extraTopics" ,
            //     editable: 'never',
            //     render: rowData => {
            //         if(rowData.extraTopics && rowData.extraTopics.length > 0){
            //             return <span>{ rowData.eventTopics.split(",").map(i => data.extraTopics.find(k => k.id === i)?.name).join(" & ")}</span>
            //         }
            //         return <span>-</span>
            //     }
            // },
         
            {title: "Hotel Required", field: "willRequireHotelRoom", editComponent: props => (
                <Switch
                        onChange={e => { props.onChange(e.target.checked ? "Yes" : "No") }}
                        checked={props.value == "Yes"}
                        color="primary"
                        name="willRequireHotelRoom"
                        inputProps={{ 'aria-label': 'primary checkbox' }} />
            )},
            { title: "Transport Method", field: "transportMethod", editComponent: props => ( 
                <Editor type={"select"} name={'transportMethod'} onChange={(name, value) => props.onChange(value)}  placeholder="Method of transportation" context={this} value={props.value} sortOption={"none"}/>
            ) },
            { title: "Flight/train/coach number", field: "flightNumber" },
            { title: "Arrival Location", field: "arrivalLocation" },
            { title: "Arrival Date", field: "arrivalDate", render: rowData => {
                if(rowData.arrivalDate){
                    return (
                        <span>
                            {new Date(rowData.arrivalDate).toDateString()}
                        </span>
                    )
                }

                return (
                    <span>
                        -
                    </span>
                )
            },
        editComponent: props => (
            <Editor type={"date"} onChange={(name, value) => props.onChange(value)} placeholder="Arrival date" context={this} value={props.value} name={"arrivalDate"}/>

        ) },
            { title: "Arrival Time (CEST)", field: "arrivalTime" , render: rowData => {

                if(rowData.arrivalTime){
                    return (
                        <span>
                            {moment(rowData.arrivalTime).format("hh:mm [CEST]")}
                        </span>
                    )
                }

                return (
                        <span>
                            -
                        </span>
                    )
                }
                ,editComponent: props => (<Editor type={"time"} onChange={(name, value) => props.onChange(value)} placeholder="Arrival time" context={this} value={props.value} name={"arrivalTime"}/>) 
             },
            { title: "Hotel Check-in Date", field: "hotelCheckinDate", render: rowData => {

                    if(rowData.hotelCheckinDate){
                        return (
                            <span>
                                {new Date(rowData.hotelCheckinDate).toDateString()}
                            </span>
                        )
                    }

                    return (
                        <span>
                            -
                        </span>
                    )
                },
                editComponent: props => ( 
                     <Editor type={"date"} onChange={(name, value) => props.onChange(value)} placeholder="Hotel check-in date" context={this} value={props.value} name={"hotelCheckinDate"} />
                )
            },
            { title: "Hotel Check Out Date", field: "hotelCheckOutDate" , render: rowData => {

                if(rowData.hotelCheckOutDate){
                    return (
                        <span>
                            {new Date(rowData.hotelCheckOutDate).toDateString()}
                        </span>
                    )
                }

                return (
                    <span>
                        -
                    </span>
                )
            },
            editComponent: props => (<Editor type={"date"} onChange={(name, value) => props.onChange(value)} placeholder="Hotel check-out date" context={this} value={props.value} name={"hotelCheckOutDate"} />)
         },
            { title: "Dietary Request", field: "dietaryRequest",
            editComponent: props => ( <Editor type={"select"} name={"dietaryRequest"} onChange={(name, value) => props.onChange(value)} placeholder="Dietary request" context={this} value={props.value} sortOption={"none"}/>
            )
        },

            // { title: "Allergies", field: "allergies" },
            { title: "Special Request", field: "specialRequest" },
            { 
              title: "Status",
              field: "status",
              editable: 'never',
            },
            {
                title: "Date Registered",
                field: "userCreatedDate",
                editable: "never",
                render: rowData => {
                    let date = moment(new Date(rowData.userCreatedDate));

                    if(date.isValid()){
                        return (
                            <span>
                                {new Date(rowData.userCreatedDate).toDateString()}
                            </span>
                        )
                    }

                    return (
                        <span>
                            -
                        </span>
                    )
                }
            },
            {
                title: "Date Approved",
                field: "dateApproved",
                editable: "never",
                render: rowData => {
                    let date = moment(new Date(rowData.dateApproved));

                    if(date.isValid()){
                        return (
                            <span>
                                {new Date(rowData.dateApproved).toDateString()}
                            </span>
                        )
                    }

                    return (
                        <span>
                            -
                        </span>
                    )
                }
            },
            {
                title: "Date Declined",
                field: "dateDeclined",
                editable: "never",
                render: rowData => {
                    let date = moment(new Date(rowData.dateDeclined));

                    if(date.isValid()){
                        return (
                            <span>
                                {new Date(rowData.dateDeclined).toGMTString()}
                            </span>
                        )
                    }

                    return (
                        <span>
                            -
                        </span>
                    )
                }
            },
            
            {
              title: "Is Admin",
              field: "isUserAdmin",
              editable: "never",
              render: rowData => {
                  return (
                    <span>
                        {rowData.isAdmin ? "Yes" : "No"}
                    </span>
                  )
              }
            },
            {
                title: "Make Admin",
                field: "isAdmin",
                editable: 'never',
                render: rowData => {
                    if(rowData.status === "UNCONFIRMED"){
                        return <span>Pending user confirmation</span>
                    }
                    if(rowData.status === "RESET_REQUIRED"){
                        return <span>Pending password reset</span>
                    }
                    if(rowData.approvedByAdmin !== "approved"){
                        return <span>Pending account approval</span>
                    }
                    return (
                        <Switch
                            checked={rowData ? rowData.isAdmin : false}
                            onChange={(e, value) => {
                                if(rowData.isAdmin && !value){
                                    this.onRemoveAdminRole(rowData)
                                }else{
                                    this.onGrantAdminRole(rowData)
                                }
                            }}
                            color="primary"
                            name="adminSwitch"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    )
                },
            },

        ];

        if (role == SUPER_ADMIN_USER_ROLE || isInSuperAdministratorsGroup) {

            columns.push(
                {
                    title: "Super Admin",
                    field: "isSuperAdmin",
                    editable: 'always',
                    render: rowData => { 
                        return (<Switch
                            checked={rowData ? rowData.isSuperAdmin : false}
                            onChange={(e, value) => {
                                if(rowData.isSuperAdmin && !value){
                                    this.onRemoveSuperAdminRole(rowData)
                                }else{
                                    this.onGrantSuperAdminRole(rowData)
                                }
                            }}
                            color="primary"
                            name="adminSwitch"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        )
                        }
                }
            );
        }
        return columns;
    }

    getTransformedUsersList(users, admins, superAdmins) {
        let isSuperAdmin = (this.authStore.isInSuperAdministratorsGroup() || (this.authStore.getRole() == SUPER_ADMIN_USER_ROLE)); 

        if (users) {
            let usersList = users.map((user, index) => {
                let model = {
                    username: user.Username,
                    enabled: user.Enabled,
                    status: user.UserStatus,
                    isAdmin: admins.some(admin => { return admin.Username === user.Username }),
                    isSuperAdmin: superAdmins.some(superAdmin => { return superAdmin.Username === user.Username }),
                    userCreatedDate: user.UserCreateDate,
                }
                user.Attributes.forEach((attr) => {
                    let key = attr.Name.split("custom:")[1] || attr.Name;
                        
                            model[key] = attr.Value;
                    
                } );
                return model;
            });
            usersList = isSuperAdmin? usersList : usersList.filter(user=>!user.isSuperAdmin);
            return usersList.sort((a, b) => moment(b.UserCreateDate).unix() - moment(a.userCreatedDate).unix());
        }
        return [];
    }
    onRowAdd = async (newData) => new Promise((resolve, reject) => {
        this.userExists(newData).then(
            (exists) => {
                if (!exists) {
                    this.addUser(newData).then(
                        (result) => {
                            if (result) {
                                this.setState((prevState) => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    return { ...prevState, data };
                                });
                                resolve();
                            } else {
                                reject();
                            }
                        }, () => {
                            reject();
                        });
                } else {
                    reject();
                }
            });
    });


    onRowUpdate = async (newData, oldData) => new Promise((resolve, reject) => {
        this.onUpdateUser(newData).then(
            (result) => {
                if (result) {
                    this.setState((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                    });
                    resolve();
                } else {
                    reject();
                }
            });
    });

    onRowDelete = async (oldData) => new Promise((resolve, reject) => {
        if (!this.canDeleteUser(oldData)) {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: 'You cannot delete a super admin if you are not a super admin' });
            reject();
            return;
        }

        this.deleteUser(oldData).then(
            (result) => {
                if (result) {
                    this.setState((prevState) => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                    });
                    resolve();
                } else {
                    reject();
                }
            });
    });

    onRemoveAdminRole = async (rowData) => {
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to remove ${rowData.email} as admin?`, onConfirm: this.removeAdminRole, data: rowData });
    }
    onRemoveSuperAdminRole = async (rowData) => {
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to remove ${rowData.email} as super admin?`, onConfirm: this.removeSuperAdminRole, data: rowData });
    }
    removeAdminRole = async (rowData) => {

        try {
            let result = await userService.removeUserFromGroup(rowData.username, "Administrators")
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `Successfully revoked administrative permissions for ${rowData.email}`, onDismiss: () => {}})
            await this.getUsers()
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `Could not revoke administrative permissions for ${rowData.email}`, onDismiss: () => {}})
        }
    }
    removeSuperAdminRole = async (rowData) => {

        try {
            let result = await userService.removeUserFromGroup(rowData.username, "SuperAdministrators")
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `Successfully revoked super administrative permissions for ${rowData.email}`, onDismiss: () => {}})
            await this.getUsers()
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `Could not revoke super administrative permissions for ${rowData.email}`, onDismiss: () => {}})
        }
    }
    onGrantAdminRole = async (rowData) => {
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to make ${rowData.email} an admin?`, onConfirm: this.grantAdminRole, data: rowData });
    }
    onGrantSuperAdminRole = async (rowData) => {
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to make ${rowData.email} a super admin?`, onConfirm: this.grantSuperAdminRole, data: rowData });
    }

    grantAdminRole = async (rowData) => {

        try {
            let result = await userService.addToGroup(rowData.username, "Administrators");
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `Successfully granted administrative permissions to ${rowData.email}`, onDismiss: () => {}})
            await this.getUsers()
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `Could not grant administrative permissions to ${rowData.email}`, onDismiss: () => {}})
        }
    }
    grantSuperAdminRole = async (rowData) => {

        try {
            let result = await userService.addToGroup(rowData.username, "SuperAdministrators");
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `Successfully granted super administrative permissions to ${rowData.email}`, onDismiss: () => {}})
            await this.getUsers()
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `Could not grant super administrative permissions to ${rowData.email}`, onDismiss: () => {}})
        }
    }
    canDeleteUser = (user) => {
        let canDelete = true;
        if (user.isSuperAdmin) {
            let isInSuperAdministratorsGroup = this.authStore.isInSuperAdministratorsGroup();
            let role = this.authStore.getRole();
            if (!isInSuperAdministratorsGroup && role != SUPER_ADMIN_USER_ROLE) {
                canDelete = false;
            }
        }
        return canDelete;
    }
    onSendPasswordReminder = (event, rowData) => new Promise((resolve) => {
        if(rowData.status === "UNCONFIRMED"){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "You cannot reset the password of a user whose account is not confirmed" });
            return true;
        }
        if(rowData.approvedByAdmin !== "approved"){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "You cannot reset the password of a user whose account is not approved" });
            return true;
        }
        if (rowData.status == 'FORCE_CHANGE_PASSWORD' || rowData.status.includes('FORCE_CHANGE_PASSWORD')) {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: "You cannot reset the password of a user who has not yet set their password until they set one." });
            return true;
        }
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to send ${rowData.email} a password reset email?`, onConfirm: this.onConfirmedPasswordReset, data: rowData });
    });
    onConfirmedPasswordReset = async (rowData) => {
        await this.sendPassWordResetEmail(rowData);
    }

    async addUser(user) {
        return await userService.createUserWithTempPassword(user).then(
            (result) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'User successfully created' });
                return true;
            },
            (error) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "An error was encountered while creating the user" });
                return false;
            });
    }
    async userExists(user) {
        return await userService.getUserByUsername(user.email).then(
            (result) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'A user with that email aready exists' });
                return true;
            },
            (error) => {
                return false;
            });
    }

    createCustomAttributesFromUser = async (user) => {
        const {username, email, enabled, isAdmin, isSuperAdmin, status, approvedByAdmin, userCreatedDate, tableData, ...attributes} = user;

        let updatedAttributes = []

        for(let [key, value] of Object.entries(attributes)){
            let keyName;
            switch(key){
                case "address":
                    keyName = `address1`;
                    break;
                case "countryCode":
                    keyName = `countryCode`;
                    value = getCountryCodeFromCountryName(attributes.country);
                    break;
                default:
                    keyName = `${key}`;
                    break;
            }
            let attribute = {
                Name: `custom:${keyName}`,
                Value: value || ""
            }
            if(keyName == "sub" || keyName == "email_verified") continue;
            updatedAttributes.push(attribute);
        }

        return updatedAttributes;
    }

    updateUser = async (user) => {
        let updatedAttributes = await this.createCustomAttributesFromUser(user)

        try {
            await userService.updateUserDetails(user.email, updatedAttributes);
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'User details successfully updated' });
            await this.getUsers()
            return true;
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'An error was encountered while updating user' });
            return false;
        }

    }

    async onUpdateUser(user) {

        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to update ${user.email}'s details?`, onConfirm: this.updateUser, data: user });

    }
    async deleteUser(user) {
        return await userService.deleteUser(user.email).then(
            (result) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'User was successfully deleted' });
                return true;
            }, (error) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: error });
                return false;
            });
    }

    async sendPassWordResetEmail(user) {
        return await userService.resetUserPassword(user.email).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'User will receive password reset instructions via email' });
            this.getUsers();
        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: error });
            return false;
        });
    }
    
    approveUser = (rowData) => new Promise((resolve) => {
        let message = `Are you sure you want to approve ${rowData.email}'s registration?`;
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: message, onConfirm: this.onConfirmApproveUser, data: rowData });
    });

    denyUser = (rowData) => new Promise((resolve) => {
        let message = `Are you sure you want to deny ${rowData.email}'s registration?`;
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: message, onConfirm: this.onConfirmDenyUser, data: rowData });
    });

    onConfirmDenyUser = async (rowData) => {
        await userService.adminDenyUser(rowData.username).then(
            (result) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `${rowData.email} 's registration was denied!` });
                this.getUsers();
            },
            (error) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: error });
            });
        
    }

    onConfirmApproveUser = async (rowData) => {
        await userService.adminApproveUser(rowData.username).then(
            (result) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `${rowData.email}'s registration was approved!` });
                this.getUsers();
            },
            (error) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: error });
            });
        
    }

    handleAdminSwitchChange = (rowData, e) => {
        this.confirmChangeUserAdminStatus(rowData);
    }
    confirmChangeUserAdminStatus = (rowData) => new Promise((resolve) => {
        let message = rowData.isAdmin ? `Are you sure you want to make ${rowData.email} a normal user?` : `Are you sure you want to make ${rowData.email} an administrator?`
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: message, onConfirm: this.onConfirmChangeUserAdminStatus, data: rowData });
    });
    onConfirmChangeUserAdminStatus = async (rowData) => {
        let message = rowData.isAdmin ? 'User was successfully made a normal user' : 'User was successfully made an Administrator';
        await (rowData.isAdmin ? userService.removeUserFromGroup(rowData.email, "Administrators") : userService.addToGroup(rowData.email, "Administrators")).then(
            (result) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: message });
                this.getUsers();
            },
            (error) => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: error });
            });
    }

    downloadUserData = async () => {

        const {data: users, events} = this.state;
        
        const csvArray = users.reduce((csvData, item) => {
            let {username, tableData, enabled, isSuperAdmin, ...userDetails} = item;

            for(let [key, _] of Object.entries(userDetails)){
                let header = capitalizeFirstLetter(key)
                if(!csvData[0].includes(header)){
                    csvData[0].push(header);
                }
                
            }
            console.table(csvData[0]);
            if(!csvData[0].includes("Attended Migraine Masterclass")){
                csvData[0].unshift("Attended Migraine Masterclass");
            }

            if(!csvData[0].includes("Attended Workshop")){
                csvData[0].unshift("Attended Workshop");
            }
            
            if(!csvData[0].includes("Migraine Masterclass Time")){
                csvData[0].unshift("Migraine Masterclass Time")
            }
            
            if(!csvData[0].includes("Migraine Masterclass Date")){
                csvData[0].unshift("Migraine Masterclass Date")
            }

            if(!csvData[0].includes("Migraine Masterclass Session")){
                csvData[0].unshift("Migraine Masterclass Session")
            }

            if(!csvData[0].includes("Workshop Time")){
                csvData[0].unshift("Workshop Time")
            }

            if(!csvData[0].includes("Workshop Date")){
                csvData[0].unshift("Workshop Date")
            }
            
            if(!csvData[0].includes("Workshops")){
                csvData[0].unshift("Workshops")
            }

            let user = [];

            user = user.concat(new Array(6).fill("Not registered"));
            user = user.concat(new Array(2).fill("No"));

            for(let detail of Object.keys(userDetails)){
                let userDetail = userDetails[detail] === undefined ? "" : userDetails[detail];
                switch(detail){
                    case "isAdmin":
                        userDetail = userDetail ? "Yes" : "No";
                        break;
                    case "approvedByAdmin":
                        userDetail = userDetail ? "Yes" : "No";
                        break;
                    default:
                        userDetail = userDetail === "" ? "Not set" : userDetail;
                        break;
                }
                userDetail = userDetail.split(",").join(" ");

                user.push(userDetail);
            }

            for(let item of events){
                for(let eventTime of item.eventTimes){
                    if(eventTime.registeredUsers.includes(username)){
                        if(isInteractiveWorkshopEvent(item)){
                            user[0] = `${item.title.replace(/,/g, '')}`
                            user[1] = `${new Date(eventTime.date).toDateString()}`;
                            user[2] = `${eventTime.time}`;
                        }else if(isMeetTheExpertEvent(item)){
                            user[3] = `${item.title.replace(/,/g, '')}`
                            user[4] = `${new Date(eventTime.date).toDateString()}`;
                            user[5] = `${eventTime.time}`;
                        }
                    }
                }
            }

            for(let item of events){
                for(let eventTime of item.eventTimes){
                    if(eventTime.attendedUsers.includes(username)){
                        if(isInteractiveWorkshopEvent(item)){
                            user[6] = `Yes`
                        }else if(isMeetTheExpertEvent(item)){
                            user[7] = `Yes`
                        }
                    }
                }
            }

            csvData[1].push(user)

            return csvData;
        }, [[], []]);

        downloadCsv(csvArray, "mhm-2021-user-data.csv")
    }
    downloadUserDataWithNewFormat = async (e, rowData) =>{

        const {data: users, events} = this.state;
        const workshop1Events = await getWorkshop1Events();
        const workshop2Events = await getWorkshop2Events();
        const clinic1Events = await getClinic1Events();
        const clinic2Events = await getClinic2Events();
        let headers = getHeaders();
        let userDatailProperties =  getuserDetailsProperties();

        let csvArray = [];
        csvArray[0] = headers;
        let usersCsvData = users.reduce((csvData, item, index)=>{
            let {username, tableData, enabled, isSuperAdmin, ...userDetails} = item;
            let user = [];

            for(let detail of userDatailProperties){
                
                let userDetail = userDetails[detail] === undefined ? "" : userDetails[detail];
                if(detail === "Workshop 1" || detail === "Workshop 2" || detail === "Question Clinic 1" || detail === "Question Clinic 2"){

                }
                switch(detail){
                    case "#":
                        userDetail = (index).toString();
                        break;
                    case "isAdmin":
                        userDetail = userDetail ? "Yes" : "No";
                        break;
                    case "approvedByAdmin":
                        userDetail = userDetail == 'approved' ? "Yes" : "No";
                        break;
                    // case "extraTopics":
                    // case "eventTopics":
                    //     userDetail = userDetail?.split(",")?.map(i => detail === "extraTopics"? data.extraTopics.find(k => k.id === i)?.name : data.eventTopics.find(k => k.id === i)?.name).join(" & ");
                    //     break;
                    case "arrivalTime":
                        userDetail = userDetail ? moment(userDetail).format("hh:mm [CEST]") : "";
                        break;
                    case "arrivalDate":
                    case "hotelCheckinDate":
                    case "hotelCheckOutDate":
                        userDetail = userDetail ? new Date(userDetail).toDateString() : "" ;
                        break
                    case "Workshop 1":
                        userDetail = isUserRegisteredInAnEvent(username, workshop1Events)
                        break;
                    case "Workshop 2":
                        userDetail = isUserRegisteredInAnEvent(username, workshop2Events)
                        break;
                    case "Question Clinic 1":
                        userDetail = isUserRegisteredInAnEvent(username, clinic1Events)
                        break;
                    case "Question Clinic 2":
                        userDetail = isUserRegisteredInAnEvent(username, clinic2Events)
                        break;
                    default:
                        userDetail = userDetail === "" ? "" : userDetail;
                        break;
                }
                userDetail = userDetail?.split(",")?.join(" ");
                user.push(userDetail);
            }
            if(!csvData || !csvData[0] ){
                csvData = [];
            }            
            var practicalWorshopDate = events.find(event => event.type  ==  data.eventTypes[0].id)?.eventTimes.find(eventTime => eventTime.registeredUsers.includes(username)) ;
            user.push(practicalWorshopDate? moment(practicalWorshopDate.date).format("Do MMMM YYYY") : "");

            user = user.concat(new Array(7).fill(" "));
            var mmEvent = events.find(event => event.type  ==  data.eventTypes[1].id && event.eventTimes.some(eventTime => eventTime.registeredUsers.includes(username))) ;
            if(mmEvent){
                user.push(mmEvent.title);
                user.push (moment(mmEvent.eventTimes.find(time => time.registeredUsers.includes(username)).date).format("Do MMMM YYYY") )
                user.push(mmEvent.eventTimes.find(time => time.registeredUsers.includes(username)).time);
            }else{
                user = user.concat(new Array(3).fill(" "));

            }
            user = user.concat(new Array(7).fill(" "));

            var isAttendingForum = events.some(event => event.type  ==  data.eventTypes[2].id && event.eventTimes.some(eventTime => eventTime.registeredUsers.includes(username))) ;
            if(isAttendingForum){
                user.push("YES");
            }else if(mmEvent || practicalWorshopDate){
                user.push("NO");
            }
            csvData.push(user);
            return csvData;
        }, 0)

        csvArray[1] = usersCsvData

        downloadCsv(csvArray, "MHM - user data.csv")
    }
    onDownloadUserData = async (e, rowData) => {
        let message = "Do you want to download these users' details into a .csv file?";
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: message, onConfirm: this.downloadUserDataWithNewFormat, data: rowData });
    }
    navigateToManageUser = async (e, rowData) => {
        if(rowData.status === "UNCONFIRMED"){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "You cannot manage events for a user whose account is not confirmed" });
            return;
        }
        if(rowData.approvedByAdmin !== "approved"){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "You cannot manage events for a user whose account is not approved" });
            return;
        }
        this.routerStore.goTo("ManageUser", { id: rowData.username });
    }

    updateCountryCodeAndTimezone = async () => {
        const {data: allUsers} = this.state;
        let modifiedUsers = [];

        for(let item of allUsers){
            let countryCode = getCountryCodeFromCountryName(item.country);
            let timeZoneName = ""; // await getTimezoneFromCountryCode(countryCode);
            let user = {...item, countryCode, timeZoneName};
            modifiedUsers.push(user);
        }

        for(let index = 0; index < modifiedUsers.length; index++){
            let user = modifiedUsers[index];
            let updatedAttributes = await this.createCustomAttributesFromUser(user);

            try {
                await userService.updateUserDetails(user.email, updatedAttributes);
            }catch(e){
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `An error was encountered while updating ${user.email}'s attributes, aborting` });
                return false;
            }
        }

        this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'All users were updated successfully' });
        await this.getUsers();
        return true;
    }

    onUpdateCountryCodeAndTimezone = (e, rowData) => {
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `You are about to update the country code and timezone for all the users. Would you like to proceed?`, onConfirm: this.updateCountryCodeAndTimezone, data: rowData })
    }

    resendEmailVerification = async (user) => {
        try {
            await Auth.resendSignUp(user.email, {isAdmin: `${user.isAdmin}`, isSuperAdmin: `${user.isSuperAdmin}`});
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `A verification email was resent to ${user.email}` });
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `Something went wrong, the verification email was not sent. Please try again.` })
        }
    }
    
    onResendEmailVerification = (e, rowData) => {
        let proceed = rowData.status === "UNCONFIRMED";
        if(proceed){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `You are about to resend an email to ${rowData.email} to verify their account. Would you like to proceed?`, onConfirm: this.resendEmailVerification, data: rowData })
        }else{
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `${rowData.email} is already verified, cannot resend verification email.`, data: rowData })
        }
    }

    autoVerifyUser = async (user) => {
        try {
            await userService.confirmUserSignup(user.username)
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: `${user.email}'s account was successfully auto verified.` });
            await this.getUsers()
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: `Something went wrong, the verification email was not sent. Please try again.` })
        }
    }
    
    onAutoVerifyUser = (e, rowData) => {
        let proceed = rowData.status === "UNCONFIRMED";
        if(proceed){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `You are about to auto verify ${rowData.email}'s registration. Would you like to proceed?`, onConfirm: this.autoVerifyUser, data: rowData })
        }else{
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `${rowData.email} is already verified, cannot auto verify them.`, data: rowData })
        }
    }
  
    render() {

        const {loading} = this.state;

        if(loading){
            return <Loader />
        }
        return (

            <div className="row">
                <div className="admin-table col-12 col-sm-12">

                    <MaterialTable
                        icons={data.tableIcons}
                        title={`Manage Users`}
                        columns={this.state.columns}
                        data={this.state.data}
                        editable={{
                            // onRowAdd: this.onRowAdd,
                            onRowUpdate: this.onRowUpdate,
                            onRowDelete: this.onRowDelete,
                        }}
                        actions={[
                            {
                                icon: () => <VpnKey />,
                                tooltip: 'Send password reset email',
                                onClick: this.onSendPasswordReminder,
                            },
                            {
                                icon: () => <CloudDownload />,
                                tooltip: "Download user data as CSV file",
                                onClick: this.onDownloadUserData,
                                isFreeAction: true
                            },
                            {
                                icon: () => <CardTravelIcon />,
                                tooltip: "Manage user events",
                                onClick: this.navigateToManageUser
                            },
                            {
                                icon: () => <VerifiedUserOutlinedIcon />,
                                tooltip: "Auto verify user",
                                onClick: this.onAutoVerifyUser
                            }
                            
                        ]}
                        options={{ searchFieldStyle: { maxWidth: '350px' }, searchFieldAlignment: 'right', sorting: true, pageSize: 25, emptyRowsWhenPaging: false }}
                    />

                </div>

            </div>
        );
    }
}

export default UsersTable;