import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/bianca-red.png"
class Modal extends React.Component {
    constructor (props){
        super(props);
        
    }
    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className={`modal-body ${styles.modalbody}`}>
                       <div className="row">
                           <div className="col-12 col-sm-12 col-md-4 text-center">
                                    <img alt="Antoinette_Maassen_van_den_Brink photo" src={ProfilePhoto}/>
                           </div>
                           <div className="col-12 col-sm-12 col-md-8">
                                    <h3> Bianca Raffaelli, MD
</h3>
                                    <p style={{ color: "#f61a19" }}> Berlin, Germany</p>
                                    <p>
                                       
                                       

                                    Bianca Raffaelli is a neurology consultant and a clinician scientist at Charité – Universitätsmedizin Berlin. She is Head of the Headache Center and Headache Research Group at Charité. Her current research focal point revolves around the investigation of sex-dependent aspects of migraine, with a specific emphasis on the role of sex hormones in migraine attack initiation.<br className="hide-for-devices"/> Dr Raffaelli serves as a Junior Editor for Journal of Headache and Pain. She is a member of the Juniors Group and of the Science and Research Committee of the International Headache Society.
                                    </p>
                            
                   
                           </div>
                       </div>
                      
                  </div>
                  <div className={`modal-footer ${styles.modal_footer}`}>
                    <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                </div>
                  
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;