import React from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { userService } from "../services/user-service"
import FormWrapper from "../templates/form-wrapper";
import IsLoggedIn from "../components/post-registration/home/index"
import IsNotLoggedIn from "../components/public/home/index"
import ConfirmationSuccessfulMessage from "../components/_shared/confirmation-successful-message"

@inject("rootStore")
@observer
class AppEntry extends React.Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.rootStore.appStores.app; 
    this.routerStore =this.props.rootStore.routerStore;
    this.state = {
      isAuthenticated: true,
      shouldShowConfirmationSuccessfulMessage: false
    }
  }

  static routeName = "home";
  static routePathPattern = "/";


  async componentDidMount(){
    let { authStore } = this.props.rootStore;
    let isAuthenticated = await authStore.isAuthenticated();
    this.setState({isAuthenticated})
    // let shouldShowConfirmationSuccessfulMessage = await this.shouldShowConfirmationSuccessfulMessage()
    // this.setState({shouldShowConfirmationSuccessfulMessage})
  }
 
  shouldShowConfirmationSuccessfulMessage = async () => {
    const { routerStore: {routerState} } = this.props.rootStore;
    let username = routerState?.queryParams?.username;

    if(!username){
      return false;
    }

    let confirmationMessage = null;

    try {
      confirmationMessage = await userService.getUserShowConfirmationMessage(username);
    }catch(e){
      return false;
    }

    if(confirmationMessage === "SHOW"){
      return true;
    }

    return false;
  }

  updateUserAttribute = async () => {
    const { routerStore: {routerState} } = this.props.rootStore;
    let username = routerState?.queryParams?.username;

    if(!username){
      this.setState({shouldShowConfirmationSuccessfulMessage: false})
      return;
    }

    try {
      await userService.hideUserConfirmationMessage(username);
    }catch(e){}

    this.setState({shouldShowConfirmationSuccessfulMessage: false})
  }

  render() {
    let {isAuthenticated, shouldShowConfirmationSuccessfulMessage} = this.state;
    
    return (
      <div className="min-vh-70">
      <FormWrapper>
        {isAuthenticated ? <IsLoggedIn/> : <IsNotLoggedIn/> }
      </FormWrapper>
       {shouldShowConfirmationSuccessfulMessage && <ConfirmationSuccessfulMessage shouldShow={shouldShowConfirmationSuccessfulMessage} close={this.updateUserAttribute} />}
      </div>
    );
  }
}

export default AppEntry;
