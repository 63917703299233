import React from 'react';
import styles from "./modal.module.css"
import AudioFile from "../../../../../media/images/migraine_education_on_demand/podcast/sample.mp3"
import OggAudioFile from "../../../../../media/images/migraine_education_on_demand/podcast/sample.ogg"
import close_button from "../../../../../media/images/CloseIcon.png"
import PodcastImg from "../../../../../media/images/migraine_education_on_demand/podcast/neuroreflections1.png"
import samplePDF from "../../../../../media/images/migraine_education_on_demand/podcast/sample.pdf"
import zazaDisclosures from "../../../../../media/images/migraine_education_on_demand/podcast/Zaza-Katsarava-disclosures.pdf"
import dagneDisclosures from "../../../../../media/images/migraine_education_on_demand/podcast/Dagny-Holle-Lee-disclosures.pdf"
import {trackAudioFunctions} from "../../../../../util/util"

class Modal extends React.Component {
    constructor (props){
        super(props);
        this.audioRef = React.createRef(null);
        this.state = {
            modal : false
        }
    }

    trackAudioPlay = (e) => {
        const audioLink = "https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Neuroimaging_Podcast_Katsarava_Holle-Lee.mp3";
        trackAudioFunctions(audioLink, "play")
    }
    
    trackAudioEnd = (e) => {
        const audioLink = "https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Neuroimaging_Podcast_Katsarava_Holle-Lee.mp3";
        trackAudioFunctions(audioLink, "end")
    }

    endAudioAndCloseModal = () => {
        if(this.audioRef){
            this.audioRef.current.pause();
        }
        const audioLink = "https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Neuroimaging_Podcast_Katsarava_Holle-Lee.mp3";
        trackAudioFunctions(audioLink, "close")
        this.props.closeModal()
    }

    render() { 
        let {closeModal, isActive} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className={styles.modal_content}>
                    <img alt =" PodcastImg" src={PodcastImg}/>
                    <div className={styles.bottom_section}>
                         <audio controls ref={this.audioRef} onPlay={this.trackAudioPlay} onEnded={this.trackAudioEnd}>
                              <source src="https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Neuroimaging_Podcast_Katsarava_Holle-Lee.mp3" type="audio/mpeg"/>
                              <source src="https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Neuroimaging_Podcast_Katsarava_Holle-Lee.ogg" type="audio/ogg"/>
                            </audio>
                         
                        <div className={styles.audio_text}>
                        <p className=" text-left ">
                            <strong>
                        The views expressed are the opinions of the faculty.
                        </strong>
                        </p>
                        <p className=" text-left ">
                        <strong>
                        This podcast may contain mention of products not licensed for use in migraine. Please refer to the mentioned
                            medications' SmPCs for the approved indications for use.
                            </strong>
                        </p>
                        <p className="text-left">
                        <a href="https://products.tevauk.com/Ajovy_UK_PI" target="-blank">Click here</a> for the fremanezumab UK  prescribing information.
                        </p>
                        <p className="text-left">
                        <strong>Disclosures:</strong> <a target="_blank" href="https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/Zaza-Katsarava-disclosures.pdf">Zaza Katsarava</a>,  <a target="_blank" href="https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/Dagny-Holle-Lee-disclosures.pdf">Dagny Holle-Lee</a> 
                        </p>
                        </div>
                    </div>
                    
                    <a className={`${styles.close_button}`} onClick={this.endAudioAndCloseModal}>
                         <img src={close_button} alt="Close Button"/>
                     </a>
                    </div>
                    
            </div>
         );
    }
}
 
export default Modal;