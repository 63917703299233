import React from "react";
import styles from "./slides.module.css";
import { Auth } from "aws-amplify"
import { inject, observer } from "mobx-react";
import SlidesImg from "../../../../media/images/slides.png";
import PPTFile from "../../../../media/images/migraine_education_on_demand/slides/samplepp.pptx";
import SubmitModal from "./SubmitModal/modal";
import {saveFileFromUrl, trackDownload} from "../../../../util/util";
import Editor from "../../../editor/editor";
import { updateViewModel, isCommentFormInvalid} from '../../../../util/formHelper';
import { emailService} from '../../../../services/email-service'
import { CircularProgress } from "@material-ui/core"

@inject("rootStore")
@observer
class Slides extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            requiredList: [],
            viewModel: {
                comment: ''
            },
            modal : false,
            link: '',
            loader1: false,
            loader2: false,
            loader3: false,
            loader4: false,
            loadSlower: true
        }
        this.onChange = this.onChange.bind(this);
        this.onCancelSubmitComment = this.onCancelSubmitComment.bind(this);
        this.onSubmitCommentConfirm = this.onSubmitCommentConfirm.bind(this);
    }
    onCancelSubmitComment = async () => {
        this.clearCommentBox();
    }
    onSubmitCommentConfirm = async () => {
        this.closeModal('modal');
        let { viewModel } = this.state;
        let user = await Auth.currentAuthenticatedUser();
        viewModel.username = user.username;
        viewModel.email = user.attributes.email;
        viewModel.firstName =user.attributes['custom:firstName'];
        viewModel.lastName = user.attributes['custom:lastName'];

        var result = await emailService.sendCommentEmail(viewModel);
        if (result) {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Your comment was successfully submitted' });
        } else {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: '' });
        }
        this.setState({ showLoading: false });
        this.clearCommentBox();
    }

    clearCommentBox(){
        let {viewModel} = this.state;
        viewModel.comment = '';
        this.setState({ viewModel }, () =>{
            this.forceUpdate()
            this.closeModal('modal');
        });
    }
    onChange(field, value) {
        updateViewModel(this, this.state.viewModel, field, value);
    }
   
    openModal = async (type) => {
        let formInvalid = await isCommentFormInvalid(this);

        if (formInvalid){
            return;
        }
        
        this.disableScroll();
        
        switch(type){
            case "modal": 
            this.setState({
                modal: true,
            })
            break;
            default: 
                 return 
        }       
    }    

    closeModal = (type) => {
        this.enableScroll()
        switch(type){
            case "modal": 
            this.setState({
                modal: false,
    
            })     
            break;
            
            default: 
                 return 
        }
     
       
    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {
      
        document.body.style.overflow = 'auto'
    }

    render(){
        let { modal, viewModel, loader1, loader2, loader3, loader4 } = this.state;
        return( 
            <div className={`${styles.slides_wrap} mt-5 pt-5`}>  
                 {/* POP-UP */}
                <SubmitModal closeModal={() => this.closeModal("modal")} isActive={modal} onCancelSubmitComment={this.onCancelSubmitComment} onSubmitCommentConfirm={this.onSubmitCommentConfirm}/>
                {/* END POP-UP */}
            <div className="row justify-content-center mb-4">
                <div className={`col-12 col-sm-12 col-md-6 col-lg-3 ${styles.slides}`}>
                    <img alt="webcast" src={SlidesImg}/>
                    <h1>Slides</h1>
                </div>
                <div className="col-12">
                    <p>Downloadable presentations that you can read in your own time.</p>
                </div>

            </div>

            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                    <p style={{color:'#f61a19'}}><strong>Elena Ruiz de la Torre</strong></p>
                    <h3>
                    Why migraine patients need neurologists more than ever right now
                    </h3>
                    <p>
                    Experience migraine patients' typical journey through the healthcare system and how expert neurologists can make a huge difference to their lives
                    </p>
                    <p>
                        <a
                        className="btn red"
                        href={"https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Ruiz_de_la_Torre_Why_Migraine_Patients_PDF.pdf"}
                        target="_blank"
                        
                        download="true"
                        >
                            view
                        </a>
                    </p>
                </div>
            </div>

            
            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                <p style={{color:'#f61a19'}}><strong>Messoud Ashina</strong></p>
                    <h3>Everything you need to know about the mAbs   </h3>
                    <p>Get a comprehensive grounding on the anti-CGRP pathway mAbs, from their mechanisms of action to their key data</p>
                    <p>
                    <a className="btn red" href={"https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Ashina_Everything_mAbs.pdf"} target="_blank" download="true">
                        view
                    </a>
                    </p>
                </div>
            </div>

            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                <p style={{color:'#f61a19'}}><strong>Andreas Gantenbein</strong></p>
                    <h3>Using the mAbs in patients with comorbidities   </h3>
                    <p>Find out what we know about the effectiveness and safety of using anti-CGRP pathway mAbs in migraine patients with various comorbidities</p>
                    <p>
                    <a
                    className="btn red"
                    href={"https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Gantenbein_Comorbidity_PDF.pdf"}
                    target="_blank"
                    download="true"
                    
                    >
                        {loader3 ? <CircularProgress style={{color: "#1a0a60"}} size={18} /> : "view"}
                    </a>
                    
                    </p>
                </div>
            </div>


            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                <p style={{color:'#f61a19'}}><strong>Dimos Mitsikostas</strong></p>
                    <h3>Practical integration of the mAbs into the clinic</h3>
                    <p>
                    Discover how the clinical study data on the anti-CGRP pathway mAbs have been interpreted into guidance on using them in clinical practice 
                    </p>                    
                    <p>
                    <a
                     className="btn red"
                     href={"https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Mitsikostas_Practical.Integration_PDF.pdf"}
                     target="_blank"
                     download="true"
                     
                     >
                        {loader4 ? <CircularProgress style={{color: "#1a0a60"}} size={18} /> : "view"}
                    </a>
                    </p>
                </div>
            </div>
            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                <p style={{color:'#f61a19'}}><strong>Dimos Mitsikostas</strong></p>
                    <h3>Medication overuse and the mAbs</h3>
                    <p>
                        Explore what we know about the effectiveness of the anti-CGRP pathway mAbs in migraine patients experiencing overuse of acute medications                    
                    </p>                    
                    <p>
                    <a className="btn red" target="_blank" href="https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Mitsikostas_Medication_Overuse.pdf" download="true"
                    >view</a>
                    </p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <div className={`p-5 comment_section  ${styles.comment_section}`}>
                        <p className="text-left">
                            <strong>
                            Submit your comments, questions and feedback about the on-demand content for the faculty to address at the Migraine Expert Forum on 18 June.
                            </strong>
                        </p>
                            <Editor type="textarea" placeholder="Leave your comment here" name='comment' rows={4} onChange={this.onChange} value={viewModel.comment} context={this} required/>
                        <p>
                        <button className="btn purple" onClick={() => this.openModal("modal")}>
                            SUBMIT
                        </button>
                        </p>
                      
                    </div>
                </div>
            </div>

            </div>   
        )
    }
}

export default Slides;