import React from 'react';
import { RouterLink } from 'mobx-state-router';
import { inject, observer } from "mobx-react";
import moment from "moment";
import FormWrapper from "../templates/form-wrapper";
import Branding1 from '../media/images/branding1.png'
import Branding2 from '../media/images/branding2.png'
import Branding3 from '../media/images/branding3.png'
import styles from "../components/forms/logIn.module.css"
import ThankYouComponent from "../components/forms/thankYou"

@inject("rootStore")
@observer
class RegisterThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.routerStore = this.props.rootStore.routerStore;
        this.state = { 
         }
    }

    static routeName = "RegistrationThankYouPage";
    static routePathPattern = "/thanks-for-registering";

    componentDidMount(){
        window.scrollTo({top: 0, behavior: "smooth"})

        const params = this?.routerStore?.routerState?.params;
        
        if(!params || !params.showThankYou){
            this.routerStore.goTo("home");
            return;
        }

    }

    render() {

        return ( 
            <FormWrapper>
                <div className={`${styles.thankyou}`}>
                <div className={styles.curve1}> 
                <img alt="Branding 1 image" src={Branding1}/>
                </div>

                <div className={styles.curve2}> 
                <img alt="Branding 2 image" src={Branding2}/>
                </div>


                <div className={styles.curve3}>
                        <img alt="Branding 3 image" src={Branding3}/>       
                </div>
                
                <ThankYouComponent />
                
            </div>
            </FormWrapper>
         );
    }
}
 
export default RegisterThankYou;