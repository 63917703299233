//key
//sd - self described
import {isNullUndefined, isObject} from "./util/util";
import {
    ADMINISTRATION_WIZARD_ROUTE,
    HOME_ROUTE,
  
  
} from "./routes";

/**
 * sd
 * by kevinbarasa
 */
export class AppNavigate {

    navigate = (navigator, to, from, navParams, secured) => {
        this.navigatedFrom = from;
        this.navigatedTo = to;
        if (!isNullUndefined(navParams) && isObject(navParams)) {
            this.navigatedToParams = navParams;
        }
        navigator.goTo(to, navParams);
    };

    navigatedToParams = null;
    navigatedTo = null;
    navigatedFrom = null;

    navigateToHome(navigator, navParams) {
        this.navigate(navigator, HOME_ROUTE.routeName, this.navigatedTo, navParams, true);
    }

    navigateToAdministrationWizard(navigator, navParams) {
        this.navigate(navigator, ADMINISTRATION_WIZARD_ROUTE.routeName, this.navigatedTo, navParams, true);
    }

    
   
   
   
}

const appNavigator = new AppNavigate();
export default appNavigator;
