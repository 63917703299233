import React from 'react';
import {RouterLink} from 'mobx-state-router';
import {inject, observer} from "mobx-react";
import { Edit } from '@material-ui/icons';
@inject('rootStore')
@observer
class submitButton extends React.Component {
    constructor(props){
      super(props);
      this.handleButtonClick = this.handleButtonClick.bind(this);
     
    }
   handleButtonClick(){
     var submittingForm = this.props.rootStore.formStore.submittingForm;
     if(submittingForm){
      return;
     }
     this.props.rootStore.formStore.setSubmittingForm(true);
     this.props.handleSubmitform();
     this.props.rootStore.formStore.setSubmittingForm(false);
   }
    render() { 
      const { showLoading, viewModel, activeSlide } = this.props;
      let buttonText = viewModel.type === "register" ? "Register" : "Save";
      let loadingText = viewModel.type === "register" ? "Registering" : "Saving";
      // buttonText = (activeSlide != 2 && viewModel.type == "register") || (activeSlide != 2 && viewModel.type == "edit")? "Next" : buttonText;
      buttonText = (activeSlide == 2)  ? "The end" : "Next";

      
        return ( 
            <>
             {/* start top buttons html */}
          <div className="row top_buttons ml-0 mr-0">
          <div className="col-12 p-0">
          <div className="">
            <div className="row justify-content-sm-center has_arrow_buttons mt-4 p-2 p-md-0">
                    {activeSlide !== 0 &&
              <div className="col-12 col-sm-12 col-md-6 mt-2 pl-md-0">
              
                  <a className="button min-width-unset" routeName="home" onClick={this.props.previous}>Previous</a>
             
              </div>
                    }

              <div className="col-12 col-sm-12 col-md-6 mt-2 pr-md-0">
               
             {showLoading ? <a className="button min-width-unset" >
                {loadingText}
                </a> : <> 
                  {
                   (activeSlide === 2) ? <></> : <a  className="button min-width-unset" onClick={this.props.next}> {buttonText} </a>
                  }


                  </>
                }
              </div>
            
            </div>
          </div>
        
          </div>
          </div>
          {/* end top buttons */}
          </>
         );
    }
}
 
export default submitButton;