import Amplify, { Auth, API } from 'aws-amplify';
import { userManagementApi, config } from "../util/config";
import { invokeApiWithSignedRequest } from "../util/util";
import axios from "axios";

export const userService = {
  createUser,
  getUsersByGroup,
  addToDefaultGroup,
  addToGroup,
  getUserByUsername,
  resetUserPassword,
  enableUser,
  disableUser,
  createEnabledUserWithWebUserRole,
  createUserWithTempPassword,
  deleteUser,
  updateUser,
  getAllusersInAGroup,
  getAllUsersInAGroupFromAPI,
  removeUserFromGroup,
  confirmUserSignup,
  adminApproveUser,
  adminDenyUser,
  getAllUsers,
  updateUserDetails,
  getUserApprovalByAdminStatus,
  getUserShowConfirmationMessage,
  hideUserConfirmationMessage,
  checkUserExists,
  listAllUsers
};

async function getUserShowConfirmationMessage(username){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.getUserShowConfirmationMessage;

  let init = {
    body: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }

  return await API.post(apiName, path, init).then((response) => {
    return response;
  });
}

async function getUserApprovalByAdminStatus(email){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.getUserApprovalByAdminStatus;

  let init = {
    body: {
      username: email,
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }

  return await API.post(apiName, path, init).then((response) => {
      return response;
   }).catch(err => {
      let errorMessage = err?.response?.data?.message;
      if(errorMessage){
        return { message: errorMessage }
      }
      return err;
   });
}

async function updateUserDetails(username, updatedAttributes){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.updateUserDetails;

  let init = {
    body: {
      username,
      updatedAttributes
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }

  return await API.post(apiName, path, init).then((response) => { return response });
}

async function adminApproveUser(username){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.adminApproveUser;

  let init = {
    body: {
      username: username
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }

  return await API.post(apiName, path, init).then((response) => { return response });
}

async function hideUserConfirmationMessage(username){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.hideUserConfirmationMessage;

  let init = {
    body: {
      username: username
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }

  return await API.post(apiName, path, init).then((response) => { return response });
}

async function adminDenyUser(username){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.adminDenyUser;

  let init = {
    body: {
      username: username
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }

  return await API.post(apiName, path, init).then((response) => { return response });
}


async function confirmUserSignup(username){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.confirmUserSignup;

  let init = {
    body: {
      username: username
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init).then((response) => { return response });
}

async function createUser(username, title, name, address,isAdmin, isSuperAdmin) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.createUserPath;
  let init = {
    body: {
      username: username,
      userAttributes: [
        { Name: "email", Value: `${username}` },
        { Name: "name", Value: `${name}` },
        { Name: "email_verified", Value: `${true}` },
        { Name: "custom:isSuperAdmin", Value: `${isSuperAdmin}` }
      ],
      isAdmin: isAdmin,
      isSuperAdmin: isSuperAdmin
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init).then((response) => { return response });
}
async function updateUser(user) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.updateUser;
  let init = {
    body: {
      username: user.email,
      userAttributes:
        [
          { Name: "name", Value: `${user.name}` },
          { Name: "custom:isSuperAdmin", Value: `${user.isSuperAdmin}` }
        ],
      isAdmin: user.isAdmin,
      isSuperAdmin: user.isSuperAdmin
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init).then((response) => { return response });
}
async function createEnabledUserWithWebUserRole(user) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.createEnabledUserWithWebUserRolePath;
  let init = {
    body: {
      username: user.email,
      userAttributes: [
        { Name: "email", Value: `${user.email}` },
        { Name: "name", Value: `${user.name}` },
        { Name: "email_verified", Value: `${true}` },
        { Name: "custom:isSuperAdmin", Value: `${user.isSuperAdmin}` }
      ],
      isAdmin: user.isAdmin,
      isSuperAdmin: user.isSuperAdmin
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init).then((response) => { return response });
}

async function createUserWithTempPassword(user) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.createUserWithTempPasswordPath;
  let init = {
    body: {
      username: user.email,
      userAttributes: [
        { Name: "email", Value: `${user.email}` },
        { Name: "name", Value: `${user.name}` },
        { Name: "email_verified", Value: `${true}` },
        { Name: "custom:isSuperAdmin", Value: `${user.isSuperAdmin}` }
      ],
      isAdmin: user.isAdmin,
      isSuperAdmin: user.isSuperAdmin
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init).then((response) => { return response });
} 

async function getUsersByGroup(limit, groupName, nextToken) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.listUsersInGroupPath;
  let authToken = (await Auth.currentSession()).getIdToken().getJwtToken()
  let init = {
    queryStringParameters: {
      "groupname": groupName,
      "limit": limit,
      "token": nextToken
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: authToken,
    }
  }
  let { NextToken, ...rest } = await API.get(apiName, path, init);
  return { NextToken: NextToken, Users: rest.Users };
}

async function getAllUsers(){
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.getAllUsers;

  let init = {
    headers: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    }
  }
  let users = await API.get(apiName, path, init);
  return users;

}


async function listAllUsers(){
  let data = [];

  await axios({
      method: "get",
      url: config.apiUrl + userManagementApi.listAllUsers,
      headers: {
          "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
  }).then((response) => {
      data = response.data;
  }, (error) => {});

  return data;
}

async function getAllUsersInAGroupFromAPI(limit, groupName) {
  const apiName = userManagementApi.apiName;
  const path = userManagementApi.getAllUsersInAGroup;
  const init = {
    body: {
      "groupName": groupName,
      "limit": limit,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  }
  let users = await API.get(apiName, path, init);
  return users;
}

async function getAllusersInAGroup(limit, groupName) {
  let nextToken = '';
  let users = [];
  do {
    let { NextToken, Users } = await getUsersByGroup(limit, groupName, nextToken);
    nextToken = NextToken;
    users = users.concat(Users);

  } while (nextToken && nextToken.length > 0)
  return users;
}

async function addToDefaultGroup(username) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.addUserToGroupPath;
  let init = {
    body: {
      "username": username,
      "groupname": "WebUser"
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}
async function addToGroup(username, groupName) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.addUserToGroupPath;
  let init = {
    body: {
      "username": username,
      "groupname": groupName
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}

async function removeUserFromGroup(username, groupName) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.removeUserFromGroup;
  let init = {
    body: {
      "username": username,
      "groupname": groupName
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}
async function getUserByUsername(username) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.getUserByUsernamePath;
  let init = {
    queryStringParameters: {
      "username": username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  
  return API.get(apiName, path, init).then((result) => { return result });
  
}

async function resetUserPassword(username, password) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.resetUserPasswordPath;
  let init = {
    body: {
      "username": username
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}

async function disableUser(username, password) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.disableUserPath;
  let init = {
    body: {
      "username": username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}
async function enableUser(username, password) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.enableUserPath;
  let init = {
    body: {
      "username": username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}

async function deleteUser(username) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.deleteUserPath;
  let init = {
    body: {
      username: username
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, init);
}

async function checkUserExists(username) {
  let apiName = userManagementApi.apiName;
  let path = userManagementApi.checkUserExistsPath;
  let init = {
    body: {
      username: username,
    },
    headers: {
      'Content-Type': 'application/json',
    }
  }
  return API.get(apiName, path, init).then((result) => { return result });
}