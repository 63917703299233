import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/uwe-purple.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="FayyazAhmed photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Uwe Reuter, MD, PhD, MBA</h3>
                  <p style={{ color: "#f61a19" }}>Berlin and Greifswald, Germany</p>
                  <p>
                  Uwe Reuter is a neurologist. He is a partner in the Headache Programme at Charité Universitätsmedizin Berlin and Chief Medical Officer & CEO at Universitätsmedizin Greifswald in Germany. Prof. Reuter has a strong interest in basic and clinical headache research. His current interest is in the investigation of biomarkers of migraine. He is a member of several national and international headache organisations, currently serves as First Vice President of the European Headache Federation and is an associate editor of the Journal of Headache and Pain.
                  </p>
                  <p>
                  After finishing his residency training in Berlin, Prof. Reuter undertook a 3-year postdoctoral fellowship at Harvard Medical School in Boston, Massachusetts, USA. During his time in Boston, he focused on basic research in primary headaches and migraine aura, authoring several peer-reviewed publications in high-impact journals. Prof. Reuter has conducted numerous clinical trials as principal investigator.
                  </p>

                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
