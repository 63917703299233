import React from 'react';
import { action, computed, observable, toJS } from 'mobx';
import moment from 'moment';
import 'moment/locale/en-gb';
import { data } from '../../data/data';
import { inject, observer } from "mobx-react";
import EventSelectorItem from './eventSelectorItem';

@inject('rootStore')
@observer
class EventsSelector extends React.Component {

    constructor(props) {
        super(props);
        const { value, name, type } = this.props;
        this.state = {
           
        };
     
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {        
      
    }
   
   

    componentWillUnmount() {
        let { context, name, id } = this.props

    }
   
   
   
    onChange(value){
        this.setState({ value: value })
        this.props.onChange(this.props.name, value ? value : '');
    }

    getFakeEvents(){

        return [

            {eventId:1,eventTitle:'Topic 1', eventDate:'01/02/2021',eventDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id lacus ultricies, lacinia sapien in, tempus velit. Vestibulum egestas, orci eget mattis pretium, ex lectus rhoncus lorem, vel malesuada nisl eros eu nisi. Vestibulum eu bibendum elit, vel aliquet ipsum. Duis gravida tortor vitae efficitur dapibus.'}
            ,{eventId:2,eventTitle:'Topic 2', eventDate:'01/03/2021',eventDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id lacus ultricies, lacinia sapien in, tempus velit. Vestibulum egestas, orci eget mattis pretium, ex lectus rhoncus lorem, vel malesuada nisl eros eu nisi. Vestibulum eu bibendum elit, vel aliquet ipsum. Duis gravida tortor vitae efficitur dapibus.'}
            ,{eventId:3,eventTitle:'Topic 3', eventDate:'01/04/2021',eventDescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id lacus ultricies, lacinia sapien in, tempus velit. Vestibulum egestas, orci eget mattis pretium, ex lectus rhoncus lorem, vel malesuada nisl eros eu nisi. Vestibulum eu bibendum elit, vel aliquet ipsum. Duis gravida tortor vitae efficitur dapibus.'}

        ]

    }
   
    render() {
        const { type, name, id, context } = this.props;
        const { value } = this.state;
        const { rootStore: { contentStore } } = this.props;
        let contentModel = toJS(contentStore.content);
       var events =[];
        if(contentModel && contentModel?.eventsModel){
            events = contentModel.eventsModel.events;
        }
        else{
            events = this.getFakeEvents();
           
        }
        return <div>
                {events.map(event => <EventSelectorItem onChange={this.onChange} context={context} event={event}/>)}


            </div>

       
    }

}

export default EventsSelector;
