//key
//sd - self described
import {getObjectClone, stringifyObject} from "../util/util";

/**
 * sd
 * @param store
 * @returns {object}
 * by kevinbarasa
 */
export const persistedStore = (store) => {
    let savedStore = JSON.parse(localStorage.getItem(store));
    return savedStore;
};

/**
 * sd
 * @param schema
 * @returns {any}
 * by kevinbarasa
 */
export const storeFromSchema = (schema) => {
    return getObjectClone(schema);
};

/**
 * sd
 * @param stores
 * @param stopStoreUpdatesPersistence
 * @param storesClassNamespacePrefix
 * by kevinbarasa
 */
export const persistStoreUpdatesToLocalStorage = (stores, stopStoreUpdatesPersistence, storesClassNamespacePrefix) => {
    try {
        for (let store of stores) {
            let storeKey = storesClassNamespacePrefix + store.storeSchema.storeName;
            localStorage.setItem(storeKey, stringifyObject(store.store));
        }
    } catch (err) {
        stopStoreUpdatesPersistence();
        alert('Persist stores failure!!');
    }
};

/**
 * sd
 * @param stores
 * @param stopStoreUpdatesPersistence
 * by kevinbarasa
 */
export function revertStoresPersistence(stores, stopStoreUpdatesPersistence) {
    try {
        stopStoreUpdatesPersistence();
        for (let store of stores) {
            store.store = store.storeSchema;
        }
        localStorage.clear();
    } catch (err) {
        alert('Unable to stop stores persistence!!');
    }
}