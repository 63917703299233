import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { AssignmentReturn } from '@material-ui/icons';
import Button from "@material-ui/core/Button";

const typeIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const snackBarContentStyle = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    icontype: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContentWrapper(props) {

    const { className, message, onClose, type, classes, ...other } = props;
    const Icon = typeIcon[type];
    return (
        <SnackbarContent
            className={clsx(classes[type], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.icontype)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );

}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
    classes: PropTypes.any
};

const MySnackbarContent = withStyles(snackBarContentStyle)(MySnackbarContentWrapper);

const myCustomizedSnackbarsCmpStyles = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
});

export function MyCustomizedSnackbarsCmp(props) {

    const { message, type, duration, position, activity, errorCode, handleCloseToast, getResetCode } = props;
    const [open, setOpen] = React.useState(false);
    let showResetButton = false;
    let resetPasswordText = 'The verification code has expired. Please click the button to have a new code sent to you.';
    if(errorCode === 'CodeMismatchException' || errorCode == 'ExpiredCodeException'){
        showResetButton = true;
    }

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        handleCloseToast ? handleCloseToast() : setOpen(false);
    }
    function getNewCode() {
        handleClose();
        getResetCode();
    }
    const getNewResetCode = (
        <Button
            variant="contained"
            color="primary"
            onClick={getNewCode}><span>Get new code</span>
        </Button>);
    return (
        <div className='container has_arrow_buttons '>
            <Snackbar
                anchorOrigin={{
                    vertical: position || 'top',
                    horizontal: 'center',
                }}
                open={true}
                autoHideDuration={duration || 4500}
                onClose={() => handleClose}>

                <MySnackbarContent
                    onClose={() => handleClose}
                    type={type || 'info'}
                    message={showResetButton == true ? resetPasswordText :message || 'Alert!'}
                    action={showResetButton == true ? getNewResetCode : ''}
                />
            </Snackbar>
        </div>
    );
}

export const AlertToast = withStyles(myCustomizedSnackbarsCmpStyles)(MyCustomizedSnackbarsCmp);
