import React from 'react';
import {data} from '../../../data/data';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField'
import { inject, observer } from "mobx-react";
import MassEmailFormButton from '../../forms/mass-email-form-buttons';
import Editor from '../../editor/editor';
import { updateViewModel, submitMassEMailForm, submitMassEMailEditForm} from '../../../util/formHelper';
import styles from "./mass-email-form.module.css";
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import AnimatedShowMore from 'react-animated-show-more';
import {emailService} from "../../../services/email-service"
import {getAllPart1Attendees, getAllPart2Attendees} from "../../../controllers/events-controller"
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { HighlightOff } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';  
import {doArraysContainSameElements, isNullUndefined} from "../../../util/util"
import { CircularProgress } from '@material-ui/core';
import { toJS } from 'mobx';

@inject('rootStore')
@observer
class MassEmailForm extends React.Component {
    constructor(props) {
        super(props); 
        this.routerStore = this.props.rootStore.routerStore;
        this.massEmailsStore = this.props.rootStore.massEmailsStore;

        this.state = {
            requiredList: [],
            viewModel: {
                id: '',
                body: '',
                subject: '',
                to: [],
                dateCreated: '',
                dateModified: '',
                status: '',
            },
            selectedCountry: '',
            countries: data.getCountries(),
            searchTerm: '',
            countryList: [],
            selectedEmail: null,
            showLoading: false,
            filterBy: "allUsers",
            part1Attendees: [],
            loadingToEmails: false,
        };
        this.handleSaveDraftButton = this.handleSaveDraftButton.bind(this);
        this.handlePreviewEmailButton = this.handlePreviewEmailButton.bind(this);
        this.handleUpdateDraftButton = this.handleUpdateDraftButton.bind(this);
        this.onCountryFilterChange = this.onCountryFilterChange.bind(this);
        this.removeCountryFilter = this.removeCountryFilter.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount(){
        this.setState({loadingToEmails: true});
        const {emailId} = this.props;
        let selectedEmail = null;

        if(emailId){
            const allEmails = await emailService.getAllEmails()
            this.massEmailsStore.addMassEmails(allEmails.Items);
            selectedEmail = allEmails?.Items?.find((email) => email.id === emailId);
            this.setState({viewModel: selectedEmail, selectedEmail: selectedEmail});
        }
        this.setState({loadingToEmails: false});

    }

    getSelectedEmail = async (emailId) => {
        let allEmails = toJS(this.massEmailsStore.massEmails);
        if (!allEmails || !allEmails.find((x) => x.id === emailId)) {
            allEmails = (await emailService.getAllEmails())?.Items;
            this.massEmailsStore.addMassEmails(allEmails);
        }
        let selectedEmail = allEmails?.find((email) => email.id === emailId);

        return selectedEmail;
        
    }

    onChange(field, value) {
        updateViewModel(this, this.state.viewModel, field, value);
    }
    onCountryFilterChange(field, value){
        let {countries, countryList, selectedCountry} = this.state;

        if(countryList.find(x => x == value) || value.length <= 0 ){
            return;
        }
        countryList.push(value);
        selectedCountry = "";
        this.setState({countryList,selectedCountry});
    }
    removeCountryFilter(value){
        let { countryList} = this.state;

        var index = countryList.indexOf(value);
        countryList.splice(index, 1);
        this.setState({countryList});
    }
    handleSaveDraftButton = async () => {
        let { showLoading} = this.state;
        if(showLoading) return;
        this.setState({ showLoading: true});
        var id = uuidv4();
        let {viewModel} = this.state;
        if (!viewModel?.id || viewModel){
            viewModel.id = id;
            viewModel.status = data?.emailStatus?.filter(x => x.name === 'Draft')[0].id;
        }
        
        this.setState({ viewModel }, async () => {
            const result = await submitMassEMailForm(this);
            if (result) {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully saved mass emails' });
            } else {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: '' });
            }
            this.setState({ showLoading: false });
        });
    }
    handleUpdateDraftButton = async () => {
        let { showLoading } = this.state;
        if (showLoading) return;
        this.setState({ showLoading: true });
        let { viewModel } = this.state;

        this.setState({ viewModel }, async () => {
            try {
                await submitMassEMailEditForm(this);
                this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully updated mass emails' });
            }catch {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Please ensure all required fields are filled' });
            }
            this.setState({ showLoading: false });
        });
    }
    selectAllUsers = (name, value) => {
        const {users} = this.props;
        const {viewModel, searchTerm} = this.state;

        let currentUsersFrontend = this.getFilteredUsers(users)?.filter(x => x.email.toLowerCase().includes(searchTerm.toLowerCase()))

        let allSelectedUsers = [];
        if(value){
            if (viewModel?.to?.length !== currentUsersFrontend?.length){

                allSelectedUsers = currentUsersFrontend.map(user => user.username);
            }
        }else{
        }
        let newViewModel = Object.assign({}, {...viewModel, to: allSelectedUsers});
        this.setState({ viewModel: newViewModel })
    }

    selectSingleUser = (username) => {
        const { viewModel} = this.state;
        let newUserList = viewModel?.to.slice();
        let newViewModel = {...viewModel};
        if (viewModel?.to.includes(username)){
            newViewModel = {...viewModel, to: newUserList.filter(user => user !== username)}
        }else{
            newViewModel = {...viewModel, to: [...viewModel?.to, username]}
        }
        this.setState({ viewModel: newViewModel });
    }

    filterOnChange = (field) => {
        this.setState({filterBy: field})
    }

    getFilteredUsers = (users) => {
        if(!users) return [];
        const { countryList } = this.state;
        if(countryList && countryList.length > 0){
            users = users.filter(i => countryList.includes(i.country));
        }

        return users;
    }

    toogleSelectUsers = async () =>{
        this.props.context.getAllUsers();
        this.setState({
            selectUsers: !this.state.selectUsers
        })
    }

    handlePreviewEmailButton(){
        let {viewModel} = this.state;
        this.massEmailsStore.addTemporaryEmail(viewModel);
        if (!viewModel?.id){
            this.routerStore.goTo("PreviewUnsavedEmail");
        }else{
            this.routerStore.goTo("PreviewEmail", { id: viewModel.id });
        }
    }
    onSearchChange = async (e) =>{
        let value = e.target.value.toLowerCase();
        this.setState({ searchTerm: value});
    }

    clearSearch = async () => {
        this.setState({ searchTerm: '' });
    }

    render() {
        const {  users } = this.props;
        const { viewModel, selectUsers, selectedEmail, searchTerm, countryList, countries, selectedCountry} = this.state;
        const updateSelectClass = selectUsers ? `${styles.show}` : "";
        const filteredUsers = this.getFilteredUsers(users);

        let currentUsersFrontend = this.getFilteredUsers(users)?.filter(x => {
            return  x.email.toLowerCase().includes(searchTerm) || x.firstName.toLowerCase().includes(searchTerm) || x.lastName.toLowerCase().includes(searchTerm)
        }).map(user => user?.username);

        return (
            <div className={` ${styles.main_wrap} ${updateSelectClass}`}>
                <div className={`row justify-content-md-center custom_mui_forms select-users `}>
                    <div className={` col-12 col-sm-12 order-2 order-md-1  ${selectUsers ? 'col-md-9' : 'col-md-12' }`} >
                        <div className="row">
                            <div className="col-12">
                               <h3>
                                   {selectedEmail ? `Edit Mass Email - ${selectedEmail.id}` : "Create a new mass email"}
                               </h3>
                            </div>
                        </div>
                        <div className={`row ${styles.bordered_section}`}>
                            <div className="col-12 col-sm-12 col-md-1 text-left">
                                <h5 className="">From:</h5>
                            </div>
                            <div className="col-12 col-sm-12 col-md-11 text-left">
                           <span className={`${styles.custom_budge}`}>MHM MEETING (no-reply@mhm-meeting.com)</span>
                          
                            </div>
                        </div>
                        <div className={`row ${styles.bordered_section}`}>
                            <div className="col-12 col-sm-12 col-md-1 text-left">
                                <h5>To:</h5>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 pr-0 text-left">
   
                                <>
                                    <div  className={`${styles.custom_budge}`}
                                        
                                        style={{ maxHeight: '332px',
                                    overflow: 'auto',
                                    padding: '10px 20px',
                                    boxShadow: '-16px 10px 40px 7px #bcbcbc',
                                    borderRadius: '7px' }}
                                    >
                                        {isNullUndefined(users) || isNullUndefined(viewModel?.to) ? (
                                            <><CircularProgress size={10} /> Loading...</>
                                        ) : (
                                            <>
                                                    {(users && viewModel?.to.length > 0) ? (
                                                
                                                viewModel?.to?.map(function(item, index){
                                                    let email = (index === 0 ? "" : ", ") + users?.find(x => x.username === item)?.email;
                                                    return email? email : item;
                                                })
                                                
                                            ) : (
                                                <span>No recipients found</span>
                                            )}
                                            </>
                                        )}
                                            
                                     </div>
                                </>
                               
                            </div>
                            <div className="col-12 col-sm-12 col-md-3 text-right">
                                <div className={`btn ${styles.select_btn} ${selectUsers ? `${styles.active}` : ""}`} onClick={this.toogleSelectUsers}>Select <AddCircleOutlinedIcon /></div> 
                            </div>
                        </div>
                        <div className="row">
                       
                            <div className="col-12 col-sm-12 col-md-1 text-left">
                                <h5 className="mt-3">Subject:</h5>
                            </div>
                            <div className="col-12 col-sm-12 col-md-11 text-left">

                                <Editor type={"text"} onChange={this.onChange} placeholder="type in your subject" name={'subject'} value={viewModel?.subject} context={this} required />
                          
                            </div>

                        </div>
                        <div className="row">
                        <div className="col-12 col-sm-12 col-md-1 text-left">
                                <h5 className="mt-3">Email body:</h5>
                            </div>
                            <div className="col-12 col-sm-12 col-md-11 text-left">
                                <p><strong>Dear [User Name]</strong></p>
                            
                                    <Editor type={"multilinetext"} onChange={this.onChange} placeholder="type in here..." name={'body'} value={viewModel?.body} context={this} rows={15} required/>
                          
                            </div>
                         

                        </div>
                      
                        <div className="row">
                            <div className="col-12">
                                <div className=''>
                                    <MassEmailFormButton handleSaveDraftButton={this.handleSaveDraftButton} handlePreviewEmailButton={this.handlePreviewEmailButton} handleUpdateDraftButton={this.handleUpdateDraftButton} showLoading={this.state.showLoading} id={viewModel?.id} status={viewModel.status}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={` mb-5 pb-4 col-12 col-sm-12 order-md-1 order-1 ${selectUsers ? 'col-md-3' : 'col-md-12' }`} >
                    <div className={styles.select_wrap}>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 ">
                                <h4>Filter by</h4>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 ">
                                {countries && countryList && countryList.length > 0 && 
                                    countryList.map((name)=>{
                                        var name =  countries.find(x => x.name == name)?.name;
                                        return <span className='filter-option'>{name} <IconButton onClick={() => {this.removeCountryFilter(name)}}><HighlightOff/></IconButton> </span>
                                    })
                                }
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 mb-2">
                                       <Editor type={"select"} name={"country"} onChange={this.onCountryFilterChange} context={this}  placeholder={'Add country'} value={selectedCountry}/>
                            </div>
                        </div>
                        
                       
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <h4>Select users</h4>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12">
                                    <TextField id="search" name="search" onChange={this.onSearchChange} value={searchTerm} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ), 
                                        endAdornment: (
                                            < InputAdornment position="end" >
                                                <IconButton onClick={this.clearSearch}>
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}/>
                            </div>
                        </div>
                        <div className="row">
                        
                            {filteredUsers.length > 0 && (
                                <div className="col-12 col-sm-12 col-md-12 mb-2">
                                    <Editor name={"selectAll"} onChange={this.selectAllUsers} context={this} type={"checkbox"} checked={doArraysContainSameElements(viewModel?.to, currentUsersFrontend)} value={doArraysContainSameElements(viewModel?.to, currentUsersFrontend) }/>
                                        Select/Deselect All
                                </div>
                            )}
                            <div className="col-12 mb-2">
                                {filteredUsers.length > 0 ? (
                                        
                                        filteredUsers.filter(x => {
                                        
                                            return searchTerm.length <= 0 || x.email.toLowerCase().includes(searchTerm) || x.firstName.toLowerCase().includes(searchTerm) || x.lastName.toLowerCase().includes(searchTerm)
                                        }).map((user) => {
                                        let isChecked = viewModel?.to.indexOf(user?.username) !== -1;
                                        
                                        return (
                                            <div key={user?.username} style={{float: "left"}}>
                                                <Editor type={"checkbox"} name={user.username} onChange={this.selectSingleUser} checked={isChecked} context={this}  />
                                                <span>
                                                    {`${user?.firstName} ${user?.lastName} - ${user.email}`}
                                                </span>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <span>No users found</span>
                                )}
                            </div>
                          
                        </div>
                    </div>
                    </div>
               
                </div>
           
           
           
            </div>
        );
    }

}

export default MassEmailForm;
