import React from 'react';
import { inject, observer } from "mobx-react";
import Editor from '../editor/editor';
import EditButton from "../forms/editButton"
import Tooltip from '@material-ui/core/Tooltip';
import { updateViewModel, submitEventTimeEditForm, deleteEventDate } from '../../util/formHelper'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {DeleteOutline} from "@material-ui/icons"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

@inject('rootStore')
@observer
class EventTimeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredList: [],
            viewModel: {
                ...this.props.viewModel,
            },
            showLoading: false,
            eventType: this.props.eventType
        };
        this.handleSubmitform = this.handleSubmitform.bind(this);
        this.onDeleteEventDate = this.onDeleteEventDate.bind(this);
        this.deleteEventConfirmed = this.deleteEventConfirmed.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(field, value) {
        updateViewModel(this, this.state.viewModel, field, value);
    }

    onSubmitEventDate = () => {

        const { viewModel } = this.state;

        if(viewModel.time.length < 1 || viewModel.eventLimit.length < 1){
            return;
        }

        if(viewModel.date.length < 1){
            this.setState({viewModel: {...viewModel, date: new Date()}}, () => {
                this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to submit this event date?`, onConfirm: this.handleSubmitform, data: { context: this, eventId: this.props.eventId }});
            })
        }else{
            this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: `Are you sure you want to submit this event date?`, onConfirm: this.handleSubmitform, data: { context: this, eventId: this.props.eventId }});
        }
    }
    
    handleSubmitform = async (data) => {
        const { viewModel } = this.state;

        try {
            let result = await submitEventTimeEditForm(data.context, data.eventId)
            
            if (viewModel.id.length <= 0) {
                viewModel.id = result.id;
            }

            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Event date was successfully submitted', onDismiss: () => {
                this.props.rootStore.alertStore.clearAlert();
                this.props.EventTimesGridContext.updateViewModel(result);
            } });
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Event date was not updated. Kindly retry again' });
        }

    }
    deleteEventConfirmed = async (data) => {
        const { viewModel } = this.state;
        try {
            let result = await deleteEventDate(data.context, data.eventId);
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully deleted event date' });
            this.props.onDeleteEventDate(viewModel.id);
        }catch(e){
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Event date was not deleted. Kindly retry again' });
        }
        return
    }
    onDeleteEventDate(){
        const { viewModel } = this.state;

        if(viewModel.time.length < 1 || viewModel.eventLimit.length < 1 || viewModel.date.length < 1){
            this.props.onDeleteEventDate(viewModel.id);
            return;
        }

        var message = `Are you sure you want to delete this event date? `;
        
        if (viewModel.registeredUsers?.length > 0){
            message += 'This event date already has ' + viewModel.registeredUsers.length + ' users registered';
        }
        this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: message, onConfirm: this.deleteEventConfirmed, data: { context: this, eventId: this.props.eventId }});
    }
    render() {
        const { viewModel, eventType } = this.state;
        const { authStore, routerStore, } = this.props.rootStore;
        return (
            <div className='container'>
                    <div className="row justify-content-md-center">
                        <div className={` col-12 col-sm-12 col-md-12`} >
                            <div className="row">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 text-center">

                                        <Editor type={"text"} onChange={this.onChange} placeholder="Speaker" name={'speakerName'} value={viewModel.speakerName} context={this} required />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-3 text-center">
                                    <Editor type={"date"} onChange={this.onChange} placeholder="Event Date" name={'date'} value={viewModel.date} context={this} required />
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 text-center">

                                    <Editor type={"text"} onChange={this.onChange} placeholder="Event Time" name={'time'} value={viewModel.time} context={this} required />
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 text-center">
                                    <Editor type={"number"} onChange={this.onChange} placeholder="Attendees limit" name={'eventLimit'} value={viewModel.eventLimit} context={this} required />
                                </div>
                                <div className="col-12 col-md-1 text-center">
                                    <div onClick={this.onSubmitEventDate} style={{cursor: "pointer"}}>
                                        <Tooltip title="Save this date">
                                            <CheckCircleOutlineIcon />
                                        </Tooltip>
                                    </div>
                                <div onClick={this.onDeleteEventDate} style={{cursor: "pointer"}}>
                                        <Tooltip title="Delete this date">
                                            <DeleteOutline />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

}

export default EventTimeForm;
