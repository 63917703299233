import React, {Fragment} from "react";
import styles from "./programmeOverview.module.css";

import PracticalWorkshopIcon from "../../../../media/images/mhm2022/practical_workshop.png";
import Forum from "../../../../media/images/mhm2022/forum.png";
// eslint-disable-next-line import/no-webpack-loader-syntax
import SeeAgendaModal from "../../../public/programmerOverview/seeAgendaModal/modal";
import MigraineExpertForum from "../../../public/programmerOverview/migraineExpertForum/modal";

class programmeOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agendaModal: false,
      expertSessionModal: false,
      migraineExpertForumModal: false
    };
  }

  openModal = (type) => {
    this.disableScroll();
    switch (type) {
      case "agendaModal":
        this.setState({
          agendaModal: true,
        });
        break;
      case "expertSessionModal":
        this.setState({
          expertSessionModal: true,
        });
        break;
      case "migraineExpertForumModal":
        this.setState({
          migraineExpertForumModal: true,
        });
        break;

      default:
        return;
    }
  };

  closeModal = (type) => {
    this.enableScroll();
    switch (type) {
      case "agendaModal":
        this.setState({
          agendaModal: false,
        });
        break;
      case "expertSessionModal":
        this.setState({
          expertSessionModal: false,
        });
        break;
      case "migraineExpertForumModal":
        this.setState({
          migraineExpertForumModal: false,
        });
        break;

      default:
        return;
    }
  };
  disableScroll = () => {
    document.body.style.overflow = "hidden";
  };
  enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  render() {
    let {
      agendaModal,
      expertSessionModal,
      migraineExpertForumModal,
      educationOnDemandModal,
    } = this.state;
    return (
<Fragment>
<div
        id="section2"
        className="pb-4"
        style={{
          background: "#e7e6ea",
          position: "relative",
          paddingBottom: "10vh !important",
        }}
      >
        <span className={styles.icon1}></span>
        <span className={styles.icon2}></span>
        <section className="mt-5" style={{ paddingLeft: "15px", paddingRight: "15px"}}>
          <div className={`container ${styles.containerProgramme}`}>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-12">
                <div
                  style={{
                    border: "1px solid #1a0a60",
                    padding: "10px 5px 5px",
                    borderRadius: "5px",
                    marginBottom: "30px",
                    textAlign:"center"
                  }}
                >
                  <p className="mb-1 mt-1">
                    <strong>
                      This website is intended for use by Healthcare Professionals
                      only.
                    </strong>
                  </p>
                  <p>
                    {" "}
                    <strong>
                      This promotional meeting is organised and funded by Teva
                      Pharmaceuticals Europe B.V.
                    </strong>
                  </p>
                  <p  >
                    Some sessions may contain product information. <br />
                    For the fremanezumab UK
                    prescribing information and adverse events reporting, please
                    see footer of the page.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.section1}>
          <div className="container" style={{ position: "relative" }}>
            <h1 className={"graphical_heading"}>Programme Overview</h1>
          </div>
        </section>
        <section className={styles.programme_content}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 text-center">
                <img src={PracticalWorkshopIcon} />
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-lg-10">
                <h3 className="mb-3">
                  Day 1 – Friday 4 October 2024, 14:00 – 18:00 CEST
                </h3>
                <p>
                    With the patient at the centre of the discussion, the first day will 
                    start by exploring how neurologists can reduce the migraine burden 
                    and improve the patient journey, and will cover the latest updates 
                    to the treatment landscape that must be understood to provide more 
                    tailored patient care.{" "}
                  </p>
                  <p>
                    Next will come a practical look at managing migraine and psychiatric 
                    comorbidities, followed by a choice of interactive workshop discussions 
                    on various topics of interest. Managing under-studied patient profiles 
                    will then be placed in the spotlight, and the day will end with a 
                    discussion on the influence of hormones on migraine and how best to 
                    manage migraine over the long-term. 
                  </p>
                <div className="row ml-0 mt-4">
                  <div className="margi-auto-mobile">
                    <a 
                      onClick={() => this.openModal("agendaModal")}
                      className="button mt-2"
                    >
                      SEE AGENDA
                    </a>
                    {/* POP UP */}
                    <SeeAgendaModal
                      closeModal={() => this.closeModal("agendaModal")}
                      isActive={agendaModal}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 text-center">
                <img src={Forum} />
              </div>
              <div className="col-12 col-sm-12 col-md-9 col-lg-10">
                <h3 className="mb-3">
                  Day 2 – Saturday 5 October 2024, 09:00 – 13:00 CEST
                </h3>
                <p>
                  The second day will take a deep dive into the practical aspects of 
                  using CGRP pathway targeting treatments, using patient cases to illustrate 
                  how to manage clinical challenges. Question clinics will provide an 
                  opportunity to get answers from experts on a number of prominent 
                  topics in migraine management, and a debate will explore whether 
                  CGRP pathway mAbs are disease-modifying therapies. {" "}
                </p>
                <p>
                  Insights into cutting-edge  developments in migraine will be provided, 
                  before a final chance to get questions answered by the faculty in a townhall discussion.
                </p>
                <div className="row ml-0 mt-4">
                  <div className="margi-auto-mobile">
                    <a
                      onClick={() => this.openModal("migraineExpertForumModal")}
                      className="button mt-2"
                    >
                      SEE AGENDA
                    </a>
                    {/* POP UP */}
                    <MigraineExpertForum
                      closeModal={() =>
                        this.closeModal("migraineExpertForumModal")
                      }
                      isActive={migraineExpertForumModal}
                    />
                  </div>
                </div>
                {/* <div className="text-center"><p className="mt-5"><em>*Not all gepants are currently approved for the treatment of migraine by the EMA.</em></p></div> */}
              </div>
            </div>

            <div style={{ paddingBottom: "10vh" }}></div>
          </div>
        </section>
      </div>
</Fragment>
    );
  }
}

export default programmeOverview;
