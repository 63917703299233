import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/roberto-de-icco-yellow.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="Roberto De Icco photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Roberto De Icco, MD, PhD</h3>
                  <p style={{ color: "#f61a19" }}>Pavia, Italy</p>
                  <p>
                    Roberto De Icco is a neurologist at the Headache Science & Neurorehabilitation Unit 
                    and Coordinator of the Analysis of Movement Research Section of the IRCCS Mondino 
                    Foundation in Pavia, Italy. He is also an assistant professor in the Department 
                    of Brain and Behavioral Sciences at the University of Pavia. His research activity 
                    is focused on headache and neurorehabilitation, with a specific interest in 
                    neurophysiological investigations, the application of neuromodulation 
                    therapies, and the analysis of movement. Dr De Icco collaborates with 
                    the International Headache Society and is a member of the regional 
                    board of the Italian Society for the Study of Headaches.
                  </p>
                  <p>
                    Dr De Icco earned his medical degree, completed his residency and earned 
                    a PhD in biomedical sciences at the University of Pavia. He has been 
                    working as a consultant neurologist at IRCCS Mondino Foundation since 
                    2017. During his PhD, Dr De Icco completed a research fellowship at 
                    the Headache Department of the IRCCS Neuromed “Istituto Neurologico 
                    Mediterraneo” in Pozzilli, Italy, while he performed a research 
                    fellowship at the Danish Headache Center in Copenhagen, Denmark.
                  </p>
                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
