export const AppSchema = storeProviderNamespace => ({
    storeName: 'app',
    storeKey: storeProviderNamespace + 'app',
    loading: false,
    updated: false,
    SIGN_IN_AUTH: 'SIGN_IN',
    SIGN_UP_AUTH: 'SIGN_UP',
    FORGOT_PASSWORD_AUTH: 'FORGOT_PASSWORD',
    selectedAuthenticationForm: "SIGN_IN",
    loadingMessage: "Loading...",
    currentUser: null,
    showAdminModel: false
});

export const AdminSchema = storeProviderNamespace => ({
    name: 'admin',
    get schemaName() {
        return this.name;
    },
    storeKey: storeProviderNamespace + this.schemaName,
    loading: false,
    updated: false,
    loadingMessage: 'Loading...',
    selectedTask: null
});
