import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/edoardo-caronna-purple.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="Piero_Barbanti photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Edoardo Caronna, MD, PhD</h3>
                  <p style={{ color: "#f61a19" }}>Barcelona, Spain  </p>
                  <p>
                    Edoardo Caronna is a neurologist and post-doc researcher in the Headache Research Group
                    at the Vall d’Hebron University Hospital in Barcelona, Spain. Since his neurology residency,
                    completed in 2020, he developed a genuine interest in headache from the clinical and
                    research standpoint. In 2022 he earned a PhD in medicine at Universitat Autonoma de
                    Barcelona and, to date, has authored several articles in the headache field. His current
                    main interest is the epidemiology of headache disorders. Dr Caronna is a Junior Editor for Cephalalgia.
                  </p>
                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
