import { Auth } from "aws-amplify";
import { notificationCallback, isNullUndefined, getCountryCodeFromCountryName } from "../util/util";
import { validateEmail } from "../util/validation"
import { userService } from "../services/user-service";

export async function handleEditUserDetails(viewModel, context){

    if(!viewModel?.email){
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Something went wrong, please try again'});
        return;
    }

    context.setState({ showLoading: true});

    let {aggreedToCommunicate, confirmEmail, confirmPassword, password, type, userId, ...rest} = viewModel;

    let UserAttributes = [];

    for(let [key, value] of Object.entries(rest)){
        if(key === "undefined" || key === "null"){
            continue;
        }

        let attribute;
        switch(key){
            case "email":
                continue;
            default:
                attribute = {Name: `custom:${key}`, Value: `${value}`};
                break;
        }
        UserAttributes.push(attribute);
    }

    UserAttributes = UserAttributes.map(attribute => {
        if(attribute.Name === "custom:countryCode"){
            return {...attribute, Value: getCountryCodeFromCountryName(viewModel.country)};
        }
        return attribute;
    })

    let countryCodeExists = UserAttributes.find(attribute => attribute.Name === "custom:countryCode");


    if(!countryCodeExists){
        UserAttributes.push({Name: `custom:countryCode`, Value: getCountryCodeFromCountryName(viewModel.country)})
    }

    try {
        const result = await userService.updateUserDetails(viewModel.email, UserAttributes);
        context.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'User details updated successfully'})
        context.setState({ showLoading: false});
    }catch(e){
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Something went wrong, please try again'})
        context.setState({ showLoading: false});
        return;
    }
}

export async function handleLogin(email, password, authStore, activity, routerStore) {

    let approvalStatus = await userService.getUserApprovalByAdminStatus(email?.toLowerCase()?.trim());

    if(approvalStatus !== "approved"){
        if(approvalStatus === "pending"){
            return {
                message: "Your account status is pending. Please wait for admin to approve it"
            }
        }
        if(approvalStatus === "denied"){
            return {
                message: "Your registration was not approved by admin. You cannot log in."
            }
        }
        return approvalStatus
    }
    

    try {
        await Auth.signIn(email?.toLowerCase()?.trim(), password.trim()).then(user => {
            if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
                authStore.userObjectToResetPassword = user;
                routerStore.goTo('set-new-password');
                return false;
            } else {
                authStore.handleLogin(user);
                return true
            }
        });
    } catch (e) {
        return e;
    }
}
export async function handleLoginToSetNewUserPassword(email, password, authStore, activity, routerStore) {
    try {
        await Auth.signIn(email, password).then(user => {
            if (user.challengeName == 'NEW_PASSWORD_REQUIRED') {
                authStore.userObjectToResetPassword = user;
                return Promise.resolve(true);
            } else {
                routerStore.goTo('home');
                return Promise.reject();
            }
        });
    } catch (e) {
        //failed
        notificationCallback("err", "Sign in failed", activity);
        return Promise.reject();

    }
}
export async function handleValidateEmailExists(email) {
    if(!validateEmail(email)){
        return false;
    }

    let exists = await userService.getUserByUsername(email)
        .then((result) => {
            return true;
        },
        (error) => {

            return false;
        });

    return exists;
}

export async function handleCompleteNewPasswordchallenge(user, newPassword, routerStore) {
    Auth.completeNewPassword(
        user,
        newPassword,
    ).then(user => {
        return true;
    }).catch(e => {
        return Promise.reject();
    });
}

export async function handleRegister(viewModel, context) {
    try {
        context.setState({ showLoading: true, registerError: null }, () => { });

        let {aggreedToCommunicate, aggreedToTerms, confirmEmail, password, confirmPassword, type, userId, ...restOfViewModel} = viewModel;

        let customAttributes = Object.keys(restOfViewModel).reduce((newViewModel, key) => {
            switch(key){
                case "email":
                    break;
                case "events":
                    newViewModel[`custom:${key}`] = "[]";
                    break;
                // case "extraTopics":
                // case "eventTopics":
                //     var ids = restOfViewModel[key].join(",");
                //     newViewModel[`custom:${key}`] = ids;
                //     break;
                default:
                    newViewModel[`custom:${key}`] = restOfViewModel[key];
                    break;
            }
            return newViewModel;
        }, {});

        customAttributes["custom:approvedByAdmin"] = "pending";
        customAttributes["custom:confirmedMessage"] = "SHOW";
        customAttributes["custom:countryCode"] = getCountryCodeFromCountryName(restOfViewModel.country);
        customAttributes["custom:timeZoneName"] = "";
        var emailOfUserSigningUp = context.props.rootStore.formStore.emailOfUserSigningUp;
        if(emailOfUserSigningUp == restOfViewModel.email.trim()){
            context.setState({ registerError: "trying double registration.......", showLoading: false, });
            return;
        }
       context.props.rootStore.formStore.setEmailOfUserSigningUp(restOfViewModel.email.trim().toLowerCase());

        return await Auth.signUp({
            username: restOfViewModel.email.trim().toLowerCase(),
            password: password.trim(),
            attributes: {
                email: restOfViewModel.email.trim().toLowerCase(),
                ...customAttributes
            },
            clientMetadata: {
                isAdmin: `${false}`,
                isSuperAdmin: `${false}`,
            }
        }).then(res => {
                context.props.rootStore.routerStore.goTo("RegistrationThankYouPage", {showThankYou: true})
        });
    } catch (e) {
        context.setState({ registerError: e, showLoading: false, });
        return e;
    }
}

export async function handleLogout(authStore, activity) {
    try {
        await Auth.signOut();
        authStore.handleLogout(null);
    } catch (e) {
        notificationCallback("err", "Sign out attempt failed", activity);
    }
}

export function googleSignInHandler(response, authStore, activity) {
    try {
        let profile = response.getBasicProfile();
        // The ID token you need to pass to your backend:
        var id_token = response.getAuthResponse().id_token;
        //success
        notificationCallback("succ", "Success", activity);
        authStore.handleLogin(null);
    } catch (e) {
        //failed
        notificationCallback("err", "Sign in failed", activity);
    }

}

export function facebookSignInHandler(response, authStore, activity) {

    try {
        notificationCallback("succ", "Success", activity);
        authStore.handleLogin(null);
    } catch (e) {
        //failed
        notificationCallback("err", "Sign in failed", activity);
    }

}
export function passwordResetHandler(email, activity) {

}