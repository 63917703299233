//key
//sd - self described

// eslint-disable-next-line no-unused-vars
// import MatomoTracker from '@datapunt/matomo-tracker-js';
import { Auth } from "aws-amplify";
import Cookies from 'universal-cookie';
import { data } from "../data/data";
import { config } from "./config";
import sigV4Client from "./sigV4Client";
import { ReactAppConfigurations } from "./config";

// import { createInstance } from '@jonkoops/matomo-tracker-react';

var downloadjs = require("downloadjs");
const cookies = new Cookies();

// export const instance = createInstance({
//     // urlBase: '//stats.mhm-meeting.com/',
//     // siteId: ReactAppConfigurations.matomoSiteId,
//     // // userId: 'UID76903202', // optional, default value: `undefined`.
//     // //trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
//     // //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
//     // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//     // heartBeat: { // optional, enabled by default
//     //   active: true, // optional, default value: true
//     //   seconds: 10 // optional, default value: `15
//     // },
//     // linkTracking: false, // optional, default value: true
//     // configurations: { // optional, default value: {}
//     //   // any valid matomo configuration, all below are optional
//     //     disableCookies: true,
//     //     setSecureCookie: true,
//     //     setRequestMethod: 'POST'
//     // }
//   })

// const tracker = new MatomoTracker({
//     urlBase: '//stats.mhm-meeting.com/',
//     siteId: ReactAppConfigurations.matomoSiteId,
//     heartBeat: { // optional, enabled by default
//         active: true, // optional, default value: true
//         seconds: 10 // optional, default value: `15
//     },
//     configurations: { // optional, default value: {}
//         setSecureCookie: true,
//         setRequestMethod: 'GET'
//     }
// })

/**
 * sd
 * @param obj
 * @returns {any}
 * by kevinbarasa
 */
export const getObjectClone = obj => {
    return Object.defineProperties({}, Object.getOwnPropertyDescriptors(obj));
};

/**
 * sd
 * @param obj
 * @returns {string}
 * by kevinbarasa
 */
export function stringifyObject(obj) {
    return JSON.stringify(obj);
}

/**
 * self-described
 * @param value
 * @returns {boolean}
 */
export function isEmptyString(value) {
    try {
        if (typeof value !== "string") {
            return true;
        }
        return value.trim() === "";
    } catch (err) {
        return true;
    }
}

/**
 * self-described
 * @param item
 */
export function isNullUndefined(item) {
    try {
        return item === null || item === undefined;
    } catch (err) {
        return true;
    }
}

/**
 * sd
 * @param item
 * @returns {boolean}
 * by kevinbarasa
 */
export function isObject(item) {
    try {
        return item !== null && item !== undefined && typeof item === "object";
    } catch (err) {
        return false;
    }
}

/**
 * self-described
 * @param obj
 * @param key
 * by kevinbarasa
 */
export function objectKeyExists(obj, key) {
    try {
        return obj.hasOwnProperty(key);
    } catch (err) {
        return false;
    }
}

/**
 * The following mimics React's natural state update behavior.
 * This function might be required to be executed in instances where MobX has run too fast at ~50ms - 100ms, beyond React's state update speed
 * Also useful in ensuring imported components are loaded, in the case in which they cannot be accessed
 * by kevinbarasa
 */
export function mimicNaturalStateUpdateBehavior(self) {
    if (isNullUndefined(self) || typeof self !== "object") {
        alert("State update failed");
        return;
    }
    if (typeof self.state !== "object")
        self.state = {updated: false};
    self.setState({updated: !self.state.updated});
}

/**
 * sd
 * @param item
 * by kevinbarasa
 */
export function isBoolean(item) {
    try {
        return typeof item === "boolean";
    } catch (err) {
        return false;
    }
}

/**
 * sd
 * @param array
 * @returns {boolean}
 * by kevinbarasa
 */
export function isEmptyArray(array) {
    try {
        if (isNullUndefined(array)) {
            return true;
        } else {
            return !(array instanceof Array && array.length > 0);
        }
    } catch (err) {
        return true;
    }
}

/**
 * Utility function to handle the custom display of messages.
 * @param notificationType
 * @param response
 * @param activity
 * @param position
 * @param duration
 * by kevinbarasa
 */
export function notificationCallback(notificationType, response, activity, position = 'top', duration = 3500) {

    let typeOfNotification = 'info';

    if (notificationType === "err" || notificationType === "error") {
        typeOfNotification = 'error';
    }
    if (notificationType === "failure" || notificationType === "fail") {
        typeOfNotification = 'error';
    }
    if (notificationType === "succ" || notificationType === "success") {
        typeOfNotification = 'success';
    }
    if (notificationType === "warn" || notificationType === "warning") {
        typeOfNotification = 'warning';
    }
    if (notificationType === "info") {
        typeOfNotification = 'info';
    }

    let toastProps = activity.state.toastProps;
    toastProps.position = position;
    toastProps.duration = duration;
    toastProps.message = response;
    toastProps.type = typeOfNotification;
    toastProps.activity = activity;
    activity.setState({toastProps: toastProps}, () => {
        setTimeout(() => {
            toastProps.message = null;
            activity.setState({toastProps: toastProps});
        }, toastProps.duration);
    });

}

/**
 * sd
 * @param obj
 * @returns {*[]|any}
 * by kevinbarasa
 */
export function instanceProvider(obj) {
    if (obj instanceof Array) {
        return [...obj];
    }
    return JSON.parse(JSON.stringify(obj));
}

/**
 * sd
 * @type {{duration: number, activity: null, position: string, message: null, type: null}}
 * by kevinbarasa
 */
export const toastProps = {
    message: null, type: null, duration: 1500,
    position: 'top', activity: null
};

export async function signRequest(path, method = "GET", headers = {}, body){

    let currentCredentials = await Auth.currentCredentials();

    let clientConfig = {
        secretKey: currentCredentials.secretAccessKey,
        accessKey: currentCredentials.accessKeyId,
        sessionToken: currentCredentials.sessionToken,
        region: "eu-west-1",
        endpoint: config.apiUrl
    }

    const signedRequest = sigV4Client
                    .newClient(clientConfig)
                    .signRequest({
                        method,
                        path,
                        headers,
                        ...(method === "POST" && {body})
                    })

    body = body ? JSON.stringify(body) : body;
    headers = signedRequest.headers;
    
    const results = await fetch(signedRequest.url, {
        method,
        headers,
        ...(method === "POST" && {body})
    });

    if (results.status !== 200) {
        throw new Error(await results.text());
    }
    
    return results.json();
}

export const invokeApiWithSignedRequest = async (path, method, headers, body) => {
    return await signRequest(path, method, headers, body);
}

export async function downloadCsv(csv, fileName = 'MHM-2023-user-data.csv', mimeType = 'application/octet-stream'){
    let headers = csv[0]
    let data = csv[1]
    let csvRows = [headers, ...data];


    var csvContent = '';

    csvRows.forEach(function(infoArray, index) {
        let dataString = infoArray.join(',');
        csvContent += index < data.length ? dataString + '\n' : dataString;
    });


    var a = document.createElement('a');

    if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob(["\ufeff", csvContent], {
            type: mimeType
        }), fileName);
    } else if (URL && 'download' in a) {
        a.href = URL.createObjectURL(new Blob(["\ufeff", csvContent], {
            type: mimeType,
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        // location.href = 'data:application/octet-stream,' + encodeURIComponent(csvContent);
    }

    trackDownload("Admin Download", "Admin downloaded a csv file containing all user details")

}

export const doArraysContainSameElements = (array1, array2) => {
    try {
        if(array1?.length !== array2?.length){
            return false;
        }

        let equal = true;
        for(let item of array1){
            if(!array2.includes(item)){
                equal = false;
                break;
            }
        }
        return equal;
    }catch(e){
        return false;
    }
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getCountryCodeFromCountryName = (countryName) => {
    const countries = data.getCountries().map(item => {
        if(item.code === "GB"){
            return {
                code: item.code,
                name: "United Kingdom",
            }
        }
        if (item.code === "CZ") {
            return {
                code: item.code,
                name: "Czech Republic",
            }
        }
        return {
            code: item.code,
            name: item.name,
        }
    }); 

    const country = countries.find(country => country.name === countryName);

    if(!country?.code){
        let lowerCaseCountrtName = countryName?.toLowerCase();
        if(lowerCaseCountrtName?.startsWith("United Kingdom of Great Britain".toLowerCase())){
            return "GB";
        }
        if (lowerCaseCountrtName?.startsWith("Czechia".toLowerCase())) {
            return "CZ";
        }
    }

    return country?.code || "";
}

export function saveFileFromUrl(url) {
    downloadjs(url);
}

export function isMeetTheExpertEvent(item){
    try {
        return item.type === data?.eventTypes?.find(item => item.id === "Migraine Masterclass")?.id
    }catch(e){
        return false
    }
}

export function isInteractiveWorkshopEvent(item){
    try {
        return item.type === data?.eventTypes?.find(item => item.id === "Practical Workshop")?.id
    }catch(e){
        return false
    }
}

export function trackLinkClick(category, action, href, linkType = "link"){
    const hasAgreed = cookies.get('mhmwebsite_cookie') === 'true';
    if(hasAgreed){
        // tracker.trackEvent({category, action})
        // tracker.trackLink({href, linkType})
        // instance.trackEvent({category, action})
        // instance.trackLink({href, linkType})
    }else {
        cookies.remove("_pk_id.1.32b8")
        cookies.remove("_pk_ses.1.32b8")
        cookies.remove("_pk_id.2.e1b8") 
        cookies.remove("_pk_ses.2.e1b8")
    }

}

export function trackDownload(category, action, href){
    trackLinkClick(category, action, href, "download")
}

export function trackHomepageVideoFunctions(link, func = "play", loggedIn = false){
    const category = `Webcast ${func}`;
    let action = "";
    
    switch(func){
        case "play":
            action = `${loggedIn? "Logged In - " : "Logged Out - "} User played the homepage video: ${link}`;
            break;
        case "end":
            action = `${loggedIn? "Logged In - " : "Logged Out - "}The homepage video played to end: ${link}`;
            break;
        case "close":
            action = `${loggedIn? "Logged In - " : "Logged Out - "}User closed the homepage video: ${link}`;
            break;
        default:
            action = `${loggedIn? "Logged In - " : "Logged Out - "}User interacted with the homepage video: ${link}`;
            break;
    }
    
    // tracker.trackEvent({category, action})
    // instance.trackEvent({category, action})
}

export function trackAudioFunctions(link, func = "play"){
    const category = `Podcast ${func}`;
    let action = "";
    
    switch(func){
        case "play":
            action = `User played the podcast: ${link}`;
            break;
        case "end":
            action = `The podcast played ended: ${link}`;
            break;
        case "close":
            action = `User closed the podcast: ${link}`;
            break;
        default:
            action = `User interacted with the podcast: ${link}`;
            break;
    }
    
    // tracker.trackEvent({category, action})
    // instance.trackEvent({category, action})
}
export function trackVideoFunctions(link, func = "play"){
    const category = `Webcast ${func}`;
    let action = "";
    
    switch(func){
        case "play":
            action = `User played the webcast: ${link}`;
            break;
        case "end":
            action = `The webcast played ended: ${link}`;
            break;
        case "close":
            action = `User closed the webcast: ${link}`;
            break;
        default:
            action = `User interacted with the webcast: ${link}`;
            break;
    }
    
    // tracker.trackEvent({category, action})
    // instance.trackEvent({category, action})
}
export function globalOnClickHandler(event){

    let link = event?.target?.href || "";
    let innerText = event?.target?.innerText || event?.target?.innerHTML || "";
    let category = "";
    let action = "";

    switch(event?.target?.nodeName){
        case "A":

            category = "Link click";

            if (event.target.download === "true") {
                action = `User clicked '${innerText}', to access: ${link}`;
                trackDownload(category, action, link);
            } else {
                action = link ? `User clicked '${innerText}', to access: ${link}` : `User clicked '${innerText}' on the page: ${window.location.href}`;
                trackLinkClick(category, action, link);
            }
            break;

        case "BUTTON":

            category = "Button click";

            action = link ? `User clicked '${innerText}', to access: ${link}` : `User clicked '${innerText}' on the page: ${window.location.href}`;

            trackLinkClick(category, action, link);
            
            break;

        default:
            break;
    }
}

export function getuserDetailsProperties() {
    return [
        "#",
        "title",
        "firstName",
        "lastName",
        "email",
        "approvedByAdmin",
        "country",
        "countryCode",
        "phoneNumber",
        "tevaContactPerson",
        "speciality",
        "companyName",
               
        "altContactName",
        "altContactEmail",
        "altContactTelephone",
        // "eventTopics",
        // "extraTopics",
        "willRequireHotelRoom",
        "transportMethod",
        "flightNumber",
        "arrivalLocation",
        "arrivalDate",
        "arrivalTime",
        "hotelCheckinDate",
        "hotelCheckOutDate",
        "dietaryRequest",
        // "allergies",
        "specialRequest",
        "isAdmin",
        "Workshop 1",
        "Workshop 2",
        "Question Clinic 1",
        "Question Clinic 2",   ]
}

export function getHeaders() {
    return [
        "#",
        "Title",
        "FirstName",
        "LastName",
        "Email",
        "ApprovedByAdmin",
        "Country",
        "CountryCode",
        "PhoneNumber",
       "tevaContactPerson", 
        "Speciality",
        "Organisation/Clinic/Institution/Hospital",
        
        "altContactName",
        "altContactEmail",
        "altContactTelephone",
        // "Plenary 3: Hot Topics",
        // "Plenary 4: Treating the Whole Patient",
        "Hotel Required",
        "transportMethod",
        "Flight/train/coach number",
        "arrivalLocation",
        "arrivalDate",
        "arrivalTime(CEST)",
        "hotelCheckinDate",
        "hotelCheckOutDate",
        "dietaryRequest",
        // "allergies",
        "specialRequest",
        "IsAdmin",
        "Workshop 1",
        "Workshop 2",
        "Question Clinic 1",
        "Question Clinic 2",
    ]

}

export function VideoPlayHandler(context){
   
}