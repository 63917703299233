import React from 'react';
import '../theme/regular-user.scss';
import {inject, observer} from "mobx-react";
import FormWrapper from "../templates/form-wrapper";
import {RouterLink} from 'mobx-state-router';

@inject('rootStore')
@observer
class CookiePolicy extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        document.title = "Cookies | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
      }

    static routeName = 'cookies';
    static routePathPattern = '/cookies';

    render() {
        let {authStore, routerStore} = this.props.rootStore;
        return (
            <FormWrapper>
                <div className={`page-wrapper`} style={{ background: "#e7e6eb", paddingBottom: "20px"}}>

                <div className="regular-user-wrapper " id="Privacy_statement">
                    <div className="container">
                    
                        <div className={"row"}>
                            <div className={"col-12 mt-4"}>
                                <div className="top_section pt-5">
                                        <h1 className="graphical_heading">Cookie Policy</h1>
                                </div>
                                    

                            </div>
                        </div>
                        <h2>
                                What are Cookies? 
                        </h2>
                        <p>
                            Cookies are text files containing small amounts of information which 
                            we download onto your computer or device when you visit our website. 
                            Therefore, when we refer to <strong>"you"</strong> in this section we mean your 
                            computer. We can recognise these cookies on subsequent visits and 
                            they allow us to remember you. These cookies help us make the 
                            website function properly, make it more secure, provide better 
                            user experience, and understand how the website performs and to 
                            analyse what works and where it needs improvement.
                        </p>
                        <h2>
                                How do we use Cookies? 
                        </h2>
                            <p>
                                This website uses strictly necessary cookies and third-party analytics cookies.
                            </p>
                            <p>
                                Strictly necessary cookies are essential for the website to function 
                                enabling you to open the website, move between the pages and 
                                enter your registration information. 
                            </p>
                            <p>
                                Third party analytics cookies are placed on your device for several purposes 
                                mainly for understanding how the website performs and how you interact with 
                                our website, with the aim of providing you with a better and improved user 
                                experience and help speed up your future interactions with our website. 
                            </p>
                            <h2>Types of Cookies we use: </h2>
                            <h3>
                                Strictly Necessary Cookies
                            </h3>
                            <p>
                                ID: ShowHcpDisclaimer<br/>
                                Duration: 150 days<br />
                                Description: This cookie is installed by the mhm-meeting.com website to track when a 
                                visitor acknowledges that they are a healthcare professional.
                            </p>
                            <p>
                                ID: mhmwebsite_cookie <br />
                                Duration: 150 days <br />
                                Description: This cookie is installed by the mhm-meeting.com website to store overall cookie preferences

                               
                            </p>
                            <h3>
                                Analytics
                            </h3>
                            <p>
                                Analytical cookies are used to understand how visitors interact with the website. 
                                These cookies help provide information on metrics such as the number of visitors, 
                                bounce rate, traffic source, etc. 
                            </p>
                            <p>
                                ID: _pk_id<br />
                                Duration: 1 year 27 days<br />
                                Description: Matomo set this cookie to store a unique user ID.<br />  <br />
                                ID: _pk_ses<br />
                              
                                Duration: 30 minutes<br />
                                Description: This cookie is used to store a unique session ID for gathering information on how the users use the website.
 
                            </p>
                            <h2>
                                Cookie Settings 
                            </h2>
                            <p>
                                When you first landed on the website you were given the chance to set your cookie preferences. 
                                If you wish to change these you can do so by clicking on Cookie Settings in the footer 
                                to return to the preference centre, or by using your browser settings.
                            </p>
                           
                            <p>
                                Last Updated: May 2024
                            </p>
                       
                    </div>

                </div>

        </div>
           </FormWrapper>
        );
    }

}

export default CookiePolicy;
