import {HistoryAdapter} from "mobx-state-router";
import {createBrowserHistory} from 'history';
import RootStore from "./root-store";

const rootStore = new RootStore();

// Observer of history changes
const historyAdapter = new HistoryAdapter(rootStore.routerStore, createBrowserHistory());
historyAdapter.observeRouterStateChanges();

export default rootStore;
