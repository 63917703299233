import React, { Component } from 'react'
import {observer, inject} from "mobx-react"

import Editor from '../../components/editor/editor';

import { contentService } from "../../services/content-service";


@inject("rootStore")
@observer
class RegisteredUsersTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            requiredList: [],
            viewModel: this.props.viewModel
        }
        this.addOrRemoveUserFromAttendedList = this.addOrRemoveUserFromAttendedList.bind(this);
    }

    async addOrRemoveUserFromAttendedList(username) {
        const { eventTime, eventId, context} = this.props;
        let message = eventTime.attendedUsers.includes(username) ? 'Successfully removed user from attended list' : 'Successfully added user to attended list';
        await contentService.addOrRemoveUserFromAttendedList(username, eventId, eventTime.id ).then((result) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: message, onDismiss: async () => await context.getUpdatedViewModel(eventId, eventTime.id)});
            return result;
        }, (error) => {
            this.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: message, onDismiss: async () => await context.getUpdatedViewModel(eventId, eventTime.id) });
            return null;
        });
    }

    filterRegisteredUsers = (users, eventTime) => {

        if(!users || !eventTime){
            return <React.Fragment />
        }

        let registeredUsers = [];

        for(let item of users){
            let isRegistered = (eventTime?.registeredUsers?.includes(item.Username)) // || eventTime?.attendedUsers?.includes(item.Username));
            if(isRegistered){
                let {Attributes, ...rest} = item;
                let user = {
                    attributes: Attributes.reduce((newAttributes,item) => {
                        newAttributes[item.Name] = item.Value;
                        return newAttributes;
                    }, {}),
                    ...rest
                }
                registeredUsers.push(user);
            }
        }

        return this.getUserListHtml(registeredUsers, eventTime);
    }

    getUserListHtml = (registeredUsers, eventTime) => {
        if(registeredUsers.length < 1){
            return (
                <p>No users are registered to this event</p>
            )
        }
        var sortedUsers = registeredUsers.sort(function(a,b) { let status =  a.attributes["custom:firstName"].localeCompare(b.attributes["custom:firstName"]); return status; });
        return sortedUsers.map((user) => {
            let name = `${user.attributes["custom:firstName"]} ${user.attributes["custom:lastName"]}`;
            let email = user.attributes.email;
            return (
                <tr key={user.Username}>
                    <td >
                        {name}
                    </td> 
                    <td >
                        {email}
                    </td> 
                    <td >
                        <Editor type={"checkbox"} onChange={() => this.addOrRemoveUserFromAttendedList(user.Username)} placeholder="Attended" name={'attended'} value={eventTime.attendedUsers.includes(user.Username)} context={this} />
                    </td>
                </tr>
            )
        })
    }

    render(){
        const {users, eventTime} = this.props;
        return (
            <React.Fragment>
                <table style={{width: "100%"}}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Attended</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.filterRegisteredUsers(users, eventTime)}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default RegisteredUsersTable;