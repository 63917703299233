import {config, serviceUrls, emailUrls} from "../util/config";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import amplify, { API } from 'aws-amplify';
import {Auth} from "aws-amplify";
import {data} from "../data/data";
 
export const contentService = {
    getAllContent,
    addContent,
    deleteContent,
    updateContent,
    getContent,
    getAllEvents,
    saveEvent,
    updateEvent,
    updateEventDetails,
    deleteEvent,
    bookEvent,
    addOrRemoveUserFromAttendedList,
    addOrRemoveUserFromRegisteredList,
    addEventDate,
    updateEventDate,
    deleteEventDate,
    getMainEmailTemplate,
    registerToEvents
};

async function getMainEmailTemplate(){
    let data = null;

    await axios({
        method: "get",
        url: config.apiUrl + emailUrls.getMainEmailTemplate + "?filename=mass-emails.html", 
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    }).then((response) => {
        data = response.data;
    }, (error) => {});

    return data;
}

async function getAllEvents(){
    let data = [];

    await axios({
        method: "get",
        url: config.apiUrl + serviceUrls.getAllEvents,
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    }).then((response) => {
        data = response.data;
    }, (error) => {});

    return data;
}

async function getAllContent() {
    let data = [];
    await axios({
        method: 'get',
        url: config.apiUrl + serviceUrls.getAllContent,
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          },
    }).then((response)=> {
        data = response.data;
    },(error) => {
    });

    return data;
}

async function addContent(model){
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.addContent,
        data: model,
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          },
    }).then((response) => {
            },
            (error) => {
            },
        );
    return;
}
async function deleteContent(id){
    await axios({
        method: 'get',
        url: config.apiUrl + serviceUrls.deleteContent + '?id=' + id,
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then(() => {},(error)=>{console.log(error)});
}

async function updateContent(model){
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.updateContent,
        data: model,
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
            },
            (error) => {
            },
        );
    return;
}

async function getContent() {
    let data = [];
    await axios({
        method: 'get',
        url: config.apiUrl + serviceUrls.getAllContent,
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          },
    }).then((response)=> {
        data = response.data;
    },(error) => {
    });

    return data;
}

async function saveEvent(model){
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.saveEvent,
        data: {eventModel: model},
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
            },
            (error) => {
            },
        );
    return;
}

async function updateEvent(newModel){
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.updateEvent,
        data: {id: newModel.id, eventModel: newModel},
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
            },
            (error) => {
            },
        );
    return;
}

async function updateEventDetails(newModel) {
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.updateEventDetails,
        data: { id: newModel.id, eventModel: newModel },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
    },
        (error) => {
        },
    );
    return;
}
async function deleteEvent(id){
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.deleteEvent,
        data: {id},
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then(() => {},(error)=>{console.log(error)});
}

async function bookEvent(newModel){
    await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.updateEvent,
        data: {id: newModel.id, eventModel: newModel},
        headers: { 
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
            },
            (error) => {
            },
        );
    return;
}

async function addOrRemoveUserFromRegisteredList(username, eventId, timeId, timeZoneName) {
    var result = await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.addOrRemoveUserFromRegisteredList,
        data: { username: username, eventId: eventId, timeId: timeId, timeZoneName: timeZoneName },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    return result;
}
async function registerToEvents(username,model){
    var result = await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.registerToEvents,
        data: { username: username, model: model },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    return result;
}
async function addOrRemoveUserFromAttendedList(username, eventId, timeId){
    var result = await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.addOrRemoveUserFromAttendedList,
        data: { username: username, eventId: eventId, timeId: timeId },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    return result;
}

async function addEventDate(model, eventId){
    model = {
        ...model,
        id: uuidv4(),
        dateCreated: new Date().toISOString(),
        dateModified: null,
        attendedUsers: [],
        registeredUsers: []
    }
    var result = await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.addEventDate,
        data: { eventId: eventId, model: model },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return model;
    },
        (error) => {
            return null;
        },
    );
    return result;
}
async function updateEventDate(model, eventId) {
    var result = await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.updateEventDate,
        data: { eventId: eventId, model: model },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    return result;
}

async function deleteEventDate(eventId, eventDateId) {
    var result = await axios({
        method: 'post',
        url: config.apiUrl + serviceUrls.deleteEventDate,
        data: { eventId: eventId, eventDateId: eventDateId },
        headers: {
            "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    }).then((response) => {
        return true;
    },
        (error) => {
            return false;
        },
    );
    return result;
}