import { inject, observer } from "mobx-react";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { getAllEvents, userAttendedPartOneEvent } from "../../../../controllers/events-controller";
// import Banner from "../../../post-registration/home/banner/banner"
import Banner from "../../../public/home/banner/banner";
import styles from "./banner.module.css";
import PracticalWorkshopIcon from "../../../../media/images/mhm2022/practical_workshop.png"
import MasterClassIcon from "../../../../media/images/mhm2022/masterclass.png"
import Forum from "../../../../media/images/mhm2022/forum.png"
import Poster from "../../../../media/images/poster.png"
import { trackHomepageVideoFunctions } from "../../../../util/util"
import OptimalMigraine from "./modals/optimalMigraine/optimalMigraine"
import { Auth } from "aws-amplify";
import { data } from "../../../../data/data";

// Presentation Images
import neuromodulation from "../../../../media/images/presentations/Isabel Pavão Martins, Francesca Puledda - Neuromodulation and nerve blocks for migraine.png";
import longTermEffectiveness from "../../../../media/images/presentations/Messoud Ashina, Andreas Gantenbein - Long-term effectiveness of CGRP pathway targeting treatments.png";
import acute from "../../../../media/images/presentations/Uwe Reuter, Cristina Tassorelli - Acute migraine - treatment landscape and current challenges.png";
import messoudAshina from "../../../../media/images/presentations/Messoud Ashina - Plenary 6 – 2024 in the spotlight - What is new in migraine research.png";
import treatmeant from "../../../../media/images/presentations/Cristina Tassorelli - Treatment persistence and outcomes.png";
import managing from "../../../../media/images/presentations/Plenary 3 – Understudied patient profiles - Edoardo Caronna.png";
import migraine from "../../../../media/images/presentations/Piero Barbanti and Edoardo Caronna - Plenary 2 – Migraine and mental health.png";
import improving from "../../../../media/images/presentations/Isabel Pavão Martins - Improving the quality of life of migraine patients in 2024.png";
import cgrpUwe from "../../../../media/images/presentations/Uwe Reuter - CGRP pathway mAbs are disease-modifying treatments (PDF).png";
import cgrpMessoud from "../../../../media/images/presentations/Messoud Ashina - CGRP pathway mAbs are NOT disease-modifying treatments (PDF).png";
import longTerm from "../../../../media/images/presentations/Bianca Raffaelli - Long-term management of migraine.png";
import management from "../../../../media/images/presentations/Antonio Russo - Management of patients with migraine taking concomitant therapies and polytherapy.png";
import keeping from "../../../../media/images/presentations/Piero Barbanti - Keeping up with the evolving treatment landscape and evidence-based practice.png";
import keepingUp from "../../../../media/images/presentations/Cristina Tassorelli - Keeping up with the evolving treatment landscape and evidence-based practice.png";
import discontinuing from "../../../../media/images/presentations/Peter J Goadsby - Discontinuing, pausing, switching and repeating.png";
import longTermSafety from "../../../../media/images/presentations/Peter J Goadsby, Bianca Raffaelli - Long-term safety and tolerability of CGRP pathway targeting treatments.png";
import whatIsNew from "../../../../media/images/presentations/Plenary 6 – What is new in migraine research - Patricia Pozo-Rosich.png";
import managingPatientsAntonio from "../../../../media/images/presentations/Antonio Russo, Gisela Terwindt - Managing patients with migraine and medication overuse - Interactive workshop.png";
import managingPatientsPatricia from "../../../../media/images/presentations/Plenary 5 - Patricia Pozo-Rosich - Managing patients with migraine and medication overuse (on hold).png";
import timeBiology from "../../../../media/images/presentations/Plenary 4 – Time, biology and migraine - Gisela Terwindt - Hormonal influences in migraine (Not available).png";
import treatment from "../../../../media/images/presentations/Treatment_migraine_in_older_patients.png";
import treatmentResponse from "../../../../media/images/presentations/Interactive Workshop - Piero Barbanti Roberto De Icco - Treatment response in  migraine management.png";
import placeholder from "../../../../media/images/presentations/Placeholder Image.png";


let plenary1 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+1_Isabel+Martins_website_v3/index.html',
        image: improving,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+1_Cristina+Tassorelli_website_v3/index.html',
        image: keepingUp,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 3,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+1_P+Barbanti_website_v3/index.html',
        image: keeping,
        hasHtml: true,
        clickable: true,
    },
]

let plenary2 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+2_E+Caronna+%2B+P+Barbanti_website_v3_/index.html',
        image: migraine,
        hasHtml: true,
        clickable: true,
    },
]

let plenary3 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+3_A+Russo_website-v3/index.html',
        image: management,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 2,
        src: '',
        image: treatment,
        hasHtml: false,
        clickable: false,
    },
    {
        id: 3,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+3_E+Caronna_website_v3/index.html',
        image: managing,
        hasHtml: true,
        clickable: true,
    },
]

let plenary4 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+4_Bianca+Raffaelli_website/index.html',
        image: longTerm,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 2,
        src: '',
        image: timeBiology,
        hasHtml: false,
        clickable: false,
    },
]


let plenary5 = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+5_Cristina+Tassorelli_website_v3/index.html',
        image: treatmeant,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+5_Peter+Goadsby_website/index.html',
        image: discontinuing,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 3,
        src: '',
        image: managingPatientsPatricia,
        hasHtml: true,
        clickable: false,
    },
]

let debate = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Debate_Reuter_website_v3/index.html',
        image: cgrpUwe,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Debate_Messoud+Ashina_website_v3/index.html',
        image: cgrpMessoud,
        hasHtml: true,
        clickable: true,
    },
]

let plenary6 = [
    {
        id: 1,
        src: '',
        image: whatIsNew,
        hasHtml: true,
        clickable: false,
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Plenary+6_Messoud+Ashina_website_v2/index.html',
        image: messoudAshina,
        hasHtml: true,
        clickable: true,
    },
]

let workshopSessions = [
    {
        id: 1,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Workshop+1_website/index.html',
        image: longTermSafety,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 2,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Workshop+2_website_v3/index.html',
        image: acute,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 3,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Workshop+3_website_v3/index.html',
        image: longTermEffectiveness,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 4,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Workshop+4_website_v3/index.html',
        image: treatmentResponse,
        hasHtml: true,
        clickable: true,
    },
    {
        id: 5,
        src: '',
        image: managingPatientsAntonio,
        hasHtml: false,
        clickable: false,
    },
    {
        id: 6,
        src: 'https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/presentations/MHM2024-html-files/MHM2024_Workshop+6_website_v3/index.html',
        image: neuromodulation,
        hasHtml: true,
        clickable: true,
    },
]




@inject('rootStore')
@observer
class IsLoggedInBanner extends React.Component {
    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.state = {
            attendedPart1: false,
            optimalMigrainePopup: false,
            selectedFileURL: null,
            showLoading: true,
            isAuthenticated: false,
            hasBookedEventSlots: false,
        }
    }

    async componentDidMount() {
        let hasBookedEventSlots = false;
        let eventsModel = await getAllEvents();

        let activeEvents = eventsModel.filter(eventItem => (eventItem.statusId !== data.status.deleted))
        let user = await Auth.currentAuthenticatedUser();

        hasBookedEventSlots = activeEvents.some(event => event.eventTimes
            .some(time => {
                let hasRegistered = time.registeredUsers?.includes(user.username)

                return hasRegistered;

            }));
        this.setState({ hasBookedEventSlots, showLoading: false });

        this.loadMatomoScript();
    }


    loadMatomoScript = () => {
        console.log("I was triggered!");
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = 'https://stats.mhm-meeting.com/js/container_rc8rLzuF.js'; s.parentNode.insertBefore(g, s); // staging
        // g.async=true; g.src='https://stats.mhm-meeting.com/js/container_O7A8fZPN.js'; s.parentNode.insertBefore(g,s);// LIVE
    }

    openModal = (type, fileURL) => {
        this.disableScroll()
        this.setState({ selectedFileURL: fileURL })
        switch (type) {
            case "optimalMigrainePopup":
                this.setState({
                    optimalMigrainePopup: true
                })
                break;
            default:
                return
        }
    }

    closeModal = (type) => {
        this.enableScroll()
        this.setState({ selectedFileURL: null })
        switch (type) {
            case "optimalMigrainePopup":
                this.setState({
                    optimalMigrainePopup: false
                })
                break;
            default:
                return
        }
    }

    getUserName = () => {

        // let title = this.authStore?.user?.attributes?.[`custom:title`];
        // if(title === "Other" || title === 'I prefer not to say'){
        //     title = '';
        // }else{
        //     title += " ";
        // }
        let lastName = this.authStore?.user?.attributes?.[`custom:lastName`];
        let firstName = this.authStore?.user?.attributes?.[`custom:firstName`];
        let fullName = ` ${firstName} ${lastName}`;
        fullName = fullName.includes(undefined) ? null : fullName;

        return fullName;
    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {

        document.body.style.overflow = 'auto'
    }
    render() {
        let { optimalMigrainePopup, isAuthenticated, hasBookedEventSlots } = this.state;
        let username = this.getUserName();
        let videoUrl = process.env.REACT_APP_ENV == 'production' ? "https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4" : "https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4";
        return (
            <div className="" id="Welcome" style={{ background: "#d6d6d8" }}>
                <Banner />
                <div className="row">
                    <span className={styles.icon1}></span>
                    <span className={styles.icon2}></span>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className={styles.curve1}>
                            </div>
                            {/* <ScrollAnimation animateIn="fadeIn"> */}
                            <div style={{ border: "1px solid #1a0a60", padding: "5px", borderRadius: "5px", marginBottom: '30px', marginTop: '30px' }}>
                                <p className="mb-1 mt-1"><strong>This website is intended for use by Healthcare Professionals only.</strong></p>
                                <p className="mt-1">     <strong>This promotional meeting is organised and funded by Teva Pharmaceuticals Europe B.V.</strong>
                                </p>
                                <p  >

                                    Some sessions may contain product information. <br />For the fremanezumab UK prescribing information and adverse events reporting, please see footer of the page.<br />
                                </p></div>
                            <h1 className="pt-2 mt-4 mb-4">Welcome {username}, </h1>
                            <p style={{ fontSize: "20px" }}>Thank you for joining us for MHM 2024. We hope to see you in 2025!</p>

                            <h2>Presentations</h2>
                            <p style={{ fontSize: "20px" }}>Most faculty have made their presentations from the meeting available for viewing below.</p>

                            {/* <p style={{ fontSize: "20px" }}>
                                We enjoyed hosting you at MHM 2024 and hope to see you in 2025!<br />
                                {hasBookedEventSlots 
                                   ? (<><a href="/my-session-slots"><strong>REGISTER NOW</strong></a> to confirm your place in the <strong>breakout sessions</strong>. These sessions are allocated on a first come first served basis and registrations will be closed on <strong>Tuesday 19 September.</strong></>)
                                    : (<><a href="/book-session-slots"><strong>REGISTER NOW</strong></a> to confirm your place in the <strong>breakout sessions</strong>. These sessions are allocated on a first come first served basis and registrations will be closed on <strong>Tuesday 19 September.</strong></>)}
                            </p> */}
                            {/* <h3 className="pt-2 mt-4 mb-4">We enjoyed hosting you at MHM 2024 and hope to see you in 2025!</h3> */}
                            {/* <p style={{ fontSize: "19px" }}>
                                        Please see below for linked presentations from the meeting where speakers<br/>
                                        have provided consent to share their content. This content is for educational
                                        purposes only.<br/> Please do not distribute this content.
                                    </p> */}
                        </div>
                    </div>


                </div>

                <div className="" >
                    <div className="row justify-content-center d-flex" style={{ position: "relative" }}>
                        <div className="col-12 col-md-10  text-center" style={{ marginBottom: '30px' }}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Plenary I – Migraine management in an ever-changing world</h3>
                                                </div>
                                                {plenary1?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}>
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Plenary 2 – Migraine and mental health</h3>
                                                </div>
                                                {plenary2?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}>
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Plenary 3 – Understudied patient profiles</h3>
                                                </div>
                                                {plenary3?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}>
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Plenary 4 – Time, biology and migraine</h3>
                                                </div>
                                                {plenary4?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}>
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Plenary 5 – Practical perspectives in migraine management with CGRP pathway targeting treatments</h3>
                                                </div>
                                                {plenary5?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}>
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Debate – Are CGRP pathway mAbs disease-modifying treatments?</h3>
                                                </div>
                                                {debate?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Plenary 6 – 2024 in the spotlight: What is new in migraine research</h3>
                                                </div>
                                                {plenary6?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 custom_list">
                                    <div className={`${styles.counter}  mt-lg-1 pt-lg-1 row`}>
                                        <div className="col-12">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-md-12 text-center">
                                                    <h3 className="text-center">Interactive workshops</h3>
                                                </div>
                                                {workshopSessions?.map((item) => {
                                                    const { id, src, image, hasHtml, clickable } = item;
                                                    return (
                                                        <div className="col-md-4 col-sm-12 pr-0" key={id}>
                                                            {hasHtml ? (
                                                                <a
                                                                    href={src}
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // This prevents the default navigation
                                                                        this.openModal("optimalMigrainePopup", src);
                                                                        if (window._paq) {
                                                                            console.log("I was clicked!")
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}
                                                                >
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className={clickable ? "" : `${styles.not_clickable}`}
                                                                    id="presentation"
                                                                    href={src}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => {
                                                                        if (window._paq) {
                                                                            window._paq.push(['trackEvent', 'Presentation Clicked', 'ClickedPresentation', 'ClickedPresentation']);
                                                                        }
                                                                    }}>
                                                                    <img src={image} alt="" />
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <OptimalMigraine closeModal={() => this.closeModal("optimalMigrainePopup")} isActive={optimalMigrainePopup} fileURL={this.state.selectedFileURL} />
                            </div>

                            {/* <p style={{ marginBottom: '30px', marginTop: '30px' }}>*<em>Not all gepants are currently approved for the treatment of migraine by the EMA.</em></p>

                                <p>
                                    <strong>MHM 2024: Patients, Treatments, Choices</strong> will take place across{" "}
                                    <strong>two half-days </strong>and will provide you with a
                                    space to:
                                </p>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-9 ">
                                        <ul className="text-left">
                                            <li>
                                                Gain expert insights on recent advances in the field
                                            </li>
                                            <li>
                                                Learn how the latest evidence can improve practice and inform decision-making
                                            </li>
                                            <li>
                                                Exchange ideas with colleagues and participate in interactive breakout sessions
                                            </li>
                                            <li>
                                                Explore different approaches to common clinical scenarios
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-9 text-left">

                                        <p style={{ marginTop: "10px" }}>
                                            <strong>
                                                <span style={{ fontSize: "25px" }}>Date: Friday 4 and Saturday 5 October 2024</span><br />
                                                Location: JW Marriott Hotel Berlin, Stauffenbergstraße 26, 10785 Berlin, Germany<br />
                                            </strong>
                                            <strong>Parking: </strong>
                                            The Hotel offers an underground garage on property with more than 200 parking spots.
                                        </p>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19426.241660826156!2d13.3525637!3d52.5102679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a851fe88220039%3A0xf83e03f9a70b3368!2sJW%20Marriott%20Hotel%20Berlin!5e0!3m2!1sen!2ske!4v1713438787828!5m2!1sen!2ske"
                                            style={{ border: 0, width: "100%", minHeight: "300px", marginBottom: "20px" }}
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                            title="Location Map"
                                        ></iframe>


                                    </div>
                                </div> */}

                            {/* <video controls width="60%" poster={Poster} onPlay={() => trackHomepageVideoFunctions(videoUrl, "play", true)} onEnded={()=> trackHomepageVideoFunctions(videoUrl, "end", true)} onPause={()=> trackHomepageVideoFunctions(videoUrl, "pause", true)}>
                                    <source src={ process.env.REACT_APP_ENV == 'production' ? "https://mhm-storage-bucket152705-prod.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4" : "https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/mhm2022/MHM2022_InfoExplainerVideo_v1.mp4"} type="Video/mp4" />
                                </video> */}
                            {/* </ScrollAnimation> */}

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default IsLoggedInBanner;