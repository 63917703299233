import { inject, observer } from "mobx-react";
import React from 'react';
import Banner from "../../../components/public/home/banner/banner";
import SteeringCommittee from "../../../components/_shared/home/steeringCommitteeSection/committeeSection";
import FormWrapper from "../../../templates/form-wrapper";
import '../../../theme/regular-user.scss';
import {withMatomo} from "../../../util/analytics";
@inject('rootStore')
@observer
class SteeringCommitee extends React.Component {
    constructor(props) {
        super(props);
      }
      
    static routeName = 'steeringCommittee';
    static routePathPattern = '/steering-committee';

    componentDidMount() {
        const pageTitle = "Steering Committee | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
        document.title = pageTitle;
        // Track the page view when the component mounts
        // this.props.matomo.trackPageView();
        // Trigger PageView event
        window._mtm = window._mtm || [];
        window._mtm.push({ event: 'mtm.PageView' });

        // Trigger DOMReady event
        window._mtm.push({ event: 'mtm.DOMReady' });

        // Trigger WindowLoad event after the window has fully loaded
        window.addEventListener('load', this.handleWindowLoad);
    }

    componentWillUnmount() {
        // Clean up the window load event listener
        window.removeEventListener('load', this.handleWindowLoad);
    }

    handleWindowLoad = () => {
        window._mtm.push({ event: 'mtm.WindowLoad' });
    };
    render() {

        return (
            <>
                <FormWrapper>
                    <div className={`page-wrapper`}>
                        <Banner />
                        <SteeringCommittee />

                    </div>
                </FormWrapper>

            </>
        );
    }

}

export default SteeringCommitee;
