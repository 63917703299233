import { Auth } from "aws-amplify"
import { contentService } from '../services/content-service'
import { data } from "../data/data"
import moment from "moment";

export async function getAllEvents() {
    try {
        let { Items: allEvents } = await contentService.getAllEvents();
        return allEvents;
    } catch (e) {
        return [];
    }
}

export async function getActiveEvents(){
    try {
        let activeEvents = (await getAllEvents())?.filter(item => item.statusId !== data.status.deleted);
        return activeEvents;
    } catch (e) {
        return [];
    }
}

export async function getPart1Events(){
    let part1EventType = data.eventTypes.find(item => item.id === "Interactive workshops");
    try {
        let { Items: allEvents } = await contentService.getAllEvents();
        let activeEvents = allEvents?.filter(item => item.statusId !== data.status.deleted)
        
        let part1Events = activeEvents?.filter(item => item.type === part1EventType?.id)

        return part1Events;
    } catch (e) {
        return [];
    }
}

export async function getPart2Events(){
    let part2EventType = data.eventTypes.find(item => item.id === "Question Clinics");
    try {
        let { Items: allEvents } = await contentService.getAllEvents();
        let activeEvents = allEvents?.filter(item => item.statusId !== data.status.deleted)
        
        let part2Events = activeEvents?.filter(item => item.type === part2EventType?.id)

        return part2Events;
    } catch (e) {
        return [];
    }
}

export async function getWorkshop1Events(){
    const part1Events = await getPart1Events();
    let workshop1EventTimes = [];
    let workshop1Events = [];
    for(let eventItem of part1Events){
        for(let eventTime of eventItem.eventTimes){
            workshop1EventTimes.push({...eventTime, title: eventItem.title});
        }
    }
    for (let eventItem of workshop1EventTimes) {
        if(eventItem?.time === "15:15–15:45" && moment(eventItem?.date).format("YYYY-MM-DD") === "2024-10-04"){
            workshop1Events.push(eventItem);
        }
    }
    return workshop1Events;
}

export async function getWorkshop2Events(){
    const part1Events = await getPart1Events(); // same as workshop 1
    let workshop2EventTimes = [];
    let workshop2Events = [];
    for(let eventItem of part1Events){
        for(let eventTime of eventItem.eventTimes){
            workshop2EventTimes.push({...eventTime, title: eventItem.title});
        }
    }
    for (let eventItem of workshop2EventTimes) {
        if(eventItem?.time === "16:00–16:30" && moment(eventItem?.date).format("YYYY-MM-DD") === "2024-10-04"){ // this is the only difference
            workshop2Events.push(eventItem);
        }
    }
    return workshop2Events;
}

export async function getClinic1Events(){
    const part1Events = await getPart2Events();
    let clinic1EventTimes = [];
    let clinic1Events = [];
    for(let eventItem of part1Events){
        for(let eventTime of eventItem.eventTimes){
            clinic1EventTimes.push({...eventTime, title: eventItem.title});
        }
    }
    for (let eventItem of clinic1EventTimes) {
        if(eventItem?.time === "10:00–10:30" && moment(eventItem?.date).format("YYYY-MM-DD") === "2024-10-05"){
            clinic1Events.push(eventItem);
        }
    }
    return clinic1Events;
}

export async function getClinic2Events(){
    const part2Events = await getPart2Events(); 
    let workshop2EventTimes = [];
    let workshop2Events = [];
    for(let eventItem of part2Events){
        for(let eventTime of eventItem.eventTimes){
            workshop2EventTimes.push({...eventTime, title: eventItem.title});
        }
    }
    for (let eventItem of workshop2EventTimes) {
        if(eventItem?.time === "10:45–11:15" && moment(eventItem?.date).format("YYYY-MM-DD") === "2024-10-05"){ // this is the only difference
            workshop2Events.push(eventItem);
        }
    }
    return workshop2Events;
}

export async function getAllPart1Attendees(){
    try {
        let part1Events = await getPart1Events();
        let attendees = [];
        let registrants = [];
        let registeredAndAttended = [];

        for(let eventItem of part1Events){
            for(let eventTime of eventItem.eventTimes){
                for(let attendee of eventTime.attendedUsers){
                    if(!attendees.includes(attendee)){
                        attendees.push(attendee);
                    }
                }
            }
        }

        for(let eventItem of part1Events){
            for(let eventTime of eventItem.eventTimes){
                for(let registrant of eventTime.registeredUsers){
                    if(!registrants.includes(registrant)){
                        registrants.push(registrant);
                    }
                }
            }
        }

        //intersection = return only users found in both lists
        registeredAndAttended = attendees.filter(attendee => registrants.includes(attendee));

        return registeredAndAttended;
    }catch(e){
        return [];
    }
}



export async function getAllPart2Attendees(){
    try {
        let part2Events = await getPart2Events();
        let attendees = [];
        let registrants = [];
        let registeredAndAttended = [];

        for(let eventItem of part2Events){
            for(let eventTime of eventItem.eventTimes){
                for(let attendee of eventTime.attendedUsers){
                    if(attendee === "299c4d4c-7373-4049-8a8f-7e79db64d429"){
                    }
                    if(!attendees.includes(attendee)){
                        attendees.push(attendee);
                    }
                }
            }
        }

        for(let eventItem of part2Events){
            for(let eventTime of eventItem.eventTimes){
                for(let registrant of eventTime.registeredUsers){
                    if(!registrants.includes(registrant)){
                        registrants.push(registrant);
                    }
                }
            }
        }

        //intersection = return only users found in both lists
        registeredAndAttended = attendees.filter(attendee => registrants.includes(attendee));

        return registeredAndAttended;
    }catch(e){
        return [];
    }
}

export function isUserRegisteredInAnEvent(userId, events) {
    for (let event of events) {
        if (event.registeredUsers.includes(userId)) {
            return `${event?.title} [${event?.speakerName}]`;
        } 
    }
    return "";
}

export async function bookEvent(eventModel, selectedDate, context, timeZoneName, activeEvents) {

    let user = null;

    try {
        user = await Auth.currentAuthenticatedUser();
    } catch (e) {
        return;
    }

    let userRegisteredAlready = selectedDate.registeredUsers.find(username => username === user.username);

    if (userRegisteredAlready) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "You have already signed up for this event" });
        return;
    }

    for (let item of eventModel.eventTimes) {
        if (item.id === selectedDate.id) {
            item.registeredUsers.push(user.username);
            item.dateModified = new Date().toISOString()
            break;
        }
    }

    try {
        await contentService.addOrRemoveUserFromRegisteredList(user.username, eventModel.id, selectedDate.id, timeZoneName);
    } catch (e) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "Something went wrong, please try again" });
        return;
    }
}
export async function bookEvents(model, context) {

    let user = null;

    try {
        user = await Auth.currentAuthenticatedUser();
    } catch (e) {
        return;
    }

    try {
        await contentService.registerToEvents(user.username,model);
    } catch (e) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: "Something went wrong, please try again" });
        return;
    }
}
const bookingHelper = async (username, eventId, timeId, timeZoneName, context) => {
    context.setState({ showLoading: true })

    try {
        var success = await contentService.addOrRemoveUserFromRegisteredList(username, eventId, timeId, timeZoneName);
        context.setState({ showLoading: false })
        if (success) {
            context.props.rootStore.alertStore.setAlert({ type: 'alert-success', message: 'Successfully signed user up for event', onDismiss: () => context.props.registerUserToEvent(eventId, timeId) });
        } else {
            context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Failed to sign user up for event, please try again' });
        }
    } catch (e) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Failed to sign user up for event, please try again' });
        context.setState({ showLoading: false })
        return;
    }
}

export async function adminBookEventForUser(username, eventId, timeId, context, userTimezone) {

    context.setState({ showLoading: true })

    if (!username) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Something went wrong, please try again' });
        return;
    }

    let activeEvents = (await getAllEvents())?.filter(eventItem => (eventItem.statusId !== data.status.deleted));

    let selectedEvent = activeEvents?.find(item => item.id === eventId);
    let selectedTime = selectedEvent?.eventTimes?.find(item => item.id === timeId);

    if (!selectedEvent || !selectedTime) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'Something went wrong, please try again' });
        return;
    }

    if (selectedTime.registeredUsers.includes(username)) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'This user is already booked for this event' });
        return;
    }

    let meetTheExpertEventId = data.eventTypes?.find(item => item.id === 'Migraine Masterclass')?.id

    if(selectedEvent.type === meetTheExpertEventId){
        let meetTheExpertEvents = activeEvents.filter(item => item.type === meetTheExpertEventId);
        for(let meetTheExpertEvent of meetTheExpertEvents){
            for(let eventTime of meetTheExpertEvent.eventTimes){
                if(eventTime.registeredUsers.includes(username)){
                    context.props.rootStore.alertStore.setAlert({ type: "alert-danger", message: "This user is already signed up for one Migraine Masterclass event" });
                    return;
                }
            }
        }
    }

    let spacesAvailable = parseInt(selectedTime.eventLimit) === -1 ? true : selectedTime.registeredUsers.length < parseInt(selectedTime.eventLimit);

    if (!spacesAvailable) {
        context.props.rootStore.alertStore.setAlert({ type: 'alert-danger', message: 'This event is fully booked' });
        return;
    }

    if (selectedEvent.type === meetTheExpertEventId) {

        let bookingCallback = async (timeZoneName) => {
            let timezoneToUse = (userTimezone !== undefined && userTimezone !== null && userTimezone !== "") ? userTimezone : timeZoneName; 

            if(typeof timezoneToUse === 'object' && timezoneToUse !== null){
                if("value" in timezoneToUse){
                    timezoneToUse = timezoneToUse.value;
                }else if("text" in timezoneToUse){
                    timezoneToUse = timezoneToUse.text;
                }
            }

            await bookingHelper(username, eventId, timeId, timezoneToUse, context)
        }

        context.props.context.openUserTimezoneSelector(bookingCallback, userTimezone);

        return;

    }

    await bookingHelper(username, eventId, timeId, null, context)

}

export async function userAttendedPartOneEvent(){
    let user = await Auth.currentAuthenticatedUser();

    if(!user){
        return false;
    }

    let partOneAttendees = await getAllPart1Attendees()

    if(partOneAttendees?.indexOf(user?.username) !== -1){
        return true;
    }

    return false;
}