import React from 'react';
import styles from "./modal.module.css"
import close_button from "../../../../../media/images/CloseIcon.png"
import {trackVideoFunctions} from "../../../../../util/util"

class Modal extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            modal : false
        }
    }

    trackVideoPlay = (e) => {
        const {videoLink} = this.props;
        trackVideoFunctions(videoLink, "play")
    }
    
    trackVideoEnd = (e) => {
        const {videoLink} = this.props;
        trackVideoFunctions(videoLink, "end")
    }

    closeModal = () => {
        const {closeModal, videoLink} = this.props;

        trackVideoFunctions(videoLink, "close")

        closeModal();
    }

    render() { 
        let {isActive, videoLink} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        
        if(!videoLink) return <React.Fragment />
        
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className={styles.modal_content}>
                    <div className={styles.bottom_section}>
                        <video controls height='auto' onPlay={this.trackVideoPlay} onEnded={this.trackVideoEnd} >
                            <source src={videoLink} type="Video/mp4"/>
                        </video>
                        <div className={styles.audio_text}>
                        <p className="text-left">
                        <strong>The views expressed are the opinions of the faculty.</strong>
                        </p>
                        <p className="text-left">
                            <strong>
                            This presentation contains photos of real migraine patients. Consent was obtained by Professor Pozo-Rosich for the presentation of these images.
                            </strong>
                        </p>
                 
                        <p className="text-left">
                        <strong>This webcast may contain mention of products not licensed for use in migraine. Please refer to the mentioned medications' SmPCs for the approved indications for use.</strong>
                        </p>
                        <p className="text-left">
                        <a href="https://products.tevauk.com/Ajovy_UK_PI" target="-blank">Click here</a> for the fremanezumab UK  prescribing information.
                        </p>
                        </div>
                    </div>
                    
                     <a className={`${styles.close_button}`} onClick={this.closeModal}>
                         <img  src={close_button} alt="Close Button"/>
                     </a>
                    </div>
                    
            </div>
         );
    }
}
 
export default Modal;