import React, { useEffect, useRef, lazy, Suspense } from 'react';
import { action, computed, observable, toJS } from 'mobx';
import { updateRequredList, removeFromRequredList, validatePassword } from '../../util/formHelper';
import { validateEmailExists, validateEmail } from '../../util/validation';
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react';

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility"
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,

}
    from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import Switch from '@material-ui/core/Switch'
import moment from 'moment';
import 'moment/locale/en-gb';

import enGB from 'date-fns/locale/en-GB';
import DateFnsUtils from '@date-io/date-fns';
import { data } from '../../data/data';
import { inject, observer } from "mobx-react";
import { contentService } from '../../services/content-service';
import { Event, LensTwoTone } from '@material-ui/icons';
import EventsSelector from './eventSelector';
import { min } from 'date-fns';

const PasswordStrengthBar = lazy(() => import("react-password-strength-bar"));

// Reusable component for toggling password visibility
const PasswordVisibilityToggle = ({ isVisible, onToggle }) => (
    <InputAdornment position="end">
        <IconButton aria-label="toggle password visibility" onClick={onToggle}>
            {isVisible ? <VisibilityOffIcon /> : <Visibility />}
        </IconButton>
    </InputAdornment>
);

@inject('rootStore')
@observer
class Editor extends React.Component {

    constructor(props) {
        super(props);
        const { value, name, type } = this.props;
        this.state = {
            value: value,
            contentModel: [],
            options: [],
            emailExists: false,
            showPassword: false,
            passwordStrength: 0,
            validationErrors: [],
            isVisible: false,
        };
        // this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleOnDateOpen = this.handleOnDateOpen.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleRichTextEditorChange = this.handleRichTextEditorChange.bind(this);
        this.handleMultiSelectSwitchChange = this.handleMultiSelectSwitchChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    }
    async componentDidMount() {
        let { context, required, type, name, id, value } = this.props;

        if (required) {
            var field = name ? name : id;
            updateRequredList(context, { name: field, required: required });
        }

    }

    async componentDidUpdate(prevProps, prevState){
        let val = this.state.value;
        
        if(prevProps.value !== this.state.value){
            let { required, name } = this.props;
            if(name === "email"){
                let emailExists = await validateEmailExists(this.state.value.trim());
                this.setState({emailExists});
            }
        }
    }

    componentWillUnmount() {
        let { context, required, name, id } = this.props

        if (required) {
            var field = name ? name : id;
            removeFromRequredList(context, field);
        }
    }

    getSelectListItems(name, options) {

        let results = [];
        switch (name) {

            case 'title':
                results = data.getUserTitles().map(item => { return { value: item, text: item, optgroup: '' } });
                break;
            case 'speciality':
                results = data.getSpeciality().map(item => { return { value: item, text: item, optgroup: '' } });
                break;
            case 'country':
                results = data.getCountries().map(item => {
                    return {
                        value: item.name,
                        text: item.name,
                        optgroup: ''
                    }
                });
                break;
            case 'type':
                results = data.getEventTypesSelectItemsList();
                break;
            case 'transportMethod':
                results = data.getMethodsOftransportation().map(item => { return { value: item, text: item, optgroup: '' } });;
                break;
            case 'dietaryRequest':
                    results = data.getDietaryRequestOptions().map(item => { return { value: item, text: item, optgroup: '' } });;
                    break;
            // case 'extraTopics':
            //     results = data.extraTopics;
            //     break;
            // case 'eventTopics':
            //     results = data.eventTopics;
            //     break;
            default:
                results = options;
                break;
        }
        return results;
    }
    handleDateChange(dateValue) {
        this.props.onChange(this.props.name, dateValue);

        this.setState({ value: dateValue });

    }
    handleOnDateOpen(event) {

    }

    // Common handleChange for other fields
    handleCommonChange = (event) => {
        const { name, onChange } = this.props;
        const fieldValue = (name === "email" || name === "confirmEmail")
        ? event.target.value.toLowerCase()
        : event.target.value;

        this.setState({ value: fieldValue });
        if (onChange) onChange(name, fieldValue);
    };

    // Special handler for password-related fields
    handlePasswordChange = (event) => {
        const { name, onChange } = this.props;
        const password = event.target.value;
        const validationErrors = validatePassword(password);
        this.setState({ value: password, validationErrors });
        if (onChange) onChange(name, password);
        if (password.length > 0) {
            this.setState({isVisible: true});
        } else {
            this.setState({isVisible: false});
        }
    };


    // handleChange(event) {
    //     const fieldValue = (this.props.name === "email" || this.props.name === "confirmEmail") ? event.target.value?.toLowerCase() : event.target.value;
    //     this.setState({ value: fieldValue })
    //     this.props.onChange(this.props.name, fieldValue);
    // }
    handleRichTextEditorChange(richText) {
        const fieldValue = richText;
        this.setState({ value: fieldValue })
        this.props.onChange(this.props.name, fieldValue);
    }
    handleSwitchChange(event) {
        const fieldValue = event.target.checked;
        this.setState({ value: fieldValue })
        this.props.onChange(this.props.name, fieldValue);
    }

    handleMultiSelectSwitchChange(event){
        let { value } = this.props;

        const checked = event.target.checked;
        const checkBoxValue = event.target.value;

        if(checked){
            (value || []).push(checkBoxValue);
        }else{
            let indexOfItem = value.indexOf(checkBoxValue);
            value.splice(indexOfItem, 1);
        }
        this.setState({ value: value })
        this.props.onChange(this.props.name, value);
    }


    handleSelectChange(event, opt) {
        this.setState({ value: opt ? opt.value : ''})
        this.props.onChange(this.props.name, opt ? opt.value : '');
    }

    handleEventSelectorChange(value) {
        this.setState({ value: value })
        this.props.onChange(this.props.name, value ? value : '');
    }
    handleRadioChange(event){
        const checkedValue = event.target.value;

        this.setState({ value: checkedValue })
        this.props.onChange(this.props.name, checkedValue );
    }
     isValueValid = (value, required) =>  {
        if (!required) {
            return;
        }

        
        if (value && value !== '') {

            var returnValue = false;
            if (required.minLength) {

                returnValue = value.length < required.minLength;
            }
            if (required.match) {
                try {
                    returnValue = value.trim() != required.match.trim();
                }catch(e){
                    returnValue = value != required.match;
                }
            }

            if(required.validEmail){
                try {
                    returnValue = !validateEmail(required.validEmail);
                }catch(e){
                    returnValue = !validateEmail(required.validEmail.trim());
                }
            }
            return returnValue;

        }

        return !value;
    }
    sortOptions(options, sortOption){
        switch (sortOption){
            case "none":
            return options;
            case "asc":
                return options.sort((a, b) => (b.optgroup && b.optgroup.length && -b.optgroup.localeCompare(a.optgroup)) || - b.text.localeCompare(a.text));
            case "desc":
                return options.sort((a, b) => (a.optgroup && a.optgroup.length && - a.optgroup.localeCompare(b.optgroup)) || - a.text.localeCompare(b.text));
            default:
                return options.sort((a, b) => (b.optgroup && b.optgroup.length && -b.optgroup.localeCompare(a.optgroup)) || - b.text.localeCompare(a.text));
        }
    }
    isMultiSelectCheckboxesFieldValid(){
        let { name, type, required,value } = this.props;
        switch(type){
            case 'multi-select-checkboxes':
                let {minOptions,maxOptions } = required;
               var status = (!minOptions ||  value?.length >= minOptions) && (!maxOptions ||  value?.length <= maxOptions);
               return status;
            
        }
        return true;
    }

    togglePasswordVisibility() {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    }
        

    render() {
        const { type, name, id, required, placeholder, options: opts, overwriteValueFrontend, autoFill, checked, rows, sortOption, maxDate, minDate} = this.props;
        const { value, emailExists, validationErrors, showPassword, isVisible } = this.state;

        const handleChange = type === 'password' ? this.handlePasswordChange : this.handleCommonChange;


        let options = this.getSelectListItems(name, opts);

        let valueToUse = type === "textarea" ? this.props.value : value;

        let isValueInvalid = this.isValueValid(valueToUse, required);
        if(type === "multi-select-checkboxes"){
            isValueInvalid = this.isMultiSelectCheckboxesFieldValid();
        }
        let isFormInvalid = toJS(this.props.rootStore.formStore.isInvalid);
        let formStepsValidity = toJS(this.props.rootStore.formStore.formStepsValidity);
        let formStepValid = true;

        if(required && required.group){
            formStepValid = formStepsValidity[required.group]
        }

        if (type === 'select') {
            return <Autocomplete
                id={id ? id : name}
                name={name}
                onChange={this.handleSelectChange}
                value={overwriteValueFrontend || value || this.props.value}
                options={this.sortOptions(options || [], sortOption)}
                getOptionLabel={(option) => option.text || options.find(item => item.id == option)?.name || option?.toString()}
                groupBy={(option) => option.optgroup} 
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoComplete={autoFill ? "on" : "off"}
                        error={required && isFormInvalid && !value}
                        className="form-control"
                        label={placeholder}
                        variant="outlined"
                    />
                )}
                />
        }
        else if (type === 'autoComplete') {
            return <Autocomplete
                error={required && isFormInvalid && !value ? `Please verify ${name} entry` : undefined}
                id={id ? id : name}
                name={name}
                onInputChange={this.handleSelectChange}
                freeSolo
                getOptionLabel={(option) => option.text}
                groupBy={(option) => option.optgroup}
                options={this.sortOptions(options || [], sortOption)}
                renderInput={(params) => <TextField inputProps={{ autoComplete: 'on' }} {...params} error={required && isFormInvalid && !value} label={placeholder} className="form-control"
                    variant="outlined"
                />}
            />
        }
        else if (type === 'date') {
            return <MuiPickersUtilsProvider utils={DateFnsUtils}> <DatePicker
                error={required && isFormInvalid && !value}
                margin="normal"
                name={name}
                id={id ? id : name}
                label={placeholder?.toUpperCase()}
                format="dd/MM/yyyy"
                value={value || this.props.value || null}
                onOpen={this.handleOnDateOpen}
                onChange={this.handleDateChange}
                minDate={minDate}
                maxDate={maxDate}
            /></MuiPickersUtilsProvider>

        }
        else if (type === 'time') {
            return <MuiPickersUtilsProvider utils={DateFnsUtils}><TimePicker
                error={required && isFormInvalid && !value}
                margin="normal"
                id={name}
                label={placeholder?.toUpperCase()}
                placeholder="08:00 AM"
                mask="__:__ _M"
                value={value || this.props.value || null}
                onOpen={this.handleOnDateOpen}
                onChange={this.handleDateChange}
            /></MuiPickersUtilsProvider>
        }
        else if (type === 'switch') {
            return <Switch
                value={name}
                onChange={this.handleSwitchChange}
                inputProps={{ 'aria-label': name, 'name': name }}

            />
        }
        else if (type === 'checkbox') {
            return <CheckBox
                error={required && isFormInvalid && !value}
                value={value || name}
                checked ={checked || value || false}
                onChange={this.handleSwitchChange}
                inputProps={{ 'aria-label': name, 'name': name, checked: checked, value: value }}
            />
        }else if(type === "radio" && name ==="willRequireHotelRoom"){
            return (
                <React.Fragment>
                    <div className="col-12 col-sm-4 col-md-4 text-left">
                        <Radio
                            error={required && isFormInvalid && !value}
                            value={value}
                            checked={checked}
                            onChange={this.handleRadioChange}
                            inputProps={{ 'aria-label': name, 'name': name, checked: checked }}
                        /> {this.props.text}
                    </div>
                    {formStepValid === false && !isValueInvalid && value === "No" && <div className="col-12 col-sm-12 col-md-12 text-center"> <span className='error' > Please select an option </span> </div>}

                </React.Fragment>
            )
        }
        else if(type === "radio"){
            return (
                <><Radio
                    error={required && isFormInvalid && !value}
                    value={value}
                    checked={checked}
                    onChange={this.handleRadioChange}
                    inputProps={{ 'aria-label': name, 'name': name, checked: checked }}
                /> {this.props.text}</>
            )
        }else if (type === "password"){
            return (
                <React.Fragment>
                    <div className="error-div">
                        {name === 'password' && validationErrors?.length > 0 && required.minLength && validationErrors.map((error, index) => (
                            <span key={index} className="error_messag">
                                {error}
                            </span>
                        ))}
                    </div>
                    <TextField
                        className="form-control"
                        id={id}
                        error={required && isFormInvalid && isValueInvalid}
                        name={name}
                        label={placeholder}
                        type={this.state.showPassword ? "text" : "password"}
                        variant="outlined"
                        value={value || this.props.value}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment:(
                                <PasswordVisibilityToggle
                                    isVisible={showPassword}
                                    onToggle={this.togglePasswordVisibility}
                                />
                            )
                        }}
                    />
                    {isVisible && name === 'password' && (
                        <Suspense fallback={<div>Loading password strength...</div>}>
                            <PasswordStrengthBar
                                className='password-strength-bar'
                                password={value || this.props.valu}
                                minLength={12}
                            />
                        </Suspense>
                    )}
                    {/* {this.state.showPassword ? (
                        <VisibilityOffIcon
                            className="view_pw"
                            onClick={() => {
                                this.setState({showPassword: false})
                            }}
                        />
                    ): (
                        <Visibility
                            className="view_pw"
                            onClick={() => {
                                this.setState({ showPassword: true});
                            }}
                        />
                    )} */}

                    {/* {value && isValueInvalid && required.minLength && <span className="error_message">Must meet all the requirements to proceed</span>} */}
                    {value && isValueInvalid && required.match && <span className="error_message">Please {placeholder} Matches </span>}
                </React.Fragment>
            )
        } else if (type === "textarea"){
            let { className, rows } = this.props;
            return (
                <React.Fragment>
                    <TextField
                        className={`form-control ${className}`}
                        name={name}
                        multiline = {true}
                        error={required && isFormInvalid && isValueInvalid}
                        id={id} label={placeholder}
                        onChange={handleChange}
                        variant="outlined"
                        value={this.props.value || ""}
                        rows={rows || 5}
                    />
                    {value && isValueInvalid && required.validEmail && <span className="error_message hide">Email is invalid</span>}
                    {placeholder === "Confirm Email" && value && isValueInvalid && <span className="error_message">Please {placeholder} matches</span>}
                    {value && emailExists && <span className="error_message">Email already registered </span>}
                </React.Fragment>
            )
        }
        else if (type === "multilinetext"){
            return (
                <React.Fragment>
                    
                    <TinyMceEditor
                        apiKey="aevqj0qzmixpe2nmemlzn2e3vrhlpb5x1moiszdyk58wx518"
                        value={value || this.props.value}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image',
                                'charmap print preview anchor help',
                                'searchreplace visualblocks code',
                                'insertdatetime media table paste wordcount',
                                'link'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | table link | help | code'
                        }}
                        onEditorChange ={this.handleRichTextEditorChange}
                    />
                    {value && isValueInvalid && required.validEmail && <span className="error_message hide">Email is invalid</span>}
                    {placeholder === "Confirm Email" && value && isValueInvalid && <span className="error_message">Please {placeholder} matches</span>}
                    {value && emailExists && <span className="error_message">Email already registered </span>}
                </React.Fragment>
            )
        }if (type === "multi-select-checkboxes"){
            return (
                
                <React.Fragment>

                        {options && options.length > 0 && options.map((option) => {
                                let isChecked = (this.props.value && this.props.value.includes(option.id)) || false;
                                let {disablewhenMaxOptionsSelected} = this.props;
                                 return <React.Fragment> <div className="col-12 col-sm-12 col-md-12 text-left">
                                    <CheckBox
                                        error={ required && isFormInvalid && !this.props.value && isValueInvalid}
                                        value={option.id}
                                        checked ={isChecked}
                                        onChange={ this.handleMultiSelectSwitchChange }
                                        inputProps={{ 'aria-label': name, 'name': name, checked: isChecked, value: option.id }}
                                        disabled = { disablewhenMaxOptionsSelected ? (this.props.value?.length >= required.maxOptions) && !isChecked : false }
                                    />
                                    {option.name}
                                </div></React.Fragment>
                        })}
                        {formStepValid == false && !isValueInvalid && <div className="col-12 col-sm-12 col-md-12 text-left"><span className='error' > Please select the required number of options</span></div> }
                </React.Fragment>

            )
        }
        else {
            return (
                <React.Fragment>
                    <TextField
                        className= {`form-control${value && isValueInvalid && required?.validEmail ?" invalid_email": ""}`}
                        name={name}
                        error={required && isFormInvalid && isValueInvalid}
                        id={id} label={placeholder}
                        onChange={handleChange}
                        variant="outlined"
                        value={value || this.props.value}
                    />
                    {value  && required?.validEmail && isValueInvalid && <span className="error_message hide">Email is invalid</span>}
                    {placeholder === "Confirm Email" && value && isValueInvalid && <span className="error_message hide">Please {placeholder} matches</span>}
                    {value && emailExists && <span className="error_message hide">Email already registered </span>}
                    {value && isValueInvalid && required?.match && <span className="error_message">Please {placeholder} Matches </span>}

                </React.Fragment>
            )
        }
    }

}

export default Editor;
