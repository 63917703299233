import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/isabel-purple.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="Roberto De Icco photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Isabel Pavão Martins, MD, PhD</h3>
                  <p style={{ color: "#f61a19" }}>Lisbon, Portugal</p>
                  <p>
                  Isabel Pavão Martins is Full Professor of Neurology and Director of Centro de Estudos Egas Moniz, a research centre in clinical neurosciences at the Faculty of Medicine, University of Lisbon, as well as a consultant neurologist at the Hospital de Santa Maria in Lisbon, Portugal. Her main research interests are headache and behavioural neurology, and she has authored about 200 scientific publications in these fields.
                  </p>
                  <p>
                  Prof. Pavão Martins completed her medical training in neurology at the University Hospital de Santa Maria, and at the National Hospital for Nervous Diseases in London, UK. She was President of the Portuguese Society of Neurology (2008–2010) and President of the Pedagogic Council of the Lisbon Faculty of Medicine (2015–2017). 
                  </p>
                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
