import React from "react"
import {inject, observer} from "mobx-react"
import { AdminHeader } from "../../components/admin/admin-header";
import AdminNav from "../../components/admin/admin-nav";
import Footer from "../../components/_shared/footer-module";
import EventForm from "../../components/admin/event-form";
import EventTimesGrid from "../../components/admin/event-times-grid";
import { ADMIN_USER_ROLE, SUPER_ADMIN_USER_ROLE } from "../../app-config";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { data } from "../../data/data";
import {getAllEvents} from "../../controllers/events-controller" 
import {userService} from "../../services/user-service"
import Loader from "../../components/_shared/loading-div"
 
@inject("rootStore")
@observer
class SelectedEvent extends React.Component {
    constructor(props){
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
            selectedEvent: null,
            users: [],
            loading: true
        }
    }

    static routeName = "SelectedEvent";
    static routePathPattern = "/admin/events/:id";

    async componentDidMount(){
        let role = this.authStore.getRole();
        if(role !== ADMIN_USER_ROLE && role !== SUPER_ADMIN_USER_ROLE){
            this.routerStore.goTo("home");
            return;
        }
        let {params} = this.routerStore.routerState;
        if(!params || Object.keys(params).length < 1){
            this.routerStore.goTo("admin");
            return;
        }
        await this.getSelectedEvent(params.id);

        await this.getAllUsers();

        this.setState({loading: false})
    }
    getAllUsers = async () => {
        let users = await userService.listAllUsers();
        this.setState({users})
    }
    getSelectedEvent = async (eventId) => {
        let selectedEvent = (await getAllEvents()).filter(eventItem => (eventItem.statusId !== data.status.deleted)).find(item => item.id === eventId);

        this.setState({selectedEvent: selectedEvent})
    }
    updateSelectedEvent = (updatedSelectedEvent) => {
        this.setState({selectedEvent: updatedSelectedEvent})
    }
    render(){
        let {selectedEvent, users, loading} = this.state;
        

        return (
            <div>
                <div className="admin header">
                    <span className={`${this.state.isActive ? `${"active"}` : ""}   `} id="menu-action" >      
                        <ViewHeadlineIcon />  
                    </span>
                    <div className="">
                        <AdminHeader authStore={this.authStore} />
                    </div>
                </div>
                <AdminNav />
                {loading ? (
                    <Loader />
                ) : (
                    <div className="main" >
                        <div style={{minHeight: "86vh"}}>
                        <div className="row">
                            {selectedEvent && 
                                <EventForm viewModel={selectedEvent} />
                            }
                        </div>
                        <div className="row">
                            {selectedEvent && 
                                    <EventTimesGrid viewModel={selectedEvent} users={users} EventContext={this} eventType={selectedEvent.type}/>
                            }
                        </div>
                        </div>
                        <Footer />
                    </div>
                )}
            </div>
        )
    }
}
export default SelectedEvent;