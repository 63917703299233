import React from "react";
import {inject, observer} from "mobx-react"
import styles from "./confirmation-successful-mesage.module.css"
@inject("rootStore")
@observer
class ConfirmationSuccessfulMessage extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        let {shouldShow, close} = this.props;

        if(!shouldShow){
            return <React.Fragment/>
        }

        return (
            <React.Fragment>
              <div className={styles.cofirmationMessageWrap}>
                  <div  className={styles.content}>
                  <p>
                Your email address has been confirmed. We will be in contact shortly regarding your registration for Migraine Headache Management 2023
                </p>
                <button className="button" onClick={close}>
                    Close
                </button>
                  </div>
                 
              </div>
            </React.Fragment>
        )
    }
}

export default ConfirmationSuccessfulMessage