import React from 'react';
import { inject, observer } from "mobx-react";
import { AppBar, Tabs, TabPanel} from '@material-ui/core';
import Editor from '../editor/editor';
import EditButton from "../forms/editButton"
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import moment from "moment"
import RegisteredUsersTable from "./registered-users-table"
import EventTimeForm from "./event-time-form"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { isThisHour } from 'date-fns';
import {getActiveEvents} from "../../controllers/events-controller" 
import { data } from "../../data/data";
import { LeakRemoveTwoTone } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

@inject('rootStore') 
@observer
class EventTimesGrid extends React.Component {
    constructor(props) {
        super(props);
        var viewModel = this.removeDeletedDatesFromModel(this.props.viewModel);
        this.state = {
            requiredList: [],
            viewModel: viewModel,
            showLoading: false,
            showEditEventDatesForm: false,
            eventType: this.props.eventType,
            updated: false
        }; 
        this.showOrHideEditEventDatesForm = this.showOrHideEditEventDatesForm.bind(this);
        this.addEventTime = this.addEventTime.bind(this);
        this.onDeleteEventDate = this.onDeleteEventDate.bind(this);
    }
    removeDeletedDatesFromModel(viewModel){
        var newEventDates = [];
        viewModel.eventTimes.forEach((time, index) => {
            if (time.statusId !== 5) {
                newEventDates.push(time);
            }
        });
        viewModel.eventTimes = newEventDates;
        return viewModel;
    }

    updateEventDates = async () => {
        const { viewModel } = this.state;

        let cleanViewModel = Object.assign({}, viewModel);

        cleanViewModel.eventTimes = cleanViewModel.eventTimes.filter(item => item.id !== "");

        this.setState({viewModel: cleanViewModel}, () => {

            var show = this.state.showEditEventDatesForm;
            if (!cleanViewModel?.eventTimes || cleanViewModel.eventTimes.length <= 0){
                cleanViewModel.eventTimes.push({ id: '', date: '', time: '', eventLimit: '', statusId:  1});
                this.setState({ viewModel: cleanViewModel, showEditEventDatesForm: !show});
            }
            else{
                this.setState({ showEditEventDatesForm: !show });
            } 

        })
    }

    async showOrHideEditEventDatesForm(){
        const { viewModel, showEditEventDatesForm } = this.state;

        if(showEditEventDatesForm){
            if(viewModel.eventTimes.find(item => item.id === "")){
                this.props.rootStore.alertStore.setAlert({ type: 'alert-info', message: 'Some event dates are empty. Please fill them in.', onDismiss: () => this.props.rootStore.alertStore.clearAlert() });
                return;
            }else{
                await this.updateEventDates()
            }
        }else{
            this.setState({ showEditEventDatesForm: !showEditEventDatesForm });
        }

    }

    invalidEventTimeExists = (viewModel) => {
        return viewModel.eventTimes.find(item => item.id === "")
    }

    addEventTime(){
        const { viewModel } = this.state;
        if(this.invalidEventTimeExists(viewModel)){
            return;
        }
        viewModel.eventTimes.push({ id: '', date: '', time: '', eventLimit: ''});
        this.setState({ viewModel: viewModel});
    }

    getUpdatedViewModel = async (eventId, eventTimeId) => {
        let viewModel = (await getActiveEvents())?.find(item => item.id === eventId);        
        this.setState({viewModel});
    }

    updateViewModel = async (addedEventTime) => {
        let exists = this.state.viewModel.eventTimes.find(item => item.id === addedEventTime.id)

        if(exists){
            let cleanEventTimes = this.state.viewModel.eventTimes.map(item => {
                if(item.id === addedEventTime.id){
                    return addedEventTime;
                }
                return item;
            });
            this.setState({
                viewModel: {
                    ...this.state.viewModel,
                    eventTimes: [...cleanEventTimes]
                }
            })
        }else{
            let cleanEventTimes = this.state.viewModel.eventTimes.filter(item => item.id !== "");
            this.setState({
                viewModel: {
                    ...this.state.viewModel,
                    eventTimes: [...cleanEventTimes, addedEventTime]
                }
            })
        }
    }
    
    onDeleteEventDate(id){
        const { viewModel } = this.state;

        let eventTimes = viewModel.eventTimes.map((time, index) => {
            if (time.id === id) {
                time.statusId = 5;
                time.dateModified = new Date().toISOString();
            }
            return time;
        });
        let newViewModel = {...viewModel, eventTimes: eventTimes}
        newViewModel = this.removeDeletedDatesFromModel(newViewModel);
        this.setState({ viewModel: newViewModel });
    }
    render() { 
        const { viewModel, showEditEventDatesForm, eventType, showLoading } = this.state;
        const { users } = this.props;
        return (
            <div className='container'>
                <div className="row justify-content-md-center">
                    <div className={` col-12 col-sm-12 col-md-12`} >
                        <div className="row mt-4">
                           <div className="col-12 col-md-7 text-center text-md-left">
                               <h1>Event Dates </h1>
                           </div>
                           <div className="col-12 col-md-5 text-center text-md-right">
                               <button className="button" onClick={this.showOrHideEditEventDatesForm}>
                                    { showEditEventDatesForm ? 'Done ' : 'Edit dates ' } 
                                    <CreateIcon />
                               </button>
                           </div>
                             

                        </div>
                        <hr/>
                        <div className="row mt-4">
                            <div className="col-12">
                            {!showEditEventDatesForm &&
                                <div>
                                    {viewModel && viewModel.eventTimes && viewModel.eventTimes.length > 0 && viewModel.eventTimes.map((eventTime, index)  => {
                        
                                        let eventDate = moment(eventTime.date).format("Do MMMM YYYY");
                                        return (
                                          <div style={{marginBottom: "30px"}} key={index}> <h4>{eventDate} ({eventTime.time}) - {eventTime.speakerName}</h4>
                                                
                                                        <RegisteredUsersTable users={users} eventTime={eventTime} eventId={viewModel.id} context={this} />
                                               </div> 
                                        )
                                    })
                                    }
                                    {!viewModel || !viewModel.eventTimes || viewModel.eventTimes.length <= 0 &&
                                        <p>Event does not have meeting times yet</p>
                                    }
                                </div>
                            }
                            {showEditEventDatesForm && viewModel.eventTimes && viewModel.eventTimes.length > 0 && viewModel.eventTimes.map((eventTime, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <EventTimeForm viewModel={eventTime} eventId={viewModel.id} onDeleteEventDate={this.onDeleteEventDate} EventContext={this.props.EventContext} EventTimesGridContext={this} eventType={eventType} />
                                    </React.Fragment>
                                )
                            })
                            }
                            {showEditEventDatesForm && 
                                <div className={` col-12 col-sm-12 col-md-12`} >
                                    <div onClick={this.addEventTime}>
                                    <Tooltip title="Add new date">
                                        <AddCircleOutlineIcon />
                                    </Tooltip>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }

}

export default EventTimesGrid;
