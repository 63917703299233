import { CircularProgress } from '@material-ui/core'
import React from 'react'
import Footer from './footer-module'
import Header from "./header-module"

export default () => {
    React.useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])
    return (<>
  
        <div className={"text-center"} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            Loading, please wait <CircularProgress size={16} className={"ml-2"} />
        </div>
      
    </>
       
    )
}