import { HmacSHA3 } from 'crypto-js';
import React from 'react';
import styles from "./modal.module.css"

class Modal extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            modal : false,
            loading: false
        }
    }

    onConfirmSendMassEmail = async ()   => {
        this.props.onConfirmSendMassEmail();
    }
   
    render() { 
        let {closeModal, isActive, sending} = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${styles.modal_dialog}`} role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className="modal-body" style={{padding: "0px !important"}}>
                     <h3 className="tect-center m-0">
                         Are you sure you want to send the email to the selected recipients?
                     </h3>
                    </div>
                    <div className={`modal-footer  ${styles.modal_footer}`}>
                            <a className={`button`} onClick={this.onConfirmSendMassEmail}>
                                CONFIRM
                            </a>
                     <a className={`button`} onClick={closeModal}>CANCEL</a>
                </div>
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;