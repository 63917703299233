//key
//sd - self described
import {RouterState, RouterStore} from 'mobx-state-router';
import AppStores from "./app-stores";
import {AuthStore} from "./auth-store";
import {FormStore} from "./form-store";
import {AlertStore} from "./alert-store";
import {routes} from "../routes";
import {ContentStore} from "./content-store";
import { MassEmailsStore } from "./mass-emails-store";
import { UsersStore} from './users-store';
const notFound = new RouterState('notFound');

/**
 * sd
 * by kevinbarasa
 */
export default class RootStore {
    appStores = new AppStores();
    authStore = new AuthStore(this);
    formStore = new FormStore(this);
    alertStore = new AlertStore(this);
    contentStore = new ContentStore(this);
    massEmailsStore = new MassEmailsStore(this);
    usersStore = new UsersStore(this);
    routerStore = new RouterStore(this, routes, notFound);

}
