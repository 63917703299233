//key
//sd - self described
import {action, computed, observable, toJS} from 'mobx';
import {RouterState} from 'mobx-state-router';
import {DEFAULT_ROUTE, HOME_ROUTE} from "../routes";
import {Auth} from "aws-amplify";


const defaultState = new RouterState(HOME_ROUTE.routeName);


/**
 * sd
 * by HashMaloo
 */
export class AlertStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //to assist with differentiation during storage to persistence media
    static namespace = 'FomrStore_';

    rootStore;

  
    @observable alert = {}
    @observable pendingRequests = 0;

    @observable actionConfirmed = {}

    @observable showCookieBanner = false;
 
    // @computed

  
	clearAlert() {
		this.alert = {};
    }
    confirmAction(event){
        this.actionConfirmed = event;
        this.clearAlert();
    }
    
	setAlert(message) {
		this.alert = message;
	}
   
    setShowCookieBanner(value){
        this.showCookieBanner = value;
    }

    @action
    revertStores = () => {
        //TODO >> might not be needed, so leave it empty like this when not needed
    };

}
