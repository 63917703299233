import React from 'react';
import { action, computed, observable, toJS } from 'mobx';
import moment from 'moment';
import 'moment/locale/en-gb';


import { inject, observer } from "mobx-react";


@inject('rootStore')
@observer
class EventsSelectorItem extends React.Component {

    constructor(props) {
        super(props);
   
        this.state = {
           selected:false
        };
     
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {        
      
    }
   
   

    componentWillUnmount() {
        let { context, required, name, id } = this.props

    }
   
   
   
    onChange(value){
        const {context} = this.props;
        this.setState({selected:!this.state.selected});
        this.props.onChange(value); 
        
    }

   
    render() {
        const { event,context} = this.props;
        const { selected} = this.state;


            return <a className={`eventItem ${selected && 'selected'}`} onClick={() => { this.onChange(event.eventId)}}><h2>{event.eventTitle}</h2></a>
       
    }

}

export default EventsSelectorItem;
