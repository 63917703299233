import React from 'react';
import '../../../theme/regular-user.scss';
import {inject, observer} from "mobx-react";
import FormWrapper from "../../../templates/form-wrapper";
import {RouterLink} from 'mobx-state-router';
import Faculty from '../../../components/post-registration/faculty/faculty';


@inject('rootStore')
@observer
class FacultyPage extends React.Component {

    constructor(props) {
        super(props);
    }

    static routeName = 'faculty';
    static routePathPattern = '/faculty';

    componentDidMount(){
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    render() {
        let {authStore, routerStore} = this.props.rootStore;
        return (
            <FormWrapper>
           <div className={`page-wrapper`}>
                <Faculty/>
          

           </div>
           </FormWrapper>
        );
    }

}

export default FacultyPage;
