//TEXT-INPUTS
import {isBoolean, isEmptyArray, isNullUndefined, mimicNaturalStateUpdateBehavior, objectKeyExists} from "../util/util";
import axios from "axios"

/**
 * sd _ kevinbarasa
 * @param model
 * @param key
 * @param activity
 * @returns {*}
 */
export function textValue(model, key, activity) {

    let feedBack = null;

    if (isNullUndefined(model)) {
        return feedBack;
    }

    //model must have key
    if (!objectKeyExists(model, key))
        return feedBack;

    if (isNullUndefined(model[key]))
        return feedBack;

    return model[key];

}

/**
 * sd _ kevinbarasa
 * @param model
 * @param text
 * @param key
 * @param activity
 * @returns {*}
 */
export function textValueChanged(model, text, key, activity) {

    let feedBack = null;

    if (isNullUndefined(model)) {
        return feedBack;
    }

    //model must have key
    if (!objectKeyExists(model, key))
        return feedBack;

    model[key] = text;

    !isNullUndefined(activity) && mimicNaturalStateUpdateBehavior(activity);

    return model[key];

}

//END

//CHECKBOXES & RADIO-BUTTONS
/**
 * sd _ kevinbarasa
 * @param model
 * @param key
 * @returns {boolean}
 */
export function checkboxItemChecked(model, key) {

    let isChecked = false;

    if (isNullUndefined(model)) {
        return isChecked;
    }

    //model must have key
    if (!objectKeyExists(model, key))
        return isChecked;

    if (isBoolean(model[key]) && !model[key])
        return isChecked;

    if (isNullUndefined(model[key]))
        return isChecked;

    isChecked = true;

    return isChecked;

}

/**
 * sd _ kevinbarasa
 * @param model
 * @param checkedTrue
 * @param key
 * @param activity
 * @returns {*}
 */
export function checkboxItemValueChanged(model, checkedTrue, key, activity) {

    let feedback = null;

    if (isNullUndefined(model)) {
        return feedback;
    }

    //model must have key
    if (!objectKeyExists(model, key))
      return feedback;

    if (checkedTrue) {
        // noinspection EqualityComparisonWithCoercionJS
        model[key] = 1;
    } else {
        // noinspection EqualityComparisonWithCoercionJS
        model[key] = null;
    }

    mimicNaturalStateUpdateBehavior(this);

    return model[key];

}

/**
 * sd _ kevinbarasa
 * @param val
 * @param key
 * @param model
 * @returns {boolean}
 */
export function radioButtonSelected(val, key, model) {

    // noinspection EqualityComparisonWithCoercionJS
    if (isNullUndefined(model)) {
        return false;
    }
    if (!objectKeyExists(model, key)) {
        return false;
    }
    if (isNullUndefined(model[key])) {
        return false;
    }
    // noinspection EqualityComparisonWithCoercionJS
    return model[key] == val;

}

/**
 * sd _ kevinbarasa
 * @param checkedTrue
 * @param value
 * @param key
 * @param model
 * @returns {*}
 */
export function radioButtonValueChanged(checkedTrue, value, key, model) {

    let feedback = null;

    if (isNullUndefined(model)) {
        return feedback;
    }

    //model must have key
    if (!objectKeyExists(model, key)) {
        return;
    }

    if (checkedTrue) {
        // noinspection EqualityComparisonWithCoercionJS
        if (model[key] != value) {
            model[key] = value;
            feedback = value;
        }
    } else {
        // noinspection EqualityComparisonWithCoercionJS
        model[key] = null;
    }

    mimicNaturalStateUpdateBehavior(this);

    return feedback;

}

//END

//PICKERS, SPINNERS, SELECTS
export function selectSelectedValue(model, pickerDefaultValue, pickerItemsArray, key) {
    // noinspection EqualityComparisonWithCoercionJS
    return (
        (isNullUndefined(model) || isEmptyArray(pickerItemsArray)) ?
            null :
            (isNullUndefined(pickerItemsArray.find(item => item.value == pickerDefaultValue))) ?
                null :
                (
                    (!objectKeyExists(model, key)) ?
                        pickerDefaultValue :
                        (
                            isNullUndefined(pickerItemsArray.find(item => item.value == model[key])) ?
                                pickerDefaultValue :
                                model[key]
                        )
                )
    );
}

export function selectOnValueChange(activity, model, val, key) {
    if (isNullUndefined(model))
        return;
    if (isNullUndefined(key))
        return;
    if (isNullUndefined(val))
        return;
    if (!objectKeyExists(model, key))
        return;
    model[key] = val;
    mimicNaturalStateUpdateBehavior(activity);
    activity.forceUpdate();
}

export async function getTimezoneFromCountryCode(countryCode){
    const apiUrl = process.env.REACT_APP_ENV === 'production' ? `https://participant.the-corpus.com/umbraco/api/Timezone/GetTimeZonesList?countryCode=${countryCode}` : `https://participant.staging.the-corpus.com/umbraco/api/Timezone/GetTimeZonesList?countryCode=${countryCode}`;

    let result = null;

    try {
        result = await axios({
            method: "get",
            url: apiUrl,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
            }
        });
    }catch(e){
        return [];
    }

    return result?.data || [];
}

// export function generatePickerItems(itemsArray) {
//     let pickerItems = null;
//     !isEmptyArray(itemsArray) && ((() => (pickerItems = itemsArray.map(item => {
//         return (<Picker.Item label={item.text || item.label} value={item.value} key={item.value}/>);
//     })))());
//     return pickerItems;
// }

//END
