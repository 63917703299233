import React from 'react';
import { action, computed, observable, observe, toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import alertStyle from './css/alert.css'
import laodingStyle from './css/loading.css'



@inject('rootStore')
@observer
class Alert extends React.Component {

    constructor(props) {
        super(props);
        this.clearAlert = this.clearAlert.bind(this);
        this.confirmAction = this.confirmAction.bind(this);
        this.state = {
            showDisclaimer: false,
            isActive: false
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { rootStore: { alertStore } } = this.props;
        let alert = toJS(alertStore.alert);

        if (alert.type == 'alert-success' || alert.type == "alert-danger") {
            setTimeout(() => {
                if(!(alertStore?.alert?.onDismiss)){
                    alertStore.clearAlert();
                }
            }, 600000);
        }
    }
    declineHcpDisclaimer = () => {
        const { rootStore: { alertStore } } = this.props;
        this.setState({showDisclaimer: true});
        this.setState({ isActive: true})
        if(alertStore?.alert?.onDismiss){
            alertStore.alert.onDismiss(alertStore?.alert?.data);

        }
    }
    clearAlert() {
        const { rootStore: { alertStore } } = this.props;
        if(alertStore?.alert?.onDismiss){
            alertStore.alert.onDismiss(alertStore?.alert?.data);
        }
        alertStore.clearAlert();
    }
    confirmAction() {
        const { rootStore: { alertStore } } = this.props;
        alertStore.alert.onConfirm(alertStore.alert.data);
        alertStore.confirmAction();
    }
    render() {

        const { rootStore: { alertStore } } = this.props;
        const {showDisclaimer} = this.state;
        let alert = alertStore.alert;
        return (
            <div>


                {alert.message &&
                    <div>
                        <div className=""></div>


                        <div className={`pop-up-wrap `}>
                            {alert.type != 'alert-request' &&
                                <div>

                                    <div className="pop-up-content">

                                        {alert.type == 'alert-success' &&
                                            <h4>
                                                
                                                <div className="message-body">
                                                    {alert.message}
                                                </div>
                                            </h4>

                                        }
                                        {alert.type == 'alert-danger' &&
                                            <h4 >
                                    
                                                <div className="message-body">
                                                {alert.message?.message || alert.message}
                                                </div>
                                            </h4>

                                        }
                                        {
                                            alert.type == 'alert-warning' || alert.type == 'alert-info' &&
                                            <h4>
                                               <div className="message-body">
                                                    {alert.message}
                                                    {alert.isHcpDisclaimerPopUp  && showDisclaimer && <> <br/><br/> Sorry, you cannot proceed to view this website as it is intended for use by Healthcare Professionals only.</> }
                                                </div>
                                            </h4>
                                        }
                                        {alert.isHcpDisclaimerPopUp &&
                                            <p className={this.state.isActive === true ? "hide" : " "}>
                                            
                                            {alert.type == 'alert-info' && alert.onConfirm && 
                                                <a className="btn btn-success" onClick={this.confirmAction}>{alert.confirmText?  alert.confirmText : "Confirm"}</a>
                                            }

                                            { (alert.type == 'alert-info' && alert.onConfirm) ?
                                                <a className="btn btn-secondary" onClick={this.declineHcpDisclaimer}>{alert.declineText ?  alert.declineText : "Dismiss"}</a>
                                            :
                                            <a className="btn btn-secondary" onClick={this.clearAlert}>Dismiss</a>

                                            }

                                            </p>
                                        }
                                        {!alert.isHcpDisclaimerPopUp &&
                                            <p className=''>
                                            { (alert.type == 'alert-info' && alert.onConfirm) ?
                                                <a className="btn btn-secondary" onClick={this.clearAlert}>{alert.declineText ?  alert.declineText : "Dismiss"}</a>
                                            :
                                            <a className="btn btn-secondary" onClick={this.clearAlert}>Dismiss</a>

                                            }

                                            {alert.type == 'alert-info' && alert.onConfirm && 
                                                <a className="btn btn-success" onClick={this.confirmAction}>{alert.confirmText?  alert.confirmText : "Confirm"}</a>
                                            }
                                            </p>
                                        }
                                    </div>

                                </div>
                            }
                            {alert.type == 'alert-request' && <div><div className="loader"></div>{alert.message}</div>}

                        </div>
                    </div>

                }
            </div>

        );
    }

}
export default Alert;
