import { inject, observer } from "mobx-react";
import React from 'react';
import EACIC_Logo from '../../../media/images/EACIC_Full-logo.jpg';
import PracticalWorkshop from "../../post-registration/programme/programmeOverviewSection/programmeOverview";
import MigraineMasterclass from "./migraineMasterclass/migraineMasterclass"
import MigraineExperForum from "./migraineExpertForum/migraineExpertForum"
@inject('rootStore')
@observer
class LoggedInProgramme extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = "Programme | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES"
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    render() {
        return (
            <div className={`page-wrapper`}>
                
               <PracticalWorkshop/>
                {/* <MigraineMasterclass/> */}
                {/* <MigraineExperForum/> */}
            </div>
        );
    }

}

export default LoggedInProgramme; 