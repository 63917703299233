//key
//sd - self described
import {action, computed, observable, toJS} from 'mobx';


/**
 * sd
 * by HashMaloo
 */
export class UsersStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //to assist with differentiation during storage to persistence media
    static namespace = 'UsersStore';

    rootStore;

  
    @observable users = null
 
    // @computed

    @computed get report(){
        if (this.users.length === 0)
            return "<none>";
            
        return this.users;
    }
	addUsers(usersList) {
        this.users= usersList;
	}

    @action
    revertStores = () => {
    };

}
