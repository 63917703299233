import React from "react"
import styles from "./webcast.module.css"
import WebcastModal from "./WebcastModal/modal"
import PatriciaWebcastModal from "./WebcastModal/patricia_webcast"

import WebcastImg from "../../../../media/images/webcast.png"
class Webcast extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            videoLink: null
        }
    }
    openModal = (type, videoLink) => {
        this.disableScroll()
        switch(type){
            case "modal": 
            this.setState({
                modal: true,
                videoLink
            })
            break;
            case "patriciaModal": 
            this.setState({
                patriciaModal: true,
                videoLink
            })
            break;
          
            
            default: 
                 return 
        }
     
       
    }
    
    
    closeModal = (type) => {
        this.enableScroll()
        switch(type){
            case "modal": 
            this.setState({
                modal: false,
                videoLink: null
            })     
            break;
            case "patriciaModal": 
            this.setState({
                patriciaModal: false,
                videoLink: null
            })     
            break;
            
            default: 
                 return 
        }
     
       
    }
    disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    enableScroll = () => {
      
        document.body.style.overflow = 'auto'
    }

    render(){
        let {modal,patriciaModal, videoLink} = this.state;
        return( 
            <div className={`${styles.webcast_wrap}`}>  
                {/* POP-UP */}
                
                <PatriciaWebcastModal closeModal={() => this.closeModal("patriciaModal")} isActive={patriciaModal} videoLink={videoLink} />

                <WebcastModal closeModal={() => this.closeModal("modal")} isActive={modal} videoLink={videoLink} />
                {/* END POP-UP */}
            <div className="row justify-content-center mb-4">
                <div className={`col-12 col-sm-12 col-md-6 col-lg-3 ${styles.webcast}`}>
                    <img alt="webcast" src={WebcastImg}/>
                    <h1>Webcasts</h1>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <p>15-20 minute video presentations by migraine experts on topics of interest.</p>
                </div>
            </div>

            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                    <p style={{color:'#5c1284'}}><strong>Zaza Katsarava</strong></p>

                    <h3>Patient identification: </h3>
                        
                    <h3>Who most needs our help? </h3>
                    <p>
                    Discover which patients are impacted most by migraine and the treatment gaps that must be overcome to improve patient outcomes
                    </p>
                    <p>
                        <button className="btn" onClick={() => this.openModal("modal", "https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Katsarava_Patient_Identification_Video.mp4")}>play</button>

                    </p>
                </div>
            </div>

            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                    <p style={{color:'#5c1284'}}><strong>Jan Hoffmann</strong></p>
                    <h3>At the bench:</h3>
                    <h3>What is happening in migraine research?</h3>
                    <p>
                    Uncover the latest research findings that are underpinning new treatment approaches for migraine
                    </p>
                    <p>
                    <button className="btn" onClick={() => this.openModal("modal", "https://mhm-storage-bucket134336-dev.s3-eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_At_the_Bench_Hoffmann_Video.mp4")}>play</button>
                    </p>
                </div>
            </div>

            <div className={`row ${styles.has_custom_border}`}>
                <div className="col-12">
                    <p style={{color:'#5c1284'}}><strong>Patricia Pozo-Rosich</strong></p>
                    <h3>Outsmarting migraine with new treatment approaches</h3>
                    <p>
                    Find out how scientific innovations in our understanding of migraine have led to solutions that improve clinical practice
                    </p>
                    <p>
                    <button className="btn" onClick={() => this.openModal("patriciaModal", "https://mhm-storage-bucket134336-dev.s3.eu-west-1.amazonaws.com/assets/migraine-education-on-demand-videos/MHM2021_Pozo-Rosich_Outsmarting_Migraine_Video.mp4")}>play</button>
                    </p>
                </div>
            </div>

            </div>   
        )
    }
}

export default Webcast;