import React from 'react';
import styles from "./modal.module.css"
import ProfilePhoto from "../../../../../media/images/faculty/francesca-puledda-yellow.png"
class Modal extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { closeModal, isActive } = this.props;
        let newClass = isActive ? `${styles.active}` : ""
        return (

            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>

                        <div className={`modal-body ${styles.modalbody}`}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-4 text-center">
                                    <img alt="Hans photo" src={ProfilePhoto} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-8">
                                    <h3>Francesca Puledda, MD, PhD
                                    </h3>
                                    <p style={{ color: "#f61a19" }}> London, UK
                                    </p>
                                    <p>
                                        Francesca Puledda is a neurologist and senior clinical research fellow in the Headache Group at King’s College London, UK. She earned her MD at the Sapienza University of Rome, Italy, where she also completed her specialization in neurology in 2016.<br className="hide-for-devices"/> In 2020 she earned a PhD in neuroscience from King’s College London, with a research project aimed at understanding the neurophysiology of visual snow syndrome, an important comorbidity of migraine.
                                    </p>
                                    <p>
                                        Dr Puledda has been actively involved in headache research since 2010. Her main interests include the pathophysiology of migraine and its comorbidities, neuroimaging, neuromodulation and the neurophysiology of primary headache disorders. She is particularly involved in the work of international societies for the study of headache, such as the International Headache Society and European Academy of Neurology.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className={`modal-footer ${styles.modal_footer}`}>
                            <button className={`button ${styles.button}`} onClick={closeModal}>CLOSE</button>
                        </div>

                    </div>


                </div>
            </div>
        );
    }
}

export default Modal;