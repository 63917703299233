//key
//sd - self described
import {action, computed, observable, toJS} from 'mobx';
import {RouterState} from 'mobx-state-router';
import {DEFAULT_ROUTE, HOME_ROUTE} from "../routes";
import {Auth} from "aws-amplify";



/**
 * sd
 * by HashMaloo
 */
export class FormStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    //to assist with differentiation during storage to persistence media
    static namespace = 'FomrStore_';

    rootStore;

  
    @observable forms = []
    @observable isInvalid = false;
    @observable formStepsValidity = [];
    @observable pendingRequests = 0;
    @observable submittingForm = false;
    @observable emailOfUserSigningUp = '';
    // @computed

    @computed get report(){
        if (this.forms.length === 0)
            return "<none>";
            
        return this.forms;
    }
	addForm(viewModel) {
		this.forms.push({
			viewModel: viewModel,
		
		
		});
	}
    setInvalid(value){
        this.isInvalid = value;
    }
    setFormStepValid(index, value){
        this.formStepsValidity[index] = value;
    }
setSubmittingForm(value){
    this.submittingForm = value;
}
setEmailOfUserSigningUp(value){
    this.emailOfUserSigningUp = value;
}

    @action
    revertStores = () => {
        //TODO >> might not be needed, so leave it empty like this when not needed
    };

}
