import React from "react";
import styles from "./modal.module.css";
import ProfilePhoto from "../../../../../media/images/faculty/andreas-gantenbein-red.png";
class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { closeModal, isActive } = this.props;
    let newClass = isActive ? `${styles.active}` : "";
    return (
      <div
        className={`modal ${styles.modal} ${newClass} `}
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className={`custom-modal-content ${styles.modal_content}`}>
            <div className={`modal-body ${styles.modalbody}`}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 text-center">
                  <img alt="Roberto De Icco photo" src={ProfilePhoto} />
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <h3>Andreas Gantenbein, MD, PhD</h3>
                  <p style={{ color: "#f61a19" }}>Bülach, Switzerland</p>
                  <p>
                  Andreas Gantenbein is a private practitioner in Bülach, Switzerland. His main research focus lies in clinical studies, including medication overuse headache, menstrual migraine and CGRP pathway antibodies. Prof. Gantenbein has authored more than 100 articles in peer-reviewed journals. He has authored and co-authored several book chapters, and is a reviewer for several neurological journals. He is Past President of the Swiss Headache Society. 
                  </p>
                  <p>
                  Prof. Gantenbein started as a clinical research fellow in the Headache Group of Prof. Peter J Goadsby at the National Hospital for Neurology and Neurosurgery, London, UK. His main research focus was on electrophysiological correlates in migraine. Following his return to Switzerland he started a broad clinical training in neurology, including electroencephalography, electroneuromyography, sleep diagnostics and pain medicine. From 2010 to 2012, he was Head of the Headache and Pain Unit at the University Hospital of Zurich, which was founded in 1966 by Hansruedi Isler. From 2012 he continued his research and clinical work at the rehabilitation centre RehaClinic Bad Zurzach, and from 2014 to 2021 he was Chief Physician of Neurology there. In 2021 he moved part-time to private practice.

                  </p>
                </div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                className={`button ${styles.button}`}
                onClick={closeModal}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
