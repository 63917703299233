import React from "react";
import {Auth} from "aws-amplify";
import {inject, observer} from "mobx-react"
import styles from "../_shared/event-signup-modal.module.css"
import moment from "moment"
import Editor from "../editor/editor"
import {getTimezoneFromCountryCode} from "../../controllers/utility-controls"
import {RouterLink} from "mobx-state-router"
import {userService} from "../../services/user-service"
import { getCountryCodeFromCountryName } from "../../util/util";

@inject("rootStore")
@observer
class EventSignupModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            timezone: "",
            timezoneList: [],
            requiredList: []
        }
    }

    async componentDidMount(){
        const {user} = this.props;
        await this.getAllUserTimezones(user);
    }

    getAllUserTimezones = async (user) => {

        const {timeZoneFromUser} = this.props;

        if(timeZoneFromUser !== undefined && timeZoneFromUser !== null && timeZoneFromUser !== ""){
            this.setState({timezoneList: [{text: timeZoneFromUser, value: timeZoneFromUser, optgroup: ""}], timezone: timeZoneFromUser});
            return;
        }

        let countryCode = user?.Attributes?.find(attribute => attribute.Name === "custom:countryCode")?.Value;
        if(!countryCode){
            let country = user?.Attributes?.find(attribute => attribute.Name === "custom:country")?.Value;
            countryCode = getCountryCodeFromCountryName(country);
        }
        let timezones = await getTimezoneFromCountryCode(countryCode);

        let timezoneList = timezones.filter(zone => zone.Value !== "").map(item => {
            return {
                text: item?.Value || "",
                value: item?.Value || "",
                optgroup: ""
            }
        })

        this.setState({timezoneList}, () => {
            if(timezoneList.length === 1){
                this.setState({timezone: timezoneList[0]})
            }
        })

    }

    onChange = (name, value) => {
        this.setState({[name]: value})
    }

    confirm = async () => {
        const {timezone} = this.state;
        const {close} = this.props;
        const {timezoneSelector} = this.props.context.state;
        if(timezone !== ""){
            close();
            await timezoneSelector.confirm(timezone)
        }
    }

    render(){
        let {shouldShow, close} = this.props;
        const {timezone, timezoneList} = this.state;

        if(!shouldShow){
            return <React.Fragment/>
        }

        return (
            <div className={styles.confirmationMessageWrap}>
            <div  className={styles.content}>
                <h4>Please {timezoneList.length === 1 ? "confirm" : "select"} the user's timezone</h4>
                <Editor type={"select"} onChange={this.onChange} placeholder="Timezone" name={'timezone'} value={timezone} options={timezoneList} />
                <button className="button mr-1" onClick={this.confirm}>
                    Confirm
                </button>
                <button className="button" onClick={close}>
                    Cancel
                </button>
            </div>
             
          </div>
        )

    }
}

export default EventSignupModal