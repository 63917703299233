import React from 'react';
import { inject, observer } from "mobx-react";
import { Auth } from "aws-amplify";
import FormWrapper from "../templates/form-wrapper";
import MySessions from "../components/meetings/my-sessions"

@inject('rootStore')
@observer

class MyProfile extends React.Component {

    constructor(props) {
        super(props);
        this.authStore = this.props.rootStore.authStore;
        this.routerStore = this.props.rootStore.routerStore;
        this.state = {
          
            
        };

    }
    static routeName = 'myProfile';
    static routePathPattern = '/my-session-slots';

  


    render() {
      
            // return 
            return <>
                <FormWrapper>
                    <MySessions />
                </FormWrapper>
               
            </>
     
        
      
    }

}

export default MyProfile;
