import React from 'react';
import styles from "../seeAgendaModal/modal.module.css"
import { inject, observer } from "mobx-react";
@inject('rootStore')
@observer
class Modal extends React.Component {
    constructor (props){
        super(props);
      this.routerStore = this.props.rootStore.routerStore;
      this.state = {
        agendaModal: false,
        expertSessionModal: false,
        migraineExpertForumModal: false,
        isAuthenticated: false

      }
    }
  async componentDidMount() {

    let { authStore } = this.props.rootStore;
    let isAuthenticated = await authStore.isAuthenticated();
    this.setState({ isAuthenticated })
  }
    render() { 
      let { closeModal, isActive, isAuthenticated} = this.props;
    
        let newClass = isActive ? `${styles.active}` : ""
        return ( 
            
            <div className={`modal ${styles.modal} ${newClass} `} id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`custom-modal-content ${styles.modal_content}`}>
                   
                    <div className="modal-body">
                        <h3>
                    Day 1 – Friday 4 October, 14:00 – 18:00 CEST


                        </h3>
 {/* MOBILE LAYOUT  */}
                  <div className="hide_large">
                  <table className="table table-sm table-bordered ">
                  
                    <tr>
                      <th> Time (CEST) </th>
                        <td> <strong>14:00–14:10</strong> </td>
                    </tr>
                   
                    <tr>
                      <th > Session and description </th>
                        <td><strong> Welcome and introduction</strong>	</td>
                    </tr>

                    <tr>
                      <th>Faculty</th>
                      <td>Peter J Goadsby</td>
                    </tr>
                  
                  </table>

                    <table className="table table-sm table-bordered ">

                      <tr>
                        <th > Time (CEST) </th>
                        <td className='mobile-td1'> <strong>14:10–14:40</strong> </td>
                      </tr>

                      <tr>
                        <th > Topic </th>
                        <td className='mobile-td1'> <strong>Plenary 1 – Migraine management in an ever-changing world</strong> <br /><br />
                        <ul style={{ margin: "0", padding: "0 15px" }}>
                          <li>Improving the quality of life of migraine patients in 2024</li>
                          <li>Keeping up with the evolving treatment landscape and evidence-based practice</li>
                        </ul>
                        </td>

                      </tr>

                      <tr>
                        <th>Faculty</th>
                        <td >
                          Moderators: Messoud Ashina <br/> + Cristina Tassorelli <br/> <br/>
                          Speakers: Isabel Pavão Martins + Piero Barbanti
                        </td>
                    </tr>
                     
                    </table>
                    
                    <table className="table table-sm table-bordered ">

                      <tr>
                        <th > Time (CEST) </th>
                        <td className='mobile-td1'> <strong>14:40–15:10</strong> </td>
                      </tr>

                      <tr>
                        <th > Topic </th>
                        <td className='mobile-t1'><strong>Plenary 2 – Migraine and mental health</strong><br /><br />
                        <ul style={{ margin: "0", padding: "0 15px" }}>
                          <li>Managing patients with migraine and different psychiatric comorbidities</li>
                        </ul>
                        </td>
                      </tr>

                      <tr>
                        <th>Faculty</th>
                        <td>Piero Barbanti + <br/>
                        Edoardo Caronna</td>
                      </tr>
                    
                    </table>

                    <table className="table table-sm table-bordered ">

                      <tr>
                        <th > Time (CEST) </th>
                        <td > <strong>15:15–15:45</strong> </td>
                      </tr>

                      <tr>
                        <th > Topic </th>
                        <td className='mobile-td1'> <strong>Interactive workshop session 1 </strong> (choose from 6 options)   <br /><br />
                        <ol style={{ margin: "0", padding: "0 15px" }}>
                          <li>Long-term safety and tolerability of CGRP pathway targeting treatments</li>
                          <li>Acute migraine: treatment landscape and current challenges</li>
                          <li>Long-term effectiveness of CGRP pathway targeting treatments</li>
                          <li>Treatment response in migraine management</li>
                          <li>Managing patients with migraine and medication overuse</li>
                          <li>Neuromodulation and nerve blocks for migraine</li>
                        </ol> 	
                        </td>

                      </tr>

                      <tr>
                        <th>Faculty</th>
                        <td className='mobile-td2'>
                          <ol style={{ margin: "0", padding: "0 15px" }}>
                            <li>Peter J Goadsby + <br/>
                              Bianca Raffaelli
                            </li>
                            <li>Uwe Reuter + <br/>
                              Cristina Tassorelli
                            </li>
                            <li>Messoud Ashina + <br/>
                            Andreas Gantenbein</li>
                            <li>Piero Barbanti + <br/>
                            Roberto De Icco</li>
                            <li>Gisela Terwindt + <br />
                            Antonio Russo</li>
                            <li>Isabel Pavão Martins + <br/> Francesca Puledda</li>
                          </ol>
                        </td>
                      </tr>
                    </table>

                    <table className="table table-sm table-bordered ">
                      <tr>
                        <th > Time (CEST) </th>
                        <td> <strong>15:45–16:00</strong> </td>
                      </tr>
                      <tr>
                        <th > Topic </th>
                        <td> <strong><i>Break</i></strong></td>
                      </tr>
                    
                    </table>
                    <table className="table table-sm table-bordered ">
                      <tr>
                        <th > Time (CEST) </th>
                        <td> <strong>16:00–16:30</strong> </td>
                        <td></td>
                      </tr>
                      <tr>
                        <th > Topic </th>
                        <td><strong>Interactive workshop session II</strong> (second opportunity to attend a workshop) 	</td>
                        <td></td>
                      </tr>
                    
                    </table>

                    <table className="table table-sm table-bordered ">

                      <tr>
                        <th> Time (CEST) </th>
                        <td> <strong>16:35–17:20</strong> </td>
                      </tr>

                      <tr>
                        <th> Topic </th>
                        <td><strong>Plenary 3 – Understudied patient profiles </strong><br /><br />
                        <ul style={{ margin: "0", padding: "0 17px" }}>
                          <li>Management of patients with migraine taking concomitant therapies and polytherapy </li>
                          <li>Treating migraine in older patients with cardiovascular risk </li>
                          <li>Managing migraine in younger patients </li>
                        </ul>
                        </td>
                      </tr>

                      <tr>
                        <th>Faculty</th>
                        <td>
                          Case 1: Antonio Russo <br/>
                          Case 2: Gisela Terwindt <br/>
                          Case 3: Edoardo Caronna <br/>
                        </td>
                      </tr>
                    
                    </table>

                    <table className="table table-sm table-bordered ">

                      <tr>
                        <th > Time (CEST) </th>
                        <td> <strong>17:20–17:50</strong> </td>
                      </tr>

                      <tr>
                        <th > Session and description </th>
                        <td> <strong>Plenary 4 – Time, biology and migraine</strong><br /><br />
                        <ul style={{ margin: "0", padding: "0 17px" }}>
                          <li>Hormonal influences in migraine</li>
                          <li>Long-term management of migraine</li>
                        </ul>
                        </td>

                        
                      </tr>

                      <tr>
                        <th>Faculty</th>
                        <td>Gisela Terwindt + <br/>
                          Bianca Raffaelli
                        </td>
                      </tr>
                    
                    </table>

                    <table className="table table-sm table-bordered ">

                      <tr>
                        <th > Time (CEST) </th>
                        <td> <strong>17:50–18:00</strong> </td>
                      </tr>

                      <tr>
                        <th > Topic </th>
                        <td>Close of Day 1</td>
                        
                      </tr>

                      <tr>
                        <th>Faculty</th>
                        <td>Messoud Ashina</td>
                      </tr>
                     
                    </table>

               
                  </div>

                  { /* END MOBILE LAYOUT */}
                  {/* DESKTOP VIEW */}
                  <div className="hide_small">
                    <table className="table table-sm table-bordered " >
                    <thead>
                                <tr>
                      
                                
                        <th style={{ width: "50px" }}>Time (CEST)</th>
                        
                               
                                <th style={{width: "300px"}} scope="col">Session and description</th>
                                <th style={{width: "50px"}} scope="col">Faculty</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr>
                                  <td> <strong>14:00–14:10</strong> </td>
                                  <td><strong> Welcome and introduction</strong>		</td>
                                  <td>Peter J Goadsby</td>
                                </tr>
                                <tr>
                                  <td> <strong>14:10–14:40</strong> </td>
                                  <td> <strong>Plenary 1 – Migraine management in an ever-changing world</strong> <br /><br />
                                  <ul style={{ margin: "0", padding: "0 18px" }}>
                                    <li>Improving the quality of life of migraine patients in 2024</li>
                                    <li>Keeping up with the evolving treatment landscape and evidence-based practice</li>
                                  </ul>
                                  </td>
                                  <td>
                                    Moderators: Messoud Ashina + Cristina Tassorelli <br/> <br/>
                                    Speakers: Isabel Pavão Martins + Piero Barbanti
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>14:40–15:10</strong></td>
                                  <td><strong>Plenary 2 – Migraine and mental health</strong><br /><br />
                                  <ul style={{ margin: "0", padding: "0 18px" }}>
                                    <li>Managing patients with migraine and different psychiatric comorbidities</li>
                                  </ul>
                                  </td>
                                  <td>Piero Barbanti + Edoardo Caronna</td>
                                  
                                </tr>

                                <tr>
                                  <td> <strong>15:15–15:45</strong> </td>
                                  <td> <strong>Interactive workshop session 1 </strong> (choose from 6 options)   <br /><br />
                                  <ol style={{ margin: "0", padding: "0 15px" }}>
                                    <li>Long-term safety and tolerability of CGRP pathway targeting treatments</li>
                                    <li>Acute migraine: treatment landscape and current challenges</li>
                                    <li>Long-term effectiveness of CGRP pathway targeting treatments</li>
                                    <li>Treatment response in migraine management</li>
                                    <li>Managing patients with migraine and medication overuse</li>
                                    <li>Neuromodulation and nerve blocks for migraine</li>
                                  </ol> 	
                                  </td>
                                  <td>
                                  <ol style={{ margin: "0", padding: "0 15px" }}>
                                    <li>Peter J Goadsby + 
                                      Bianca Raffaelli
                                    </li>
                                    <li>Uwe Reuter + 
                                      Cristina Tassorelli
                                    </li>
                                    <li>Messoud Ashina + 
                                    Andreas Gantenbein</li>
                                    <li>Piero Barbanti + 
                                    Roberto De Icco</li>
                                    <li>Gisela Terwindt + 
                                    Antonio Russo</li>
                                    <li>Isabel Pavão Martins + Francesca Puledda</li>
                                  </ol>
                                  </td>
                                </tr>
                                <tr>
                                  <td> <strong>15:45–16:00</strong> </td>
                                  <td> <strong><i>Break</i></strong></td>
                                  <td></td>
                                </tr>


                                <tr>
                                  <td> <strong>16:00–16:30</strong> </td>
                                  <td><strong>Interactive workshop session II</strong> (second opportunity to attend a workshop) 	</td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td> <strong>16:35–17:20</strong> </td>
                                  <td><strong>Plenary 3 – Understudied patient profiles </strong><br /><br />
                                  <ul style={{ margin: "0", padding: "0 18px" }}>
                                    <li>Management of patients with migraine taking concomitant therapies and polytherapy </li>
                                    <li>Treating migraine in older patients with cardiovascular risk </li>
                                    <li>Managing migraine in younger patients</li>
                                  </ul>
                                  </td>
                                  <td>
                                  Case 1: Antonio Russo <br/>
                                  Case 2: Gisela Terwindt <br/>
                                  Case 3: Edoardo Caronna <br/>

                                  </td>
                                  
                                </tr>

                                <tr>
                                  <td> <strong>17:20–17:50</strong> </td>
                                  <td> <strong>Plenary 4 – Time, biology and migraine</strong><br /><br />
                                  <ul style={{ margin: "0", padding: "0 18px" }}>
                                    <li>Hormonal influences in migraine</li>
                                    <li>Long-term management of migraine</li>
                                  </ul>
                                  </td>
                                  <td>Gisela Terwindt + 
                                    Bianca Raffaelli
                                  </td>
                                  
                                </tr>

                                <tr>
                                  <td> <strong>17:50–18:00</strong> </td>
                                  <td><strong>Close of Day 1</strong></td>
                                  <td>Messoud Ashina</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                          {/* END DESKTOP VIEW  */}
                                            
                    </div>
                    <div className={`modal-footer`}>
                    <button className={`button`} onClick={closeModal}>CLOSE</button>
                </div>
                    </div>
               
               
                </div>
            </div>
         );
    }
}
 
export default Modal;