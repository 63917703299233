import React from 'react';
import '../../../theme/regular-user.scss';
import { inject, observer } from "mobx-react";
import FormWrapper from "../../../templates/form-wrapper";
import { RouterLink } from 'mobx-state-router';
import Contact from "../../../components/_shared/contact/contact"
import ContactLoggedIn from "../../../components/_shared/contact/contact-logged-in"
import {Auth} from "aws-amplify";


import Banner from "../../../components/public/home/banner/banner"
@inject('rootStore')
@observer
class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            user: "",
            trackPageView: null
        }
    }

    static routeName = 'Contact';
    static routePathPattern = '/Contact';
    async componentDidMount() {
        const pageTitle = "Contact us | Migraine Headache Management | PATIENTS, TREATMENTS, CHOICES";
        document.title = pageTitle;

      


        let user = null;
        try{
            user = await Auth.currentAuthenticatedUser();

        }catch(e){

        }
        this.setState({user})
    }
    render() {
        const {user} = this.state;

        return (
            <div style={{background: "#e7e6eb"}}>
    
            <FormWrapper >
                <div className={`page-wrapper`} style={{overflow: "hidden"}}>
                     <Banner/>
                     {user ? <ContactLoggedIn/> :
                        <Contact />
                     }

                </div>
            </FormWrapper>
            </div>
        );
    }

}

export default ContactUs;
