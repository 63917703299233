import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import {ReactAppConfigurations} from './app/util/config';

export function configureAmplifyForS3() {
  Amplify.configure( 
  {
   Auth: {
     identityPoolId: ReactAppConfigurations.identityPoolId,
     region: ReactAppConfigurations.region,
     userPoolId: ReactAppConfigurations.userPoolId,
     userPoolWebClientId: ReactAppConfigurations.userPoolWebClientId,
    },
  Storage: { 
     bucket: ReactAppConfigurations.bucket,
     region: ReactAppConfigurations.bucketRegion,
     identityPoolId: ReactAppConfigurations.identityPoolId
    }
  }
 );
}
//Configure Storage with default S3 bucket information
export function setS3ConfigForDefaultBucket(){
   Storage.configure({ 
          bucket: ReactAppConfigurations.region,
          level: ReactAppConfigurations.defaultProtectionLevel,
          region: ReactAppConfigurations.region,  
          identityPoolId: ReactAppConfigurations.identityPoolId 
       });
}

//Configure Storage with S3 bucket information
export function setS3Config(bucket, level){
    Storage.configure({ 
           bucket: bucket,
           level: level,
           region: ReactAppConfigurations.region,  
           identityPoolId: ReactAppConfigurations.identityPoolId 
        });
 }